import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Modal, notification } from 'antd'
import { useDispatch } from 'react-redux';
import { addCustomer } from '../../../redux/Customer';
import { addAddress } from '../../../redux/Address';
import { addPhone } from '../../../redux/Phone';
import { addImage } from '../../../redux/Image';
import { addOldLoan } from '../../../redux/OldLoan';
import { addCar } from '../../../redux/Car';
import { addLoan } from '../../../redux/Loan';
import { addLand } from '../../../redux/Land';
import { addLoanLand } from '../../../redux/LoanLand';
import { addOldLoanLand } from '../../../redux/OldLoanLand';
import { addGuarantor } from '../../../redux/Guarantor';
import { addCareerIncome } from '../../../redux/CareerIncome';
import { addOccupation } from '../../../redux/Occupation';
import { addEmail } from '../../../redux/Email';
import { addSocial } from '../../../redux/Social';

import SendUploadImg from './SendUploadImg';
import SendEditCustomer from './SendEditCustomer';
import SendEditLand from './SendEditLand';
import SendGuarantor from './SendGuarantor';
import SendOcc from './SendOcc';
import SendDetail from './SendDetail';
import { findlandpn, showrelationship1 } from '../../file_mid/all_api';

export default function SendModalEditer({ open, close, dataFromTable }) {

    const [count, setCount] = useState('1')
    const dispatch = useDispatch()
    const [api, contextHolder] = notification.useNotification();
    // const customers = useSelector(state => state.customers)

    const [newDataLand, setNewDataLand] = useState([]);
    const [relationship, setRelationship] = useState([])

// console.log('relationship',relationship);

    useEffect(() => {
        if (relationship.length === 0) {
            loadRelationship();
        }
        loadDataNew()
    }, [count === '1' || count === '6' ])


    const handleCancel = () => {
        close(false);
        dispatch(addCustomer())
        dispatch(addAddress())
        dispatch(addPhone())
        dispatch(addCar())
        dispatch(addGuarantor())
        dispatch(addCareerIncome())
        dispatch(addLoan())
        dispatch(addOldLoan())
        dispatch(addImage())
        dispatch(addLand())
        dispatch(addLoanLand())
        dispatch(addOldLoanLand())
        dispatch(addOccupation())
        dispatch(addEmail())
        dispatch(addSocial())
    };

    const loadRelationship = async () => {
        await axios.get(showrelationship1)
            .then(res => {
                if (res.data) {
                    setRelationship(res.data)
                }
            })
            .catch((err) => console.log(err))
    }

    const NotiSuccess = (placement) => {
        api.success({
            message: <b>แก้ไขข้อมูลแล้ว</b>,
            placement,
        });
    }
    const NotiError = (placement) => {
        api.error({
            message: <b>แก้ไขข้อมูลส่วนไม่สำเร็จ</b>,
            placement,
        });
    }
    // const NotiErrorIMG = (placement) => {
    //     api.warning({
    //         message: <b>ไม่พบรูปภาพ</b>,
    //         placement,
    //     });
    // }
    const AddImgSucc = (placement) => {
        api.success({
            message: <b>เพิ่มรูปภาพแล้ว</b>,
            placement,
        });
    }
    const DeleteImgSucc = (placement) => {
        api.success({
            message: <b>ลบรูปภาพแล้ว</b>,
            placement,
        });
    }
    const DeleteImgError = (placement) => {
        api.error({
            message: <b>ลบรูปภาพไม่สำเร็จ</b>,
            placement,
        });
    }

    const loadDataNew = async () => {
        await axios.post(findlandpn, { landLoanId: dataFromTable.landLoanId })
            .then(res => {
                if (res.status === 200) {
                    setNewDataLand(res.data)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    return (
        <div>
            <Modal open={open}
                closable={false}
                //onCancel={handleCancel}
                width={1000}
                footer={null}>
                {/* <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Radio.Group disabled value={count} onChange={(e) => setCount(e.target.value)} block>
                            <Radio.Button value="1">รูปผู้ขอกู้</Radio.Button>
                            <Radio.Button value="2">ข้อมูลส่วนตัว</Radio.Button>
                            <Radio.Button value="3">รายละเอียด</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row> */}
                {/* <Card> */}
                {count === '1' && (<SendUploadImg onCancel={handleCancel} changepage={setCount} notification={NotiSuccess} notification2={NotiError} newDataLand={newDataLand} />)}
                {count === '2' && (<SendEditCustomer onCancel={handleCancel} changepage={setCount} notification={NotiSuccess} notification2={NotiError} />)}
                {count === '3' && (<SendEditLand onCancel={handleCancel} changepage={setCount} editData={NotiSuccess} AddImgSucc={AddImgSucc} DeleteImgSucc={DeleteImgSucc} DeleteImgError={DeleteImgError} />)}
                {count === '4' && (<SendGuarantor onCancel={handleCancel} changepage={setCount} notification={NotiSuccess} notification2={NotiError} relationship={relationship}/>)}
                {count === '5' && (<SendOcc onCancel={handleCancel} changepage={setCount} notification={NotiSuccess} notification2={NotiError} />)}
                {count === '6' && (<SendDetail onCancel={handleCancel} changepage={setCount} notification={NotiSuccess} notification2={NotiError} newDataLand={newDataLand} />)}
                {/* </Card> */}
            </Modal>
            {contextHolder}
        </div>
    )
}
