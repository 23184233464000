
export function buttonApprovedLandPn(value, priceStatus) {

    return value !== "อนุมัติ" && priceStatus === true && value !== "ปัดตก" &&
        value !== "ไม่อนุมัติ" && value !== "Reject" &&
        value !== "ลูกค้าปฏิเสธ" && value !== "รอทำสัญญา" &&
        value !== "ลูกค้าคอนเฟิร์ม" && value !== "รอตรวจสอบเอกสาร" &&
        value !== "อนุมัติแบบมีเงื่อนไข"
}

export function uploadIMGCheckInfoLand(value) {

    return value.approvalStatus === 13 || value.approvalStatus === 22 || value.approvalStatus === 21
}
export function uploadIMGCheckInfoCar(value) {

    return value.approvalStatus === 22 || value.approvalStatus === 21
}

export function showNoteModalPreemAD(status) {

    return status === "ผ่านการวิเคราะห์" || status === "ไม่ผ่านการวิเคราะห์"
}
export function showNoteModalPreemPG(status) {

    return status !== "ผ่านการวิเคราะห์" && status !== "ไม่ผ่านการวิเคราะห์"
}

export function checkSNAM(snam) {
    return snam === "หสน." || snam === "ห.จ.ก" ||
        snam === "ห.จ.ก." || snam === "หจก" || snam === "หจก." ||
        snam === "อนามัย" || snam === "โรงพิมพ์" || snam === "บ." ||
        snam === "บจก." || snam === "บริษัท" || snam === "บริษีท" || snam === "บริษั  \u0000"
}

export function checkREPUPCHANGE(productLoanType) {
    return productLoanType === "Pre-Aaprove-รี" ||
        productLoanType === "Pre-Aaprove-ปรับโครงสร้าง" ||
        productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
        productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
        productLoanType === "รี" ||
        productLoanType === "ปรับโครงสร้าง" ||
        productLoanType === "เปลี่ยนสัญญา" ||
        productLoanType === "รี+เปลี่ยนสัญญา"
}

export function checkProductLoanType(productLoanType) {
    return productLoanType === "Pre-Aaprove-รีโอน"
        || productLoanType === "Pre-Aaprove-รี-Ploan"
        || productLoanType === "Pre-Aaprove-รี"
        || productLoanType === "รีโอน"
        || productLoanType === "รี-Ploan"
        || productLoanType === "รี"
        || productLoanType === "Pre-Aaprove-ปรับโครงสร้าง"
        || productLoanType === "ปรับโครงสร้าง"
        || productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา"
        || productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา"
        || productLoanType === "รี+เปลี่ยนสัญญา"
        || productLoanType === "เปลี่ยนสัญญา"
}

export function checkapprovalStatus(approvalStatus) {
    return approvalStatus === 3
        || approvalStatus === 21
        || approvalStatus === 22
        || approvalStatus === 2
}