import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col, Input, Table, Select, DatePicker } from "antd";
import axios from "axios";
import DataColumnsTabel from "./data/DataColumnsTabel";
import DataCase from "./data/DataCase";
import moment from "moment/moment";
import SearchPDF from "./SearchPDF";
const { RangePicker } = DatePicker;
function SearchTabel() {
  //รับข้อมูลเคส จาก input
  const [inputSearchCase, setInputSearchCase] = useState(false);
  //รับข้อมูลตัวอักษร จาก input
  const [inputSearchText, setinputSearchText] = useState(false);
  //รับข้อมูลวันเวลา จาก input
  const [inputDate, setInputDate] = useState([]);
  //รับ tableSearch
  const [tableSearch, setTableSearch] = useState([]);
  //tabelA
  const [tabelA, settabelA] = useState([]);
  //tabelB
  const [tabelB, settabelB] = useState([]);
  //รับค่า
  const onChangeLocalAll = (key, value, option) => {
    setInputSearchCase(key);
    //console.log("aaaaaaa",key);
  };
   //เซ็ต ค่าวันที่ ถึงวันที่
  const onChangeDate = (dateSelect2) => {
  // ตรวจสอบว่า dateSelect2 ไม่เป็น null (เพื่อป้องกันการผิดพลาด)
  if (dateSelect2) {
    const startDate = moment(dateSelect2[0]).startOf('day').format("YYYY-MM-DDT00:00:00+07:00"); // กำหนดให้เริ่มต้นที่เวลา 00:00:00
    const endDate = moment(dateSelect2[1]).endOf('day').format("YYYY-MM-DDT23:59:59+07:00"); // กำหนดให้สิ้นสุดที่เวลา 23:59:59
    setInputDate({ startDate, endDate }); // เก็บเป็น object
    //console.log("formattedDate", formattedDate);
  }
  };
  //form-Error
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  //form-Success
  const onFinish = async (values) => {
    //console.log("Success:", values.inputSearch);
    //setInputSearchCase(values.inputSearch);
    setinputSearchText(values.SearchText);
  };
  //ดึงข้อมูล
  useEffect(() => {
    const token = localStorage.getItem("token");
    const tk = JSON.parse(token);
    const headers = {
      Authorization: `Bearer ${tk}`,
      Menu: JSON.stringify("5"),
    };
    const fetchData = async () => {
      try {
        const res = await axios.get(
          "https://api-search-contno-db2-i67f6gaaqa-as.a.run.app/api-db2/main-receipt",
          { headers: headers }
        );
        const dataA = res.data;
        //setTableSearch(dataA);
        settabelA(dataA);
      } catch (err) {
        console.log(err.message);
      }
    };
    fetchData();
  }, []);
  //ดึงข้อมูลค้นหา
  useEffect(() => {
    const token = localStorage.getItem("token");
    const tk = JSON.parse(token);
    const headers = {
      Authorization: `Bearer ${tk}`,
      Menu: JSON.stringify("5"),
    };
    const fetchData = async () => {
      try {
        const res = await axios.post(
          "https://api-search-contno-db2-i67f6gaaqa-as.a.run.app/api-db2/search-receipt",
          {
            day1: inputDate.startDate,
            day2: inputDate.endDate,
            // productType: inputSearchCase,
            // text: inputSearchText,
          },
          { headers: headers }
        );
        const dataB = res.data;
        console.log(inputSearchCase, "//", inputSearchText, "res2468", dataB);
        settabelB(dataB);
      } catch (err) {
        console.log(err.message);
      }
    };
    if (inputSearchCase && inputSearchText) {
      fetchData();
    }
  }, [inputSearchCase, inputSearchText, inputDate]);
  //เช็คตาราง
  useEffect(() => {
    if (tabelB && tabelB.length > 0) {
      setTableSearch(tabelB);
      console.log("tabelB", tabelB);
    } else {
      setTableSearch(tabelA);
      //console.log("tabelA",tabelA);
    }
  }, [tabelA, tabelB]);

  return (
    <>
    <div style={{padding: "10px"}}>
      <h1 style={{textAlign: "center", color: "black"}}>ฟอร์ม-การเงินออโต้</h1>
      <Form
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {" "}
        <Row>
          <Col span={4}>
            <Form.Item
              label="เลือกวันที่"
              name="SearchDate"
              rules={[
                {
                  required: true,
                  message: "เลือกวันที่!",
                },
              ]}
            >
              <RangePicker
                placeholder={["เลือกวันที่", "ถึงวันที่"]}
                style={{ margin: "0 4px 4px" }}
                format="YYYY-MM-DD"
                onChange={(dateSelect, dateSelect2) => {
                  onChangeDate(dateSelect2);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="เลือกสาขาหลัก"
              name="SearchCase"
              rules={[
                {
                  required: true,
                  message: "เลือกประเภท!",
                },
              ]}
            >
              <Select
                onChange={(value, option) => {
                  const key = option.key;
                  //const label = option.label;
                  onChangeLocalAll(key, value, option);
                }}
                placeholder="เลือกประเภท"
                options={DataCase}
                value={inputSearchCase}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="กรอกข้อมูล"
              name="SearchText"
              rules={[
                {
                  required: true,
                  message: "กรอก เลขสัญญา!",
                },
              ]}
              style={{ margin: "0 4px 4px" }}
            >
              <Input style={{ height: "33px" }}/>
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item label=" ">
              <Button
                type="primary"
                htmlType="submit"
                style={{ margin: "0 2px 4px" }}
              >
                ค้นหา
              </Button>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label=" ">
              <SearchPDF tableSearch={tableSearch}></SearchPDF>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        columns={DataColumnsTabel}
        dataSource={
          tableSearch && tableSearch.length > 0
            ? tableSearch.map((item, index) => ({
                ...item,
                key: index,
                TMBILDT: moment(item.TMBILDT).format("DD/MM/YYYY HH:mm:ss "),
              }))
            : []
        }
      />
    </div>
    </>
  );
}
export default SearchTabel;
