  const dataCase = [
    {
      value: "เลขสัญญา",
      key: 1,
    },
    {
      value: "เลขใบเสร็จ",
      key: 2,
    },
    {
      value: "เลขบัตรประชาชนลูกค้า",
      key: 3,
    },
  ];
export default dataCase;
