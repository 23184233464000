import React, { useState, useEffect } from "react";
import { HomeFilled, PhoneOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Table, Spin, Tag, Space, Card, Divider, DatePicker, Statistic } from 'antd';
import '../css/Media.css'
import axios from "axios";
import dayjs from "dayjs";
import { useDispatch } from 'react-redux';
import { addCustomer } from '../../redux/Customer';
import { addCar } from '../../redux/Car';
import { addAddress } from '../../redux/Address';
import { addPhone } from '../../redux/Phone';
import { addLoan } from '../../redux/Loan';
import { addOldLoan } from '../../redux/OldLoan';
import { addImage } from '../../redux/Image';
import { addLand } from '../../redux/Land';
import { addLoanLand } from '../../redux/LoanLand';
import { addOldLoanLand } from '../../redux/OldLoanLand';
import { addGuarantor } from '../../redux/Guarantor';
import { addCareerIncome } from '../../redux/CareerIncome';
import { addOccupation } from '../../redux/Occupation';
import { addEmail } from '../../redux/Email';
import { addSocial } from '../../redux/Social';
import Contno from './modal/Contno';
import { loadcontnomkre, showre, tellAun } from '../file_mid/all_api';
import { ApprovalStatusColor } from "../file_mid/status_color";

export default function Main_Re() {
    const dispatch = useDispatch()
    const nicknameSalcod = localStorage.getItem('nicknameSalcod');
    const username = localStorage.getItem('username');
    const nickname = localStorage.getItem('nickname');
    const token = localStorage.getItem('token');
    const branch = localStorage.getItem('branch');
    const [axiosData, setAxiosData] = useState([]);
    const [arrayTable, setArrayTable] = useState([]);
    const day1User = dayjs("2000-01-01")
    const day1Admin = dayjs("2023-01-01")
    const toDayShow = dayjs().format("YYYY-MM-DD")
    const toDay = dayjs()
    const [dataPost, setDataPost] = useState({ day1: "2000-01-01", day2: toDayShow })
    const [loading, setLoading] = useState(false)
    const [dataCont, setDataCont] = useState()
    const [dataType, setDataType] = useState()
    const [aun, setAun] = useState(null)
    const [modalCont, setModalCont] = useState(false)
    const [againMode, setAgainMode] = useState(false)
    const { Countdown } = Statistic;

    useEffect(() => {
        if (username === "admin777" || username === "admin888" || username === "admin999") {
            setDataPost({ ...dataPost, day1: "2023-01-01" })
        }

        // console.log("ss", username+','+nickname+','+nicknameSalcod)
        localStorage.removeItem('addCustomer');
        localStorage.removeItem('addAddress');
        localStorage.removeItem('addPhone');
        localStorage.removeItem('addCar');
        localStorage.removeItem('addGuarantor');
        localStorage.removeItem('addCareerIncome');
        localStorage.removeItem('addLoan');
        localStorage.removeItem('addOldLoan');
        localStorage.removeItem('addLand');
        localStorage.removeItem('addLoanLand');
        localStorage.removeItem('addOldLoanLand');
        localStorage.removeItem('addOccupation');
        localStorage.removeItem('addEmail');
        localStorage.removeItem('addSocial');
        localStorage.removeItem('addImage');
        localStorage.removeItem('addBroker');
        dispatch(addCustomer())
        dispatch(addAddress())
        dispatch(addPhone())
        dispatch(addGuarantor())
        dispatch(addCareerIncome())
        dispatch(addCar())
        dispatch(addLoan())
        dispatch(addOldLoan())
        dispatch(addImage())
        dispatch(addLand())
        dispatch(addLoanLand())
        dispatch(addOldLoanLand())
        dispatch(addOccupation())
        dispatch(addEmail())
        dispatch(addSocial())
    }, [])

    useEffect(() => {
        if (!modalCont) {
            setAgainMode(false)
        }
    }, [modalCont, aun])

    const loadData = async (yourname) => {
        if (dataPost?.day2 !== "") {
            setLoading(true)
            const tk = JSON.parse(token)
            const headers = {
                "Authorization": `Bearer ${tk}`,
                "Menu": JSON.stringify("3")
            }
            var mainData = { "day1": dataPost.day1, "day2": dataPost.day2, "SALCOD": yourname, "proposalBy": username }
            await axios.post(showre, mainData, { headers: headers })
                .then(async (res) => {
                    console.log("loadData", res.data)
                    setArrayTable(res.data)
                    setAxiosData(res.data)
                    if (res.data) {
                        await loadDataREMK(res.data)
                    }
                    res.data.map((e) => {
                        // if (e.newOfferDate !== "0001-01-01T00:00:00Z" && (e.approvalStatus ===22 || e.approvalStatus ===8)) {
                        //     console.log("loadData", e)
                        // }
                        if (e.CONTNO === "2-0011741") {
                            console.log("loadData", e)
                        }
                    })
                }).catch((err) => {
                    console.log(err)
                    alert("ไม่พบข้อมูล !!!")
                })
            setLoading(false)
        } else {
            alert("กรุณาเลือกวันที่ให้ถูกต้อง !!!")
        }
    }

    const loadDataREMK = async (e) => {
        setLoading(true)
        await axios.get(loadcontnomkre)
            .then(async (res) => {
                if (res.data) {
                    // console.log("loadDataREMK", res.data)
                    const updatedArrayTable = e.map(tableEntry => {
                        const match = res.data.find(dataEntry => dataEntry.contno === tableEntry.CONTNO);
                        if (match) {
                            return { ...tableEntry, ...match };
                        }
                        return tableEntry;
                    });
                    setArrayTable(updatedArrayTable);
                    setAxiosData(updatedArrayTable)
                    await loadDataTellAun(updatedArrayTable)
                    // updatedArrayTable.map((e) => {
                    //     if (e.newOfferDate !== "0001-01-01T00:00:00Z" && e.id > 0 && e.DATA_TYPE === "land") {
                    //         console.log("loadDataREMK", e)
                    //     }
                    // })
                }
            }).catch((err) => {
                console.log(err)
            })
        setLoading(false)
    }

    const loadDataTellAun = async (e) => {
        setLoading(true)
        await axios.get(tellAun)
            .then(async (res) => {
                if (res.data) {
                    // console.log("loadDataTellAun", res.data)
                    const updatedArrayTable2 = e.map(tableEntry => {
                        const match = res.data.find(dataEntry => dataEntry.CONTNO === tableEntry.CONTNO);
                        // console.log("match", match)
                        if (match) {
                            return { ...tableEntry, ...match, tell: "aun" };
                        }
                        return tableEntry;
                    });
                    setArrayTable(updatedArrayTable2);
                    setAxiosData(updatedArrayTable2)
                    // updatedArrayTable2.map((e) => {
                    //     if (e.CONTNO === "2-0006935") {
                    //         console.log("loadDataTellAun", e)
                    //     }
                    // })
                }
            }).catch((err) => {
                console.log(err)
            })
        setLoading(false)
    }

    const handleYG = async () => {
        let yourname = username + ',' + nickname + ',' + nicknameSalcod
        // console.log("dataPost", dataPost)

        if (yourname) {
            if (branch === "MIT" || branch === "UD" || branch === "LEX" || branch === "S4") {
                await loadData(yourname)

            } else {
                alert("คุณไม่มีสิทธิ์ใช้งานเมนูนี้ !")
            }
        } else {
            alert("ไม่พบข้อมูล !")
        }
    }

    const search = (data) => {
        const greaterThanTen = axiosData.filter(
            (item) =>
                item.CONTNO.toLowerCase().includes(data.target.value) ||
                item.CUSCOD.toLowerCase().includes(data.target.value)
        );
        setArrayTable(greaterThanTen)
    };

    const columns = [
        {
            title: "ลำดับ",
            dataIndex: "index",
            key: 'index',
            align: 'center',
            //   fixed: true,
            width: "10%",
            render: (text, object, index) => index + 1
        },
        {
            title: "วันที่",
            dataIndex: "SDATE",
            width: "30%",
            align: 'center',
            render: (text, record) => (
                <Space size="middle">
                    {
                        record.SDATE !== "" ?
                            <Space>
                                <>{dayjs(record.SDATE).format("DD-MM-YYYY")}</>
                            </Space>
                            : null
                    }
                </Space>
            ),
            defaultSortOrder: 'descend',
        },
        {
            title: "รายละเอียดผู้กู้",
            key: 'CONTNO',
            align: 'center',
            width: "30%",
            render: (text, record) => (
                <div>
                    <div>ชื่อ-สกุล <b>{record.SNAM}{record.NAME1} {record.NAME2}</b></div>
                    <div>เลขบัตรประชาชน <b>{record.CUSCOD}</b></div>
                </div>
            ),
        },
        {
            title: "เลขที่สัญญา",
            dataIndex: "CONTNO",
            key: 'CONTNO',
            align: 'center',
            width: "30%",
            render: (text, record) => (
                <>{record.CONTNO} {record.DATA_TYPE === "psfhp" ? "(เก่า)" : record.DATA_TYPE === "rpsl" ? "(ใหม่)" : null }</>
            ),
        },
        {
            title: "Action",
            key: 'Action',
            align: 'center',
            width: "30%",
            render: (text, record) => {
                //* รี ต้อง 6 งวด / ปรับโครงสร้าง เกรด A-B ไม่ได้ *//
                const newOfferDate = dayjs(record.newOfferDate).startOf('day'); // วันที่ปลดรี แบบนับแต่วันที่
                const currentDate = dayjs().startOf('day'); // วันที่ปัจจุบัน แบบนับแต่วันที่
                const compareDates = newOfferDate < currentDate
                const appOne = record.approvalStatus === 5
                const appTwo = record.approvalStatus === 7
                const appThree = record.approvalStatus === 6
                const appFour = record.approvalStatus === 10
                const appFive = record.approvalStatus === 17

                let colorBTN
                if (record.checkRe) {
                    colorBTN = ""
                }
                if (record.checkPub) {
                    colorBTN = "orange"
                }
                if (record.checkChange) {
                    colorBTN = "purple"
                }

                return (
                    <>
                        <Space size="middle">
                            {
                                record.approvalStatus === 9 ?
                                    <>
                                        <b><Tag color="geekblue">กรุณาติดต่อเร่งรัดภาคสนาม</Tag></b>
                                        <Button
                                            type="primary"
                                            style={{ backgroundColor: "green" }}
                                            icon={<PhoneOutlined />}
                                            onClick={() => {
                                                setModalCont(true)
                                                setDataCont(record)
                                                setDataType("โทร")
                                                setAun(record?.tell)
                                            }}
                                        >
                                            ช่องทางติดต่อ
                                        </Button>
                                    </>
                                    :
                                    <>
                                        {
                                            record.id ?
                                                <>
                                                    {
                                                        record.approvalStatus === 22 || record.approvalStatus === 8 ?
                                                            <>
                                                                {
                                                                    newOfferDate > currentDate ? // ตรวจสอบว่า วันที่ปลดรี > วันที่ปัจจุบัน
                                                                        <Countdown title="สามารถ รี/ปรับ หลังจาก" value={dayjs(newOfferDate).toDate()} format="D วัน H ชม. m นาที s วินาที" />
                                                                        :
                                                                        <>
                                                                            {/* {
                                                                                record.checkRe ?
                                                                                    <>
                                                                                        <Button
                                                                                            type="primary"
                                                                                            onClick={() => {
                                                                                                setDataType("รี")
                                                                                                setModalCont(true)
                                                                                                setDataCont(record)
                                                                                            }}>เคส-รี</Button>
                                                                                    </>
                                                                                    : record.checkPub ?
                                                                                        <>
                                                                                            <Button
                                                                                                type="primary"
                                                                                                style={{ backgroundColor: "orange", color: 'white' }}
                                                                                                onClick={() => {
                                                                                                    setDataType("ปรับโครงสร้าง")
                                                                                                    setModalCont(true)
                                                                                                    setDataCont(record)
                                                                                                }}>ปรับโครงสร้าง</Button>
                                                                                        </>
                                                                                        : record.checkChange ?
                                                                                            <>
                                                                                                <Button
                                                                                                    type="primary"
                                                                                                    style={{ backgroundColor: "purple", color: 'white' }}
                                                                                                    onClick={() => {
                                                                                                        setDataType("เปลี่ยนสัญญา")
                                                                                                        setModalCont(true)
                                                                                                        setDataCont(record)
                                                                                                    }}>เปลี่ยนสัญญา</Button>
                                                                                            </>
                                                                                            : null
                                                                            } */}
                                                                            <Button
                                                                                type="primary"
                                                                                style={{ backgroundColor: colorBTN, color: 'white' }}
                                                                                onClick={() => {
                                                                                    if (record.checkRe) {
                                                                                        setDataType("รี")
                                                                                    } else if (record.checkPub) {
                                                                                        setDataType("ปรับโครงสร้าง")
                                                                                    } else if (record.checkChange) {
                                                                                        setDataType("เปลี่ยนสัญญา")
                                                                                    }
                                                                                    setAgainMode(true)
                                                                                    setModalCont(true)
                                                                                    setDataCont(record)
                                                                                }}>ยื่นคำร้องอีกครั้ง {
                                                                                    record.checkRe ? "(รี)"
                                                                                        : record.checkPub ? "(ปรับโครงสร้าง)"
                                                                                            : record.checkChange ? "(เปลี่ยนสัญญา)" : null
                                                                                }</Button>
                                                                            <Button
                                                                                type="primary"
                                                                                style={{ backgroundColor: "green" }}
                                                                                icon={<PhoneOutlined />}
                                                                                onClick={() => {
                                                                                    setModalCont(true)
                                                                                    setDataCont(record)
                                                                                    setDataType("โทร")
                                                                                    setAun(null)
                                                                                }}
                                                                            >ช่องทางติดต่อ</Button>
                                                                        </>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    record.approvalStatus === "" || record.approvalStatus === null ?
                                                                        <>
                                                                            {
                                                                                record.checkRe ?
                                                                                    <>
                                                                                        <Button
                                                                                            type="primary"
                                                                                            onClick={() => {
                                                                                                setDataType("รี")
                                                                                                setModalCont(true)
                                                                                                setDataCont(record)
                                                                                            }}>เคส-รี</Button>
                                                                                    </>
                                                                                    : record.checkPub ?
                                                                                        <>
                                                                                            <Button
                                                                                                type="primary"
                                                                                                style={{ backgroundColor: "orange", color: 'white' }}
                                                                                                onClick={() => {
                                                                                                    setDataType("ปรับโครงสร้าง")
                                                                                                    setModalCont(true)
                                                                                                    setDataCont(record)
                                                                                                }}>ปรับโครงสร้าง</Button>
                                                                                        </>
                                                                                        : record.checkChange ?
                                                                                            <>
                                                                                                <Button
                                                                                                    type="primary"
                                                                                                    style={{ backgroundColor: "purple", color: 'white' }}
                                                                                                    onClick={() => {
                                                                                                        setDataType("เปลี่ยนสัญญา")
                                                                                                        setModalCont(true)
                                                                                                        setDataCont(record)
                                                                                                    }}>เปลี่ยนสัญญา</Button>
                                                                                            </>
                                                                                            : null
                                                                            }
                                                                            <Button
                                                                                type="primary"
                                                                                style={{ backgroundColor: "green" }}
                                                                                icon={<PhoneOutlined />}
                                                                                onClick={() => {
                                                                                    setModalCont(true)
                                                                                    setDataCont(record)
                                                                                    setDataType("โทร")
                                                                                    setAun(null)
                                                                                }}
                                                                            >
                                                                                ช่องทางติดต่อ
                                                                            </Button>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {
                                                                                record?.tell === "aun" && !record.sendPg ?
                                                                                    <>
                                                                                        <b><Tag color="geekblue">กรุณาติดต่อเร่งรัดภาคสนาม</Tag></b>
                                                                                        <Button
                                                                                            type="primary"
                                                                                            style={{ backgroundColor: "green" }}
                                                                                            icon={<PhoneOutlined />}
                                                                                            onClick={() => {
                                                                                                setModalCont(true);
                                                                                                setDataCont(record);
                                                                                                setDataType("โทร");
                                                                                                setAun(record?.tell);
                                                                                            }}
                                                                                        >
                                                                                            ช่องทางติดต่อ
                                                                                        </Button>
                                                                                    </>
                                                                                    :
                                                                                    (compareDates && appOne) ||  // ยื่นคำร้อง
                                                                                        (compareDates && appTwo) ||  // รับคำร้อง
                                                                                        (compareDates && appThree) || // คำร้องไม่ถูกต้อง
                                                                                        (compareDates && appFour) || // เสนอเคสรีแล้ว
                                                                                        (compareDates && appFive) ?  // รออนุมัติราคารถ
                                                                                        <>
                                                                                            <Button
                                                                                                type="primary"
                                                                                                style={{ backgroundColor: colorBTN, color: 'white' }}
                                                                                                onClick={() => {
                                                                                                    if (record.checkRe) {
                                                                                                        setDataType("รี");
                                                                                                    } else if (record.checkPub) {
                                                                                                        setDataType("ปรับโครงสร้าง");
                                                                                                    } else if (record.checkChange) {
                                                                                                        setDataType("เปลี่ยนสัญญา");
                                                                                                    }
                                                                                                    setAgainMode(true);
                                                                                                    setModalCont(true);
                                                                                                    setDataCont(record);
                                                                                                }}
                                                                                            >
                                                                                                ยื่นคำร้องอีกครั้ง {
                                                                                                    record.checkRe ? "(รี)"
                                                                                                        : record.checkPub ? "(ปรับโครงสร้าง)"
                                                                                                            : record.checkChange ? "(เปลี่ยนสัญญา)"
                                                                                                                : null
                                                                                                }
                                                                                            </Button>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <b>
                                                                                                สัญญานี้อยู่ในการดำเนินการ <Tag color={ApprovalStatusColor(record.approvalStatus)}>{record.approvalStatusName}</Tag>
                                                                                            </b>
                                                                                        </>
                                                                            }

                                                                            {/* {
                                                                                (compareDates && appOne) || // ยื่นคำร้อง
                                                                                    (compareDates && appTwo) || // รับคำร้อง
                                                                                    (compareDates && appThree) ? // คำร้องไม่ถูกต้อง
                                                                                    <>
                                                                                        <Button
                                                                                            type="primary"
                                                                                            style={{ backgroundColor: colorBTN, color: 'white' }}
                                                                                            onClick={() => {
                                                                                                if (record.checkRe) {
                                                                                                    setDataType("รี")
                                                                                                } else if (record.checkPub) {
                                                                                                    setDataType("ปรับโครงสร้าง")
                                                                                                } else if (record.checkChange) {
                                                                                                    setDataType("เปลี่ยนสัญญา")
                                                                                                }
                                                                                                setAgainMode(true)
                                                                                                setModalCont(true)
                                                                                                setDataCont(record)
                                                                                            }}>ยื่นคำร้องอีกครั้ง {
                                                                                                record.checkRe ? "(รี)"
                                                                                                    : record.checkPub ? "(ปรับโครงสร้าง)"
                                                                                                        : record.checkChange ? "(เปลี่ยนสัญญา)" : null
                                                                                            }</Button>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <b>สัญญานี้อยู่ในการดำเนินการ <Tag color={ApprovalStatusColor(record.approvalStatus)}>{record.approvalStatusName}</Tag></b>
                                                                                    </>
                                                                            } */}

                                                                        </>
                                                                }
                                                            </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    {
                                                        record?.tell === "aun" ?
                                                            <>

                                                                <b><Tag color="geekblue">กรุณาติดต่อเร่งรัดภาคสนาม</Tag></b>
                                                                <Button
                                                                    type="primary"
                                                                    style={{ backgroundColor: "green" }}
                                                                    icon={<PhoneOutlined />}
                                                                    onClick={() => {
                                                                        setModalCont(true)
                                                                        setDataCont(record)
                                                                        setDataType("โทร")
                                                                        setAun(record?.tell)
                                                                    }}
                                                                >
                                                                    ช่องทางติดต่อ
                                                                </Button>
                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    record.DATA_TYPE === "" ?
                                                                        null
                                                                        :
                                                                        <>
                                                                            {
                                                                                record.approvalStatus === 22 || record.approvalStatus === 8 ?
                                                                                    <>
                                                                                        {
                                                                                            newOfferDate > currentDate ? // ตรวจสอบว่า วันที่ปลดรี > วันที่ปัจจุบัน
                                                                                                <Countdown title="สามารถ รี/ปรับ หลังจาก" value={dayjs(newOfferDate).toDate()} format="D วัน H ชม. m นาที s วินาที" />
                                                                                                :
                                                                                                <>
                                                                                                    {
                                                                                                        // SMPAYTOT_UPAY งวด
                                                                                                        record.SMPAYTOT_UPAY >= Math.ceil(record?.T_NOPAY * 0.3) ?
                                                                                                            <>
                                                                                                                <Button
                                                                                                                    // style={{ width: "80px" }}
                                                                                                                    type="primary"
                                                                                                                    onClick={() => {
                                                                                                                        setDataType("รี")
                                                                                                                        setAgainMode(true)
                                                                                                                        setModalCont(true)
                                                                                                                        setDataCont(record)
                                                                                                                    }}>เคส-รี</Button>
                                                                                                            </>
                                                                                                            : null
                                                                                                    }
                                                                                                    {
                                                                                                        // GRDCOD เกรด
                                                                                                        record.GRDCOD !== "A" && record.GRDCOD !== "B" ?
                                                                                                            <>
                                                                                                                <Button
                                                                                                                    type="primary"
                                                                                                                    style={{ backgroundColor: "orange", color: 'white' }}
                                                                                                                    onClick={() => {
                                                                                                                        setDataType("ปรับโครงสร้าง")
                                                                                                                        setAgainMode(true)
                                                                                                                        setModalCont(true)
                                                                                                                        setDataCont(record)
                                                                                                                    }}>ปรับโครงสร้าง</Button>
                                                                                                            </>
                                                                                                            : null
                                                                                                    }
                                                                                                    {
                                                                                                        record.SMPAYTOT_UPAY >= Math.ceil(record?.T_NOPAY * 0.3) || record.GRDCOD !== "A" && record.GRDCOD !== "B" ?
                                                                                                            // checkOpenTel === 1 ?
                                                                                                            <>
                                                                                                                <Button
                                                                                                                    type="primary"
                                                                                                                    style={{ backgroundColor: "green" }}
                                                                                                                    icon={<PhoneOutlined />}
                                                                                                                    onClick={() => {
                                                                                                                        setModalCont(true)
                                                                                                                        setDataCont(record)
                                                                                                                        setDataType("โทร")
                                                                                                                        setAun(null)
                                                                                                                    }}
                                                                                                                >
                                                                                                                    ช่องทางติดต่อ
                                                                                                                </Button>
                                                                                                            </>
                                                                                                            : null
                                                                                                    }
                                                                                                </>
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {
                                                                                            record.approvalStatus === "" || record.approvalStatus === null ?
                                                                                                <>
                                                                                                    {
                                                                                                        record.SMPAYTOT_UPAY >= Math.ceil(record?.T_NOPAY * 0.3) ?
                                                                                                            <>
                                                                                                                <Button
                                                                                                                    // style={{ width: "80px" }}
                                                                                                                    type="primary"
                                                                                                                    onClick={() => {
                                                                                                                        setDataType("รี")
                                                                                                                        setModalCont(true)
                                                                                                                        setDataCont(record)
                                                                                                                    }}>เคส-รี</Button>
                                                                                                            </>
                                                                                                            : null
                                                                                                    }
                                                                                                    {
                                                                                                        record.GRDCOD !== "A" && record.GRDCOD !== "B" ?
                                                                                                            <>
                                                                                                                <Button
                                                                                                                    type="primary"
                                                                                                                    style={{ backgroundColor: "orange", color: 'white' }}
                                                                                                                    onClick={() => {
                                                                                                                        setDataType("ปรับโครงสร้าง")
                                                                                                                        setModalCont(true)
                                                                                                                        setDataCont(record)
                                                                                                                    }}>ปรับโครงสร้าง</Button>
                                                                                                            </>
                                                                                                            : null
                                                                                                    }
                                                                                                    {
                                                                                                        record.SMPAYTOT_UPAY >= Math.ceil(record?.T_NOPAY * 0.3) || record.GRDCOD !== "A" && record.GRDCOD !== "B" ?
                                                                                                            <>
                                                                                                                <Button
                                                                                                                    type="primary"
                                                                                                                    style={{ backgroundColor: "green" }}
                                                                                                                    icon={<PhoneOutlined />}
                                                                                                                    onClick={() => {
                                                                                                                        setModalCont(true)
                                                                                                                        setDataCont(record)
                                                                                                                        setDataType("โทร")
                                                                                                                        setAun(null)
                                                                                                                    }}
                                                                                                                >
                                                                                                                    ช่องทางติดต่อ
                                                                                                                </Button>
                                                                                                            </>
                                                                                                            : null
                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    {
                                                                                                        (compareDates && appOne) || // ยื่นคำร้อง
                                                                                                            (compareDates && appTwo) || // รับคำร้อง
                                                                                                            (compareDates && appThree) ? // คำร้องไม่ถูกต้อง
                                                                                                            <>
                                                                                                                {
                                                                                                                    record.SMPAYTOT_UPAY >= Math.ceil(record?.T_NOPAY * 0.3) ?
                                                                                                                        <>
                                                                                                                            <Button
                                                                                                                                // style={{ width: "80px" }}
                                                                                                                                type="primary"
                                                                                                                                onClick={() => {
                                                                                                                                    setDataType("รี")
                                                                                                                                    setAgainMode(true)
                                                                                                                                    setModalCont(true)
                                                                                                                                    setDataCont(record)
                                                                                                                                }}>เคส-รี</Button>
                                                                                                                        </>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    record.GRDCOD !== "A" && record.GRDCOD !== "B" ?
                                                                                                                        <>
                                                                                                                            <Button
                                                                                                                                type="primary"
                                                                                                                                style={{ backgroundColor: "orange", color: 'white' }}
                                                                                                                                onClick={() => {
                                                                                                                                    setDataType("ปรับโครงสร้าง")
                                                                                                                                    setAgainMode(true)
                                                                                                                                    setModalCont(true)
                                                                                                                                    setDataCont(record)
                                                                                                                                }}>ปรับโครงสร้าง</Button>
                                                                                                                        </>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    record.SMPAYTOT_UPAY >= Math.ceil(record?.T_NOPAY * 0.3) || record.GRDCOD !== "A" && record.GRDCOD !== "B" ?
                                                                                                                        <>
                                                                                                                            <Button
                                                                                                                                type="primary"
                                                                                                                                style={{ backgroundColor: "green" }}
                                                                                                                                icon={<PhoneOutlined />}
                                                                                                                                onClick={() => {
                                                                                                                                    setModalCont(true)
                                                                                                                                    setDataCont(record)
                                                                                                                                    setDataType("โทร")
                                                                                                                                    setAun(null)
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                ช่องทางติดต่อ
                                                                                                                            </Button>
                                                                                                                        </>
                                                                                                                        : null
                                                                                                                }
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <b>สัญญานี้อยู่ในการดำเนินการ <Tag color={ApprovalStatusColor(record.approvalStatus)}>{record.approvalStatusName}</Tag></b>
                                                                                                            </>
                                                                                                    }
                                                                                                    {/* <b>สัญญานี้อยู่ในการดำเนินการ <Tag color={ApprovalStatusColor(record.approvalStatus)}>{record.approvalStatus}</Tag></b> */}
                                                                                                </>
                                                                                        }
                                                                                    </>
                                                                            }
                                                                        </>
                                                                }
                                                            </>
                                                    }
                                                </>
                                        }
                                    </>
                            }
                        </Space >
                    </>
                );
            },
        },
    ];
    return (
        <>
            <Card>
                <Row style={{ textAlign: 'center' }}>
                    <Col>
                        <Spin spinning={loading} size='large' tip=" Loading... ">
                            <div className='text-center'>
                                <h2>ตรวจสอบข้อมูลสัญญา(การตลาด)</h2>
                            </div>
                            <Divider></Divider>
                            <Row className="main">
                                <div>
                                    <Col span={24} style={{ marginTop: '10px' }}>
                                        <>{"วันที่ : "}</>
                                        {
                                            username === "admin777" || username === "admin888" || username === "admin999" ?
                                                <DatePicker format={'YYYY-MM-DD'}
                                                    defaultValue={day1Admin}
                                                    style={{ height: '40px' }}
                                                    onChange={(e) => {
                                                        if (e) {
                                                            setDataPost({ ...dataPost, day1: e.format('YYYY-MM-DD') })
                                                        }
                                                    }} />
                                                :
                                                <DatePicker format={'YYYY-MM-DD'}
                                                    defaultValue={day1User}
                                                    style={{ height: '40px' }}
                                                    onChange={(e) => {
                                                        if (e) {
                                                            setDataPost({ ...dataPost, day1: e.format('YYYY-MM-DD') })
                                                        }
                                                    }} />
                                        }
                                    </Col>
                                </div>
                                <div>
                                    <Col span={24} style={{ marginTop: '10px' }}>
                                        <>{"ถึง : "}</>
                                        <DatePicker format={'YYYY-MM-DD'}
                                            defaultValue={toDay}
                                            style={{ height: '40px' }}
                                            onChange={(e) => {
                                                if (e) {
                                                    setDataPost({ ...dataPost, day2: e.format('YYYY-MM-DD') })
                                                }
                                            }} />
                                        <Button style={{ marginLeft: '15px' }} type="primary" onClick={(e) => { handleYG(e) }}>ค้นหา</Button>
                                    </Col>
                                </div>
                            </Row>
                            <Row gutter={32} style={{ margin: 10 }}>
                                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                                    <Input.Search
                                        style={{ width: '250px' }}
                                        placeholder="ค้นหา...."
                                        onChange={search}
                                        name="search"
                                        id="search"

                                    />
                                </Col>
                            </Row>
                            <Row gutter={32}>
                                <Col span={24}>
                                    <Table
                                        // pagination={false}
                                        rowKey={(record) => record.uid}
                                        scroll={{
                                            x: 1500,
                                            y: 400,
                                        }}
                                        dataSource={arrayTable}
                                        columns={columns}
                                    >
                                    </Table>
                                    <Divider />
                                </Col>
                            </Row>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Space>
                                    {/* <Button href="/offercase" type="primary" ><PlusCircleFilled />เพิ่ม</Button> */}
                                    <Button href="/" type="primary"><HomeFilled />หน้าหลัก</Button>
                                </Space>
                            </Col>
                        </Spin>
                    </Col>
                </Row>
            </Card >
            {
                modalCont ?
                    <Contno open={modalCont} close={setModalCont} data={dataCont} aun={aun} dataType={dataType} againMode={againMode} />
                    : null
            }
            {/* {contextHolder} */}
        </>
    )
}
