import React from "react";
import SearchTabel from "./SearchTabel";
function Main() {
  return (
    <>
      <SearchTabel></SearchTabel>
    </>
  );
}
export default Main;
