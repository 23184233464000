import React, { useState, useEffect } from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Input, Row, Space, Spin, Table, notification, DatePicker, Checkbox, Modal, Form } from 'antd'
import axios from 'axios';
import dayjs from "dayjs";
import { currencyFormatOne, msData, msPays, msError, msOK, msCancel, msContnoData } from "../file_mid/allFormat";
import DetailContno from "./DetailContno";
import { loadDataAuto, insertPayamt } from "../file_mid/all_api";
import { onMessageListener } from "../../firebase";

export default function Main() {
  const { confirm } = Modal
  const [form] = Form.useForm();
  const user = localStorage.getItem('username');
  const branch = localStorage.getItem('branch');
  // const currentToken = localStorage.getItem("currentToken");
  // const token = localStorage.getItem('token');
  const currentDateTime = dayjs();
  // const oneDayAgo = currentDateTime.subtract(1, 'day');
  const [dataPost, setDataPost] = useState({ sec: 0, days1: currentDateTime.format('YYYY-MM-DD'), days2: currentDateTime.format('YYYY-MM-DD') })
  const [axiosData, setAxiosData] = useState([]);
  const [arrayTable, setArrayTable] = useState([]);
  const [resMix, setResMix] = useState();
  const [dataDetail, setDataDetail] = useState({ arr: [], money: 0 });
  const [checkMoney, setCheckMoney] = useState(0);
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false)
  const [isModalInfo, setIsModalInfo] = useState(false);
  const [connt, setConnt] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [query, setQuery] = useState("");

  // useEffect(() => {
  //   loadData();
  // }, [])

  const loadData = async () => {
    let mainData = {}
    var resultLoadDataAuto = []
    var resultDetail = []
    let sumAmount = 0;
    setLoading(true)
    setCheckMoney(0)
    // const tk = JSON.parse(token)
    // const headers = {
    //   "Authorization": `Bearer ${tk}`,
    //   "Menu": JSON.stringify("4")
    // }
    mainData = { day1: dataPost.days1, day2: dataPost.days2, user: user, branch: branch }
    console.log("mainData", mainData)
    // await axios.post("http://localhost:8070/api-db2/load-data-auto", mainData)
    await axios.post(loadDataAuto, mainData)
      .then(async (res) => {
        console.log("loadData 1 =>", res.data)
        if (res.status === 200) {
          setResMix(res.data)
          if (res.data.VIEWARMASTBC) {
            await res.data.VIEWARMASTBC.forEach((item) => {
              if (item.CONTNO !== "") {
                resultLoadDataAuto.push(item)
              }
            })
            await res.data.VIEWARMASTBC.forEach((item) => {
              if (item.CONTNO !== "") {
                if (item.flagFollow === true && item.money >= item.PAYLIMITAMT) {
                  resultDetail.push(item)
                  sumAmount = sumAmount + item.money
                } else if (item.flagFollow === false) {
                  resultDetail.push(item)
                  sumAmount = sumAmount + item.money
                } else {
                }
              }
            })
            setArrayTable(resultLoadDataAuto)
            setAxiosData(resultLoadDataAuto)
            if (resultDetail?.length > 0) {
              setDataDetail({ arr: resultDetail, money: sumAmount })
            }
          }
        } else if (res.status === 203) {
          setResMix()
          setDataDetail({ arr: [], money: 0 })
          setArrayTable([])
          setAxiosData([])
          msContnoData(api, "top")
        } else {
          setResMix()
          setDataDetail({ arr: [], money: 0 })
          setArrayTable([])
          setAxiosData([])
          msData(api, "top")
        }
        setSelectedRows([])
        setQuery("")
        setLoading(false)
      }).catch((err) => {
        setResMix()
        setDataDetail({ arr: [], money: 0 })
        setArrayTable([])
        setAxiosData([])
        setSelectedRows([])
        setQuery("")
      })
    setLoading(false)
  }

  // console.log("dataDetail", dataDetail)
  const handleSubmit = () => {
    loadData()
  }
  const showConfirm = () => {
    confirm({
      title: 'คอมเฟิร์มทำรายการ...?',
      content: 'กด OK เพื่อยืนยัน',
      onOk() {
        setLoading(true)
        handleCheck()
      },
      onCancel() {
        msCancel(api, "top")
      },
    });
  }
  // const testNoti = async () => {
  //   setLoading(true)
  //   await axios.post('http://localhost:8070/api-db2/TestSendFCMNotification', { fcmToken: currentToken })
  //     .then(async (res) => {
  //       console.log("Notification sent successfully:", res);
  //       setLoading(false)
  //     }).catch((err) => console.log(err))
  //   setLoading(false)
  // }
  const handleCheck = async () => {
    console.log("selectedRows", selectedRows)
    if (selectedRows.length > 0) {
      await axios.post(insertPayamt, selectedRows)
        .then(async (res) => {
          if (res.status === 200) {
            console.log("ok", res.data)
            msOK(api, "top")
          }
          setLoading(false)
        })
        .catch((err) => {
          console.log("err", err)
          msError(api, "top")
          setLoading(false)
        })
    } else {
      msPays(api, "top")
      setLoading(false)
    }
  }

  const search = (data) => {
    setQuery(data.target.value)
    const greaterThanTen = axiosData.filter(
      (item) =>
        item.CONTNO.toLowerCase().includes(data.target.value)
    );
    setArrayTable(greaterThanTen)
  };
  // Handle selecting all rows
  const onSelectAllChange = (e) => {
    console.log("e.target.checked", e.target.checked)
    let sum = 0;
    if (e.target.checked) {
      // setSelectedRows(arrayTable); // Store all row data when selecting all
      setSelectedRows(arrayTable.filter(row =>
        (row.flagFollow === true && row.money >= row.PAYLIMITAMT) ||
        row.flagFollow === false
        // (row.flagFollow === false && row.money >= row.TOTUPAY)
      ));
      arrayTable.forEach((item) => {
        if (item.CONTNO !== "") {
          if (item.flagFollow === true && item.money >= item.PAYLIMITAMT) {
            sum = sum + item.money
          } else if (item.flagFollow === false) {
            sum = sum + item.money
          } else {
          }
        }
      })
      setCheckMoney(sum)
    } else {
      setSelectedRows([]); // Deselect all
      setCheckMoney(0)
    }
  };

  // Handle selecting individual row
  const onSelectChange = (record, flag) => {
    console.log("flag =>", flag)
    var results = {}
    let sum = 0;
    if (selectedRows.find(row => row.keyId === record.keyId)) { // คลิกเอาออก
      // console.log("selectedRows =>", selectedRows.filter(row => row.keyId !== record.keyId))
      results = selectedRows.filter(row => row.keyId !== record.keyId)
      setSelectedRows(results);
      results.forEach((item) => { sum = sum + item.money })
    } else { // คลิกเพิ่ม
      console.log("record =>", record)
      if (selectedRows?.length > 0) {
        // console.log("1", selectedRows)
        selectedRows.forEach((item) => { sum = sum + item.money })
        // console.log("sum", sum)
      }
      setSelectedRows([...selectedRows, record]); // Add full record instead of just uid
      sum = sum + record.money
    }
    setCheckMoney(sum)
  };

  const columns = [
    {
      title: (
        <Checkbox
          style={{ transform: 'scale(1.5)' }}
          onChange={onSelectAllChange}
          // checked={selectedRows.length === arrayTable.length && arrayTable.length > 0}
          indeterminate={selectedRows.length > 0 && selectedRows.length < arrayTable.length}
        />
      ),
      dataIndex: 'select',
      key: 'select',
      align: 'center',
      width: "10%",
      render: (text, record) => (
        <>
          {
            record.flagFollow === true && record.money >= record.PAYLIMITAMT ?
              <Checkbox
                style={{ transform: 'scale(1.5)' }}
                onChange={() => onSelectChange(record, true)}
                checked={selectedRows.find(row => row.keyId === record.keyId)}
              />
              :
              record.flagFollow === false ?
                // record.flagFollow === false && record.money >= record.TOTUPAY ?
                <Checkbox
                  style={{ transform: 'scale(1.5)' }}
                  onChange={() => onSelectChange(record, false)}
                  checked={selectedRows.find(row => row.keyId === record.keyId)}
                />
                : null
          }
        </>
      ),
    },
    {
      key: 'index',
      title: "ลำดับ",
      dataIndex: "index",
      width: "5%",
      align: 'center',
      render: (text, object, index) => index + 1
    },
    {
      title: "รายละเอียดสัญญา",
      dataIndex: "CONTNO",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <>
          <div>เลขสัญญา : {record.CONTNO}</div>
          <div>เกรด : {record.GRDCOD}</div>
          <div>ค่างวด : <b style={{ fontSize: '16px', color: 'red' }}>{currencyFormatOne(record.TOTUPAY)}</b></div>
          {
            record.flagFollow === true ?
              <div>ชำระขั้นต่ำ : <b style={{ fontSize: '16px', color: 'red' }}>{currencyFormatOne(record.PAYLIMITAMT)}</b></div>
              : null
          }
        </>
      ),
    },
    {
      key: 'money',
      title: "รายละเอียดการโอน",
      dataIndex: "money",
      width: "auto",
      align: 'center',
      render: (text, record) => (
        <>
          <div>วันที่ชำระ : {dayjs(record.PAYMENTDATE).format("DD-MM-YYYY")}</div>
          <div>ยอดเงิน : <b style={{ fontSize: '16px', color: 'red' }}>{currencyFormatOne(record.money)}</b></div>
        </>
      ),
    },
    {
      title: "การติดตาม",
      dataIndex: "moneyFollow",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <>
          {
            record.moneyFollow > 0 ?
              <div>ค่าติดตาม : <b style={{ fontSize: '16px', color: 'red' }}>{currencyFormatOne(record.moneyFollow)}</b></div>
              : <div>ค่าติดตาม : <b style={{ fontSize: '16px', color: 'red' }}>0.00</b></div>
          }
          <div>ยอดที่เหลือ : <b style={{ fontSize: '16px', color: 'red' }}>{currencyFormatOne(record.moneyCut)}</b></div>
        </>
      ),
    },
    {
      title: "ลูกหนี้อื่นๆ",
      dataIndex: "AROTHR",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          {
            <Space>
              <>{currencyFormatOne(record.AROTHR)}</>
            </Space>
          }
        </Space>
      ),
    },
    {
      title: "รับฝาก",
      dataIndex: "TOTAMT",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          {
            <Space>
              <>{currencyFormatOne(record.TOTAMT)}</>
            </Space>
          }
        </Space>
      ),
    },
    {
      key: 'Action',
      title: "Action",
      fixed: 'right',
      align: 'center',
      width: "10%",
      render: (record) => (
        <Space size="middle">
          <Button onClick={() => {
            setIsModalInfo(true)
            setConnt(record)
          }}><SearchOutlined /></Button>
        </Space>
      ),
    },
  ];
  return <>
    <Card
      bordered={false}
      style={{
        width: 'auto',
      }}
    >
      <Row style={{ textAlign: 'center' }}>
        <Col span={24}>
          <Spin spinning={loading} size='large' tip=" Loading... ">
            <Form>
              <div className='text-center'>
                <h2>รับเงินเข้างวด ออโต้ test</h2>
              </div>
              <Row gutter={32} justify={'center'}>
                <Row gutter={24} justify={'center'} style={{ margin: 10 }}>
                  <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                    <Space>
                      <>{"เลือกวันที่ต้องการ :"}</>
                      <DatePicker format={'YYYY-MM-DD'}
                        defaultValue={currentDateTime}
                        style={{ height: '40px' }}
                        onChange={(e) => {
                          if (e) {
                            setDataPost({ ...dataPost, days1: e.format('YYYY-MM-DD') })
                          }
                        }} />
                      {/* <>{"ถึง"}</>
                    <DatePicker format={'YYYY-MM-DD'}
                      defaultValue={currentDateTime}
                      style={{ height: '40px' }}
                      onChange={(e) => {
                        if (e) {
                          setDataPost({ ...dataPost, days2: e.format('YYYY-MM-DD') })
                        }
                      }} /> */}
                      <Button disabled={loading} type='primary' onClick={handleSubmit} >ค้นหาสัญญา</Button>
                      <Button style={{ marginRight: '15px' }} disabled={loading} onClick={showConfirm} >คอมเฟิร์มสัญญาที่จะตัดเข้าตารางดิว</Button>
                      {/* <Button style={{ marginRight: '15px' }} disabled={loading} onClick={testNoti} >ทดสอบแจ้งเตือน</Button> */}
                    </Space>
                  </Col>
                </Row>
              </Row>
              <Divider style={{ margin: 5 }} />
              <Row gutter={32} justify={'center'}>
                <Col span={12}>
                  <Card title="รายละเอียดสัญญา" bordered={false}>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="ยอดสัญญาทั้งหมด" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(resMix?.sumMoney)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                        </Form.Item>
                        <Form.Item label="ยอดสัญญาทั้งหมดที่หาพบ" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(resMix?.sumMoneyON)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                        </Form.Item>
                        <Form.Item label="ยอดสัญญาทั้งหมดที่หาไม่พบ" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(resMix?.sumMoneyOFF)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="สัญญาทั้งหมด" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', color: 'red' }}>{resMix?.countAllContno}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> สัญญา</span>
                        </Form.Item>
                        <Form.Item label="สัญญาที่หาพบ" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', color: 'red' }}>{resMix?.countContnoT}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> สัญญา</span>
                        </Form.Item>
                        <Form.Item label="สัญญาหาไม่พบ" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', color: 'red' }}>{resMix?.countContnoF}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> สัญญา</span>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card title="รายละเอียดการตัดเข้าค่างวด" bordered={false}>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="สัญญาที่ตัดเข้างวดได้" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', color: 'red' }}>{dataDetail.arr?.length}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> สัญญา</span>
                        </Form.Item>
                        <Form.Item label="ยอดที่ตัดเข้า" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(dataDetail?.money)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="สัญญาที่เลือก" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', color: 'red' }}>{selectedRows?.length}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> สัญญา</span>
                        </Form.Item>
                        <Form.Item label="ยอดรวม" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(checkMoney)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Divider style={{ margin: 5 }} />
              <Row gutter={32} style={{ margin: 10 }}>
                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                  <Input.Search
                    style={{ width: '250px' }}
                    placeholder="ค้นหาสัญญา...."
                    onChange={search}
                    name="search"
                    id="search"
                    size="large"
                    value={query}
                  />
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={24}>
                  <Table
                    // pagination={false}
                    rowKey={(record) => record.keyId}
                    scroll={{
                      x: 1500,
                      y: 400,
                    }}
                    dataSource={arrayTable}
                    columns={columns}
                  >
                  </Table>
                  <Divider />
                  {/* <Pagination
                  current={currentPage}
                  onChange={onChangePagination}
                  onShowSizeChange={onChangePagination}
                  defaultPageSize={10}
                  defaultCurrent={1}
                  total={testPage?.totalCount}
                /> */}
                  {/* <Divider /> */}
                </Col>
              </Row>
            </Form>
          </Spin>
        </Col>
      </Row>
    </Card >
    {
      isModalInfo ?
        <DetailContno
          open={isModalInfo}
          close={setIsModalInfo}
          dataFromTable={connt}
        />
        : null
    }
    {contextHolder}
  </>
}

