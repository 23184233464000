//แก้แล้วแต่ input ค่ายังไม่เปลี่ยนใน pdf
import React, { useState, useEffect } from "react";
import { Button, Table, DatePicker, Select, InputNumber, Space, Input, Row, Card,Checkbox } from "antd";
import axios from "axios";
import DotaColums from "./dotaColums";
import DataCase from "./dotaCase";
import { getlandpn1 } from "../../file_mid/all_api";
import '../../css/Media.css'

const { RangePicker } = DatePicker;
function TabelLandRegistrationSet() {
  const [inputDate, setInputDate] = useState([]);
  const [inputCase, setInputCase] = useState([]);
  const [inputDateText, setInputDateText] = useState([]);
  const [inputDateText2, setInputDateText2] = useState([]);
  const [inputDateText3, setInputDateText3] = useState([]);
  const [inputDateText4, setInputDateText4] = useState([]);
  const [inputDateText5, setInputDateText5] = useState([]);
  const [inputDateText6, setInputDateText6] = useState([]);
  const [inputDateText7, setInputDateText7] = useState([]);
  const [inputDateText8, setInputDateText8] = useState(true);

  //เซ็ต ค่าวันที่ ถึงวันที่
  const onChangeDate = (dateSelect2) => {
    setInputDate(dateSelect2);
  };
  //case
  const onChangeCase = (value) => {
    //console.log(`selected ${value}`);
    setInputCase(value);
  };
  //time
  const onChangeDatePicker = (date, dateString) => {
    //console.log(date, dateString);
    setInputDateText(dateString);
  };
  const onChangeDatePicker2 = (date, dateString) => {
    //console.log(date, dateString);
    setInputDateText2(dateString);
  };
  //ตัวเลข
  const onChangeDatePicker3 = (dataint) => {
    //console.log("dataint1", dataint);
    setInputDateText3(dataint);
  };
  const onChangeDatePicker4 = (dataint) => {
    //console.log("dataint2", dataint);
    setInputDateText4(dataint);
  };
  const onChangeDatePicker5 = (event) => {
    const value = event.target.value;
    setInputDateText5(value);
  };
  const onChangeDatePicker6 = (event) => {
    const value = event.target.value;
    setInputDateText6(value);
  };
  //ตัวเลข
  const onChangeDatePicker7 = (dataint) => {
    setInputDateText7(dataint);
  };
  const onChangeCheckbox8 = (e) => {
    const Checkbox8 = `${e.target.checked}`;
      setInputDateText8(Checkbox8);
  };
  //ประกาศตัวแปรรับ tabel
  const [dataTabel, setDataTabel] = useState([]);
  const [checkDataTabel, setcheckDataTabel] = useState([]);
  //Button1
  const onClickA = () => {
    if (inputDate?.length === 0) {
      alert("เลือกวันที่-ถึงวันที่ก่อน");
      return;
    } else if (inputCase?.length === 0) {
      alert("เลือกเรื่องก่อน");
      return;
    } else if (inputDateText2?.length === 0) {
      alert("เลือกวันประชุมก่อน");
      return;
    } else if (inputDateText?.length === 0) {
      alert("เลือกวันหนังสือมอบอำนาจ");
      return;
    } else if (inputDateText3?.length === 0) {
      alert("กรอกเลขที่ดิน");
      return;
    } else if (inputDateText4?.length === 0) {
      alert("กรอกเลขหน้าสำรวจ");
      return;
    } else if (inputDateText7?.length === 0) {
      alert("กรอกเลขการประชุมครั้งที่");
    }
    else {
      setDataTabel(checkDataTabel);
    }
  };

  //useEffect สำหรับเช็คการเปลี่ยนแปลงของ
  useEffect(() => {
    const fetchData = async () => {
      await axios
        .post(
          getlandpn1,
          //"https://api-checkinfoland-i67f6gaaqa-as.a.run.app/info_land/getlandpn",
          {
            days1: inputDate[0],
            days2: inputDate[1],
            approvalStatus: "ทั้งหมด",
          }
        )
        .then((res) => {
          //2024-04-02 วันที่มีเคส
          //console.log("res11",res.data);
          const filteredapprovalStatus = res.data.filter((item) =>
            item.approvalStatus === 3 ||
            item.approvalStatus === 24 ||
            item.approvalStatus === 26 ||
            item.approvalStatus === 27 ||
            item.approvalStatus === 21
          );
          const newDataFilteredapprovalStatus = filteredapprovalStatus.map(
            (item) => ({
              ...item,
              inputCase: inputCase, // สมมติว่า inputCase มาจากตัวแปรใดตัวหนึ่งที่กำหนดไว้นอกฟังก์ชัน onClickA
              inputDateText: inputDateText,
              inputDateText2: inputDateText2,
              inputDateText3: inputDateText3,
              inputDateText4: inputDateText4,
              inputDateText5: inputDateText5,
              inputDateText6: inputDateText6,
              inputDateText7: inputDateText7,
              inputDateText8: inputDateText8
            })
          );
          setcheckDataTabel(newDataFilteredapprovalStatus);
        })
        .catch((err) => console.log(err.message));
    };
    fetchData();
  }, [
    inputDate,
    inputCase,
    inputDateText,
    inputDateText2,
    inputDateText3,
    inputDateText4,
    inputDateText5,
    inputDateText6,
    inputDateText7,
    inputDateText8
  ]);
  //console.log("inputDateText4",inputDateText4);
  return (
    <>
      <Card>
        <Row justify={'center'}>
          <h1>แบบฟร์อมชุดจดที่ดิน</h1>
        </Row>

        <Row justify={'center'}>
          <Row className="main">
            <aside>
              <div style={{ display: 'flex', alignItems: 'center', margin: 2 }}>
                <span style={{ width: '100px', textAlign: 'right', paddingRight: '10px' }}>
                  เลือกวันที่ :
                </span>
                <RangePicker
                  placeholder={["เลือกวันที่", "ถึงวันที่"]}
                  style={{ height: '40px', width: '280px', }}
                  format="YYYY-MM-DD"
                  onChange={(dateSelect, dateSelect2) => {
                    onChangeDate(dateSelect2);
                  }}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', margin: 2 }}>
                <span style={{ width: '100px', textAlign: 'right', paddingRight: '10px' }}>
                  เลือกเรื่อง :
                </span>
                <Select
                  style={{ height: '40px', width: '280px', }}
                  showSearch
                  placeholder="เลือกเรื่อง"
                  optionFilterProp="children"
                  onChange={(value) => {
                    onChangeCase(value);
                  }}
                  options={DataCase}
                  value={inputCase}
                  defaultValue={"รับจำนองที่ดิน"}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', margin: 2 }}>
                <span style={{ width: '100px', textAlign: 'right', paddingRight: '10px' }}>
                  วันประชุม :
                </span>
                <DatePicker onChange={onChangeDatePicker2} style={{ height: '40px', width: '280px', }} placeholder="วันประชุม" />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', margin: 2 }}>
                <span style={{ width: '100px', textAlign: 'right', paddingRight: '10px' }}>
                  วันหนังสือมอบอำนาจ :
                </span>
                <DatePicker onChange={onChangeDatePicker} style={{ height: '40px', width: '280px', }} placeholder="วันหนังสือมอบอำนาจ" />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', margin: 2 }}>
                <span style={{ width: '100px', textAlign: 'right', paddingRight: '10px' }}>
                  เลขที่ดิน :
                </span>
                <InputNumber placeholder="เลขที่ดิน" style={{ height: '40px', width: '280px', }} onChange={onChangeDatePicker3} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', margin: 2 }}>
                <span style={{ width: '100px', textAlign: 'right', paddingRight: '10px' }}>
                  หน้าสำรวจ :
                </span>
                <InputNumber placeholder="หน้าสำรวจ" style={{ height: '40px', width: '280px', }} onChange={onChangeDatePicker4} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', margin: 2 }}>
                <span style={{ width: '100px', textAlign: 'right', paddingRight: '10px' }}>
                  ครั้งที่ :
                </span>
                <InputNumber placeholder="ครั้งที่" style={{ height: '40px', width: '280px', }} onChange={onChangeDatePicker7} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', margin: 2 }}>
                <span style={{ width: '100px', textAlign: 'right', paddingRight: '10px' }}>
                  ระวาง :
                </span>
                <Checkbox style={{ width: "30px", marginLeft: "20px", transform: "scale(1.4)"}} onChange={onChangeCheckbox8} defaultChecked={true}></Checkbox>
                <Input placeholder="ระวาง" style={{ height: '40px', width: '280px', }} onChange={onChangeDatePicker5} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', margin: 2 }}>
                <span style={{ width: '100px', textAlign: 'right', paddingRight: '10px' }}>
                  ตำบล :
                </span>
                <Input placeholder="ตำบล" style={{ height: '40px', width: '280px', }} onChange={onChangeDatePicker6} />
              </div>
            </aside>
          </Row>
        </Row>
        <Row justify={'center'} style={{ marginTop: '15px' }}>
          <b>
            <label> วันที่ : </label>
            <label style={{ color: "#0D36EE" }}>{inputDate[0]}</label>
            <label> ถึงวันที่ : </label>
            <label style={{ color: "#0D36EE" }}>{inputDate[1]}</label>
          </b>
        </Row>
        <Row justify={'center'} style={{ margin: '10px' }}>
          <Button
            type="primary"
            // onClick={postData}
            onClick={onClickA}
            style={{ width: '200px' }}
          >
            ค้นหา
          </Button>
        </Row>
        <Row justify={'center'}>
          <Table
            columns={DotaColums}
            dataSource={dataTabel.map((item, index) => ({ ...item, key: index }))}
            style={{ width: "100%" }}
          />
        </Row>
      </Card>
    </>
  );
}
export default TabelLandRegistrationSet;
