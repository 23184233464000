import { Button, Card, Space, Row, Spin, Form, Col, Divider, Image, Upload, Select, message, Modal, notification } from 'antd'
import React, { useState, useEffect } from 'react'
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs';
import Compressor from 'compressorjs';
import { getImagess, mutips2, testupload, checkNewCCAP, bookCar, bookCarBig } from '../../file_mid/all_api';
import { checkSNAM } from '../../file_mid/condition';
const { Option } = Select;

function UploadImg({ page, changepage, close, data, allData, setSendBack }) {

    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const token = localStorage.getItem('token');
    const addCars = localStorage.getItem('addCar')


    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileListCustomer, setFileListCustomer] = useState([]);
    const [fileListCustomerCar, setFileListCustomerCar] = useState([]);
    const [fileListCustomerDocument, setFileListCustomerDocument] = useState([]);
    const [fileListCustomerStatementImage, setFileListCustomerStatementImage] = useState([]);
    const [fileListCustomerStatementPDF, setFileListCustomerStatementPDF] = useState([]);
    const [fileListCustomerCareer, setFileListCustomerCareer] = useState([]);
    const [fileListCustomerDocument1, setFileListCustomerDocument1] = useState([]);
    const [fileList, setFileList] = useState([]);

    /*เก็บรูปทั้งหมด*/
    const [allImg, setAllImg] = useState([]);
    const [dataAddImage, setDataAddImage] = useState([]) // โชว์รูป
    const [checkIDCard] = useState([]) // 
    const [selectTypeCar, setSelectTypeCar] = useState(1) // 1 รถ , 2 ที่ดิน
    const [count] = useState()

    const newFormData = new FormData();
    const [checkSnam, setCheckSnam] = useState(false);
    const [dataPreCustomer, setDataPreCustomer] = useState();
    const [dataPreAddress, setDataPreAddress] = useState();
    const [foundCus, setFoundCus] = useState(false);
    const [readCard, setReadCard] = useState(false);
    const [DATAADDCARS, setDATAADDCARS] = useState();
    const [api, contextHolder] = notification.useNotification();
    // const dataAddCars = JSON.parse(addCars)

    useEffect(() => {
        // if (addCars) {
        //     setDATAADDCARS(JSON.parse(addCars))
        // }
        if (addCars) {
            try {
                const parsedData = JSON.parse(addCars);
                setDATAADDCARS(parsedData);
            } catch (error) {
                console.error("Error parsing JSON:", error);
                // Handle the error, maybe set an empty array or an error state
                setDATAADDCARS();
            }
        } else {
            // Handle the case where there is no data in localStorage
            setDATAADDCARS();
        }

        setSelectTypeCar({ ...selectTypeCar, productTypeCar: data?.productTypeCar })
        form.setFieldsValue({
            productTypeId: 1,
            productTypeCar: data?.productTypeCar
        })
        if (allData?.cars?.img?.length > 0) {
            var imgNew = []
            allData?.cars?.img.map((e) => {
                if (e.type !== 14) {
                    imgNew.push(e)
                }
                if (e.type === 4) {
                    checkIDCard.push(e)
                }
            })
            setDataAddImage(imgNew)
        }
        // console.log("data", data)
        if (checkSNAM(data.snam)) {
            setCheckSnam(true)
        }
    }, [allData])

    const imgSend = (resultImgs) => {
        return new Promise(async (resolve, reject) => {
            try {
                var status;
                newFormData.append('idcus', data?.carId);
                //newFormData.append('productTypeId', "1");
                newFormData.append('tm', dayjs().format("YYYY-MM-DDTHH:mm:ssZ"));

                for (const file of resultImgs) {
                    var image = {}
                    newFormData.append('file', file.imageCompressedFile);
                    newFormData.append("type", file.type);
                    image.type = file.type
                }
                const response = await axios.post(mutips2, newFormData);
                if (response.data) {
                    setAllImg([])
                    console.log("TY", response.data);
                    status = response.status;
                    resolve(status);
                }

            } catch (error) {
                error(error);
                reject(error);
            } finally {
                setLoading(false);
                console.log("OK")
                setSendBack(dayjs())
                handleGo()
            }
        });
    };

    const notiNotIDCARD = (placement) => {
        api.warning({
            message: <b>กรุณาเพิ่มรูปบัตรประชาชน</b>,
            placement,
        });
    }

    const handleSubmit = async () => {
        setLoading(true)
        await handleReadBookCar()
        if (!checkSnam) {
            ///เช็ครูปบัตร///
            if (fileListCustomer?.length > 0 || checkIDCard.length > 0) {
                if (readCard) {
                    if (!foundCus) {
                        localStorage.setItem('addCustomer2', JSON.stringify(dataPreCustomer))
                        // localStorage.setItem('addPhone2', JSON.stringify(dataPreCustomer))
                        localStorage.setItem('addAddress', JSON.stringify(dataPreAddress))
                    }
                } else {
                    // localStorage.removeItem('addCustomer2');
                    // localStorage.removeItem('addAddress');
                }
                if (allImg?.length > 0) {
                    await imgSend(allImg)
                } else {
                    handleGo()
                }
            } else {
                notiNotIDCARD("top")
                // handleGo()
            }
        } else {
            if (allImg?.length > 0) {
                await imgSend(allImg)
            } else {
                handleGo()
            }
        }
        setLoading(false)
    }

    const handleGo = () => {
        setLoading(false)
        changepage(page + 1)
    }
    const handleClose = () => {
        close()
    }

    const compressImage = async (ez, types) => {
        var image = {};
        return new Promise(async (resolve) => {
            if (types !== 7) {
                if (ez.size > 4000000) {
                    console.log("4m ไม่ให้ส่ง");
                    resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                } else {
                    let quality;
                    if (ez.size >= 2500000 && ez.size <= 4000000) {
                        quality = 0.1;
                    } else if (ez.size >= 2000000 && ez.size <= 2499999) {
                        quality = 0.15;
                    } else if (ez.size >= 1500000 && ez.size <= 1999999) {
                        quality = 0.2;
                    } else if (ez.size >= 1000000 && ez.size <= 1499999) {
                        quality = 0.3;
                    } else {
                        quality = 0.5;
                    }

                    try {
                        // ใช้ Promise เพื่อบีบอัดรูปภาพ
                        const compressedFile = await new Promise((resolveCompress) => {
                            new Compressor(ez, {
                                quality: quality,
                                success: (result) => {
                                    resolveCompress(result);
                                },
                            });
                        });

                        const fileOptions = {
                            type: compressedFile.type,
                            lastModified: Date.now(),
                        };
                        const compressedFileAsFile = new File([compressedFile], ez.name, fileOptions);
                        image.imageCompressedFile = compressedFileAsFile;
                        image.type = types
                        allImg.push(image);
                        //setAllImg((prevAllImg) => [...prevAllImg, image]);

                        // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
                        resolve(image);
                    } catch (error) {
                        console.error('Image compression error:', error);
                        resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                    }
                }
            } else {
                // ... (โลจิกสำหรับ type 7)
                image.imageCompressedFile = ez;
                image.type = types
                allImg.push(image);
                //setAllImg((prevAllImg) => [...prevAllImg, image]);
                resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
            }
        });
    };
    const beforeUpts = async (file, ty) => {
        try {
            setLoading(true)
            const compressedFile = await compressImage(file, ty);
            return Promise.resolve(compressedFile);
        } catch (error) {
            console.error('Image compression error:', error);
            message.error('Image compression failed.');
            return Promise.reject(); // เปลี่ยนให้ return Promise.reject() เมื่อไม่สามารถบีบอัดได้
        }
    };

    async function convertFileToBlob(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const blob = new Blob([reader.result], { type: file.type });
                resolve(blob);
            };
            reader.onerror = reject;

            reader.readAsArrayBuffer(file.originFileObj);
        });
    }

    // const handleProductTypeCar = (value) => {
    //     setSelectTypeCar({ ...selectTypeCar, productTypeCar: value })
    // }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const handleCancel = () => {
        setPreviewVisible(false);
    }

    ///////////////// อ่านบัตร //////////////////////////////
    const handleChangeCustomer = async ({ fileList }) => { // 4
        for (const removedFile of fileListCustomer.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 4)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 4));
                setAllImg(updatedAllImg);
            }
        }

        setFileListCustomer(fileList);
        if (fileList) {
            handleFileInputChange(fileList);
        } else {
            handleFileInputChange([]);
        }
        setLoading(false)
    };
    ////////////// รับไฟล์รูปบัตร /////////////////////////////////
    const handleFileInputChange = async (event) => {
        if (event.length > 0) {
            var file = event[0].originFileObj
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg']
            if (file) {
                if (validImageTypes.includes(file.type)) {
                    const reader = new FileReader(); // สร้าง FileReader object
                    reader.onloadend = () => { // ฟังก์ชันที่ทำงานเมื่ออ่านไฟล์เสร็จสิ้น    
                        const base64Data = reader.result; // รับข้อมูล Base64 จาก FileReader result
                        handleScanIdCard(base64Data, event)
                    }
                    reader.readAsDataURL(file) // อ่านไฟล์และแปลงเป็น Base64    
                }
            }
        }
    }
    /////////// ดึงข้อมูลจากรูปบัตรประชาชน ///////////////////////////
    const handleScanIdCard = async (value, event) => {
        const imgId = { image: value }
        setLoading(true)
        const headers = { 'Content-Type': 'application/json' }
        await axios.post(testupload, imgId, { headers: headers })
            .then(res => {
                if (res.data) {
                    // console.log("res.data[0]", res.data[0])
                    if (res.data[0]?.identificationId) {
                        if (data?.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" || data?.productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา") {
                            splitAddress(res.data[0])
                            setDataPreCustomer(res.data[0])
                            loadDataOne(res.data[0])
                            setLoading(false)
                        } else {
                            if (res.data[0].identificationId === allData.identificationId) {
                                splitAddress(res.data[0])
                                setDataPreCustomer(res.data[0])
                                loadDataOne(res.data[0])
                                setLoading(false)
                            } else {
                                alert('บัตรไม่ตรงกับข้อมูลลูกค้า')
                                setFileListCustomer([])
                                if (allImg.some(img => img.imageCompressedFile.name === event[0].name && img.type === 4)) {
                                    const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === event[0].name && img.type === 4));
                                    setAllImg(updatedAllImg);
                                }
                                setLoading(false)
                            }
                        }
                    } else {
                        localStorage.removeItem('addPhone2');
                        localStorage.removeItem('addAddress');
                        localStorage.removeItem('addCareerIncome');
                        localStorage.removeItem('addCustomer2');
                        alert('ไม่สามารถอ่านบัตรได้')
                        setLoading(false)
                    }
                } else {
                    localStorage.removeItem('addPhone2');
                    localStorage.removeItem('addAddress');
                    localStorage.removeItem('addCareerIncome');
                    localStorage.removeItem('addCustomer2');
                    alert('ไม่สามารถอ่านบัตรได้')
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log("err", err)
                localStorage.removeItem('addPhone2');
                localStorage.removeItem('addAddress');
                localStorage.removeItem('addCareerIncome');
                localStorage.removeItem('addCustomer2');
                alert('ไม่สามารถอ่านบัตรได้')
            })
        setLoading(false)
    }
    //////////////// เก็บแอสเดรส ////////////////////////////////////
    const splitAddress = (value) => {
        let updatedCount = { ...count }
        let part = value.address.split(" ")
        if (part[1].includes("หมู่ที่") === true) {
            updatedCount.houseNumber = part[0] + part[1] + part[2]
        } else {
            updatedCount.houseNumber = part[0]
        }
        for (let i = 0; i <= part.length; i++) {
            if (part[i]?.includes("ซ.") === true) {
                let s = part[i].split("ซ.")
                //setSoi(s[1])
                updatedCount.soi = s[1]
            }
            if (part[i]?.includes("ถ.") === true) {
                let tanon = part[i].split("ถ.")
                //setRoad(tanon[1])
                updatedCount.road = tanon[1]
            }
            if (part[i]?.includes("ต.") === true) {
                let bn = part[i].split("ต.")
                //setSubdistrict(bn[1])
                updatedCount.subdistrict = bn[1]
            }
            if (part[i]?.includes("อ.") === true) {
                let oo = part[i].split("อ.")
                let oop = oo[1].split(",")
                if (oo[1]?.includes(",") === false) {
                    //setDistrict(oo[1])
                    updatedCount.district = oo[1]
                }
                if (oop[1]?.includes("จ.") === true) {
                    //setDistrict(oop[0])
                    updatedCount.district = oop[0]
                    let oops = oop[1].split("จ.")
                    //setProvince(oops[1])
                    updatedCount.province = oops[1]
                }
            }
            if (part[i]?.includes("จ.") === true) {
                let jh = part[i].split("จ.")
                //setProvince(jh[1])
                updatedCount.province = jh[1]
            }
        }
        // setDataAddress({
        //     ...dataAddress,
        //     customerId: 0,
        //     addressId: 0,
        //     houseNumber: updatedCount.houseNumber,
        //     houseRegistrationNumber: updatedCount.houseRegistrationNumber,
        //     village: updatedCount.village,
        //     zipCode: updatedCount.zipCode,
        //     soi: updatedCount.soi,
        //     road: updatedCount.road,
        //     subdistrict: updatedCount.subdistrict,
        //     district: updatedCount.district,
        //     province: updatedCount.province,
        //     addressStatus: true,
        //     addressOnIdcard: true,
        //     workAddress: false,
        //     otherAddress: false,
        //     currentAddress: false,
        //     sendDocuments: false,
        //     houseRegistration: false,
        // })
        setDataPreAddress([{
            ...dataPreAddress,
            customerId: data.customerId,
            addressId: 0,
            houseNumber: updatedCount.houseNumber,
            houseRegistrationNumber: updatedCount.houseRegistrationNumber,
            village: updatedCount.village,
            zipCode: updatedCount.zipCode,
            soi: updatedCount.soi,
            road: updatedCount.road,
            subdistrict: updatedCount.subdistrict,
            district: updatedCount.district,
            province: updatedCount.province,
            addressStatus: true,
            addressOnIdcard: true,
            workAddress: false,
            otherAddress: false,
            currentAddress: false,
            sendDocuments: false,
            houseRegistration: false,
        }])
    }
    ////////////// ข้อมูลยูสในเดต้าเบส ////////////////////////////////////////////////////////////////
    const loadDataOne = async (data) => {
        const one = { identificationId: data.identificationId }
        if (!data.identificationId) {
            console.log("ไม่มีเลขบัตรคนกู้")
            alert('ไม่สามารถอ่านบัตรผู้ขอกู้ได้')
            setReadCard(false)
            localStorage.removeItem('addCareerIncome');
        } else {
            setLoading(true)
            await axios.post(checkNewCCAP, one)
                .then((res) => {
                    if (res.data) {
                        // delete res.data.address
                        delete res.data.emails
                        // delete res.data.ojs
                        delete res.data.socials
                        message.info('มีข้อมูลในฐานข้อมูล')
                        // console.log("res.data", res.data)
                        localStorage.setItem('addPhone2', JSON.stringify(res.data?.phones))
                        localStorage.setItem('addAddress', JSON.stringify(res.data?.address))
                        if (res.data?.ojs.length > 0) {
                            localStorage.setItem('addCareerIncome', JSON.stringify(res.data?.ojs[0]))
                        }
                        delete res.data.ojs
                        delete res.data.address
                        localStorage.setItem('addCustomer2', JSON.stringify(res.data))
                        setFoundCus(true)
                    }
                    else {
                        message.info('ไม่พบข้อมูลในฐานข้อมูล')
                        setFoundCus(false)
                    }
                    setReadCard(true)
                })
                .catch((err) => console.log(err))
            setLoading(false)
        }
    }

    ///////////////// อัปโหลดรูปภาพ //////////////////////////////
    const handleChangeCustomerCar = async ({ fileList }) => { // 1
        for (const removedFile of fileListCustomerCar.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 1)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 1));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerCar(fileList);
        setLoading(false)
    };

    /////////อ่านเล่มรถ/////////// 
    const handleChangeCustomerDocument = async ({ fileList }) => { // 2
        for (const removedFile of fileListCustomerDocument.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 2)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 2));
                setAllImg(updatedAllImg);
            }
        }
        console.log("fileList book", fileList)
        setFileListCustomerDocument(fileList);
        var imageListCustomerDocument = []
        const formData = new FormData()
        for (const file of fileList) {
            const blob = await convertFileToBlob(file);
            formData.append('image', blob, file.name);
            formData.append('productTypeCar', data?.productTypeCar);
        }
        console.log("formData", formData)
        setFileList(formData)
        setFileListCustomerDocument1({ ...fileListCustomerDocument1, imageListCustomerDocument });
        setLoading(false)
    };

    const handleReadBookCar = async () => {
        if (data?.productTypeCar === 1 || data?.productTypeCar === 2) {
            console.log("รถเล็ก")
            if (fileListCustomerDocument?.length > 0) {
                console.log("มีเล่มรถเล็ก")
                await handleDocumentsCar() // functions read Document Car
            } else {
                console.log("ไม่มีเล่มรถเล็ก")
            }
        } else if (data?.productTypeCar === 3) {
            console.log("รถใหญ่")
            if (fileListCustomerDocument?.length > 0) {
                console.log("มีเล่มรถใหญ่")
                await handleDocumentsTruckandVehicles() // functions read Document Car
            } else {
                console.log("ไม่มีเล่มรถใหญ่")
            }
        }
    }

    const handleDocumentsCar = async () => {  //โยนไปปุ่ม submits
        console.log("รกเล็ก")
        // setLoading(true)
        await axios.post(bookCar, fileList) //ตัวจริง
            .then(res => {
                if (res.data) {
                    localStorage.setItem('addCar', JSON.stringify({
                        ...DATAADDCARS,
                        vehicleModel: res.data?.result?.vehicle_model,
                    }))
                    // setLoading(false)
                }
            })
            .catch((err) => console.error('เกิดข้อผิดพลาดในการอัปโหลดรูปภาพ:', err))
        // setLoading(false)
    };
    const handleDocumentsTruckandVehicles = async () => { //โยนไปปุ่ม submit
        // setLoading(true)
        await axios.post(bookCarBig, fileList) //ตัวจริง
            .then(res => {
                if (res.data) {
                    localStorage.setItem('addCar', JSON.stringify({
                        ...DATAADDCARS,
                        vehicleModel: res.data?.result?.vehicle_model,
                    }))
                    // setLoading(false)
                }
            })
            .catch((err) => console.error('เกิดข้อผิดพลาดในการอัปโหลดรูปภาพ:', err))
        // setLoading(false)
    };

    // const handleChangeCustomerDocument = async ({ fileList }) => { // 2
    //     for (const removedFile of fileListCustomerDocument.filter(file => file.status === 'removed')) {
    //         if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 2)) {
    //             const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 2));
    //             setAllImg(updatedAllImg);
    //         }
    //     }
    //     setFileListCustomerDocument(fileList);
    //     setLoading(false)
    // };

    const handleChangeCustomerStatementImage = async ({ fileList }) => { // 8
        for (const removedFile of fileListCustomerStatementImage.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 8)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 8));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerStatementImage(fileList);
        setLoading(false)
    };
    const handleChangeCustomerStatementPDF = async ({ fileList }) => { // 7
        for (const removedFile of fileListCustomerStatementPDF.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 7)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 7));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerStatementPDF(fileList);
        setLoading(false)
    };
    const handleChangeCustomerCareer = async ({ fileList }) => { // 6
        for (const removedFile of fileListCustomerCareer.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 6)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 6));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerCareer(fileList);
        setLoading(false)
    };

    return (
        <Row justify={'center'}>
            <Card style={{ width: '1000px' }}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <div className='text-center'>
                        <h2>อัปโหลดรูปภาพเสนอเคส</h2>
                    </div>
                    <Divider />
                    <Row justify={'center'}>
                        <Form
                            name="basic"
                            labelCol={{
                                span: 12,
                            }}

                            style={{
                                width: '90%',
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            form={form}
                            onFinish={handleSubmit}
                            autoComplete="off"
                        >
                            {/* <Card style={{ margin: 0 }}> */}
                            <Col className='main1'>
                                <Row justify={'center'}>
                                    <b>
                                        <Form.Item label='เลือกเสนอเคส' name='productTypeId'
                                            style={{ textAlign: 'center', width: '300px' }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your username!',
                                                },
                                            ]}
                                        >
                                            <Select placeholder={'เลือก'}
                                                disabled
                                                style={{ width: '250px' }}
                                            // onChange={(value) => handleProduct(value)}
                                            >
                                                <Option value={1}>รถ</Option>
                                                <Option value={2}>พรี-ที่ดิน</Option>
                                                <Option value={3}>ขอยอดที่ดิน</Option>
                                            </Select>
                                        </Form.Item>
                                    </b>
                                </Row>
                                <Row justify={'center'}>
                                    <b>
                                        <Form.Item label='เลือกประเภทรถ' name='productTypeCar'
                                            style={{ textAlign: 'center', width: '300px' }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your username!',
                                                },
                                            ]}
                                        >
                                            <Select placeholder={'เลือก'}
                                                style={{ width: '250px' }}
                                                disabled
                                            // onChange={(value) => handleProductTypeCar(value)}
                                            >
                                                <Option value={1}>รถยนต์</Option>
                                                <Option value={2}>รถเครื่องจักการเกษตร</Option>
                                                <Option value={3}>รถบรรทุก</Option>
                                            </Select>
                                        </Form.Item>
                                    </b>
                                </Row>
                                <Divider style={{ margin: 5 }} />
                                {
                                    checkSnam ?
                                        <>
                                            <Row justify={'left'}><b><h3><u>รูปบัตรประชาชน (อัพโหลดไฟล์ไม่เกิน 3MB)</u></h3></b></Row>
                                            <Row justify={'center'} style={{ margin: 5 }}><b><h4 style={{ color: 'red' }}><u>เป็น "นิติบุคคล" ไม่ต้องอัพรูปในช่องผู้ทำสัญญา</u></h4></b></Row>
                                            <Row justify={'center'}>
                                                <div className='gutter-row' span={12}>
                                                    <b>
                                                        {dataAddImage?.map((imageData, index) => {
                                                            if (imageData.type === 4) {
                                                                return (
                                                                    <Row justify={'center'}>
                                                                        <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                    </Row>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                        <Form.Item label='' style={{ textAlign: 'center' }}>
                                                            {/* <Upload
                                                                accept="image/*"
                                                                maxCount={1}
                                                                style={{ textAlign: 'center' }}
                                                                listType="picture-card"
                                                                fileList={fileListCustomer}
                                                                onPreview={handlePreview}
                                                                onChange={handleChangeCustomer}
                                                                beforeUpload={(file) => beforeUpts(file, 4).then(() => false).catch(() => true)}
                                                            > */}
                                                            {/* {fileListCustomer?.length >= 1 ? null : (
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div className="ant-upload-text">upload</div>
                                                                    </div>
                                                                )} */}
                                                            {/* </Upload>
                                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                            </Modal> */}
                                                        </Form.Item>
                                                    </b>
                                                </div>
                                            </Row>
                                            <Divider style={{ margin: 5 }} />
                                        </>
                                        :
                                        <>
                                            <Row justify={'left'}><b><h3><u>รูปบัตรประชาชน (อัพโหลดไฟล์ไม่เกิน 3MB)</u></h3></b></Row>
                                            <Row justify={'center'} style={{ margin: 5 }}><b><h4 style={{ color: 'red' }}><u>ถ้าเป็น "นิติบุคคล" ห้ามอัพรูปในช่องผู้ทำสัญญา</u></h4></b></Row>
                                            <Row justify={'center'}>
                                                <div className='gutter-row' span={12}>
                                                    <b>
                                                        {dataAddImage?.map((imageData, index) => {
                                                            if (imageData.type === 4) {
                                                                return (
                                                                    <Row justify={'center'}>
                                                                        <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                    </Row>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                        <Form.Item label='' style={{ textAlign: 'center' }}>
                                                            <Upload
                                                                accept="image/*"
                                                                maxCount={1}
                                                                style={{ textAlign: 'center' }}
                                                                listType="picture-card"
                                                                fileList={fileListCustomer}
                                                                onPreview={handlePreview}
                                                                onChange={handleChangeCustomer}
                                                                beforeUpload={(file) => beforeUpts(file, 4).then(() => false).catch(() => true)}
                                                            >
                                                                {fileListCustomer?.length >= 1 ? null : (
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div className="ant-upload-text">upload</div>
                                                                    </div>
                                                                )}
                                                            </Upload>
                                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                            </Modal>
                                                        </Form.Item>
                                                    </b>
                                                </div>
                                            </Row>
                                            <Divider style={{ margin: 5 }} />
                                        </>

                                }

                                {selectTypeCar?.productTypeCar === 1 || selectTypeCar?.productTypeCar === 2 || selectTypeCar?.productTypeCar === 3 ?
                                    <>
                                        <Row justify={'left'}><b><h3><u>หน้าคู่มือ (อัพโหลดไฟล์ไม่เกิน 3MB)</u></h3></b></Row>
                                        <Row justify={'center'}>
                                            <div className='gutter-row' span={12}>
                                                <b>
                                                    {dataAddImage?.map((imageData, index) => {
                                                        if (imageData.type === 2) {
                                                            return (
                                                                <Row justify={'center'}>
                                                                    <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Row>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                    <Form.Item
                                                        style={{ textAlign: 'center' }}
                                                    >
                                                        <Upload
                                                            accept="image/*"
                                                            maxCount={1}
                                                            listType="picture-card"
                                                            style={{ textAlign: 'center' }}
                                                            fileList={fileListCustomerDocument}
                                                            onPreview={handlePreview}
                                                            onChange={handleChangeCustomerDocument}
                                                            beforeUpload={(file) => beforeUpts(file, 2).then(() => false).catch(() => true)}
                                                        >
                                                            {fileListCustomerDocument?.length >= 1 ? null : (
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div className="ant-upload-text">upload</div>
                                                                </div>
                                                            )}
                                                        </Upload>
                                                        <Modal open={previewVisible} onCancel={handleCancel}>
                                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                        </Modal>
                                                    </Form.Item>
                                                </b>
                                            </div>
                                            {/* </aside> */}
                                        </Row>
                                    </>
                                    : null
                                }
                                <Divider style={{ margin: 5 }} />
                                <Row justify={'left'}><b><h3><u>ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม (อัพโหลดไฟล์ไม่เกิน 3MB)</u></h3></b></Row>
                                <Row justify={'center'}>
                                    {/* <aside> */}
                                    <b>
                                        {dataAddImage?.map((imageData, index) => {
                                            if (imageData.type === 1) {
                                                return (
                                                    <Row justify={'center'}>
                                                        <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                    </Row>
                                                );
                                            }
                                            return null;
                                        })}
                                        <Form.Item
                                            style={{ textAlign: 'center' }}
                                        >
                                            <Upload
                                                accept="image/*"
                                                multiple={true}
                                                style={{ textAlign: 'center' }}
                                                listType="picture-card"
                                                fileList={fileListCustomerCar}
                                                onPreview={handlePreview}
                                                onChange={handleChangeCustomerCar}
                                                //beforeUpload={() => false}
                                                beforeUpload={(file) => beforeUpts(file, 1).then(() => false).catch(() => true)}
                                            // beforeUpload={(file) => {
                                            //     setFileListCustomerCar([...fileListCustomerCar, file])
                                            //     return false;
                                            // }}
                                            >
                                                {fileListCustomerCar?.length >= 20 ? null : (
                                                    <div>
                                                        <PlusOutlined />
                                                        <div className="ant-upload-text">upload</div>
                                                    </div>
                                                )}
                                            </Upload>
                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                        </Form.Item>
                                    </b>
                                    {/* </aside> */}
                                </Row>
                                <Divider style={{ margin: 5 }} />
                                <Row justify={'left'}><b><h3><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ (อัพโหลดไฟล์ไม่เกิน 3MB)</u></h3></b></Row>
                                <Row justify={'center'}>
                                    {/* <aside justify={'center'}> */}
                                    <b>
                                        {dataAddImage?.map((imageData, index) => {
                                            if (imageData.type === 6) {
                                                return (
                                                    <Row justify={'center'}>
                                                        <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                    </Row>
                                                );
                                            }
                                            return null;
                                        })}
                                        <Form.Item
                                            style={{ textAlign: 'center' }}
                                        >
                                            <Upload
                                                accept="image/*"
                                                multiple={true}
                                                style={{ textAlign: 'center' }}
                                                listType="picture-card"
                                                fileList={fileListCustomerCareer}
                                                onPreview={handlePreview}
                                                onChange={handleChangeCustomerCareer}
                                                beforeUpload={(file) => beforeUpts(file, 6).then(() => false).catch(() => true)}
                                            >
                                                {fileListCustomerCareer?.length >= 20 ? null : (
                                                    <div>
                                                        <PlusOutlined />
                                                        <div className="ant-upload-text">upload</div>
                                                    </div>
                                                )}
                                            </Upload>
                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                        </Form.Item>
                                    </b>

                                    {/* </aside > */}
                                </Row>
                                <Divider style={{ margin: 5 }} />
                                <Row justify={'left'}><b><h3><u>รูปสเตทเมนต์ผู้กู้ (อัพโหลดไฟล์ไม่เกิน 3MB)</u></h3></b></Row>
                                <Row justify={'center'}>
                                    <Col span={24}>
                                        {/* <aside justify={'center'}> */}
                                        <Row justify={'center'}><b><h3><u>ไฟล์ภาพ</u></h3></b></Row>
                                        <Row justify={'center'}>
                                            <b>
                                                {dataAddImage?.map((imageData, index) => {
                                                    if (imageData.type === 8) {
                                                        return (
                                                            <Row justify={'center'}>
                                                                <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            </Row>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                                <Form.Item
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    <Upload
                                                        accept="image/*"
                                                        multiple={true}
                                                        style={{ textAlign: 'center' }}
                                                        listType="picture-card"
                                                        fileList={fileListCustomerStatementImage}
                                                        onPreview={handlePreview}
                                                        onChange={handleChangeCustomerStatementImage}
                                                        //beforeUpload={() => false}
                                                        beforeUpload={(file) => beforeUpts(file, 8).then(() => false).catch(() => true)}
                                                    // beforeUpload={(file) => {
                                                    //     setFileListCustomerStatementImage([...fileListCustomerStatementImage, file])
                                                    //     return false;
                                                    // }}
                                                    >
                                                        {fileListCustomerStatementImage?.length >= 20 ? null : (
                                                            <div>
                                                                <PlusOutlined />
                                                                <div className="ant-upload-text">upload</div>
                                                            </div>
                                                        )}
                                                    </Upload>
                                                    <Modal open={previewVisible} onCancel={handleCancel}>
                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </Form.Item>
                                            </b>
                                        </Row>
                                        {/* <div className='gutter-row' span={12}>
                                                        <Row justify={'center'}><b><h3><u>ไฟล์ PDF</u></h3></b></Row>
                                                        <b> <Form.Item
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                        >
                                                            <Upload
                                                                accept="application/pdf"
                                                                multiple={true}
                                                                style={{ textAlign: 'center' }}
                                                                listType="picture-card"
                                                                fileList={fileListCustomerStatementPDF}
                                                                onPreview={handlePreview}
                                                                onChange={handleChangeCustomerStatementPDF}
                                                                //beforeUpload={() => false}
                                                                beforeUpload={(file) => beforeUpts(file, 7).then(() => false).catch(() => true)}
                                                            >
                                                                {fileListCustomerStatementPDF?.length >= 10 ? null : (
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div className="ant-upload-text">upload</div>
                                                                    </div>
                                                                )}
                                                            </Upload>
                                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                            </Modal>
                                                        </Form.Item>
                                                        </b>
                                                    </div> */}
                                        {/* </aside> */}
                                    </Col>
                                </Row>
                                <Divider style={{ margin: 5 }} />
                                <Row justify={'left'}><b><h3><u>สเตทเมนต์ผู้กู้ (PDF)</u></h3></b></Row>
                                <Row justify={'center'}>
                                    <Col span={24}>
                                        {/* <aside justify={'center'}> */}
                                        {/* <div className='gutter-row'>
                                                        <Row justify={'center'}><b><h3><u>ไฟล์ภาพ</u></h3></b></Row>
                                                        <b> <Form.Item
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                        >
                                                            <Upload
                                                                accept="image/*"
                                                                multiple={true}
                                                                style={{ textAlign: 'center' }}
                                                                listType="picture-card"
                                                                fileList={fileListCustomerStatementImage}
                                                                onPreview={handlePreview}
                                                                onChange={handleChangeCustomerStatementImage}
                                                                //beforeUpload={() => false}
                                                                beforeUpload={(file) => beforeUpts(file, 8).then(() => false).catch(() => true)}
                                                            // beforeUpload={(file) => {
                                                            //     setFileListCustomerStatementImage([...fileListCustomerStatementImage, file])
                                                            //     return false;
                                                            // }}
                                                            >
                                                                {fileListCustomerStatementImage?.length >= 20 ? null : (
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div className="ant-upload-text">upload</div>
                                                                    </div>
                                                                )}
                                                            </Upload>
                                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                            </Modal>
                                                        </Form.Item>
                                                        </b>

                                                    </div> */}
                                        <Row justify={'center'}><b><h3><u>ไฟล์ PDF</u></h3></b></Row>
                                        <Row justify={'center'}>
                                            <b>
                                                {dataAddImage?.map((imageData, index) => {
                                                    if (imageData.type === 7) {
                                                        return (
                                                            <Row justify={'center'}>
                                                                <object data={`${getImagess}/${imageData.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                                                    <p>Alternative text - include a link <a href={`${getImagess}/${imageData.pathImage}?token=${token}`}>to the PDF!</a></p>
                                                                </object>
                                                                <p></p>
                                                            </Row>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                                <Form.Item
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    <Upload
                                                        accept="application/pdf"
                                                        multiple={true}
                                                        style={{ textAlign: 'center' }}
                                                        listType="picture-card"
                                                        fileList={fileListCustomerStatementPDF}
                                                        onPreview={handlePreview}
                                                        onChange={handleChangeCustomerStatementPDF}
                                                        //beforeUpload={() => false}
                                                        beforeUpload={(file) => beforeUpts(file, 7).then(() => false).catch(() => true)}
                                                    >
                                                        {fileListCustomerStatementPDF?.length >= 10 ? null : (
                                                            <div>
                                                                <PlusOutlined />
                                                                <div className="ant-upload-text">upload</div>
                                                            </div>
                                                        )}
                                                    </Upload>
                                                    <Modal open={previewVisible} onCancel={handleCancel}>
                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </Form.Item>
                                            </b>
                                        </Row>
                                        {/* </aside> */}
                                    </Col>
                                </Row>

                                <Divider style={{ margin: 5 }} />


                            </Col>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Space>
                                    <Button type='primary' danger onClick={handleClose} >ยกเลิก</Button>
                                    {
                                        dataAddImage?.length > 0 || allImg?.length > 0 ?
                                            <Button type='primary' htmlType='submit' style={{ backgroundColor: "green" }} >ต่อไป</Button>
                                            :
                                            <Button disabled type='primary' htmlType='submit' style={{ backgroundColor: "green" }} >ต่อไป</Button>
                                    }

                                </Space>
                            </Col>
                        </Form>
                    </Row>
                </Spin>
                {contextHolder}
            </Card>
        </Row >
    )
}

export default UploadImg