import React, { useState, useEffect, useRef } from "react";
import { Modal, Divider, Row, Col, FloatButton, message, Spin } from "antd";
import {
  DownloadOutlined,
  CloseOutlined,
  ProfileOutlined,
  RetweetOutlined,
  ExclamationCircleFilled,
  CreditCardOutlined,
} from "@ant-design/icons";
import axios from "axios";
import logo from "../../../../assets/images/logo.png";
import ModalInstallment from "../../../check_info_PG_RE/modals/ModalInstallment";
import "../../../css/MobileActive.css";
import html2canvas from "html2canvas";
import QRCodeWithContractNo from "./QRCodeWithContractNo";
import moment from "moment";
import { insertcase, loaddetailmysqlDB2 } from "../../../file_mid/all_api";
import NoteToCEO from "./NoteToCEO";

require("moment/locale/th");

const BillExpedite = ({
  open,
  close,
  continoBill,
  data,
  onChangeStatus,
  status,
}) => {
  const [dataGuarantor, setDataGuarantor] = useState([]);
  const [isModalInstallment, setIsModalInstallment] = useState(false);
  const [isModalQrCode, setModalQrCode] = useState(null);
  const elementRef = useRef(null);
  const user = localStorage.getItem("username");
  const idPosition = localStorage.getItem("idSep");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(null);
  const [isModalOpenNote, setIsModalOpenNote] = useState(false);
  const { confirm } = Modal;

  useEffect(() => {
    setIsModalOpen(open);
    if (isModalOpen === true) {
      loadData();
      currentDateTime();
      console.log("BillExpedite1", data);
    }
  }, [isModalOpen]);

  const handleClose = () => {
    setIsModalOpen(false);
    close(false);
  };

  // const showConfirm = () => {
  //   confirm({
  //     title: "คำร้องขออนุมัติ",
  //     icon: <ExclamationCircleFilled />,
  //     content: "คุณต้องการส่งคำร้องขออนุมัติรีไฟแนนซ์ใช่หรือไม่",
  //     okText: "ยืนยัน",
  //     cancelText: "ยกเลิก",
  //     onOk() {
  //       sendRequestApproval();
  //       console.log(
  //         `ส่งอนุมัติรีไฟแนนซ์ -->${continoBill}  ${currentDateTime()}`
  //       );
  //       message.success(
  //         `ยืนยันคำร้องขออนุมัติสำเร็จ ${continoBill}  ${currentDateTime()}`
  //       );
  //       handleClose();
  //     },
  //     onCancel() {
  //       console.log("Cancel");
  //     },
  //   });
  // };

  const showConfirmSave = () => {
    confirm({
      title: "คำร้องขอบันทึกรูปภาพ",
      icon: <ExclamationCircleFilled />,
      content: "คุณต้องการบันทึกรูปภาพใช่หรือไม่",
      okText: "บันทึก",
      cancelText: "ยกเลิก",
      onOk() {
        htmlToImageConvert();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const showQrCode = () => {
    confirm({
      title: "คำร้องเปิด Qr Code",
      icon: <ExclamationCircleFilled />,
      content: "คุณต้องการจะสร้าง Qr code ชำระเงินใช่หรือไม่",
      okText: "ต้องการ",
      cancelText: "ยกเลิก",
      onOk() {
        setModalQrCode(true);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // const sendRequestApproval = async () => {
  //   setLoading(true);
  //   const urlRequestApproval = insertcase;
  //   await axios
  //     .post(urlRequestApproval, {
  //       CONTNO: data.CONTNO,
  //       offerBy: user,
  //       proposalBy: data.SALCOD, //แก้กลับเป็น data.SALCOD
  //       reviewedBy: data.CHECKER, //แก้กลับเป็น data.CHECKER
  //     })
  //     .then(async (resReq) => {
  //       if (resReq.data) {
  //         onChangeStatus({ ...data, approvalStatus: "28" }, status);
  //       } else {
  //       }
  //     })
  //     .catch((err) => console.log(err));
  //   setLoading(false);
  // };


  const loadData = async () => {
    const tk = JSON.parse(token);
    const urlGuarantor = loaddetailmysqlDB2;

    const headers = {
      Authorization: `Bearer ${tk}`,
      Menu: JSON.stringify("25"),
    };

    await axios
      .post(
        urlGuarantor,
        {
          id: data.idCarsApproveCeo,
          CONTNO: continoBill,
        },
        { headers: headers }
      )
      .then(async (resGuarantor) => {
        if (resGuarantor.data) {
          setDataGuarantor(resGuarantor.data);
        } else {
          setDataGuarantor([]);
        }
      })
      .catch((err) => console.log(err));
  };

  const currentDateTime = () => {
    const date = moment().add(543, "year").format("LLL");
    return date;
  };

  const currencyFormatOne = (amount) => {
    if (amount) {
      return Number(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    } else {
      return 0;
    }
  };

  const htmlToImageConvert = () => {
    html2canvas(elementRef.current, { cacheBust: false })
      .then((canvas) => {
        canvas.toBlob((blob) => {
          if (blob) {
            const file = new File(
              [blob],
              `การ์ดลูกค้าสัญญาเลขที่ ${continoBill} ${currentDateTime()}.png`,
              { type: "image/png" }
            );
            const link = document.createElement("a");
            link.href = URL.createObjectURL(file);
            link.download = file.name;
            document.body.appendChild(link); // เพิ่มลิงก์ใน DOM
            link.click();
            document.body.removeChild(link); // ลบลิงก์ออกจาก DOM
            message.success(`บันทึกรูปภาพสำเร็จ ${continoBill}`);
          } else {
            throw new Error("Blob is null");
          }
        }, "image/png"); // ใช้ MIME type เป็น 'image/png'
      })
      .catch((err) => {
        console.log("เกิดข้อผิดพลาด ", err);
        message.error("ยกเลิกการบันทึกภาพ");
      });
  };

  const dataGuarantors = ({ item, index }) => {
    return (
      <>
        <div label="ผู้ค้ำที่" style={{ marginTop: "10px" }}>
          {" "}
          <b>ผู้ค้ำที่ {index + 1}: </b>
          {item.NAME ? item.NAME : "-"}
        </div>
        <div
          label="ที่อยู่ผู้ค้ำ"
          style={{ margin: 0, textWrap: "break-word" }}
        >
          {" "}
          <b>ที่อยู่ผู้ค้ำที่ {index + 1}: </b>
          {item.ADDRES ? item.ADDRES : "-"} {item.SOI ? item.SOI : "-"}{" "}
          {item.TUMB ? item.TUMB : "-"} {item.AUMP ? item.AUMP : "-"}{" "}
          {item.PROVDES ? item.PROVDES : "-"} {item.ZIP ? item.ZIP : "-"} <br />
        </div>
        <div label="เบอร์โทร" style={{ margin: 0 }}>
          {" "}
          <b>เบอร์โทร : </b>
          {item.TELP ? item.TELP : "-"}
        </div>
      </>
    );
  };
  const dataCal = ({ item, index }) => {
    if (data.DATA_TYPE === item.DATA_TYPE) {
      return (
        <div label="ลูกหนี้อื่น ๆ" style={{ marginRight: "5px" }}>
          <b>ลูกหนี้อื่น ๆ : </b>
          {currencyFormatOne(item.OTHR)} บาท
        </div>
      );
    }
  };

  return (
    <Modal
      title="เอกสารติดตามงวด"
      open={open}
      onCancel={handleClose}
      width={850}
      footer={[null]}
    >
      <FloatButton.Group open={open} style={{ right: 14 }}>
        <FloatButton
          icon={<ProfileOutlined />}
          title="ตารางการผ่อน"
          style={{ boxShadow: "0 4px 8px" }}
          onClick={() => {
            setIsModalInstallment(true);
            console.log("ตารางผ่อน 2 -->", continoBill);
          }}
        ></FloatButton>
        <FloatButton
          icon={<DownloadOutlined />}
          title="บันทึกรูปภาพ"
          style={{ boxShadow: "0 4px 8px" }}
          onClick={() => {
            showConfirmSave();
          }}
        />

        {idPosition === "1" || idPosition === "4" ? (
          <FloatButton
            icon={<CreditCardOutlined />}
            title="ชำระเงิน"
            style={{ boxShadow: "0 4px 8px" }}
            onClick={showQrCode}
          />
        ) : null}
        {data.approvalStatus === 0 &&
          (idPosition === "1" || idPosition === "5") ? (
          <FloatButton
            title="ส่งอนุมัติรีไฟแนนซ์"
            icon={<RetweetOutlined />}
            style={{ boxShadow: "0 4px 8px" }}
            onClick={() => {
              // showConfirm();
              setIsModalOpenNote(true)
            }}
          />
        ) : null}
        <FloatButton
          icon={<CloseOutlined />}
          style={{ boxShadow: "0 4px 8px" }}
          title="ปิด"
          onClick={() => {
            handleClose();
            console.log("ปิด modal");
          }}
        ></FloatButton>
      </FloatButton.Group>

      <Spin spinning={loading} size="large" tip=" Loading... ">
        <div
          key={data.CONTNO ? data.CONTNO : Math.random()}
          ref={elementRef}
          className="center"
          style={{
            backgroundColor: "#ffffff", // กำหนดสีพื้นหลัง
            margin: "0 auto",
            border: "1px solid #ccc", // กำหนดเส้นขอบ
            fontFamily: "Arial, Helvetica, sans-serif",
            borderCollapse: "collapse",
          }}
        >
          <Col span={24} style={{ marginLeft: "0.5%" }}>
            <h5 style={{ opacity: 0.5 }}>{currentDateTime()}</h5>
          </Col>
          <Row className="main">
            <Col
              span={12}
              style={{
                textAlign: "right",
                marginRight: "5px",
                margin: "",
              }}
            >
              <img width={"70%"} src={logo} alt="Logo" />
            </Col>
            <Col span={11}>
              <div label="วันที่ทำสัญญา" style={{ textAlign: "right" }}>
                {" "}
                <b>วันที่ทำสัญญา </b>
                <br />
                {": "}
                {!data.SDATE
                  ? "-"
                  : moment(data.SDATE).add(543, "year").format("DD/MM/YYYY ")}
              </div>
              <div label="เลขที่สัญญา" style={{ textAlign: "right" }}>
                {" "}
                <b>เลขที่สัญญา </b>
                <br /> {": "}
                {!data.CONTNO ? "-" : data.CONTNO}
              </div>
            </Col>
            {data?.products === "land" ? (
              <>
                <Col style={{ margin: "10px" }}>
                  <div label="ชื่อผู้ทำสัญญา">
                    {" "}
                    <b>ชื่อผู้ทำสัญญา : </b>
                    {!data.CNAME ? "-" : data.CNAME}
                  </div>
                  <div label="ที่อยู่ผู้ทำสัญญา">
                    {" "}
                    <b>ที่อยู่ผู้ทำสัญญา : </b>
                    {!data.ADDRESS ? "-" : data.ADDRESS}{" "}
                    {!data.TUMB ? "-" : data.TUMB}{" "}
                    {!data.AUMP ? "-" : data.AUMP}{" "}
                    {!data.PROVDES ? "-" : data.PROVDES}{" "}
                    {!data.ZIP ? "-" : data.ZIP}
                  </div>
                  <div label="เบอร์โทรผู้ทำสัญญา">
                    {" "}
                    <b>เบอร์โทรผู้ทำสัญญา : </b>
                    {!data.TELP ? "-" : data.TELP}
                  </div>

                  <div label="ชื่อผู้ขาย" style={{ marginTop: "10px" }}>
                    {" "}
                    <b>ชื่อผู้ขาย : </b>
                    {!data.SALCOD ? "-" : data.SALCOD}
                  </div>
                </Col>

                <Divider orientation="center" style={{ margin: 0 }}>
                  <b>รายละเอียดที่ดิน</b>
                </Divider>
                <Row
                  className="left"
                  style={{
                    marginLeft: "20%",

                    marginTop: "5px",
                  }}
                >
                  <Col>
                    <div label="ตำบล">
                      {" "}
                      <b>ตำบล : </b>
                      {!data.MODEL ? "-" : data.MODEL}
                    </div>
                    <div label="อำเภอ">
                      {" "}
                      <b>อำเภอ : </b>
                      {!data.BAAB ? "-" : data.BAAB}
                    </div>
                    <div label="จังหวัด">
                      {" "}
                      <b>จังหวัด : </b>
                      {!data.TYPE ? "-" : data.TYPE}
                    </div>
                    <div label="ประเภทที่ดิน">
                      {" "}
                      <b>ประเภทที่ดิน : </b>
                      {!data.COLOR ? "-" : data.COLOR}
                    </div>
                    <div label="เลขที่ดิน">
                      {" "}
                      <b>เลขที่ดิน : </b>
                      {!data.REGNO ? "-" : data.REGNO}
                    </div>
                    <div label="เลขโฉนดที่ดิน">
                      {" "}
                      <b>เลขโฉนดที่ดิน : </b>
                      {!data.STRNO ? "-" : data.STRNO}
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Col style={{ margin: "10px" }}>
                  <div label="ชื่อผู้ทำสัญญา">
                    {" "}
                    <b>ชื่อผู้ทำสัญญา : </b>
                    {!data.CNAME ? "-" : data.CNAME}
                  </div>
                  <div label="ที่อยู่ผู้ทำสัญญา">
                    {" "}
                    <b>ที่อยู่ผู้ทำสัญญา : </b>
                    {!data.ADDRESS ? "-" : data.ADDRESS}{" "}
                    {!data.TUMB ? "-" : data.TUMB}{" "}
                    {!data.AUMP ? "-" : data.AUMP}{" "}
                    {!data.PROVDES ? "-" : data.PROVDES}{" "}
                    {!data.ZIP ? "-" : data.ZIP}
                  </div>
                  <div label="เบอร์โทรผู้ทำสัญญา">
                    {" "}
                    <b>เบอร์โทรผู้ทำสัญญา : </b>
                    {!data.TELP ? "-" : data.TELP}
                  </div>
                  <Divider orientation="center" style={{ marginTop: "10px" }}>
                    <b>รายละเอียดคนค้ำ</b>
                  </Divider>
                  {dataGuarantor?.guarantors?.map((item, index) => {
                    return dataGuarantors({ item, index, key: `{item} - ${index}` });
                  })}

                  <div label="ชื่อผู้ขาย" style={{ marginTop: "10px" }}>
                    {" "}
                    <b>ชื่อผู้ขาย : </b>
                    {!data.SALCOD ? "-" : data.SALCOD}
                  </div>
                </Col>
                <Divider orientation="center" style={{ margin: 0 }}>
                  <b>รายละเอียดรถ</b>
                </Divider>
                <Row
                  className="left"
                  style={{
                    marginLeft: "20%",

                    marginTop: "5px",
                  }}
                >
                  <Col>
                    <div label="ยี่ห้อ">
                      {" "}
                      <b>ยี่ห้อ : </b>
                      {!data.TYPE ? "-" : data.TYPE}
                    </div>
                    <div label="รุ่น">
                      {" "}
                      <b>รุ่น : </b>
                      {!data.MODEL ? "-" : data.MODEL}
                    </div>
                    <div label="สี">
                      {" "}
                      <b>สี : </b>
                      {!data.COLOR ? "-" : data.COLOR}
                    </div>
                    <div label="ชนิดรถ">
                      {" "}
                      <b>ชนิดรถ : </b>
                      {!data.BAAB ? "-" : data.BAAB}
                    </div>
                    <div label="ทะเบียน">
                      {" "}
                      <b>ทะเบียน : </b>
                      {!data.REGNO ? "-" : data.REGNO}
                    </div>
                    <div label="ปีรถ">
                      {" "}
                      <b>ปีรถ : </b>
                      {!data.MANUYR ? "-" : data.MANUYR}
                    </div>
                    <div label="เลขตัวถังรถ">
                      {" "}
                      <b>เลขตัวถังรถ : </b>
                      {!data.STRNO ? "-" : data.STRNO}
                    </div>
                  </Col>
                </Row>
              </>
            )}
            <Divider orientation="center">
              <b>รายละเอียดสัญญา</b>
            </Divider>
            <Row
              className="left"
              style={{
                marginLeft: "20%",

                marginTop: "5px",
              }}
            >
              <Col>
                <div label="ราคาขาย" style={{ marginRight: "10px" }}>
                  <b>ราคาขาย : </b>
                  {currencyFormatOne(data.TOTPRC)} บาท
                </div>
                <div label="ชำระเงินดาว" style={{ marginRight: "5px" }}>
                  <b>ชำระเงินดาว : </b>
                  {currencyFormatOne(data.TOTDWN)} บาท
                </div>
                <div label="คงเหลือ" style={{ marginRight: "5px" }}>
                  <b>คงเหลือ : </b>
                  {currencyFormatOne(data.TOTPRC - data.TOTDWN)} บาท{" "}
                </div>
                <div label="งวดละ" style={{ marginRight: "5px" }}>
                  <b>งวดละ : </b>
                  {currencyFormatOne(data.TOT_UPAY)} บาท
                </div>
                {dataGuarantor?.dataCal?.map((item, index) => {
                  return dataCal({ item, index });
                })}

                <div label="ผ่อนจำนวน" style={{ marginRight: "5px" }}>
                  <b>ผ่อนจำนวน : </b> {data.T_NOPAY} งวด
                </div>

                <div label="ยอดคงเหลือ" style={{ marginRight: "5px" }}>
                  <b>ยอดคงเหลือ : </b>
                  {currencyFormatOne(data.TOTPRC - data.SMPAY)} บาท
                </div>

                <div label="ชำระงวดล่าสุด" style={{ marginRight: "5px" }}>
                  <b>ชำระงวดล่าสุด : </b>{" "}
                  {moment(data.LPAYD).format("DD/MM/YYYY")}
                </div>
                <div label="ยอดชำระล่าสุด" style={{ marginRight: "5px" }}>
                  <b>ยอดชำระล่าสุด : </b> {currencyFormatOne(data.LPAYA)} บาท
                </div>
                <div label="ค้างค่างวด" style={{ marginRight: "5px" }}>
                  <b>ค้างค่างวด : </b> {currencyFormatOne(data.EXP_AMT)} บาท
                </div>
                <div label="เบี้ยปรับ" style={{ marginRight: "5px" }}>
                  <b>เบี้ยปรับ : </b> {currencyFormatOne(dataGuarantor.beeypup)}{" "}
                  บาท
                </div>
                <div label="เงินกั๊ก" style={{ marginRight: "5px" }}>
                  <b>เงินกั๊ก : </b> {currencyFormatOne(data.KANG)} บาท
                </div>
                <div label="เงินตั้งพักค่างวด" style={{ marginRight: "5px" }}>
                  <b>เงินตั้งพักค่างวด : </b> {currencyFormatOne(data.KANGOTH)}{" "}
                  บาท
                </div>
                <div label="เกรด" style={{ marginRight: "5px", color: "red" }}>
                  <b>เกรด : </b> {data.GRDCOD}
                </div>
                <div
                  label="ชำระขั้นต่ำ"
                  style={{ marginRight: "5px", color: "red" }}
                >
                  <b>ชำระขั้นต่ำ : </b> {currencyFormatOne(data.PAYLIMITAMT)}{" "}
                  บาท
                </div>
              </Col>
            </Row>
          </Row>
          <Row>
            <Col span={23} style={{ textAlign: "left", marginTop: "10px" }}>
              <div
                label="ค้างทั้งหมด"
                style={{
                  color: "red",
                  textAlign: "center",
                  margin: "auto",
                }}
              >
                {" "}
                <b>ค้างทั้งหมด : </b>
                {!data.EXP_PRD ? "-" : data.EXP_PRD} งวด จากงวดที่:{" "}
                {!data.EXP_FRM ? "-" : data.EXP_FRM} ถึงงวดที่:{" "}
                {!data.EXP_TO ? "-" : data.EXP_TO}
              </div>
              <div
                className="center"
                style={{
                  fontWeight: "bold",
                  marginTop: "10px",
                  textWrap: "brek-word",
                  margin: "5px",
                }}
              >
                <span style={{ marginTop: "20px" }}>หมายเหตุ : </span>
                {!data.MEMO1 ? "-" : data.MEMO1}
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
      {
        <ModalInstallment
          open={isModalInstallment}
          close={setIsModalInstallment}
          contno={continoBill}
        />
      }
      {
        <QRCodeWithContractNo
          open={isModalQrCode}
          close={setModalQrCode}
          dataQr={continoBill}
        />
      }
      {
        isModalOpenNote ? // เพิ่มหมายเหตุถึง CEO
          <NoteToCEO
            open={isModalOpenNote}
            close={setIsModalOpenNote}
            closeAll={handleClose}
            getData={data}
            onChangeStatus={onChangeStatus}
            status={status}
          />
          : null
      }
    </Modal>
  );
};

export default BillExpedite;
