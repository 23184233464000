import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button, Space, Card, Upload, message, Modal, Spin, notification, Divider, Image } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import '../../offer_case/css/Media.css';
import axios from 'axios';
import dayjs from 'dayjs';
import Compressor from 'compressorjs';
import TextArea from 'antd/es/input/TextArea';
import { findcarmk, getImagess, getImagessPre, mutips2, mutips2pre } from '../../file_mid/all_api';


function EditOther({ getData, close, sendback, saveResendNote, setSaveResendNote, checkPreNotPass }) {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const token = localStorage.getItem('token');
    const [dataModalEdit, setDataModalEdit] = useState(getData)
    const [fileListOther, setFileListOther] = useState([]); // type 6 ประวัติ / ทะเบียนบ้าน / อาชีพ
    const [allImg, setAllImg] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const [api, contextHolder] = notification.useNotification();

    const newFormData = new FormData();
    const [resultImage, setResultImage] = useState([]);

    const [resendNote, setResendNote] = useState();
    const [imageBlobzz, setImageBlobzz] = useState();
    const [checkSendNEW, setCheckSendNEW] = useState(false);


    useEffect(() => {
        if (saveResendNote.otherNote) {
            form.setFieldsValue({
                ...saveResendNote
            })
        }
    }, [])

    useEffect(() => {
        imageBlobzz?.map((e, index) => {
            var PreAaproveSplit = []
            PreAaproveSplit = e.pathImage.split("/")
            if (PreAaproveSplit[3] === "13") {
                setCheckSendNEW(true)
            }
        })
    }, [imageBlobzz])

    useEffect(() => {
        const loadDataLand = async () => {
            setLoading(true)
            await axios.post(findcarmk, { carId: getData.cars.carId, customerId: getData.customerId })
                .then(res => {
                    if (res.status === 200) {
                        console.log("res.data", res.data)
                        setImageBlobzz(res.data.cars.img)
                        setLoading(false)
                    }
                })
        }
        loadDataLand()
    }, [getData])

    const handleCancel1 = () => {
        close();
    }

    const SuccImg = (placement) => {
        api.success({
            message: <b>เพิ่มรูปสำเร็จ</b>,
            // description: `กรุณาเพิ่มที่อยู่ผู้ค้ำ ลำดับที่ ${index + 1}`,
            placement,
        });
    }
    const SuccData = (placement) => {
        api.success({
            message: <b>เพิ่มข้อมูลสำเร็จ</b>,
            // description: `กรุณาเพิ่มที่อยู่ผู้ค้ำ ลำดับที่ ${index + 1}`,
            placement,
        });
    }

    const handleSubmit = async () => {
        setLoading(true)
        if (allImg?.length > 0) {
            await imgSend(allImg)
            setLoading(false)

        } else {
            SuccData("top")
            setLoading(false)

        }
        if (getData?.cars?.carLoanDetails?.approvalStatus !== 21) {
            handleSendNote()
        }
    };

    const handleSendNote = async () => {
        if (resendNote !== undefined) {
            // console.log("!= undefined")
            if (resendNote.otherNote !== "") {
                // console.log("if")
                setSaveResendNote({ ...saveResendNote, ...resendNote })
            } else {
                // console.log("else")
                setSaveResendNote({ ...saveResendNote, otherNote: null })
            }
        } else {
            // console.log("= undefined")
        }
    };

    const imgSend = (resultImgs) => {
        return new Promise(async (resolve, reject) => {
            try {
                var status;
                newFormData.append('idcus', dataModalEdit?.cars?.carId);
                //newFormData.append('productTypeId', "1");
                newFormData.append('tm', dayjs().format("YYYY-MM-DDTHH:mm:ssZ"));

                for (const file of resultImgs) {
                    var image = {}
                    newFormData.append('file', file.imageCompressedFile);
                    newFormData.append("type", file.type);
                    image.type = file.type
                    resultImage.push(image)
                }
                if (checkPreNotPass) {
                    const response = await axios.post(mutips2pre, newFormData);
                    if (response.data) {
                        //success(randomTenDigitNumber);
                        setFileListOther([])
                        setAllImg([])
                        console.log("TY Pre", response.data);
                        //dispatch(addSocial(response.data))
                        status = response.status;
                        resolve(status);
                    }
                } else {
                    const response = await axios.post(mutips2, newFormData);
                    if (response.data) {
                        //success(randomTenDigitNumber);
                        setFileListOther([])
                        setAllImg([])
                        console.log("TY", response.data);
                        //dispatch(addSocial(response.data))
                        status = response.status;
                        resolve(status);
                    }
                }
            } catch (error) {
                error(error);
                reject(error);
            } finally {
                setLoading(false);
                console.log("OK")
                SuccImg("top")
                sendback(dayjs())
            }
        });
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const handleCancel = () => {
        setPreviewVisible(false);
    }

    const compressImage = async (ez, types) => {
        var image = {};
        return new Promise(async (resolve) => {
            if (types !== 7) {
                if (ez.size > 4000000) {
                    console.log("4m ไม่ให้ส่ง");
                    resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                } else {
                    let quality;
                    if (ez.size >= 2500000 && ez.size <= 4000000) {
                        quality = 0.1;
                    } else if (ez.size >= 2000000 && ez.size <= 2499999) {
                        quality = 0.15;
                    } else if (ez.size >= 1500000 && ez.size <= 1999999) {
                        quality = 0.2;
                    } else if (ez.size >= 1000000 && ez.size <= 1499999) {
                        quality = 0.3;
                    } else {
                        quality = 0.5;
                    }

                    try {
                        // ใช้ Promise เพื่อบีบอัดรูปภาพ
                        const compressedFile = await new Promise((resolveCompress) => {
                            new Compressor(ez, {
                                quality: quality,
                                success: (result) => {
                                    resolveCompress(result);
                                },
                            });
                        });

                        const fileOptions = {
                            type: compressedFile.type,
                            lastModified: Date.now(),
                        };
                        const compressedFileAsFile = new File([compressedFile], ez.name, fileOptions);
                        image.imageCompressedFile = compressedFileAsFile;
                        image.type = types
                        allImg.push(image);
                        //setAllImg((prevAllImg) => [...prevAllImg, image]);

                        // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
                        resolve(image);
                    } catch (error) {
                        console.error('Image compression error:', error);
                        resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                    }
                }
            } else {
                // ... (โลจิกสำหรับ type 7)
                image.imageCompressedFile = ez;
                image.type = types
                allImg.push(image);
                //setAllImg((prevAllImg) => [...prevAllImg, image]);
                resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
            }
        });
    };

    const beforeUpts = async (file, ty) => {
        try {
            setLoading(true)
            const compressedFile = await compressImage(file, ty);
            return Promise.resolve(compressedFile);
        } catch (error) {
            console.error('Image compression error:', error);
            message.error('Image compression failed.');
            return Promise.reject(); // เปลี่ยนให้ return Promise.reject() เมื่อไม่สามารถบีบอัดได้
        }
    };

    const handleChangeOther = async ({ fileList }) => {
        for (const removedFile of fileListOther.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 12)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 12));
                setAllImg(updatedAllImg);
            }
        }
        setFileListOther(fileList);
        setLoading(false)
    };

    return (
        <Row justify={'center'}>
            <Row justify={'center'} style={{ margin: '5px', fontSize: '18px' }} >เอกสารเพิ่มเติม</Row>
            <Card style={{ width: '1100px' }}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Row justify={'center'}>
                        <Form
                            form={form}
                            labelCol={{
                                span: 12,
                            }}
                            style={{
                                width: '100%',
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            autoComplete="off"
                            onFinish={handleSubmit}
                        >
                            <Row justify={'center'} style={{ margin: '5px' }} >
                                <Col span={20} style={{ textAlign: 'center' }}>
                                    {
                                        getData.cars.carLoanDetails.approvalStatus === 21 ?
                                            null
                                            :
                                            <>
                                                <Form.Item label="หมายเหตุ(การตลาด)" name="otherNote">
                                                    <TextArea rows={5} name='otherNote' placeholder='หมายเหตุถึงธุรการ' onChange={(e) => setResendNote({ otherNote: e.target.value })} />
                                                </Form.Item>
                                                <Divider />
                                            </>
                                    }
                                    <Row justify={'center'} style={{ margin: '5px' }} >เอกสารเพิ่มเติม (อัพโหลดไฟล์ไม่เกิน 4MB)</Row>
                                    <Row justify={'center'}>
                                        <Col span={24}>
                                            <aside gutter={24} justify={'center'}>
                                                <div className='gutter-row' span={12}>
                                                    <Row justify={'center'}><b><h3><u>ไฟล์ภาพ</u></h3></b></Row>
                                                    <Row justify={'center'}>
                                                        <b> <Form.Item
                                                            style={{ textAlign: 'center' }}
                                                        >
                                                            <Upload
                                                                accept="image/*"
                                                                multiple={true}
                                                                style={{ textAlign: 'center' }}
                                                                listType="picture-card"
                                                                fileList={fileListOther}
                                                                onPreview={handlePreview}
                                                                onChange={handleChangeOther}
                                                                beforeUpload={(file) => beforeUpts(file, 12).then(() => false).catch(() => true)}
                                                            >
                                                                {fileListOther?.length >= 20 ? null : (
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div className="ant-upload-text">upload</div>
                                                                    </div>
                                                                )}
                                                            </Upload>
                                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                            </Modal>
                                                        </Form.Item>
                                                        </b>
                                                    </Row>
                                                </div>
                                            </aside>
                                        </Col>
                                    </Row>
                                    <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u></b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        <Space direction="vertical">
                                            <Row gutter={32} justify={'center'}>
                                                {imageBlobzz?.map((e, index) => {
                                                    var PreAaproveSplit = []
                                                    PreAaproveSplit = e.pathImage.split("/")
                                                    if (PreAaproveSplit[3] !== "13") {
                                                        if (e.type === 12) {
                                                            return (
                                                                <>
                                                                    <Image width={"50px"} height={'50px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </>
                                                            );
                                                        }
                                                    }
                                                    return null;
                                                })}
                                            </Row>
                                        </Space>
                                    </Row>
                                    <Divider />
                                    {
                                        checkSendNEW ?
                                            <>
                                                <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u> (ใหม่)</b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Space direction="vertical">
                                                        <Row gutter={32} justify={'center'}>
                                                            {imageBlobzz?.map((e, index) => {
                                                                var PreAaproveSplit = []
                                                                PreAaproveSplit = e.pathImage.split("/")
                                                                if (PreAaproveSplit[3] === "13") {
                                                                    if (e.type === 12) {
                                                                        return (
                                                                            <>
                                                                                <Image width={"50px"} height={'50px'} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                            </>
                                                                        );
                                                                    }
                                                                }
                                                                return null;
                                                            })}
                                                        </Row>
                                                    </Space>
                                                </Row>
                                                <Divider />
                                            </>
                                            : null
                                    }
                                    <Row >
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <Space>
                                                <Button type="primary" danger onClick={handleCancel1}>ปิด</Button>
                                                {/* <Button type="primary" danger onClick={handleSendNote}>note</Button> */}
                                                <Button type="primary" htmlType="submit" style={{ background: "green" }} disabled={fileListOther.length === 0}>บันทึก</Button>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Row>
                </Spin>
            </Card>
            {contextHolder}
        </Row>

    )
};

export default EditOther