import { Button, Card, Space, Row, Spin, Form, Col, Divider, Image, Upload, Select, message, Modal, notification } from 'antd'

import React, { useState, useEffect } from 'react'
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs';
import Compressor from 'compressorjs';
import { checkSNAM } from '../../file_mid/condition';
import { checkNewCCAP, getImagess, mutips3, testupload } from '../../file_mid/all_api';
const { Option } = Select;



function UploadImgLand({ page, changepage, close, data, allData, setSendBack }) {

    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const token = localStorage.getItem('token');
    const addLands = localStorage.getItem('addLand')
    const dataAddLands = JSON.parse(addLands)

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileListCustomer, setFileListCustomer] = useState([]);
    const [fileListCustomerLand, setFileListCustomerLand] = useState([]);
    const [fileListCustomerDeed, setFileListCustomerDeed] = useState([]);
    const [fileListCustomerLeafDivider, setFileListCustomerLeafDivider] = useState([]);
    const [fileListCustomerBurden, setFileListCustomerBurden] = useState([]);
    const [fileListCustomerStatementImage, setFileListCustomerStatementImage] = useState([]);
    const [fileListCustomerStatementPDF, setFileListCustomerStatementPDF] = useState([]);
    const [fileListCustomerCareer, setFileListCustomerCareer] = useState([]);
    const [foundCus, setFoundCus] = useState(false);
    const [readCard, setReadCard] = useState(false);
    const [checkSnam, setCheckSnam] = useState(false);
    const [dataPreCustomer, setDataPreCustomer] = useState();
    const [dataPreAddress, setDataPreAddress] = useState();
    const [count] = useState()

    /*เก็บรูปทั้งหมด*/
    const [allImg, setAllImg] = useState([]);
    const [dataAddImage, setDataAddImage] = useState([]) // โชว์รูป
    const [checkIDCard] = useState([]) // 

    const [selectProduct, setSelectProduct] = useState(1) // 1 รถ , 2 ที่ดิน
    const newFormData = new FormData();
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        setSelectProduct({ productTypeId: 2 })
        form.setFieldsValue({
            ...dataAddLands,
            productTypeId: 2,
        })
        if (allData?.land?.img?.length > 0) {
            var imgNew = []
            // var imgIdCard = []
            allData?.land?.img?.map((e) => {
                if (e.type !== 14) {
                    imgNew.push(e)
                }
                if (e.type === 4) {
                    checkIDCard.push(e)
                }
            })
            setDataAddImage(imgNew)
            // setFileListCustomer(imgIdCard)
        }
        if (checkSNAM(data.snam)) {
            setCheckSnam(true)
        }
    }, [allData])

    const imgSend = (resultImgs) => {
        return new Promise(async (resolve, reject) => {
            try {
                var status;
                newFormData.append('idcus', dataAddLands?.landId);
                newFormData.append('tm', dayjs().format("YYYY-MM-DDTHH:mm:ssZ"));

                for (const file of resultImgs) {
                    var image = {}
                    newFormData.append('file', file.imageCompressedFile);
                    newFormData.append("type", file.type);
                    image.type = file.type
                }
                const response = await axios.post(mutips3, newFormData);
                if (response.data) {
                    setAllImg([])
                    console.log("TY", response.data);
                    status = response.status;
                    resolve(status);
                }

            } catch (error) {
                error(error);
                reject(error);
            } finally {
                setLoading(false);
                console.log("OK")
                setSendBack(dayjs())
                handleGo()
            }
        });
    };

    const notiNotIDCARD = (placement) => {
        api.warning({
            message: <b>กรุณาเพิ่มรูปบัตรประชาชน</b>,
            placement,
        });
    }

    const handleSubmit = async () => {
        setLoading(true)
        if (!checkSnam) {
            ///เช็ครูปบัตร///
            if (fileListCustomer?.length > 0 || checkIDCard.length > 0) {
                if (readCard) {
                    if (!foundCus) {
                        localStorage.setItem('addCustomer2', JSON.stringify(dataPreCustomer))
                        // localStorage.setItem('addPhone2', JSON.stringify(dataPreCustomer))
                        localStorage.setItem('addAddress', JSON.stringify(dataPreAddress))
                    }
                } else {
                    // localStorage.removeItem('addCustomer2');
                    // localStorage.removeItem('addAddress');
                }
                if (allImg?.length > 0) {
                    await imgSend(allImg)
                } else {
                    handleGo()
                }
            } else {
                notiNotIDCARD("top")
                // handleGo()
            }
        } else {
            if (allImg?.length > 0) {
                await imgSend(allImg)
            } else {
                handleGo()
            }
        }
        setLoading(false)
    }

    const handleGo = () => {
        setLoading(false)
        changepage(page + 1)
    }
    const handleClose = () => {
        close()
    }

    const compressImage = async (ez, types) => {
        var image = {};
        return new Promise(async (resolve) => {
            if (types !== 7) {
                if (ez.size > 4000000) {
                    console.log("4m ไม่ให้ส่ง");
                    resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                } else {
                    let quality;
                    if (ez.size >= 2500000 && ez.size <= 4000000) {
                        quality = 0.1;
                    } else if (ez.size >= 2000000 && ez.size <= 2499999) {
                        quality = 0.15;
                    } else if (ez.size >= 1500000 && ez.size <= 1999999) {
                        quality = 0.2;
                    } else if (ez.size >= 1000000 && ez.size <= 1499999) {
                        quality = 0.3;
                    } else {
                        quality = 0.5;
                    }

                    try {
                        // ใช้ Promise เพื่อบีบอัดรูปภาพ
                        const compressedFile = await new Promise((resolveCompress) => {
                            new Compressor(ez, {
                                quality: quality,
                                success: (result) => {
                                    resolveCompress(result);
                                },
                            });
                        });

                        const fileOptions = {
                            type: compressedFile.type,
                            lastModified: Date.now(),
                        };
                        const compressedFileAsFile = new File([compressedFile], ez.name, fileOptions);
                        image.imageCompressedFile = compressedFileAsFile;
                        image.type = types
                        allImg.push(image);
                        //setAllImg((prevAllImg) => [...prevAllImg, image]);

                        // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
                        resolve(image);
                    } catch (error) {
                        console.error('Image compression error:', error);
                        resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                    }
                }
            } else {
                // ... (โลจิกสำหรับ type 7)
                image.imageCompressedFile = ez;
                image.type = types
                allImg.push(image);
                //setAllImg((prevAllImg) => [...prevAllImg, image]);
                resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
            }
        });
    };
    const beforeUpts = async (file, ty) => {
        try {
            setLoading(true)
            const compressedFile = await compressImage(file, ty);
            return Promise.resolve(compressedFile);
        } catch (error) {
            console.error('Image compression error:', error);
            message.error('Image compression failed.');
            return Promise.reject(); // เปลี่ยนให้ return Promise.reject() เมื่อไม่สามารถบีบอัดได้
        }
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const handleCancel = () => {
        setPreviewVisible(false);
    }
    ///////////////// อัปโหลดรูปภาพ //////////////////////////////
    // const handleChangeCustomer = async ({ fileList }) => { // 4
    //     for (const removedFile of fileListCustomer.filter(file => file.status === 'removed')) {
    //         if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 4)) {
    //             const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 4));
    //             setAllImg(updatedAllImg);
    //         }
    //     }
    //     setFileListCustomer(fileList);
    //     setLoading(false)
    // };

    ///////////////// อ่านบัตร //////////////////////////////
    const handleChangeCustomer = async ({ fileList }) => { // 4
        for (const removedFile of fileListCustomer.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 4)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 4));
                setAllImg(updatedAllImg);
            }
        }

        setFileListCustomer(fileList);
        if (fileList) {
            handleFileInputChange(fileList);
        } else {
            handleFileInputChange([]);
        }
        setLoading(false)
    };
    ////////////// รับไฟล์รูปบัตร /////////////////////////////////
    const handleFileInputChange = async (event) => {
        if (event.length > 0) {
            var file = event[0].originFileObj
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg']
            if (file) {
                if (validImageTypes.includes(file.type)) {
                    const reader = new FileReader(); // สร้าง FileReader object
                    reader.onloadend = () => { // ฟังก์ชันที่ทำงานเมื่ออ่านไฟล์เสร็จสิ้น    
                        const base64Data = reader.result; // รับข้อมูล Base64 จาก FileReader result
                        handleScanIdCard(base64Data, event)
                    }
                    reader.readAsDataURL(file) // อ่านไฟล์และแปลงเป็น Base64    
                }
            }
        }
    }
    /////////// ดึงข้อมูลจากรูปบัตรประชาชน ///////////////////////////
    const handleScanIdCard = async (value, event) => {
        const imgId = { image: value }
        setLoading(true)
        const headers = { 'Content-Type': 'application/json' }
        await axios.post(testupload, imgId, { headers: headers })
            .then(res => {
                if (res.data) {
                    // console.log("res.data[0]", res.data[0])
                    if (res.data[0]?.identificationId) {
                        if (data?.productLoanLandType === "Pre-Aaprove-เปลี่ยนสัญญา" || data?.productLoanLandType === "Pre-Aaprove-รี+เปลี่ยนสัญญา") {
                            splitAddress(res.data[0])
                            setDataPreCustomer(res.data[0])
                            loadDataOne(res.data[0])
                            setLoading(false)
                        } else {
                            if (res.data[0].identificationId === allData.identificationId) {
                                splitAddress(res.data[0])
                                setDataPreCustomer(res.data[0])
                                loadDataOne(res.data[0])
                                setLoading(false)
                            } else {
                                alert('บัตรไม่ตรงกับข้อมูลลูกค้า')
                                setFileListCustomer([])
                                if (allImg.some(img => img.imageCompressedFile.name === event[0].name && img.type === 4)) {
                                    const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === event[0].name && img.type === 4));
                                    setAllImg(updatedAllImg);
                                }
                                setLoading(false)
                            }
                        }
                    } else {
                        localStorage.removeItem('addPhone2');
                        localStorage.removeItem('addAddress');
                        localStorage.removeItem('addCareerIncome');
                        localStorage.removeItem('addCustomer2');
                        alert('ไม่สามารถอ่านบัตรได้')
                        setLoading(false)
                    }
                }
                else {
                    localStorage.removeItem('addPhone2');
                    localStorage.removeItem('addAddress');
                    localStorage.removeItem('addCareerIncome');
                    localStorage.removeItem('addCustomer2');
                    alert('ไม่สามารถอ่านบัตรได้')
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log("err", err)
                localStorage.removeItem('addPhone2');
                localStorage.removeItem('addAddress');
                localStorage.removeItem('addCareerIncome');
                localStorage.removeItem('addCustomer2');
                alert('ไม่สามารถอ่านบัตรได้')
            })
        setLoading(false)
    }
    //////////////// เก็บแอสเดรส ////////////////////////////////////
    const splitAddress = (value) => {
        let updatedCount = { ...count }
        let part = value.address.split(" ")
        if (part[1].includes("หมู่ที่") === true) {
            updatedCount.houseNumber = part[0] + part[1] + part[2]
        } else {
            updatedCount.houseNumber = part[0]
        }
        for (let i = 0; i <= part.length; i++) {
            if (part[i]?.includes("ซ.") === true) {
                let s = part[i].split("ซ.")
                //setSoi(s[1])
                updatedCount.soi = s[1]
            }
            if (part[i]?.includes("ถ.") === true) {
                let tanon = part[i].split("ถ.")
                //setRoad(tanon[1])
                updatedCount.road = tanon[1]
            }
            if (part[i]?.includes("ต.") === true) {
                let bn = part[i].split("ต.")
                //setSubdistrict(bn[1])
                updatedCount.subdistrict = bn[1]
            }
            if (part[i]?.includes("อ.") === true) {
                let oo = part[i].split("อ.")
                let oop = oo[1].split(",")
                if (oo[1]?.includes(",") === false) {
                    //setDistrict(oo[1])
                    updatedCount.district = oo[1]
                }
                if (oop[1]?.includes("จ.") === true) {
                    //setDistrict(oop[0])
                    updatedCount.district = oop[0]
                    let oops = oop[1].split("จ.")
                    //setProvince(oops[1])
                    updatedCount.province = oops[1]
                }
            }
            if (part[i]?.includes("จ.") === true) {
                let jh = part[i].split("จ.")
                //setProvince(jh[1])
                updatedCount.province = jh[1]
            }
        }
        // setDataAddress({
        //     ...dataAddress,
        //     customerId: 0,
        //     addressId: 0,
        //     houseNumber: updatedCount.houseNumber,
        //     houseRegistrationNumber: updatedCount.houseRegistrationNumber,
        //     village: updatedCount.village,
        //     zipCode: updatedCount.zipCode,
        //     soi: updatedCount.soi,
        //     road: updatedCount.road,
        //     subdistrict: updatedCount.subdistrict,
        //     district: updatedCount.district,
        //     province: updatedCount.province,
        //     addressStatus: true,
        //     addressOnIdcard: true,
        //     workAddress: false,
        //     otherAddress: false,
        //     currentAddress: false,
        //     sendDocuments: false,
        //     houseRegistration: false,
        // })
        setDataPreAddress([{
            ...dataPreAddress,
            customerId: data.customerId,
            addressId: 0,
            houseNumber: updatedCount.houseNumber,
            houseRegistrationNumber: updatedCount.houseRegistrationNumber,
            village: updatedCount.village,
            zipCode: updatedCount.zipCode,
            soi: updatedCount.soi,
            road: updatedCount.road,
            subdistrict: updatedCount.subdistrict,
            district: updatedCount.district,
            province: updatedCount.province,
            addressStatus: true,
            addressOnIdcard: true,
            workAddress: false,
            otherAddress: false,
            currentAddress: false,
            sendDocuments: false,
            houseRegistration: false,
        }])
    }
    ////////////// ข้อมูลยูสในเดต้าเบส ////////////////////////////////////////////////////////////////
    const loadDataOne = async (data) => {
        const one = { identificationId: data.identificationId }
        if (!data.identificationId) {
            console.log("ไม่มีเลขบัตรคนกู้")
            alert('ไม่สามารถอ่านบัตรผู้ขอกู้ได้')
            setReadCard(false)
            localStorage.removeItem('addCareerIncome');
        } else {
            setLoading(true)
            await axios.post(checkNewCCAP, one)
                .then((res) => {
                    if (res.data) {
                        // delete res.data.address
                        delete res.data.emails
                        // delete res.data.ojs
                        delete res.data.socials
                        message.info('มีข้อมูลในฐานข้อมูล')
                        // console.log("res.data", res.data)
                        localStorage.setItem('addPhone2', JSON.stringify(res.data?.phones))
                        localStorage.setItem('addAddress', JSON.stringify(res.data?.address))
                        if (res.data?.ojs.length > 0) {
                            localStorage.setItem('addCareerIncome', JSON.stringify(res.data?.ojs[0]))
                        }
                        delete res.data.ojs
                        delete res.data.address
                        localStorage.setItem('addCustomer2', JSON.stringify(res.data))
                        setFoundCus(true)
                    }
                    else {
                        message.info('ไม่พบข้อมูลในฐานข้อมูล')
                        setFoundCus(false)
                    }
                    setReadCard(true)
                })
                .catch((err) => console.log(err))
            setLoading(false)
        }
    }

    const handleChangeCustomerLand = async ({ fileList }) => { // 1
        if (selectProduct?.productTypeId === 3) {
            setAllImg({ ...allImg, ts1: fileList });
        }
        else {
            for (const removedFile of fileListCustomerLand.filter(file => file.status === 'removed')) {
                if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 1)) {
                    const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 1));
                    setAllImg(updatedAllImg);
                }
            }
        }
        setFileListCustomerLand(fileList);
        setLoading(false)
    };
    const handleChangeCustomerDeed = async ({ fileList }) => { // 3
        // setLoading(true)
        if (selectProduct?.productTypeId === 3) {
            setAllImg({ ...allImg, ts3: fileList });
        } else {
            for (const removedFile of fileListCustomerDeed.filter(file => file.status === 'removed')) {
                if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 3)) {
                    const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 3));
                    setAllImg(updatedAllImg);
                }
            }
        }
        setFileListCustomerDeed(fileList);
        setLoading(false)
    };
    const handleChangeCustomerLeafDivider = async ({ fileList }) => { // 10
        // setLoading(true)
        if (selectProduct?.productTypeId === 3) {
            setAllImg({ ...allImg, ts10: fileList });
        } else {
            for (const removedFile of fileListCustomerLeafDivider.filter(file => file.status === 'removed')) {
                if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 10)) {
                    const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 10));
                    setAllImg(updatedAllImg);
                }
            }
        }
        setFileListCustomerLeafDivider(fileList);
        setLoading(false)
    };
    const handleChangeCustomerBurden = async ({ fileList }) => { // 11
        // setLoading(true)
        if (selectProduct?.productTypeId === 3) {
            setAllImg({ ...allImg, ts11: fileList });
        } else {
            for (const removedFile of fileListCustomerBurden.filter(file => file.status === 'removed')) {
                if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 11)) {
                    const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 11));
                    setAllImg(updatedAllImg);
                }
            }
        }
        setFileListCustomerBurden(fileList);
        setLoading(false)
    };
    const handleChangeCustomerStatementImage = async ({ fileList }) => { // 8
        for (const removedFile of fileListCustomerStatementImage.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 8)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 8));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerStatementImage(fileList);
        setLoading(false)
    };
    const handleChangeCustomerStatementPDF = async ({ fileList }) => { // 7
        for (const removedFile of fileListCustomerStatementPDF.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 7)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 7));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerStatementPDF(fileList);
        setLoading(false)
    };
    const handleChangeCustomerCareer = async ({ fileList }) => { // 6
        for (const removedFile of fileListCustomerCareer.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 6)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 6));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerCareer(fileList);
        setLoading(false)
    };

    return (
        <Row justify={'center'}>
            <Card style={{ width: '1000px' }}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <div className='text-center'>
                        <h2>อัปโหลดรูปภาพเสนอเคส</h2>
                    </div>
                    <Divider />
                    <Row justify={'center'}>
                        <Form
                            name="basic"
                            labelCol={{
                                span: 12,
                            }}

                            style={{
                                width: '90%',
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            form={form}
                            onFinish={handleSubmit}
                            autoComplete="off"
                        >
                            {/* <Card style={{ margin: 0 }}> */}
                            <Col className='main1'>
                                <Row justify={'center'}>
                                    <b>
                                        <Form.Item label='เลือกเสนอเคส' name='productTypeId'
                                            style={{ textAlign: 'center', width: '300px' }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your username!',
                                                },
                                            ]}
                                        >
                                            <Select placeholder={'เลือก'}
                                                disabled
                                                style={{ width: '250px' }}
                                            // onChange={(value) => handleProduct(value)}
                                            >
                                                <Option value={1}>รถ</Option>
                                                <Option value={2}>พรี-ที่ดิน</Option>
                                                <Option value={3}>ขอยอดที่ดิน</Option>
                                            </Select>
                                        </Form.Item>
                                    </b>
                                </Row>
                                <Divider style={{ margin: 5 }} />
                                {
                                    checkSnam ?
                                        <>
                                            <Row justify={'left'}><b><h3><u>รูปบัตรประชาชน (อัพโหลดไฟล์ไม่เกิน 3MB)</u></h3></b></Row>
                                            <Row justify={'center'} style={{ margin: 5 }}><b><h4 style={{ color: 'red' }}><u>เป็น "นิติบุคคล" ไม่ต้องอัพรูปในช่องผู้ทำสัญญา</u></h4></b></Row>
                                            <Row justify={'center'}>
                                                <div className='gutter-row' span={12}>
                                                    <b>
                                                        {dataAddImage?.map((imageData, index) => {
                                                            if (imageData.type === 4) {
                                                                return (
                                                                    <Row justify={'center'}>
                                                                        <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                    </Row>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                        <Form.Item label='' style={{ textAlign: 'center' }}>
                                                            {/* <Upload
                                                                accept="image/*"
                                                                maxCount={1}
                                                                style={{ textAlign: 'center' }}
                                                                listType="picture-card"
                                                                fileList={fileListCustomer}
                                                                onPreview={handlePreview}
                                                                onChange={handleChangeCustomer}
                                                                beforeUpload={(file) => beforeUpts(file, 4).then(() => false).catch(() => true)}
                                                            > */}
                                                            {/* {fileListCustomer?.length >= 1 ? null : (
                                                            <div>
                                                                <PlusOutlined />
                                                                <div className="ant-upload-text">upload</div>
                                                            </div>
                                                        )} */}
                                                            {/* </Upload>
                                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                            </Modal> */}
                                                        </Form.Item>
                                                    </b>
                                                </div>
                                            </Row>
                                            <Divider style={{ margin: 5 }} />
                                        </>
                                        :
                                        <>
                                            <Row justify={'left'}><b><h3><u>รูปบัตรประชาชน (อัพโหลดไฟล์ไม่เกิน 3MB)</u></h3></b></Row>
                                            <Row justify={'center'} style={{ margin: 5 }}><b><h4 style={{ color: 'red' }}><u>ถ้าเป็น "นิติบุคคล" ห้ามอัพรูปในช่องผู้ทำสัญญา</u></h4></b></Row>
                                            <Row justify={'center'}>
                                                <div className='gutter-row' span={12}>
                                                    <b>
                                                        {dataAddImage?.map((imageData, index) => {
                                                            if (imageData.type === 4) {
                                                                return (
                                                                    <Row justify={'center'}>
                                                                        <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                    </Row>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                        <Form.Item label='' style={{ textAlign: 'center' }}>
                                                            <Upload
                                                                accept="image/*"
                                                                maxCount={1}
                                                                style={{ textAlign: 'center' }}
                                                                listType="picture-card"
                                                                fileList={fileListCustomer}
                                                                onPreview={handlePreview}
                                                                onChange={handleChangeCustomer}
                                                                beforeUpload={(file) => beforeUpts(file, 4).then(() => false).catch(() => true)}
                                                            >
                                                                {fileListCustomer?.length >= 1 ? null : (
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div className="ant-upload-text">upload</div>
                                                                    </div>
                                                                )}
                                                            </Upload>
                                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                            </Modal>
                                                        </Form.Item>
                                                    </b>
                                                </div>
                                            </Row>
                                            <Divider style={{ margin: 5 }} />
                                        </>
                                }

                                <Divider style={{ margin: 5 }} />
                                <Row justify={'left'}><b><h3><u>รูปโฉนด/หน้า-หลัง (อัพโหลดไฟล์ไม่เกิน 3MB)</u></h3></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <div className='gutter-row' span={12}>
                                        <b>
                                            {dataAddImage?.map((imageData, index) => {
                                                if (imageData.type === 3) {
                                                    return (
                                                        <Row justify={'center'}>
                                                            <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </Row>
                                                    );
                                                }
                                                return null;
                                            })}
                                            <Form.Item label='' name='imageIDGuCar'>
                                                <Upload
                                                    accept="image/*"
                                                    name='imageIDGuCar'
                                                    multiple={true}
                                                    listType="picture-card"
                                                    fileList={fileListCustomerDeed}
                                                    onPreview={handlePreview}
                                                    onChange={handleChangeCustomerDeed}
                                                    beforeUpload={(file) => beforeUpts(file, 3).then(() => false).catch(() => true)}
                                                >
                                                    {fileListCustomerDeed?.length >= 10 ? null : (
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                    )}
                                                </Upload>
                                                <Modal open={previewVisible} onCancel={handleCancel}>
                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                </Modal>
                                            </Form.Item>
                                        </b>
                                    </div>
                                </Row>
                                <Divider />
                                <Row justify={'left'}><b><h3><u>ใบแบ่งใบต่อ (อัพโหลดไฟล์ไม่เกิน 3MB)</u></h3></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <div className='gutter-row' span={12}>
                                        <b>
                                            {dataAddImage?.map((imageData, index) => {
                                                if (imageData.type === 10) {
                                                    return (
                                                        <Row justify={'center'}>
                                                            <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </Row>
                                                    );
                                                }
                                                return null;
                                            })}
                                            <Form.Item label='' name='imageIDGuCar' >
                                                <Upload
                                                    accept="image/*"
                                                    name='imageIDGuCar'
                                                    multiple={true}
                                                    listType="picture-card"
                                                    fileList={fileListCustomerLeafDivider}
                                                    onPreview={handlePreview}
                                                    onChange={handleChangeCustomerLeafDivider}
                                                    beforeUpload={(file) => beforeUpts(file, 10).then(() => false).catch(() => true)}
                                                >
                                                    {fileListCustomerLeafDivider?.length >= 10 ? null : (
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                    )}
                                                </Upload>
                                                <Modal open={previewVisible} onCancel={handleCancel}>
                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                </Modal>
                                            </Form.Item>
                                        </b>
                                    </div>
                                </Row>
                                <Divider />
                                <Row justify={'left'}><b><h3><u>ระวาง (อัพโหลดไฟล์ไม่เกิน 3MB)</u></h3></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <div className='gutter-row' span={12}>
                                        <b>
                                            {dataAddImage?.map((imageData, index) => {
                                                if (imageData.type === 11) {
                                                    return (
                                                        <Row justify={'center'}>
                                                            <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </Row>
                                                    );
                                                }
                                                return null;
                                            })}
                                            <Form.Item label='' name='imageIDGuCar'>
                                                <Upload
                                                    accept="image/*"
                                                    name='imageIDGuCar'
                                                    multiple={true}
                                                    listType="picture-card"
                                                    fileList={fileListCustomerBurden}
                                                    onPreview={handlePreview}
                                                    onChange={handleChangeCustomerBurden}
                                                    beforeUpload={(file) => beforeUpts(file, 11).then(() => false).catch(() => true)}
                                                >
                                                    {fileListCustomerBurden?.length >= 10 ? null : (
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                    )}
                                                </Upload>
                                                <Modal open={previewVisible} onCancel={handleCancel}>
                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                </Modal>
                                            </Form.Item>
                                        </b>
                                    </div>

                                </Row>
                                <Divider />
                                <Row justify={'left'}><b><h3><u>ใบประเมิน/รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน(อัพโหลดไฟล์ไม่เกิน 3MB)</u></h3></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <div className='gutter-row' span={12}>
                                        <b>
                                            {dataAddImage?.map((imageData, index) => {
                                                if (imageData.type === 1) {
                                                    return (
                                                        <Row justify={'center'}>
                                                            <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </Row>
                                                    );
                                                }
                                                return null;
                                            })}
                                            <Form.Item label='' name='imageIDGuCar'>
                                                <Upload
                                                    accept="image/*"
                                                    name='imageIDGuCar'
                                                    multiple={true}
                                                    listType="picture-card"
                                                    fileList={fileListCustomerLand}
                                                    onPreview={handlePreview}
                                                    onChange={handleChangeCustomerLand}
                                                    beforeUpload={(file) => beforeUpts(file, 1).then(() => false).catch(() => true)}
                                                >
                                                    {fileListCustomerLand?.length >= 10 ? null : (
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                    )}
                                                </Upload>
                                                <Modal open={previewVisible} onCancel={handleCancel}>
                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                </Modal>
                                            </Form.Item>
                                        </b>
                                    </div>
                                </Row>
                                <Divider style={{ margin: 5 }} />
                                <Row justify={'left'}><b><h3><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ (อัพโหลดไฟล์ไม่เกิน 3MB)</u></h3></b></Row>
                                <Row justify={'center'}>
                                    <b>
                                        {dataAddImage?.map((imageData, index) => {
                                            if (imageData.type === 6) {
                                                return (
                                                    <Row justify={'center'}>
                                                        <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                    </Row>
                                                );
                                            }
                                            return null;
                                        })}
                                        <Form.Item
                                            style={{ textAlign: 'center' }}
                                        >
                                            <Upload
                                                accept="image/*"
                                                multiple={true}
                                                style={{ textAlign: 'center' }}
                                                listType="picture-card"
                                                fileList={fileListCustomerCareer}
                                                onPreview={handlePreview}
                                                onChange={handleChangeCustomerCareer}
                                                beforeUpload={(file) => beforeUpts(file, 6).then(() => false).catch(() => true)}
                                            >
                                                {fileListCustomerCareer?.length >= 20 ? null : (
                                                    <div>
                                                        <PlusOutlined />
                                                        <div className="ant-upload-text">upload</div>
                                                    </div>
                                                )}
                                            </Upload>
                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                        </Form.Item>
                                    </b>
                                </Row>
                                <Divider style={{ margin: 5 }} />
                                <Row justify={'left'}><b><h3><u>รูปสเตทเมนต์ผู้กู้ (อัพโหลดไฟล์ไม่เกิน 3MB)</u></h3></b></Row>
                                <Row justify={'center'}>
                                    <Col span={24}>
                                        <Row justify={'center'}><b><h3><u>ไฟล์ภาพ</u></h3></b></Row>
                                        <Row justify={'center'}>
                                            <b>
                                                {dataAddImage?.map((imageData, index) => {
                                                    if (imageData.type === 8) {
                                                        return (
                                                            <Row justify={'center'}>
                                                                <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            </Row>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                                <Form.Item
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    <Upload
                                                        accept="image/*"
                                                        multiple={true}
                                                        style={{ textAlign: 'center' }}
                                                        listType="picture-card"
                                                        fileList={fileListCustomerStatementImage}
                                                        onPreview={handlePreview}
                                                        onChange={handleChangeCustomerStatementImage}
                                                        beforeUpload={(file) => beforeUpts(file, 8).then(() => false).catch(() => true)}
                                                    >
                                                        {fileListCustomerStatementImage?.length >= 20 ? null : (
                                                            <div>
                                                                <PlusOutlined />
                                                                <div className="ant-upload-text">upload</div>
                                                            </div>
                                                        )}
                                                    </Upload>
                                                    <Modal open={previewVisible} onCancel={handleCancel}>
                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </Form.Item>
                                            </b>
                                        </Row>
                                    </Col>
                                </Row>
                                <Divider style={{ margin: 5 }} />
                                <Row justify={'left'}><b><h3><u>รูปสเตทเมนต์ผู้กู้</u></h3></b></Row>
                                <Row justify={'center'}>
                                    <Col span={24}>
                                        <Row justify={'center'}><b><h3><u>ไฟล์ PDF</u></h3></b></Row>
                                        <Row justify={'center'}>
                                            <b>
                                                {dataAddImage?.map((imageData, index) => {
                                                    if (imageData.type === 7) {
                                                        return (
                                                            <Row justify={'center'}>
                                                                <object data={`${getImagess}/${imageData.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                                                    <p>Alternative text - include a link <a href={`${getImagess}/${imageData.pathImage}?token=${token}`}>to the PDF!</a></p>
                                                                </object>
                                                                <p></p>
                                                            </Row>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                                <Form.Item style={{ textAlign: 'center' }} >
                                                    <Upload
                                                        accept="application/pdf"
                                                        multiple={true}
                                                        style={{ textAlign: 'center' }}
                                                        listType="picture-card"
                                                        fileList={fileListCustomerStatementPDF}
                                                        onPreview={handlePreview}
                                                        onChange={handleChangeCustomerStatementPDF}
                                                        beforeUpload={(file) => beforeUpts(file, 7).then(() => false).catch(() => true)}
                                                    >
                                                        {fileListCustomerStatementPDF?.length >= 10 ? null : (
                                                            <div>
                                                                <PlusOutlined />
                                                                <div className="ant-upload-text">upload</div>
                                                            </div>
                                                        )}
                                                    </Upload>
                                                    <Modal open={previewVisible} onCancel={handleCancel}>
                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </Form.Item>
                                            </b>
                                        </Row>
                                        {/* </aside> */}
                                    </Col>
                                </Row>
                                <Divider style={{ margin: 5 }} />
                            </Col>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Space>
                                    <Button type='primary' danger onClick={handleClose} >ยกเลิก</Button>
                                    {
                                        dataAddImage?.length > 0 || allImg?.length > 0 ?
                                            <Button type='primary' htmlType='submit' style={{ backgroundColor: "green" }} >ต่อไป</Button>
                                            :
                                            <Button disabled type='primary' htmlType='submit' style={{ backgroundColor: "green" }} >ต่อไป</Button>
                                    }
                                </Space>
                            </Col>
                        </Form>
                    </Row>
                </Spin>
                {contextHolder}
            </Card>
        </Row >
    )
}

export default UploadImgLand