import React, { useState, useEffect } from 'react';
import { Form, Input, Row, Col, Button, Select, Divider, Space, Radio, DatePicker, Card, Upload, message, Modal, Spin, notification, Image } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import '../../offer_case/css/Media.css';
import axios from 'axios';
import dayjs from 'dayjs';
import Compressor from 'compressorjs';
import { editcustomer, findcarmk, getImagess, getImagessPre, mutips2, mutips2pre } from '../../file_mid/all_api';


function EditNewCustomer({ getData, close, notPassData, sendback, saveResendNote, setSaveResendNote, checkPreNotPass }) {
    const [loading, setLoading] = useState(false)
    const { TextArea } = Input;
    const [form] = Form.useForm()
    const [holds, setHolds] = useState();
    const [dataModalEdit, setDataModalEdit] = useState(getData)
    const [fileListCustomerCareer, setFileListCustomerCareer] = useState([]); // type 6 ประวัติ / ทะเบียนบ้าน / อาชีพ
    const [allImg, setAllImg] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [api, contextHolder] = notification.useNotification();
    const newFormData = new FormData();
    const [resultImage, setResultImage] = useState([]);
    const [resendNote, setResendNote] = useState();
    const token = localStorage.getItem('token');
    const [imageBlobzz, setImageBlobzz] = useState();
    const [checkSendNEW, setCheckSendNEW] = useState(false);


    useEffect(() => {
        const loadDataLand = async () => {
            setLoading(true)
            await axios.post(findcarmk, { carId: getData.cars.carId, customerId: getData.customerId })
                .then(res => {
                    if (res.status === 200) {
                        // console.log("res.data555", res.data)
                        setImageBlobzz(res.data.cars.img)
                        setLoading(false)
                    }
                })
        }
        loadDataLand()
    }, [getData])

    useEffect(() => {
        imageBlobzz?.map((e, index) => {
            var PreAaproveSplit = []
            PreAaproveSplit = e.pathImage.split("/")
            if (PreAaproveSplit[3] === "13") {
                setCheckSendNEW(true)
            }
        })
    }, [imageBlobzz])

    useEffect(() => {
        setDataModalEdit(getData)
        setHolds(getData?.nationalId)
        if (getData !== "") {
            form.setFieldsValue(
                {
                    ...notPassData,
                    ...getData,
                    birthDate: dayjs(getData?.birthDate),
                    issudt: dayjs(getData?.issudt),
                    expdt: dayjs(getData?.expdt),
                }
            )
        }
        if (saveResendNote.customerNote) {
            setResendNote(saveResendNote)
            form.setFieldsValue({
                ...saveResendNote
            })
        }
    }, [getData])


    const handleCancel1 = () => {
        close();
    }

    const SuccSend = (placement) => {
        api.success({
            message: <b>แก้ไขข้อมูลสำเร็จ</b>,
            // description: `กรุณาเพิ่มที่อยู่ผู้ค้ำ ลำดับที่ ${index + 1}`,
            placement,
        });
    }

    const SuccImg = (placement) => {
        api.success({
            message: <b>เพิ่มรูปสำเร็จ</b>,
            // description: `กรุณาเพิ่มที่อยู่ผู้ค้ำ ลำดับที่ ${index + 1}`,
            placement,
        });
    }

    const handleSubmit = async () => {
        setLoading(true);
        var data = { ...dataModalEdit, updatedAt: dayjs() }
        await axios.post(editcustomer, data)
            .then(async (res) => {
                if (res.status === 200) {
                    if (allImg?.length > 0) {
                        await imgSend(allImg)
                    } else {
                        sendback(dayjs())
                        setLoading(false);
                    }
                    handleSendNote()
                    SuccSend("top")
                }
            }).catch((err) => console.log(err))
        setLoading(false);
    };

    const handleSendNote = async () => {
        if (resendNote !== undefined) {
            if (resendNote.customerNote !== "") {
                setSaveResendNote({ ...saveResendNote, ...resendNote })
            } else {
                setSaveResendNote({ ...saveResendNote, customerNote: null })
            }
        } else {
            // console.log("= undefined")
        }
    };

    const imgSend = (resultImgs) => {
        return new Promise(async (resolve, reject) => {
            try {
                var status;
                newFormData.append('idcus', dataModalEdit?.cars?.carId);
                //newFormData.append('productTypeId', "1");
                newFormData.append('tm', dayjs().format("YYYY-MM-DDTHH:mm:ssZ"));

                for (const file of resultImgs) {
                    var image = {}
                    newFormData.append('file', file.imageCompressedFile);
                    newFormData.append("type", file.type);
                    image.type = file.type
                    resultImage.push(image)
                }
                if (checkPreNotPass) {
                    // console.log("pre")
                    const response = await axios.post(mutips2pre, newFormData);
                    if (response.data) {
                        setFileListCustomerCareer([])
                        setAllImg([])
                        console.log("TY Pre", response.data);
                        status = response.status;
                        resolve(status);
                    }
                } else {
                    // console.log("not")
                    const response = await axios.post(mutips2, newFormData);
                    if (response.data) {
                        setFileListCustomerCareer([])
                        setAllImg([])
                        console.log("TY", response.data);
                        status = response.status;
                        resolve(status);
                    }
                }

            } catch (error) {
                error(error);
                reject(error);
            } finally {
                //setLoading(false);
                console.log("OK")
                SuccImg("top")
                sendback(dayjs())
            }
        });
    };

    const handleProduct = (value) => {
        setHolds(value)
        setDataModalEdit({ ...dataModalEdit, nationalId: value })
    }

    const handletypeCompany = (value) => {
        setDataModalEdit({ ...dataModalEdit, snam: value })
    }

    const handletypeCompanyed = (value) => {
        setDataModalEdit({ ...dataModalEdit, lastname: value })
    }

    const inputTaxId = (e) => {
        setDataModalEdit({ ...dataModalEdit, identificationId: e.target.value })
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleCancel = () => {
        setPreviewVisible(false);
    }

    const compressImage = async (ez, types) => {
        var image = {};
        return new Promise(async (resolve) => {
            if (types !== 7) {
                if (ez.size > 4000000) {
                    console.log("4m ไม่ให้ส่ง");
                    resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                } else {
                    let quality;
                    if (ez.size >= 2500000 && ez.size <= 4000000) {
                        quality = 0.1;
                    } else if (ez.size >= 2000000 && ez.size <= 2499999) {
                        quality = 0.15;
                    } else if (ez.size >= 1500000 && ez.size <= 1999999) {
                        quality = 0.2;
                    } else if (ez.size >= 1000000 && ez.size <= 1499999) {
                        quality = 0.3;
                    } else {
                        quality = 0.5;
                    }

                    try {
                        // ใช้ Promise เพื่อบีบอัดรูปภาพ
                        const compressedFile = await new Promise((resolveCompress) => {
                            new Compressor(ez, {
                                quality: quality,
                                success: (result) => {
                                    resolveCompress(result);
                                },
                            });
                        });

                        const fileOptions = {
                            type: compressedFile.type,
                            lastModified: Date.now(),
                        };
                        const compressedFileAsFile = new File([compressedFile], ez.name, fileOptions);
                        image.imageCompressedFile = compressedFileAsFile;
                        image.type = types
                        allImg.push(image);
                        //setAllImg((prevAllImg) => [...prevAllImg, image]);

                        // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
                        resolve(image);
                    } catch (error) {
                        console.error('Image compression error:', error);
                        resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                    }
                }
            } else {
                // ... (โลจิกสำหรับ type 7)
                image.imageCompressedFile = ez;
                image.type = types
                allImg.push(image);
                //setAllImg((prevAllImg) => [...prevAllImg, image]);
                resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
            }
        });
    };

    const beforeUpts = async (file, ty) => {
        try {
            setLoading(true)
            const compressedFile = await compressImage(file, ty);
            return Promise.resolve(compressedFile);
        } catch (error) {
            console.error('Image compression error:', error);
            message.error('Image compression failed.');
            return Promise.reject(); // เปลี่ยนให้ return Promise.reject() เมื่อไม่สามารถบีบอัดได้
        }
    };

    const handleChangeCustomerCareer = async ({ fileList }) => { // 6
        for (const removedFile of fileListCustomerCareer.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 6)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 6));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerCareer(fileList);
        setLoading(false)
    };

    return (
        <Row justify={'center'}>
            <Row justify={'center'} style={{ margin: '5px', fontSize: '18px' }} >ข้อมูลส่วนตัว</Row>
            <Card style={{ width: '1100px' }}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Row justify={'center'}>
                        <Form
                            form={form}
                            labelCol={{
                                span: 12,
                            }}
                            style={{
                                width: '100%',
                            }}
                            initialValues={{
                                remember: true,
                            }}

                            autoComplete="off"
                            onFinish={handleSubmit}
                        >
                            <Row justify={'center'} style={{ margin: '5px' }} >
                                <Col span={20} style={{ textAlign: 'center' }}>

                                    <aside style={{ width: '90%' }}>
                                        <Row justify={'center'} >
                                            <Form.Item label='หมายเหตุ(ธุรการ)' name='npCustomerNote' style={{ textAlign: 'center' }}>
                                                <TextArea rows={5} name='npCustomerNote' disabled />
                                            </Form.Item>
                                            <Form.Item label='หมายเหตุ(การตลาด)' name='customerNote' style={{ textAlign: 'center' }}>
                                                <TextArea rows={5} name='customerNote' placeholder='หมายเหตุถึงธุรการ' onChange={(e) => setResendNote({ customerNote: e.target.value })} />
                                            </Form.Item>
                                        </Row>
                                    </aside>
                                    {
                                        !checkPreNotPass ?
                                            <>
                                                <Form.Item label='ประเภท' name='nationalId'
                                                    style={{ textAlign: 'center', width: '300px' }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !',
                                                        },]}>
                                                    <Select
                                                        disabled
                                                        placeholder={'เลือก'}
                                                        style={{ textAlign: 'center', width: '250px', height: '40px', }}
                                                        defaultValue={'บุคคลธรรมดา'}
                                                        name='legal'
                                                        onChange={(value) => handleProduct(value)}
                                                        options={[
                                                            {
                                                                label: 'บุคคลธรรมดา',
                                                                value: 'บุคคลธรรมดา',
                                                            },
                                                            {
                                                                label: 'นิติบุคคล',
                                                                value: 'นิติบุคคล',
                                                            },
                                                        ]}
                                                    />
                                                </Form.Item>
                                                {holds === "นิติบุคคล" ?
                                                    <>
                                                        <Row className='main2' justify={'center'}>
                                                            <Col span={24} style={{ textAlign: 'center', }}>
                                                                <aside style={{ width: '90%' }}>
                                                                    <div>
                                                                        <Form.Item label='ประเภทห้าง' name='snam'
                                                                            style={{ textAlign: 'center', width: '300px' }}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please input !',
                                                                                },]}>
                                                                            <Select
                                                                                placeholder={'เลือก'}
                                                                                style={{ width: '250px', height: '40px', }}
                                                                                name='snam'
                                                                                initialvalues={1}
                                                                                onChange={(value) => handletypeCompany(value)}
                                                                                options={[
                                                                                    {
                                                                                        label: 'หจก.',
                                                                                        value: 'หจก',
                                                                                    },
                                                                                    {
                                                                                        label: 'บริษัท',
                                                                                        value: 'บริษัท',
                                                                                    },
                                                                                ]}
                                                                            />


                                                                        </Form.Item>
                                                                    </div>
                                                                    <div>
                                                                        <Form.Item
                                                                            label='เลขประจำตัวผู้เสียภาษี'
                                                                            name='identificationId'
                                                                            style={{ textAlign: 'center', width: '300px' }}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please input !',
                                                                                },]}>

                                                                            <Input disabled type='text' maxLength={13}
                                                                                name='identificationId'
                                                                                // disabled={CheckIdCard ? false : true}
                                                                                style={{ width: '250px', marginRight: '5px', marginLeft: '5px', color: 'black' }}
                                                                                //  onChange={(e) => setDataPost({ ...dataPost, taxID: e.target.value }, isThaiNationalID(e.target.value))}></Input>
                                                                                onChange={(e) => inputTaxId(e)}></Input>
                                                                        </Form.Item>

                                                                    </div>

                                                                    {/* {typeCompany } */}
                                                                    <div>
                                                                        <Form.Item label='ชื่อบริษัท' name='firstname'
                                                                            style={{ textAlign: 'center', width: '300px' }}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please input !',
                                                                                },]}
                                                                        >
                                                                            <Input type='text'
                                                                                style={{ width: '250px', color: 'black' }}
                                                                                onChange={(e) => setDataModalEdit({ ...dataModalEdit, firstname: e.target.value })}>

                                                                            </Input>

                                                                        </Form.Item>
                                                                    </div>
                                                                    <div>
                                                                        <Form.Item label='' name='lastname'
                                                                            style={{ textAlign: 'center', width: '300px' }}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please input !',
                                                                                },]}
                                                                        >
                                                                            <Select
                                                                                placeholder={'เลือก'}
                                                                                style={{ textAlign: 'left', height: '40px', width: '250px' }}
                                                                                name='lastname'
                                                                                initialvalues={'จำกัด'}
                                                                                onChange={(value) => handletypeCompanyed(value)}
                                                                                options={[
                                                                                    {
                                                                                        label: 'มหาชน',
                                                                                        value: 'มหาชน',
                                                                                    },
                                                                                    {
                                                                                        label: 'จำกัด',
                                                                                        value: 'จำกัด',
                                                                                    },
                                                                                ]}
                                                                            />
                                                                        </Form.Item>
                                                                    </div>
                                                                    <div>
                                                                        <Form.Item label='วันจดทะเบียน' name='birthDate'
                                                                            style={{ textAlign: 'center', width: '300px' }}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please input !',
                                                                                },]}>
                                                                            <DatePicker format={'YYYY-MM-DD'}
                                                                                style={{ height: '40px', width: '250px', color: 'black' }}
                                                                                onChange={(e) => {
                                                                                    if (e) {
                                                                                        setDataModalEdit({ ...dataModalEdit, birthDate: e.format('YYYY-MM-DD') })
                                                                                    }
                                                                                }} />
                                                                        </Form.Item>
                                                                    </div>
                                                                </aside>
                                                            </Col>
                                                        </Row>
                                                        <Divider />
                                                    </>
                                                    :
                                                    <>
                                                        <Row className='main2' justify={'center'}>
                                                            <Col span={24} style={{ textAlign: 'center', }}>
                                                                <aside style={{ width: '90%' }}>
                                                                    <Form.Item label='เลขประจำตัวประชาชน' name='identificationId'
                                                                        style={{ width: '300px' }}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please input !',
                                                                            },]}>
                                                                        <Input type='text' disabled style={{ width: '250px' }} onChange={e => setDataModalEdit({ ...dataModalEdit, identificationId: e.target.value })} />
                                                                    </Form.Item>
                                                                    <Form.Item label='คำนำหน้าชื่อ' name='snam'
                                                                        style={{ width: '300px' }}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please input !',
                                                                            },]}>
                                                                        <Radio.Group style={{ width: '250px' }} onChange={(e) => setDataModalEdit({ ...dataModalEdit, snam: e.target.value })} >
                                                                            <Radio value="นาย"> นาย </Radio>
                                                                            <Radio value="นาง"> นาง </Radio>
                                                                            <Radio value="นางสาว"> นางสาว </Radio>
                                                                        </Radio.Group>
                                                                    </Form.Item>
                                                                    <Form.Item label='ชื่อ' name='firstname' style={{ width: '300px' }}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please input !',
                                                                            },]}>
                                                                        <Input type='text' style={{ width: '250px' }} onChange={e => setDataModalEdit({ ...dataModalEdit, firstName: e.target.value })} />
                                                                    </Form.Item>
                                                                    <Form.Item label='นามสกุล' name='lastname' style={{ width: '300px' }}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please input !',
                                                                            },]}>
                                                                        <Input type='text' style={{ width: '250px' }} onChange={e => setDataModalEdit({ ...dataModalEdit, lastName: e.target.value })} />
                                                                    </Form.Item>
                                                                    <Form.Item label='ชื่อเล่น' name='nickname' style={{ width: '300px' }}>
                                                                        <Input type='text' style={{ width: '250px' }} onChange={e => setDataModalEdit({ ...dataModalEdit, nickname: e.target.value })} />
                                                                    </Form.Item>
                                                                    <Form.Item label='เกิดวันที่' name='birthDate' style={{ width: '300px' }}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please input !',
                                                                            },]}>
                                                                        <DatePicker
                                                                            format={'YYYY-MM-DD'}
                                                                            style={{ width: '250px', height: "40px" }}
                                                                            name="birthDate"
                                                                            onChange={(e) => {
                                                                                if (e) {
                                                                                    setDataModalEdit({ ...dataModalEdit, birthDate: e.format('YYYY-MM-DD') })
                                                                                }
                                                                            }} />
                                                                    </Form.Item>
                                                                    <Form.Item label='เพศ' name='gender' style={{ width: '300px' }}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please input !',
                                                                            },]}>
                                                                        <Select

                                                                            style={{ width: '250px', height: "40px" }}
                                                                            onChange={(value) => setDataModalEdit({ ...dataModalEdit, gender: value })}
                                                                            options={[
                                                                                {
                                                                                    label: 'ชาย',
                                                                                    value: 'M',
                                                                                },
                                                                                {
                                                                                    label: 'หญิง',
                                                                                    value: 'F',
                                                                                },
                                                                            ]}
                                                                        />
                                                                    </Form.Item>
                                                                    <Form.Item label='เจ้าพนักงานออกบัตร' name='issuby' style={{ width: '300px' }}>
                                                                        <Input type='text' style={{ width: '250px' }} onChange={e => setDataModalEdit({ ...dataModalEdit, issuby: e.target.value })} />
                                                                    </Form.Item>
                                                                    <Form.Item label='วันออกบัตร' name='issudt' style={{ width: '300px' }}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please input !',
                                                                            },]}>
                                                                        <DatePicker format={'YYYY-MM-DD'} name='issudt' style={{ width: '250px', height: "40px" }}
                                                                            onChange={(e) => {
                                                                                if (e) {
                                                                                    setDataModalEdit({ ...dataModalEdit, issudt: e.format('YYYY-MM-DD') })
                                                                                }
                                                                            }} />
                                                                    </Form.Item>
                                                                    <Form.Item label='วันบัตรหมดอายุ' name='expdt' style={{ width: '300px' }}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please input !',
                                                                            },]}>
                                                                        <DatePicker format={'YYYY-MM-DD'} name='expdt' style={{ width: '250px', height: "40px" }}
                                                                            onChange={(e) => {
                                                                                if (e) {
                                                                                    setDataModalEdit({ ...dataModalEdit, expdt: e.format('YYYY-MM-DD') })
                                                                                }
                                                                            }} />
                                                                    </Form.Item>
                                                                </aside>
                                                            </Col>
                                                        </Row>
                                                        <Divider />
                                                    </>
                                                }

                                            </>
                                            :
                                            null
                                    }
                                    <Row justify={'center'} style={{ margin: '5px' }} >ประวัติผู้กู้ / ทะเบียนบ้าน (อัพโหลดไฟล์ไม่เกิน 3MB)</Row>
                                    <Row justify={'center'}>
                                        <Col span={24}>
                                            <aside gutter={24} justify={'center'}>
                                                <div className='gutter-row' span={12}>
                                                    <Row justify={'center'}><b><h3><u>ไฟล์ภาพ</u></h3></b></Row>
                                                    <Row justify={'center'}>
                                                        <b> <Form.Item
                                                            style={{ textAlign: 'center' }}
                                                        >
                                                            <Upload
                                                                accept="image/*"
                                                                multiple={true}
                                                                style={{ textAlign: 'center' }}
                                                                listType="picture-card"
                                                                fileList={fileListCustomerCareer}
                                                                onPreview={handlePreview}
                                                                onChange={handleChangeCustomerCareer}
                                                                beforeUpload={(file) => beforeUpts(file, 6).then(() => false).catch(() => true)}
                                                            >
                                                                {fileListCustomerCareer?.length >= 20 ? null : (
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div className="ant-upload-text">upload</div>
                                                                    </div>
                                                                )}
                                                            </Upload>
                                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                            </Modal>
                                                        </Form.Item>
                                                        </b>
                                                    </Row>
                                                </div>
                                            </aside>
                                        </Col>
                                    </Row>
                                    <Divider />
                                    <Row justify={'left'}><b><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ</u></b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        {imageBlobzz?.map((e, index) => {
                                            var PreAaproveSplit = []
                                            PreAaproveSplit = e.pathImage.split("/")
                                            if (PreAaproveSplit[3] !== "13") {
                                                if (e.type === 6) {
                                                    return (
                                                        <>
                                                            <Image width={"50px"} height={'50px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </>
                                                    );
                                                }
                                            }
                                            return null;
                                        })}
                                    </Row>
                                    <Divider />
                                    {
                                        checkSendNEW ?
                                            <>
                                                <Row justify={'left'}><b><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ</u> (ใหม่)</b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Space direction="vertical" >
                                                        <Row gutter={32} justify={'center'}>
                                                            {imageBlobzz?.map((e, index) => {
                                                                var PreAaproveSplit = []
                                                                PreAaproveSplit = e.pathImage.split("/")
                                                                if (PreAaproveSplit[3] === "13") {
                                                                    if (e.type === 6) {
                                                                        return (
                                                                            <>
                                                                                <Image width={"50px"} height={'50px'} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                            </>
                                                                        );
                                                                    }
                                                                }
                                                                return null;
                                                            })}
                                                        </Row>
                                                    </Space>
                                                </Row>
                                            </>
                                            : null
                                    }


                                    <Divider />

                                    <Row >
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <Space>
                                                <Button type="primary" danger onClick={handleCancel1}>ปิด</Button>
                                                <Button type="primary" htmlType="submit" style={{ background: "green" }} disabled={fileListCustomerCareer.length === 0}>บันทึก</Button>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Row>
                </Spin>
            </Card>
            {contextHolder}
        </Row>

    )
};

export default EditNewCustomer