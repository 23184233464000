import dayjs from "dayjs";

const currencyFormatOne = (amount) => {
    if (amount) {
      return Number(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    } else {
      return "-"
    }
  }
  export function dayFormatOne(value) {
    if (value) {
      // console.log("vv",value)
      return dayjs(value).format('YYYY-MM-DD');
    } else {
      return ""
    }
  }

const columnsMiss = [
    {
      key: 'index',
      title: "ลำดับ",
      dataIndex: "index",
      width: "5%",
      align: 'center',
      render: (text, object, index) => index + 1
    },
    {
      title: "รายละเอียดสัญญา",
      dataIndex: "REF2",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <>
          <div>รหัส REF2 : <b style={{ fontSize: '16px', color: 'red' }}>{record.REF2}</b></div>
        </>
      ),
    },
    {
      key: 'money',
      title: "รายละเอียดการโอน",
      dataIndex: "money",
      width: "auto",
      align: 'center',
      render: (text, record) => (
        <>
          <div>ยอดเงิน : <b style={{ fontSize: '16px', color: 'red' }}>{currencyFormatOne(record.money)}</b></div>
        </>
      ),
    },
    {
      title: "วันที่ชำระ",
      dataIndex: "PAYMENTDATE",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <>
          <div>วันที่ชำระ : <b style={{ fontSize: '16px', color: 'red' }}>{dayjs(record.PAYMENTDATE).format("DD-MM-YYYY")}</b></div>
        </>
      ),
    }
  ];


export { columnsMiss };