import React, { useState, useEffect } from 'react'
import { Button, Form, Select, Col, Row, Input, Modal, Card, Upload, message, Space, Divider, notification, InputNumber, Spin, Image } from 'antd';
import axios from 'axios';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import Currency from 'currency.js';
import '../../offer_case/css/Media.css';

import { useDispatch, useSelector } from 'react-redux';
// import { addCar } from '../../../redux/Car';
import TextArea from 'antd/es/input/TextArea';
import TableRateCar from '../../offer_case/rate_car/TableRateCar';
import { HirePurchase } from '../../offer_case/hire_purchase/HirePurchase';
import { Installment } from '../../offer_case/ploan/Installment';
import Yearpick from './Yearpick';
import Compressor from 'compressorjs';
import { productLoanTypeCar } from '../../file_mid/all_options';
import { dataModel, editcarloan, findcarmk, getImagess, getImagessPre, getprice, loadProvinces, mutips2, mutips2pre, ratecarsmodel } from '../../file_mid/all_api';


export default function EditCLD({ getData, sendback, close, notPassData, saveResendNote, setSaveResendNote, checkPreNotPass }) {

    // const token = localStorage.getItem('token');
    // const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const token = localStorage.getItem('token');
    const [notPassData2, setNotPassData2] = useState(notPassData)
    const { Option } = Select;
    const cars = useSelector(state => state.cars)
    const [form] = Form.useForm()
    const [api, contextHolder] = notification.useNotification();

    const [selectProduct, setSelectProduct] = useState({}) // 1 รถ , 2 ที่ดิน
    // const [imageBlobzz, setImageBlobzz] = useState([]);

    /////////////////////////////////////////////////////
    const [dataPost2, setDataPost2] = useState({ productTypeCar: 1 })  //car
    const [selectTypeCar, setSelectTypeCar] = useState(1) // 1 รถ , 2 ที่ดิน
    const [money, setMoney] = useState({}) //loan
    const [moneyOld, setMoneyOld] = useState({}) //loanOld
    const [typeMoney, setTypeMoney] = useState({})
    const [provincesCar, setProvincesCar] = useState([]);

    ///////////////// ตาราง ///////////////////////////////
    const [openTable, setOpenTable] = useState(false); //ตารางค่างวด p-loan
    const [openTableHP, setOpenTableHP] = useState(false); //ตารางค่างวด เช่าซื้อ
    const [openTableCar, setOpenTableCar] = useState(false); //ตารางเรทรถ

    /////////////////// รถ ////////////////////////////////////
    const [resultData, setResultData] = useState([]) // API
    const [typeData, setTypeData] = useState([]) // API
    const [brandData, setBrandData] = useState([]) // API
    const [modelData, setModelData] = useState([]) // API
    const [yearPrice, setYearPrice] = useState([])
    const [typeSelected, setTypeSelected] = useState(null)

    ////////////////// select car ////////////////////////////////
    const [counterBrand, setCounterBrand] = useState(false)
    const [counterModel, setCounterModel] = useState(false)
    const [counterCarBaab, setCounterCarBaab] = useState(false)

    ///////////////// อัปโหลดรูป //////////////////////////////////////
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [fileListCustomerDocument, setFileListCustomerDocument] = useState([]);
    const [fileListCustomerDocument1, setFileListCustomerDocument1] = useState([]);
    const [fileListCustomerCar, setFileListCustomerCar] = useState([]);
    /*เก็บรูปทั้งหมด*/
    const [allImg, setAllImg] = useState([]);
    //////////////// เลือก p-loan หรือ เช่าซื้อ /////////////////////
    const [checkPCH, setCheckPCH] = useState({ PLorCH: getData.cars.carLoanDetails?.productType })

    const currentTime = dayjs();
    const delayedTime = currentTime.add(7, 'hour');
    const formattedTime = delayedTime.format('YYYY-MM-DDTHH:mm:ssZ');

    const newFormData = new FormData();
    const [resultImage, setResultImage] = useState([]);

    const [resendNote, setResendNote] = useState();
    const [imageBlobzz, setImageBlobzz] = useState();
    const [checkSendNEW, setCheckSendNEW] = useState(false);



    useEffect(() => {
        if (cars.data?.brand_id !== undefined) {
            setDataPost2({ ...cars.data, carYear: cars.data.carYear.toString(), carId: getData.cars.carId, productTypeId: 1 })
            setSelectProduct({ ...selectProduct, productTypeId: 1 })
            setMoney({ ...getData.cars.carLoanDetails })
            setMoneyOld({ ...getData.cars.carOldLoanDetails })
            setTypeData(getData?.cars?.productTypeCar)
            setTypeSelected(getData?.cars?.productTypeCar)
            loadDataBrand(getData?.cars?.productTypeCar)
            form.setFieldsValue({
                ...getData.cars.carLoanDetails,
                ...getData.cars,
                ...checkPCH,
                ...getData.cars.carOldLoanDetails,
                ...cars.data,
                productTypeId: 1,
                loanAmount: "",
                loanTerm: "",
                carPlateNumber: getData.cars.carPlateNumber,
                carProvince: getData.cars.carProvince,
                monthlyPayment: "",
                interest: "",
                installmentWithInterest: "",
            })
        } else {
            if (getData.cars.carId !== undefined) {
                setDataPost2({ ...getData.cars, productTypeId: 1 })
                setSelectProduct({ ...selectProduct, productTypeId: 1 })
                setMoney({ ...getData.cars.carLoanDetails })
                setMoneyOld({ ...getData.cars.carOldLoanDetails })
                setTypeData(getData?.cars?.productTypeCar)
                setTypeSelected(getData?.cars?.productTypeCar)
                loadDataBrand(getData?.cars?.productTypeCar)
                setCheckPCH({ PLorCH: getData.cars.carLoanDetails.productType })
                if (getData.cars.carLoanDetails.productLoanType === "ย้ายไฟแนนซ์" || getData.cars.carLoanDetails.productLoanType === "Pre-Aaprove-ย้ายไฟแนนซ์") {
                    setMoneyOld({ ...getData.cars.carOldLoanDetails, productLoanType: getData.cars.carLoanDetails.productLoanType })
                }
                form.setFieldsValue({
                    ...getData.cars.carLoanDetails,
                    ...getData.cars.carOldLoanDetails,
                    ...checkPCH,
                    ...getData.cars,
                    productTypeId: 1,
                })
            }
        }
        form.setFieldsValue({
            ...notPassData,
        })
        if (saveResendNote.guarantorNote) {
            form.setFieldsValue({
                ...saveResendNote
            })
        }
        // loadImage()
        fetchAllProvinces()
    }, [cars.data])

    useEffect(() => {
        const loadDataLand = async () => {
            setLoading(true)
            await axios.post(findcarmk, { carId: getData.cars.carId, customerId: getData.customerId })
                .then(res => {
                    if (res.status === 200) {
                        console.log("res.data555", res.data)
                        setImageBlobzz(res.data.cars.img)
                        setLoading(false)
                    }
                })
        }
        loadDataLand()
    }, [getData])

    useEffect(() => {
        imageBlobzz?.map((e, index) => {
            var PreAaproveSplit = []
            PreAaproveSplit = e.pathImage.split("/")
            if (PreAaproveSplit[3] === "13") {
                setCheckSendNEW(true)
            }
        })
    }, [imageBlobzz])



    useEffect(() => {
        if (money && dataPost2.productTypeCar > 1 && checkPCH.PLorCH === "เช่าซื้อ") {
            car_Calculate()
        }
        if (money && dataPost2.productTypeCar === 1 && checkPCH.PLorCH === "เช่าซื้อ1") {
            car_Calculate()
        }
        // if (moneyOld && moneyOld?.productLoanType === 'ย้ายไฟแนนซ์') {
        //     // console.log("GG")
        //     car_Calculate_close()
        // }
    }, [money?.loanAmount, money?.interestRate, money?.loanTerm, money?.installmentAmountt])

    // const car_Calculate_close = () => {
    //     if (moneyOld?.oldLoanAmount && moneyOld?.oldMonthlyPayment && moneyOld?.oldLoanTerm) {
    //         var loanTermKhang = moneyOld?.oldKangPayment / moneyOld?.oldMonthlyPayment
    //         setMoneyOld({
    //             ...moneyOld,
    //             oldKangPaymentTerm: loanTermKhang,
    //         })
    //         form.setFieldsValue(
    //             {
    //                 oldKangPaymentTerm: loanTermKhang,
    //             }
    //         )
    //     } else {
    //         // console.log("")
    //     }
    // }

    // console.log("moneyOld", moneyOld)
    // console.log("money", money)

    const handleCancel = () => {
        close()
    }

    const SuccSend = (placement) => {
        api.success({
            message: <b>แก้ไขข้อมูลสำเร็จ</b>,
            // description: `กรุณาเพิ่มที่อยู่ผู้ค้ำ ลำดับที่ ${index + 1}`,
            placement,
        });
    }
    const SuccImg = (placement) => {
        api.success({
            message: <b>เพิ่มรูปสำเร็จ</b>,
            // description: `กรุณาเพิ่มที่อยู่ผู้ค้ำ ลำดับที่ ${index + 1}`,
            placement,
        });
    }

    const handleSubmit = async () => {
        setLoading(true);
        var MixData = { ...moneyOld, ...dataPost2, ...money, TypeLoan: "car" }
        console.log("MixData", MixData)
        await axios.post(editcarloan, MixData)
            .then(async (res) => {
                if (res.status === 200) {
                    if (allImg?.length > 0) {
                        await imgSend(allImg)
                        setLoading(false);

                    } else {
                        setLoading(false);
                        sendback(dayjs())
                    }
                    SuccSend("top")
                    handleSendNote()
                    setLoading(false);

                }
            }).catch(error => {
                console.error(error);
            });
        setLoading(false);
    }

    const handleSendNote = async () => {
        if (resendNote !== undefined) {
            // console.log("!= undefined")
            if (resendNote.productNote !== "") {
                // console.log("if")
                setSaveResendNote({ ...saveResendNote, ...resendNote })
            } else {
                // console.log("else")
                setSaveResendNote({ ...saveResendNote, productNote: null })
            }
        } else {
            // console.log("= undefined")
        }
    };

    const imgSend = (resultImgs) => {
        return new Promise(async (resolve, reject) => {
            try {
                var status;
                newFormData.append('idcus', getData?.cars?.carId);
                //newFormData.append('productTypeId', "1");
                newFormData.append('tm', dayjs().format("YYYY-MM-DDTHH:mm:ssZ"));

                for (const file of resultImgs) {
                    var image = {}
                    newFormData.append('file', file.imageCompressedFile);
                    newFormData.append("type", file.type);
                    image.type = file.type
                    resultImage.push(image)
                }
                if (checkPreNotPass) {
                    const response = await axios.post(mutips2pre, newFormData);
                    if (response.data) {
                        setFileListCustomerDocument([])
                        setFileListCustomerDocument1([])
                        setFileListCustomerCar([])
                        setAllImg([])
                        console.log("TY Pre", response.data);
                        status = response.status;
                        resolve(status);
                    }
                } else {
                    const response = await axios.post(mutips2, newFormData);
                    if (response.data) {
                        setFileListCustomerDocument([])
                        setFileListCustomerDocument1([])
                        setFileListCustomerCar([])
                        setAllImg([])
                        console.log("TY", response.data);
                        status = response.status;
                        resolve(status);
                    }
                }

            } catch (error) {
                error(error);
                reject(error);
            } finally {
                //setLoading(false);
                console.log("OK")
                SuccImg("top")
                sendback(dayjs())
            }
        });
    };

    //////////////// จังหวัด รถ /////////////////////
    const fetchAllProvinces = async () => {
        await axios.get(loadProvinces)
            .then(response => {
                setProvincesCar(response.data);
            }).catch(error => {
                console.error(error);
            });
    }

    const handleProvinceChangeCar = (value, element) => {
        setDataPost2({ ...dataPost2, carProvince: value, });
    };

    /////////////// โมดาว ค่า งวด p-loan และ แบบ เช่าซื้อ ///////////////////
    const handleMoney = (values) => {
        setMoney({
            ...money,
            interestRate: values?.interestRate,
            loanAmount: values?.loanAmountt,
            loanTerm: values?.installmentAmountt,
            interest: values?.interestt,
            monthlyPayment: values?.monthlyPayment,
            installmentWithInterest: values?.installmentWithInterestt,
            productType: checkPCH.PLorCH,
            PLorCH: checkPCH.PLorCH
        })
        form.setFieldsValue(
            {
                loanAmount: values?.loanAmountt,
                loanTerm: values?.installmentAmountt,
                interest: values?.interestt,
                monthlyPayment: values?.monthlyPayment,
                installmentWithInterest: values?.installmentWithInterestt,
            }
        )
    }

    const handleMoneyHP = (values) => {
        console.log("values", values)
        setMoney({
            ...money,
            interestRate: values?.interestRate,
            loanAmount: values?.loanAmountt,
            loanTerm: values?.installmentAmountt,
            interest: values?.interestt,
            monthlyPayment: values?.monthlyPayment,
            installmentWithInterest: values?.installmentWithInterestt,
            productType: checkPCH.PLorCH,
            PLorCH: checkPCH.PLorCH
        })
        form.setFieldsValue(
            {
                loanAmount: values?.loanAmountt,
                loanTerm: values?.installmentAmountt,
                interest: values?.interestt,
                monthlyPayment: values?.monthlyPayment,
                installmentWithInterest: Currency(values?.installmentWithInterestt),
            }
        )
    }

    const compressImage = async (ez, types) => {
        var image = {};
        return new Promise(async (resolve) => {
            if (types !== 7) {
                if (ez.size > 4000000) {
                    console.log("4m ไม่ให้ส่ง");
                    resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                } else {
                    let quality;
                    if (ez.size >= 2500000 && ez.size <= 4000000) {
                        quality = 0.1;
                    } else if (ez.size >= 2000000 && ez.size <= 2499999) {
                        quality = 0.15;
                    } else if (ez.size >= 1500000 && ez.size <= 1999999) {
                        quality = 0.2;
                    } else if (ez.size >= 1000000 && ez.size <= 1499999) {
                        quality = 0.3;
                    } else {
                        quality = 0.5;
                    }

                    try {
                        // ใช้ Promise เพื่อบีบอัดรูปภาพ
                        const compressedFile = await new Promise((resolveCompress) => {
                            new Compressor(ez, {
                                quality: quality,
                                success: (result) => {
                                    resolveCompress(result);
                                },
                            });
                        });

                        const fileOptions = {
                            type: compressedFile.type,
                            lastModified: Date.now(),
                        };
                        const compressedFileAsFile = new File([compressedFile], ez.name, fileOptions);
                        image.imageCompressedFile = compressedFileAsFile;
                        image.type = types
                        allImg.push(image);
                        //setAllImg((prevAllImg) => [...prevAllImg, image]);

                        // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
                        resolve(image);
                    } catch (error) {
                        console.error('Image compression error:', error);
                        resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                    }
                }
            } else {
                // ... (โลจิกสำหรับ type 7)
                image.imageCompressedFile = ez;
                image.type = types
                allImg.push(image);
                //setAllImg((prevAllImg) => [...prevAllImg, image]);
                resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
            }
        });
    };

    const beforeUpts = async (file, ty) => {
        try {
            setLoading(true)
            const compressedFile = await compressImage(file, ty);
            return Promise.resolve(compressedFile);
        } catch (error) {
            console.error('Image compression error:', error);
            message.error('Image compression failed.');
            return Promise.reject(); // เปลี่ยนให้ return Promise.reject() เมื่อไม่สามารถบีบอัดได้
        }
    };

    ////////////// รถ ///////////////////
    const handleChangeType = (value) => {
        if (value === 'ย้ายไฟแนนซ์' || value === 'Pre-Aaprove-ย้ายไฟแนนซ์') {
            setMoney({ ...money, productLoanType: value, productType: checkPCH.PLorCH })
            setMoneyOld({ ...moneyOld, productLoanType: value })
            setTypeMoney({ ...typeMoney, productLoanType: 'ย้ายไฟแนนซ์', productType: checkPCH.PLorCH })
            setNotPassData2({ ...notPassData2, npOldLoan: false })
        }
        else {
            setMoney({ ...money, productLoanType: value, productType: checkPCH.PLorCH, oldLoanId: getData?.cars?.carOldLoanDetails?.oldLoanId })
            setTypeMoney({ ...typeMoney, productLoanType: 'ถือเล่มมา', productType: checkPCH.PLorCH })
            setNotPassData2({ ...notPassData2, npOldLoan: true })
            setMoneyOld({ oldLoanId: getData?.cars?.carOldLoanDetails?.oldLoanId })
            form.setFieldsValue(
                {
                    oldKangPayment: '',
                    oldKangPaymentTerm: '',
                    oldLoanAmount: '',
                    oldLoanTerm: '',
                    oldMonthlyPayment: '',
                }
            )
        }
    }

    /////////////// เลือก p-loan หรือ เช่าซื้อ ///////////////////
    const handleChangePLorCH = (value) => {
        if (value === "p-loan") {
            setCheckPCH({ PLorCH: value })
            setMoney({
                ...money,
                loanAmount: "",
                loanTerm: "",
                interest: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterest: "",
                PLorCH: value,
                productType: value
            })
            form.setFieldsValue(
                {
                    loanAmount: "",
                    loanTerm: "",
                    interest: "",
                    interestRate: "",
                    monthlyPayment: "",
                    installmentWithInterest: "",
                }
            )
        } else if (value === "เช่าซื้อ1") {
            setCheckPCH({ PLorCH: value })
            setMoney({
                ...money,
                loanAmount: "",
                loanTerm: "",
                interest: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterest: "",
                PLorCH: value,
                productType: value
            })
            form.setFieldsValue(
                {
                    loanAmount: "",
                    loanTerm: "",
                    interest: "",
                    interestRate: "",
                    monthlyPayment: "",
                    installmentWithInterest: "",
                }
            )
        } else {
            setCheckPCH({ PLorCH: value })
            setMoney({
                ...money,
                loanAmount: "",
                loanTerm: "",
                interest: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterest: "",
                PLorCH: value,
                productType: value
            })
            form.setFieldsValue(
                {
                    loanAmount: "",
                    loanTerm: "",
                    interest: "",
                    interestRate: "",
                    monthlyPayment: "",
                    installmentWithInterest: "",
                }
            )
        }
    }

    //////////// กรณีไม่มีรถ /////////////////////
    const AddBrand = () => {
        setCounterBrand(true)
        setCounterModel(true)
        setCounterCarBaab(true)
        setDataPost2({
            ...dataPost2,
            carBrand: "",
            carModel: "",
            carBaab: "",
            carYear: "",
            carPrice: 0,
            carPriceStatus: false,
        })
        form.setFieldsValue(
            {
                carBrand: '',
                carModel: '',
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }

    const AddBrands = () => {
        setCounterBrand(false)
        setCounterModel(false)
        setCounterCarBaab(false)
        setDataPost2({
            ...dataPost2,
            carBrand: "",
            carModel: "",
            carBaab: "",
            carYear: "",
            carPrice: 0,
            carPriceStatus: false,
        })
        form.setFieldsValue(
            {
                carBrand: '',
                carModel: '',
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }

    //////////// กรณีไม่มีรถ /////////////////////
    const AddModel = () => {
        setCounterModel(true)
        setCounterCarBaab(true)
        setDataPost2({
            ...dataPost2,
            carModel: "",
            carBaab: "",
            carYear: "",
            carPrice: 0,
            carPriceStatus: false,
        })
        form.setFieldsValue(
            {
                carModel: '',
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    const AddModels = () => {
        setCounterModel(false)
        setCounterCarBaab(false)
        setDataPost2({
            ...dataPost2,
            carModel: "",
            carBaab: "",
            carYear: "",
            carPrice: 0,
            carPriceStatus: false,
        })
        form.setFieldsValue(
            {
                carModel: '',
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    //////////// กรณีไม่มีรถ /////////////////////
    const AddCarBaab = () => {
        setCounterCarBaab(true)
        setDataPost2({
            ...dataPost2,
            carBaab: "",
            carYear: "",
            carPrice: 0,
            carPriceStatus: false,
        })
        form.setFieldsValue(
            {
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    const AddCarBaabs = () => {
        setCounterCarBaab(false)
        setDataPost2({
            ...dataPost2,
            carBaab: "",
            carYear: "",
            carPrice: 0,
            carPriceStatus: false,
        })
        form.setFieldsValue(
            {
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    const onYeehor = (e) => {
        setDataPost2({ ...dataPost2, carBrand: e.target.value })
    }

    const onMoChange = (e) => {
        setDataPost2({ ...dataPost2, carModel: e.target.value })
    }

    const onDeChange = (e) => {
        setDataPost2({ ...dataPost2, carBaab: e.target.value })
    }

    ////// ตัวอย่างรถ กับ ตารางค่างวด ////////
    const getTable = () => {
        setOpenTable(true)
    }
    const getTableHP = () => {
        setOpenTableHP(true)
    }
    const getTableCar = () => {
        setOpenTableCar(true)
    }
    /////////////////////////////////////

    const loadDataBrand = async (value) => { //รถยนต์ รถเครื่องจักร รถบรรทุก
        setLoading(true)
        await axios.get(`https://shark-app-j9jc9.ondigitalocean.app/ratecars/brand/${value}`)
            .then((res) => {
                setBrandData(res.data)
                setLoading(false)
                // form.setFieldsValue(
                //     {
                //         carBrand: "",
                //         carModel: "",
                //         carBaab: "",
                //         carYear: "",
                //         carPrice: 0
                //     }
                // )
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const loadDataModel = async (value) => {
        setLoading(true)
        await axios.get(dataModel + value)
            .then((res) => {
                setModelData(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }
    const getResult = async (value) => {
        setLoading(true)
        const result = await axios.get(ratecarsmodel,
            { params: { typeId: typeSelected, modelId: value }, })
        setResultData(result.data)
        setLoading(false)
    }

    const onTypeChange = (value) => {
        //console.log("ชนิด =", value)
        // if(value === 2 || value === 3) {
        //     form.setFieldsValue(
        //         {
        //             carBrand: "",
        //             carModel: "",
        //             carBaab: "",
        //             carYear: "",
        //             carPrice: 0
        //         }
        //     )
        // }
        setDataPost2({ ...dataPost2, productTypeCar: value, carYear: "0", carPrice: 0, carPriceStatus: false, carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), carDateStatus: dayjs().format("YYYY-MM-DDTHH:mm:ssZ") })
        setTypeData(value)
        setTypeSelected(value)
        loadDataBrand(value)
        form.setFieldsValue(
            {
                carProvince: "",
                carPlateNumber: "",
                loanAmount: "",
                loanTerm: "",
                interest: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterest: "",
                oldLoanAmount: "",
                oldMonthlyPayment: "",
                oldLoanTerm: "",
                oldKangPayment: "",
                oldKangPaymentTerm: ""
            }
        )
    }
    const onBrandChange = (value) => {
        setDataPost2({ ...dataPost2, carBrand: brandData.find((a) => a.idbrand === value).brand })
        form.setFieldsValue({
            ...dataPost2,
            carModel: "",
            carBaab: "",
            carYear: "",
            carPrice: 0
        })
        loadDataModel(value)
    }
    const onModelChange = (value) => {
        setCounterBrand(false)
        setDataPost2({ ...dataPost2, carModel: modelData.find((a) => a.idmodel_te === value).model })
        form.setFieldsValue({
            ...dataPost2,
            carBaab: "",
            carYear: "",
            carPrice: 0
        })
        getResult(value)
    }
    const onDetailChange = (value) => {
        setDataPost2({ ...dataPost2, carBaab: resultData.find((a) => a.idmodel === value).models })
        form.setFieldsValue({
            ...dataPost2,
            carYear: "",
            carPrice: 0
        })
        getPrice(typeData, value)
    }
    const getPrice = async (typeId, carId) => {
        setLoading(true)
        await axios.get(getprice, { params: { typeId, carId }, })
            .then((res) => {
                if (res.data) {
                    setYearPrice(res.data)
                    setLoading(false)
                }
            })
            .catch((err) => alert('ไม่พบข้อมูล ราคารถ'))
        setLoading(false)
    }
    const onResult = (k, v) => {
        const pYear = parseInt(v.key)
        if (k) {
            setDataPost2({ ...dataPost2, carPriceStatus: true, carPrice: k, carYear: pYear.toString(), carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), carDateStatus: dayjs().format("YYYY-MM-DDTHH:mm:ssZ") })
            parseInt(dataPost2.carYear)
            form.setFieldsValue({
                carPrice: v.value
            })

        }
    }

    const checkMoneyCar = (value) => {
        if (money?.productLoanType === "ย้ายไฟแนนซ์" || money?.productLoanType === "Pre-Aaprove-ย้ายไฟแนนซ์") {
            setMoney({ ...money, loanAmount: parseInt(value) })
        } else {
            if (dataPost2?.carPrice === 0) {
                setMoney({ ...money, loanAmount: parseInt(value) })
            } else {
                if (parseInt(value) > dataPost2?.carPrice) {
                    alert("ไม่สามารถใส่ยอดจัดเกินเรทรถได้")
                    form.setFieldsValue(
                        {
                            loanAmount: 0,
                        }
                    )
                } else {
                    setMoney({ ...money, loanAmount: parseInt(value) })
                }
            }
        }
    }

    ///////////////// อัปโหลดรูปภาพ //////////////////////////////
    /////////อ่านเล่มรถ/////////// 
    const handleChangeCustomerDocument = async ({ fileList }) => { // 2
        // setLoading(true)
        // setAllImg({ ...allImg, ts2: fileList });
        for (const removedFile of fileListCustomerDocument.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 2)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 2));
                setAllImg(updatedAllImg);
            }
        }
        console.log("fileList book", fileList)
        setFileListCustomerDocument(fileList);
        // var imageListCustomerDocument = []
        // const formData = new FormData()
        // var i = 0
        // for (const file of fileList) {
        //     // const compressedFile = await new Promise((resolve) => {
        //     //     new Compressor(file.originFileObj, {
        //     //         quality: 0.6, // เช่น, 60%
        //     //         success: (result) => {
        //     //             resolve(result);
        //     //         },
        //     //     });
        //     // });
        //     // var image = {}
        //     // var filename = file.name
        //     // var image64 = await getBase64(compressedFile)
        //     // var image64 = file.originFileObj
        //     // image.filename = filename
        //     // image.image64 = image64
        //     // image.type = 2
        //     // imageListCustomerDocument.push(image)
        //     const blob = await convertFileToBlob(file);
        //     formData.append('image', blob, file.name);
        //     formData.append('productTypeCar', selectTypeCar.productTypeCar);
        //     i++
        // }
        // const ageValues = formData.getAll('image');
        // const typeValues = formData.getAll('productTypeCar');
        // // console.log("ageValues =", ageValues)
        // // console.log("typeValues =", typeValues)
        // setFileList(formData)
        // setFileListCustomerDocument1({ ...fileListCustomerDocument1, imageListCustomerDocument });
        setLoading(false)
    };
    const handleChangeCustomerCar = async ({ fileList }) => { // 1
        // setLoading(true)
        // setAllImg({ ...allImg, ts1: fileList });

        for (const removedFile of fileListCustomerCar.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 1)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 1));
                setAllImg(updatedAllImg);
            }
        }

        setFileListCustomerCar(fileList);

        setLoading(false)
    };
    // const handleChange = async ({ fileList }) => {
    //     //console.log("fileList", fileList)
    //     setFileList(fileList);
    //     var imageList = []
    //     for (const file of fileList) {
    //         var image = {}
    //         var filename = file.name
    //         var image64 = await getBase64(file.originFileObj)
    //         image.filename = filename
    //         image.image64 = image64
    //         image.type = 1
    //         imageList.push(image)
    //     }
    //     setFileListResult1({ ...fileListResult1, imageList });
    //     //dispatch(addImage(imageList))
    // };
    // const handleChangeBook = async ({ fileList }) => {
    //     //console.log("fileListBook", fileList)
    //     setFileListBook(fileList);
    //     var imageListBook = []
    //     for (const file of fileList) {
    //         var image = {}
    //         var filename = file.name
    //         var image64 = await getBase64(file.originFileObj)
    //         image.filename = filename
    //         image.image64 = image64
    //         image.type = 2
    //         imageListBook.push(image)
    //     }
    //     setFileListResult2({ ...fileListResult2, imageListBook });
    //     //dispatch(addImage(imageListBook))
    // };

    // const handleChangeDeed = async ({ fileList }) => {
    //     setFileListBook(fileList);
    //     var imageListBook = []
    //     for (const file of fileList) {
    //         var image = {}
    //         var filename = file.name
    //         var image64 = await getBase64(file.originFileObj)
    //         image.filename = filename
    //         image.image64 = image64
    //         image.type = 3
    //         imageListBook.push(image)
    //     }
    //     setFileListResult2({ ...fileListResult2, imageListBook });
    //     //dispatch(addImage(imageListBook))
    // };


    const handleCancelImg = () => {
        setPreviewVisible(false);
    }
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const car_Calculate = () => {
        if (money.loanAmount && money.interestRate && money.loanTerm) {
            // var interestRateCar = parseFloat(money.interestRate) / 100 // อัตราดอก / 100
            // var rateCar = ((parseFloat(money.loanAmount) * Currency(interestRateCar, { precision: 5 }).value) * parseInt(money.loanTerm)) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
            // var loanTermCar = Math.ceil(Currency((rateCar + parseFloat(money.loanAmount)) / parseInt(money.loanTerm), { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
            // var newLoanTermCar = Math.ceil(Currency(loanTermCar * 0.07)) + loanTermCar // งวดละใหม่ = ( งวดละ * 0.07 ) + งวดละ
            // var resultRateCar = (newLoanTermCar * parseInt(money.loanTerm)) - parseFloat(money.loanAmount) // งวดละใหม่ * งวด - ยอดจัด
            // var newResultPriceCar = newLoanTermCar * parseInt(money.loanTerm) // งวดละใหม่ * งวด

            var interestRateCar = parseFloat(money.interestRate) / 100 // อัตราดอก / 100
            var rateCar = ((parseFloat(money.loanAmount) * Currency(interestRateCar, { precision: 5 }).value) * money.loanTerm) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
            var loanTermCar = (rateCar + parseFloat(money.loanAmount)) / parseInt(money.loanTerm) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
            var newLoanTermCar = Math.ceil(Currency((loanTermCar * 0.07) + loanTermCar), { precision: 5 })// งวดละใหม่ = ( งวดละ * 0.07 ) + งวดละ
            var resultRateCar = Math.ceil(Currency(newLoanTermCar, { precision: 5 }) * parseInt(money.loanTerm)) - parseFloat(money.loanAmount) // งวดละใหม่ * งวด - ยอดจัด
            var newResultPriceCar = Math.ceil(Currency(newLoanTermCar) * parseInt(money.loanTerm), { precision: 5 }) // งวดละใหม่ * งวด
            setMoney({
                ...money,
                monthlyPayment: newLoanTermCar,
                interest: resultRateCar,
                installmentWithInterest: newResultPriceCar
            })
            form.setFieldsValue(
                {
                    monthlyPayment: newLoanTermCar,
                    interest: resultRateCar,
                    installmentWithInterest: newResultPriceCar
                }
            )
        }
        else {
            form.setFieldsValue(
                {
                    monthlyPayment: 0,
                    interest: 0,
                    installmentWithInterest: 0
                }
            )
        }
    }

    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    return (
        <Row justify={'center'}>
            <Spin spinning={loading} size='large' tip=" Loading... ">

                <Row justify={'center'} style={{ margin: '5px', fontSize: '25px' }} ><b>รายละเอียดสินค้า</b></Row>
                <Form
                    form={form}
                    labelCol={{
                        span: 12,
                    }}
                    style={{
                        width: '100%',
                    }}
                    initialValues={{
                        remember: true,
                    }}

                    autoComplete="off"
                    onFinish={handleSubmit}
                >
                    <Card className='main1'>

                        {
                            !checkPreNotPass ?
                                <>
                                    <Row justify={'center'}>
                                        <Col span={20}>
                                            <aside style={{ width: '90%', textAlign: 'center' }}>
                                                <div>
                                                    {
                                                        notPassData2.npProduct === false ?
                                                            <Form.Item label="หมายเหตุ(ธุรการ) รถ" name="npProductNote">
                                                                <TextArea rows={5} name='npProductNote' disabled />
                                                            </Form.Item>
                                                            : null
                                                    }
                                                    {
                                                        notPassData2.npLoan === false ?
                                                            <Form.Item label="หมายเหตุ(ธุรการ) ยอดกู้" name="npLoanNote">
                                                                <TextArea rows={5} name='npLoanNote' disabled />
                                                            </Form.Item>
                                                            : null
                                                    }
                                                    {
                                                        notPassData2.npOldLoan === false ?
                                                            <Form.Item label="หมายเหตุ(ธุรการ) ย้ายไฟแแนนซ์" name="npOldLoanNote">
                                                                <TextArea rows={5} name='npOldLoanNote' disabled />
                                                            </Form.Item>
                                                            : null
                                                    }
                                                    <Form.Item label="หมายเหตุ(การตลาด)" name="productNote">
                                                        <TextArea rows={5} name='productNote' placeholder='หมายเหตุถึงธุรการ' onChange={(e) => setResendNote({ productNote: e.target.value })} />
                                                    </Form.Item>
                                                    <Form.Item label='กลุ่มสินค้า' name='productTypeId'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Select placeholder={'เลือก'} disabled style={{ height: "40px" }}>
                                                            <Option value={1}>รถ</Option>
                                                            <Option value={2}>ที่ดิน</Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item label='เรทรถ'>
                                                        <Button type='primary' style={{ width: "100%" }} onClick={getTableCar} >เรท</Button>
                                                        {
                                                            openTableCar ?
                                                                <TableRateCar open={openTableCar} close={setOpenTableCar} productTypeCar={dataPost2?.productTypeCar} />
                                                                : null
                                                        }
                                                    </Form.Item>
                                                    <Form.Item label='ชนิดรถ' name='productTypeCar'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Select placeholder={'เลือก'} disabled allowClear style={{ height: "40px" }}
                                                            onChange={onTypeChange}>
                                                            <Option value={1}>รถยนต์</Option>
                                                            <Option value={2}>รถเครื่องจักการเกษตร</Option>
                                                            <Option value={3}>รถบรรทุก</Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Row justify={'center'}>
                                                        <Col className='gutter-row' span={21}>
                                                            <Form.Item label='ยี่ห้อ' name='carBrand' style={{ lineHeight: '32px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                {!counterBrand ? (
                                                                    <Select
                                                                        // disabled={notPassData2.npProduct}
                                                                        showSearch
                                                                        style={{ height: "40px" }}
                                                                        onChange={onBrandChange}
                                                                        optionFilterProp="children"
                                                                        placeholder="Search to Select"
                                                                        filterOption={(inputValue, option) =>
                                                                            option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                        }
                                                                    >
                                                                        {brandData?.map((e) => {
                                                                            return (
                                                                                <Select.Option key={e.idbrand} value={e.idbrand}>
                                                                                    {e.brand} ({e.brand_th})
                                                                                </Select.Option>
                                                                            )
                                                                        })}
                                                                    </Select>
                                                                ) : (
                                                                    <>
                                                                        {counterBrand ? (<Input style={{ height: "40px" }} onChange={onYeehor} />) : (<Select
                                                                            // {counterBrand ? (<Input style={{ height: "40px" }} disabled={notPassData2.npProduct} onChange={onYeehor} />) : (<Select
                                                                            // disabled={notPassData2.npProduct}
                                                                            style={{ height: "40px" }}
                                                                            showSearch
                                                                            onChange={onBrandChange}
                                                                            placeholder="Search to Select"
                                                                            optionFilterProp="children"
                                                                            filterOption={
                                                                                (inputValue, option) =>
                                                                                    option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                            }
                                                                        >
                                                                            {modelData?.map((e) => {
                                                                                return (
                                                                                    <Select.Option key={e.idmodel_te} value={e.idmodel_te}>
                                                                                        {e.model} {e.model_te ? e.model_te : null}
                                                                                    </Select.Option>
                                                                                )
                                                                            })}
                                                                        </Select>)
                                                                        }
                                                                    </>

                                                                )}
                                                            </Form.Item>
                                                        </Col>
                                                        <Col className='gutter-row' span={1} style={{ textAlign: 'center' }}>
                                                            {counterBrand === false && (
                                                                <Button onClick={AddBrand} style={{ lineHeight: '15px' }}>
                                                                    {/* <Button disabled={notPassData2.npProduct} onClick={AddBrand} style={{ lineHeight: '15px' }}> */}
                                                                    +
                                                                </Button>)
                                                            }
                                                            {counterBrand === true && (
                                                                <Button onClick={AddBrands} >
                                                                    {/* <Button disabled={notPassData2.npProduct} onClick={AddBrands} > */}
                                                                    -
                                                                </Button>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    <Row justify={'center'}>
                                                        <Col className='gutter-row' span={21}>
                                                            <Form.Item label='รุ่นรถ' name='carModel'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                {counterBrand ? ( //ไม่จริงเข้าตรงนี้
                                                                    <>
                                                                        {!counterModel ? (
                                                                            <Select //ไม่จริงเข้าตรงนี้
                                                                                // disabled={notPassData2.npProduct}
                                                                                showSearch
                                                                                style={{ height: "40px" }}
                                                                                onChange={onModelChange}
                                                                                placeholder="Search to Select"
                                                                                optionFilterProp="children"
                                                                                filterOption={(inputValue, option) =>
                                                                                    option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                                }
                                                                            >
                                                                                {modelData?.map((e) => {
                                                                                    return (
                                                                                        <Select.Option key={e.idmodel_te} value={e.idmodel_te}>
                                                                                            {e.model} {e.model_te ? e.model_te : null}
                                                                                        </Select.Option>
                                                                                    )
                                                                                })}
                                                                            </Select>
                                                                        ) : (

                                                                            <Input style={{ height: "40px" }} onChange={onMoChange} />
                                                                            // <Input disabled={notPassData2.npProduct} style={{ height: "40px" }} onChange={onMoChange} />
                                                                        )}

                                                                    </>
                                                                ) : (<>

                                                                    {counterModel ? (<Input onChange={onMoChange} />) : (<Select
                                                                        // {counterModel ? (<Input onChange={onMoChange} disabled={notPassData2.npProduct} />) : (<Select
                                                                        // disabled={notPassData2.npProduct}
                                                                        style={{ height: "40px" }}
                                                                        showSearch
                                                                        onChange={onModelChange}
                                                                        placeholder="Search to Select"
                                                                        optionFilterProp="children"
                                                                        filterOption={
                                                                            (inputValue, option) =>
                                                                                option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                        }
                                                                    >
                                                                        {modelData?.map((e) => {
                                                                            return (
                                                                                <Select.Option key={e.idmodel_te} value={e.idmodel_te}>
                                                                                    {e.model} {e.model_te ? e.model_te : null}
                                                                                </Select.Option>
                                                                            )
                                                                        })}
                                                                    </Select>)
                                                                    }
                                                                </>
                                                                )}
                                                            </Form.Item>
                                                        </Col>
                                                        <Col className='gutter-row' span={1} style={{ textAlign: 'center' }}>
                                                            {counterModel === false && (
                                                                <Button onClick={AddModel} style={{ lineHeight: '15px' }}>
                                                                    {/* <Button disabled={notPassData2.npProduct} onClick={AddModel} style={{ lineHeight: '15px' }}> */}
                                                                    +
                                                                </Button>)
                                                            }
                                                            {counterModel === true && (
                                                                <Button onClick={AddModels}>
                                                                    {/* <Button disabled={notPassData2.npProduct} onClick={AddModels}> */}
                                                                    -
                                                                </Button>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    <Row justify={'center'}>
                                                        <Col className='gutter-row' span={21}>
                                                            <Form.Item label='แบบรถ' name='carBaab'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                {counterBrand ? (
                                                                    <>
                                                                        {!counterCarBaab ? (
                                                                            <Select
                                                                                // disabled={notPassData2.npProduct}
                                                                                style={{ height: "40px" }}
                                                                                showSearch
                                                                                onChange={(value) => { onDetailChange(value) }}
                                                                                placeholder="Search to Select"

                                                                            >
                                                                                {resultData?.map((e) => {
                                                                                    return (
                                                                                        <Select.Option key={e.idmodel} value={e.idmodel}>
                                                                                            {e.models} {e.idmodel ? e.idmodel : null}
                                                                                        </Select.Option>
                                                                                    )
                                                                                })}
                                                                            </Select>
                                                                        ) : (

                                                                            <Input style={{ height: "40px" }} onChange={onDeChange} />
                                                                            // <Input disabled={notPassData2.npProduct} style={{ height: "40px" }} onChange={onDeChange} />
                                                                        )}
                                                                    </>
                                                                ) : (<>

                                                                    {counterCarBaab ? (<Input onChange={onDeChange} />) : (<Select
                                                                        // {counterCarBaab ? (<Input onChange={onDeChange} disabled={notPassData2.npProduct} />) : (<Select
                                                                        // disabled={notPassData2.npProduct}
                                                                        style={{ height: "40px" }}
                                                                        showSearch
                                                                        onChange={(value) => { onDetailChange(value) }}
                                                                        placeholder="Search to Select"
                                                                    >
                                                                        {resultData?.map((e) => {
                                                                            return (
                                                                                <Select.Option key={e.idmodel} value={e.idmodel}>
                                                                                    {e.models} {e.idmodel ? e.idmodel : null}
                                                                                </Select.Option>
                                                                            )
                                                                        })}
                                                                    </Select>)
                                                                    }
                                                                </>
                                                                )}
                                                            </Form.Item>
                                                        </Col>
                                                        <Col className='gutter-row' span={1} style={{ textAlign: 'center' }}>
                                                            {counterCarBaab === false && (
                                                                <Button onClick={AddCarBaab} style={{ lineHeight: '15px' }}>
                                                                    {/* <Button disabled={notPassData2.npProduct} onClick={AddCarBaab} style={{ lineHeight: '15px' }}> */}
                                                                    +
                                                                </Button>)
                                                            }
                                                            {counterCarBaab === true && (
                                                                <Button onClick={AddCarBaabs} >
                                                                    {/* <Button disabled={notPassData2.npProduct} onClick={AddCarBaabs} > */}
                                                                    -
                                                                </Button>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    <Form.Item label='ปีรถ' name='carYear'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        {counterBrand ? (
                                                            <>
                                                                {!counterCarBaab ? (
                                                                    <Select
                                                                        // disabled={notPassData2.npProduct}
                                                                        style={{ height: "40px" }}
                                                                        onChange={(k, v) => (onResult(k, v))}
                                                                    >
                                                                        {Object.entries(yearPrice).map(([k, v]) => {
                                                                            if (k.charAt(0) === 'p' && v) {
                                                                                const year = k.replace('p', '')
                                                                                return (
                                                                                    <Select.Option key={year} value={v * 1000}>
                                                                                        {year}
                                                                                    </Select.Option>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </Select>
                                                                ) : (
                                                                    <Yearpick
                                                                        picker="year"
                                                                        style={{ height: "40px" }}
                                                                        onChange={(e, string) => {
                                                                            if (e) {
                                                                                let year = e.$y
                                                                                let yearstr = year.toString();
                                                                                setDataPost2({ ...dataPost2, carYear: yearstr })
                                                                            }
                                                                        }} />
                                                                )}

                                                            </>
                                                        )
                                                            :
                                                            (
                                                                <>
                                                                    {counterCarBaab ? (
                                                                        // <Input onChange={onReChange} />
                                                                        <Yearpick
                                                                            picker="year"
                                                                            style={{ height: "40px" }}
                                                                            // name="birthDate"
                                                                            onChange={(e, string) => {
                                                                                if (e) {
                                                                                    let year = e.$y
                                                                                    let yearstr = year.toString();
                                                                                    setDataPost2({ ...dataPost2, carYear: yearstr })
                                                                                }
                                                                            }} />
                                                                    ) : (
                                                                        <Select
                                                                            // disabled={notPassData2.npProduct}
                                                                            style={{ height: "40px" }}
                                                                            onChange={(k, v) => (onResult(k, v))}
                                                                        >
                                                                            {Object.entries(yearPrice).map(([k, v]) => {
                                                                                if (k.charAt(0) === 'p' && v) {
                                                                                    const year = k.replace('p', '')
                                                                                    return (
                                                                                        <Select.Option key={year} value={v * 1000}>
                                                                                            {year}
                                                                                        </Select.Option>
                                                                                    )
                                                                                }
                                                                            })}
                                                                        </Select>
                                                                    )}
                                                                </>

                                                            )}
                                                    </Form.Item>
                                                    <Form.Item label='ราคา' name='carPrice'>
                                                        <Input disabled defaultValue={0} style={{ height: "40px" }}
                                                            onChange={(e) => setDataPost2({ ...dataPost2, carPrice: e.value })} />
                                                    </Form.Item>
                                                    <Form.Item label='เลขทะเบียน' name='carPlateNumber'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}>
                                                        <Input style={{ height: "40px" }}
                                                            // <Input disabled={notPassData2.npProduct} style={{ height: "40px" }}
                                                            onChange={(e) => setDataPost2({ ...dataPost2, carPlateNumber: e.target.value })} />
                                                    </Form.Item>
                                                    <Form.Item label='จังหวัด' name='carProvince'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}>
                                                        <Select
                                                            // disabled={notPassData2.npProduct}
                                                            showSearch
                                                            name='carProvince'
                                                            style={{ height: "40px" }}
                                                            placeholder='จังหวัด'
                                                            onChange={(key, value) => { handleProvinceChangeCar(key, value) }}
                                                        >
                                                            {provincesCar?.map((pro, index) => (
                                                                <Option key={pro.provinceId} value={pro.provinceName}>
                                                                    {pro.provinceName}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div>
                                                    {/* โลนรถ */}
                                                    <Form.Item label='ประเภทขอกู้รถ' name='productLoanType'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}>
                                                        <Select
                                                            // disabled={notPassData2.npLoan}
                                                            placeholder={'เลือก'}
                                                            style={{ height: '40px' }}
                                                            onChange={(value) => { handleChangeType(value) }}
                                                            options={productLoanTypeCar}
                                                        />
                                                    </Form.Item>
                                                    {
                                                        dataPost2.productTypeCar === 1 ?
                                                            <Form.Item label='ประเภทค่างวด' name='PLorCH'>
                                                                <Select
                                                                    // disabled={notPassData2.npLoan}
                                                                    placeholder={'เลือก'}
                                                                    style={{ height: '40px' }}
                                                                    onChange={(value) => { handleChangePLorCH(value) }}
                                                                    options={[
                                                                        {
                                                                            label: 'P-LOAN',
                                                                            value: 'p-loan',
                                                                        },
                                                                        {
                                                                            label: 'เช่าซื้อ',
                                                                            value: 'เช่าซื้อ',
                                                                        },
                                                                        {
                                                                            label: 'เช่าซื้อ (0.84-0.75)',
                                                                            value: 'เช่าซื้อ1',
                                                                        },
                                                                    ]}
                                                                />
                                                            </Form.Item>
                                                            :
                                                            <Form.Item label='ประเภทค่างวด' name='PLorCH'>
                                                                <Select
                                                                    // disabled={notPassData2.npLoan}
                                                                    placeholder={'เลือก'}
                                                                    style={{ height: '40px' }}
                                                                    onChange={(value) => { handleChangePLorCH(value) }}
                                                                    options={[
                                                                        {
                                                                            label: 'P-LOAN',
                                                                            value: 'p-loan',
                                                                        },
                                                                        {
                                                                            label: 'เช่าซื้อ',
                                                                            value: 'เช่าซื้อ',
                                                                        },
                                                                    ]}
                                                                />
                                                            </Form.Item>
                                                    }


                                                    {/* P-LOAN */}
                                                    {checkPCH && typeof checkPCH.PLorCH !== 'undefined' && (checkPCH.PLorCH === "p-loan") ? (
                                                        <>
                                                            <Form.Item label='P-LOAN'>
                                                                <Button type="primary" style={{ width: "100%" }} onClick={getTable}>
                                                                    {/* <Button disabled={notPassData2.npLoan} type="primary" style={{ width: "100%" }} onClick={getTable}> */}
                                                                    ตารางค่างวด P-LOAN
                                                                </Button>
                                                                {
                                                                    openTable ?
                                                                        <Installment open={openTable} close={setOpenTable} money={handleMoney} cp={dataPost2.carPrice} cp2={money.loanTerm} type={money} checkGARFE={3} />
                                                                        : null
                                                                }
                                                            </Form.Item>
                                                            <Form.Item label='ยอดจัด' name='loanAmount'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                                    onChange={(e) => setMoney({ ...money, loanAmount: e.target.value })} />
                                                            </Form.Item>
                                                            <Form.Item label='จำนวนงวด' name='loanTerm'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <Input disabled style={{ color: 'black' }} suffix="งวด"
                                                                    onChange={(e) => setMoney({ ...money, loanTerm: e.target.value })} />
                                                            </Form.Item>
                                                            <Form.Item label='งวดละ' name='monthlyPayment'>
                                                                <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                                    onChange={(e) => setMoney({ ...money, monthlyPayment: e.target.value })} />
                                                            </Form.Item>
                                                            <Form.Item label='ดอกเบี้ย' name='interest'>
                                                                <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                                    onChange={(e) => setMoney({ ...money, interest: e.target.value })} />
                                                            </Form.Item>
                                                            <Form.Item label='รวมราคา' name='installmentWithInterest'>
                                                                <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                                    defaultValue={currencyFormatOne(~~getData?.cars.carLoanDetails?.monthlyPayment * ~~getData?.cars.carLoanDetails?.loanTerm)}
                                                                    onChange={(e) => setMoney({ ...money, installmentWithInterest: e.target.value })} />
                                                            </Form.Item>
                                                        </>
                                                    ) : null}

                                                    {/* เช่าซื้อ */}

                                                    {dataPost2 && typeof dataPost2.productTypeCar !== 'undefined' && (dataPost2.productTypeCar === 1 && checkPCH.PLorCH === "เช่าซื้อ") ? (
                                                        <>
                                                            <Form.Item label='เช่าซื้อ'>
                                                                <Button type="primary" onClick={getTableHP}>
                                                                    {/* <Button disabled={notPassData2.npLoan} type="primary" onClick={getTableHP}> */}
                                                                    ตารางค่างวด เช่าซื้อ
                                                                </Button>
                                                                {
                                                                    openTableHP ?
                                                                        <HirePurchase open={openTableHP} close={setOpenTableHP} type={money} money={handleMoneyHP} cp={dataPost2.carPrice} cp2={money.loanTerm} checkGARFE={3} />
                                                                        : null
                                                                }
                                                            </Form.Item>
                                                            <Form.Item label='ยอดจัด' name='loanAmount'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]}>
                                                                <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                                    onChange={(e) => setMoney({ ...money, loanAmount: e.target.value })} />
                                                            </Form.Item>
                                                            <Form.Item label='จำนวนงวด' name='loanTerm'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]}>
                                                                <Input disabled style={{ color: 'black' }} suffix="งวด"
                                                                    onChange={(e) => setMoney({ ...money, loanTerm: e.target.value })} />
                                                            </Form.Item>
                                                            <Form.Item label='งวดละ' name='monthlyPayment'>
                                                                <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                                    onChange={(e) => setMoney({ ...money, monthlyPayment: e.target.value })} />
                                                            </Form.Item>
                                                            <Form.Item label='ดอกเบี้ย' name='interest'>
                                                                <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                                    onChange={(e) => setMoney({ ...money, interest: e.target.value })} />
                                                            </Form.Item>
                                                            <Form.Item label='ราคารวม' name='installmentWithInterest'>
                                                                <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                                    defaultValue={getData?.cars.carLoanDetails?.monthlyPayment * getData?.cars.carLoanDetails?.loanTerm} />
                                                            </Form.Item>
                                                        </>
                                                    ) : dataPost2.productTypeCar === 1 && checkPCH.PLorCH === "เช่าซื้อ1" ?
                                                        <>
                                                            <Form.Item label='ยอดจัด' name='loanAmount'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <InputNumber
                                                                    name="loanAmount"
                                                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                                    size="large"
                                                                    style={{ width: 'auto', color: 'black', height: '40px' }}
                                                                    // onChange={(value) => setMoney({ ...money, loanAmountt: parseInt(value) })}
                                                                    onChange={(value) => checkMoneyCar(value)}
                                                                />
                                                                {/* <Input
                                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                    type='number' suffix="บาท" name="loanAmount" style={{ color: 'black' }}
                                                    onChange={(value) => checkMoneyCar(value)} 
                                                    // onChange={(e) => setMoney({ ...money, loanAmount: parseInt(e.target.value) })} 
                                                    /> */}
                                                            </Form.Item>
                                                            <Form.Item label='อัตราดอกเบี้ย' name='interestRate'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <Select
                                                                    placeholder={'เลือกอัตราดอกเบี้ย'}
                                                                    style={{ height: '40px' }}
                                                                    onChange={(e) => setMoney({ ...money, interestRate: parseFloat(e) })}
                                                                    options={[
                                                                        {
                                                                            label: '0.84%',
                                                                            value: 0.84,
                                                                        },
                                                                        {
                                                                            label: '0.75%',
                                                                            value: 0.75,
                                                                        },
                                                                    ]}
                                                                />
                                                            </Form.Item>
                                                            <Form.Item label='จำนวนงวด' name='loanTerm'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <Input type='number' suffix="งวด" name="loanTerm" style={{ color: 'black' }}
                                                                    onChange={(e) => setMoney({ ...money, loanTerm: parseInt(e.target.value) })} />
                                                                {/* onChange={(e) => { car_Calculate(e) }} /> */}
                                                            </Form.Item>
                                                            <Form.Item label='งวดละ' name='monthlyPayment'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                                    onChange={(e) => setMoney({ ...money, monthlyPayment: parseInt(e.target.value) })} />
                                                            </Form.Item>
                                                            <Form.Item label='ดอกเบี้ย' name='interest'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                                    onChange={(e) => setMoney({ ...money, interest: parseInt(e.target.value) })} />
                                                            </Form.Item>
                                                            <Form.Item label='รวมราคา' name='installmentWithInterest'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                                    defaultValue={currencyFormatOne(~~getData?.cars.carLoanDetails?.monthlyPayment * ~~getData?.cars.carLoanDetails?.loanTerm)}
                                                                    onChange={(e) => setMoney({ ...money, installmentWithInterest: parseInt(e.target.value) })} />
                                                            </Form.Item>
                                                        </>
                                                        : null
                                                    }

                                                    {dataPost2 && typeof dataPost2.productTypeCar !== 'undefined' && (dataPost2.productTypeCar === 2 && checkPCH.PLorCH === "เช่าซื้อ") ? (
                                                        <>
                                                            <Form.Item label='ยอดจัด' name='loanAmount'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <InputNumber
                                                                    name="loanAmount"
                                                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                                    size="large"
                                                                    style={{ width: 'auto', color: 'black', height: '40px' }}
                                                                    // onChange={(value) => setMoney({ ...money, loanAmountt: parseInt(value) })}
                                                                    onChange={(value) => checkMoneyCar(value)}
                                                                />
                                                            </Form.Item>
                                                            <Form.Item label='อัตราดอกเบี้ย' name='interestRate'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <Input type='number' suffix="% +VAT" name="interestRate" style={{ color: 'black' }}
                                                                    onChange={(e) => setMoney({ ...money, interestRate: parseFloat(e.target.value) })} />
                                                            </Form.Item>
                                                            <Form.Item label='จำนวนงวด' name='loanTerm'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <Input type='number' suffix="งวด" name="loanTerm" style={{ color: 'black' }}
                                                                    onChange={(e) => setMoney({ ...money, loanTerm: parseInt(e.target.value) })} />
                                                                {/* onChange={(e) => { car_Calculate(e) }} /> */}
                                                            </Form.Item>
                                                            <Form.Item label='งวดละ' name='monthlyPayment'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                                    onChange={(e) => setMoney({ ...money, monthlyPayment: parseInt(e.target.value) })} />
                                                            </Form.Item>
                                                            <Form.Item label='ดอกเบี้ย' name='interest'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                                    onChange={(e) => setMoney({ ...money, interest: parseInt(e.target.value) })} />
                                                            </Form.Item>
                                                            <Form.Item label='รวมราคา' name='installmentWithInterest'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                                    defaultValue={currencyFormatOne(~~getData?.cars.carLoanDetails?.monthlyPayment * ~~getData?.cars.carLoanDetails?.loanTerm)}
                                                                    onChange={(e) => setMoney({ ...money, installmentWithInterest: parseInt(e.target.value) })} />
                                                            </Form.Item>
                                                        </>
                                                    ) : null}

                                                    {dataPost2 && typeof dataPost2.productTypeCar !== 'undefined' && (dataPost2.productTypeCar === 3 && checkPCH.PLorCH === "เช่าซื้อ") ? (
                                                        <>
                                                            <Form.Item label='ยอดจัด' name='loanAmount'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <InputNumber
                                                                    name="loanAmount"
                                                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                                    size="large"
                                                                    style={{ width: 'auto', color: 'black', height: '40px' }}
                                                                    // onChange={(value) => setMoney({ ...money, loanAmountt: parseInt(value) })}
                                                                    onChange={(value) => checkMoneyCar(value)}
                                                                />
                                                                {/* <Input type='number' suffix="บาท" style={{ color: 'black' }}
                                                    onChange={(e) => setMoney({ ...money, loanAmount: parseInt(e.target.value) })} /> */}
                                                            </Form.Item>
                                                            <Form.Item label='อัตราดอกเบี้ย' name='interestRate'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <Input type='number' suffix="% +VAT" style={{ color: 'black' }}
                                                                    onChange={(e) => setMoney({ ...money, interestRate: parseFloat(e.target.value) })} />
                                                            </Form.Item>
                                                            <Form.Item label='จำนวนงวด' name='loanTerm'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <Input type='number' suffix="งวด" style={{ color: 'black' }}
                                                                    onChange={(e) => setMoney({ ...money, loanTerm: parseInt(e.target.value) })} />
                                                            </Form.Item>
                                                            <Form.Item label='งวดละ' name='monthlyPayment'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                                    onChange={(e) => setMoney({ ...money, monthlyPayment: parseInt(e.target.value) })} />
                                                            </Form.Item>
                                                            <Form.Item label='ดอกเบี้ย' name='interest'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                                    onChange={(e) => setMoney({ ...money, interest: parseInt(e.target.value) })} />
                                                            </Form.Item>
                                                            <Form.Item label='รวมราคา' name='installmentWithInterest'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                                    defaultValue={currencyFormatOne(~~getData?.cars.carLoanDetails?.monthlyPayment * ~~getData?.cars.carLoanDetails?.loanTerm)}
                                                                    onChange={(e) => setMoney({ ...money, installmentWithInterest: parseInt(e.target.value) })} />
                                                            </Form.Item>
                                                        </>
                                                    ) : null}

                                                    {(moneyOld && moneyOld.productLoanType === 'ย้ายไฟแนนซ์') || (moneyOld && moneyOld.productLoanType === 'Pre-Aaprove-ย้ายไฟแนนซ์') ? (
                                                        <>
                                                            <Form.Item label='ปิดไฟแนนซ์จาก' name='issuno'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <Input type='text' style={{ color: 'black' }}
                                                                    // <Input disabled={notPassData2.npOldLoan} type='text' style={{ color: 'black' }}
                                                                    onChange={(e) => setMoneyOld({ ...moneyOld, issuno: e.target.value })} />
                                                            </Form.Item>
                                                            <Form.Item label='ยอดปิดไฟแนนซ์' name='oldLoanAmount'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <Input type='number' suffix="บาท" style={{ color: 'black' }}
                                                                    // <Input disabled={notPassData2.npOldLoan} type='number' suffix="บาท" style={{ color: 'black' }}
                                                                    onChange={(e) => setMoneyOld({ ...moneyOld, oldLoanAmount: parseInt(e.target.value) })} />
                                                            </Form.Item>
                                                            <Form.Item label='ค่างวด/เดือน' name='oldMonthlyPayment'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <Input type='number' suffix="บาท" style={{ color: 'black' }}
                                                                    // <Input disabled={notPassData2.npOldLoan} type='number' suffix="บาท" style={{ color: 'black' }}
                                                                    onChange={(e) => setMoneyOld({ ...moneyOld, oldMonthlyPayment: parseInt(e.target.value) })} />
                                                            </Form.Item>
                                                            <Form.Item label='จำนวนผ่อนกี่งวด' name='oldLoanTerm'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <Input type='number' suffix="งวด" style={{ color: 'black' }}
                                                                    // <Input disabled={notPassData2.npOldLoan} type='number' suffix="งวด" style={{ color: 'black' }}
                                                                    onChange={(e) => setMoneyOld({ ...moneyOld, oldLoanTerm: parseInt(e.target.value) })} />
                                                            </Form.Item>
                                                        </>
                                                    ) : null}

                                                </div>
                                            </aside>

                                        </Col>
                                    </Row>
                                    <Divider />

                                </>
                                : null
                        }
                        <Row justify={'center'}>
                            <Col>
                                <Row justify={'center'} style={{ margin: '5px' }} ><h3><b>หน้าคู่มือ (อัพโหลดไฟล์ไม่เกิน 4MB)</b></h3></Row>
                                <Row justify={'center'}>
                                    <Form.Item>
                                        <Upload
                                            accept="image/*"
                                            multiple={true}
                                            listType="picture-card"
                                            style={{ textAlign: 'center' }}
                                            fileList={fileListCustomerDocument}
                                            onPreview={handlePreview}
                                            onChange={handleChangeCustomerDocument}
                                            beforeUpload={(file) => beforeUpts(file, 2).then(() => false).catch(() => true)}
                                        >
                                            {fileListCustomerDocument?.length >= 10 ? null : (
                                                <div>
                                                    <PlusOutlined />
                                                    <div className="ant-upload-text">upload</div>
                                                </div>
                                            )}
                                        </Upload>
                                        <Modal open={previewVisible} onCancel={handleCancel}>
                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                        </Modal>
                                    </Form.Item>
                                </Row>
                                <Divider />
                                <Row justify={'center'} style={{ margin: '5px' }} ><h3><b>ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม (อัพโหลดไฟล์ไม่เกิน 4MB)</b></h3></Row>
                                <Row justify={'center'}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input !'
                                            },]}
                                    >
                                        <Upload
                                            accept="image/*"
                                            multiple={true}
                                            style={{ textAlign: 'center' }}
                                            listType="picture-card"
                                            fileList={fileListCustomerCar}
                                            onPreview={handlePreview}
                                            onChange={handleChangeCustomerCar}
                                            beforeUpload={(file) => beforeUpts(file, 1).then(() => false).catch(() => true)}
                                        >
                                            {fileListCustomerCar?.length >= 20 ? null : (
                                                <div>
                                                    <PlusOutlined />
                                                    <div className="ant-upload-text">upload</div>
                                                </div>
                                            )}
                                        </Upload>
                                        <Modal open={previewVisible} onCancel={handleCancel}>
                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                        </Modal>

                                    </Form.Item>
                                </Row>
                            </Col>
                        </Row>


                    </Card>
                    <Card>
                        <Divider style={{ margin: 5 }} />
                        <Row justify={'left'}><b><u>หน้าคู่มือ</u></b></Row>
                        <Row gutter={32} justify={'center'}>
                            <Space direction="vertical">
                                <Row gutter={32} justify={'center'}>
                                    {imageBlobzz?.map((e, index) => {
                                        var PreAaproveSplit = []
                                        PreAaproveSplit = e.pathImage.split("/")
                                        if (PreAaproveSplit[3] !== "13") {
                                            if (e.type === 2) {
                                                return (
                                                    <>
                                                        <Image width={"50px"} height={'50px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                    </>
                                                );
                                            }
                                        }
                                        return null;
                                    })}
                                </Row>

                            </Space>
                        </Row>
                        <Divider />
                        {
                            checkSendNEW ?
                                <>
                                    <Row justify={'left'}><b><u>หน้าคู่มือ</u> (ใหม่)</b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        <Space direction="vertical">
                                            <Row gutter={32} justify={'center'}>
                                                {imageBlobzz?.map((e, index) => {
                                                    var PreAaproveSplit = []
                                                    PreAaproveSplit = e.pathImage.split("/")
                                                    if (PreAaproveSplit[3] === "13") {
                                                        if (e.type === 2) {
                                                            return (
                                                                <>
                                                                    <Image width={"50px"} height={'50px'} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </>
                                                            );
                                                        }
                                                    }
                                                    return null;
                                                })}
                                            </Row>
                                        </Space>
                                    </Row>
                                </>
                                : null
                        }

                        <Divider />

                        <Row justify={'left'}><b><u>ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม</u></b></Row>
                        <Row gutter={32} justify={'center'}>
                            {imageBlobzz?.map((e, index) => {
                                var PreAaproveSplit = []
                                PreAaproveSplit = e.pathImage.split("/")
                                if (PreAaproveSplit[3] !== "13") {
                                    if (e.type === 1) {
                                        return (
                                            <>
                                                <Image width={"50px"} height={'50px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                            </>
                                        );
                                    }
                                }
                                return null;
                            })}

                        </Row>
                        <Divider />
                        {
                            checkSendNEW ?
                                <>
                                    <Row justify={'left'}><b><u>ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม</u> (ใหม่)</b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        <Space direction="vertical">
                                            <Row gutter={32} justify={'center'}>
                                                {imageBlobzz?.map((e, index) => {
                                                    var PreAaproveSplit = []
                                                    PreAaproveSplit = e.pathImage.split("/")
                                                    if (PreAaproveSplit[3] === "13") {
                                                        if (e.type === 1) {
                                                            return (
                                                                <>
                                                                    <Image width={"50px"} height={'50px'} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </>
                                                            );
                                                        }
                                                    }
                                                    return null;
                                                })}
                                            </Row>
                                        </Space>
                                    </Row>
                                </>
                                : null
                        }
                        <Divider />
                    </Card>

                    <Row justify={'center'}>
                        <Space>
                            <Button type="primary" danger onClick={handleCancel}>
                                ปิด
                            </Button>
                            {/* <Button type="primary" danger onClick={handleSendNote}>
                            note
                        </Button> */}
                            <Button type="primary" htmlType="submit" style={{ background: 'green' }} disabled={(fileListCustomerDocument.length && fileListCustomerCar.length) === 0}>
                                บันทึก
                            </Button>
                        </Space>
                    </Row>
                </Form>
            </Spin>
            {contextHolder}
        </Row>
    )
}
