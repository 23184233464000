
const oppgre =
    [
        {
            label: 'ทั้งหมด', value: 'ทั้งหมด',
        },
        {
            label: 'ยื่นคำร้อง', value: '5',
        },
        {
            label: 'รับคำร้อง', value: '7',
        },
        {
            label: 'ปฏิเสธคำร้อง', value: '8',
        },
        {
            label: 'คำร้องไม่ถูกต้อง', value: '6',
        },
        {
            label: 'เสนอเคสรีแล้ว', value: '10',
        },
        {
            label: 'รอธุรการรับ', value: '16',
        },
        {
            label: 'รอวิเคราะห์', value: '18',
        },
        {
            label: 'ผ่านการวิเคราะห์', value: '19',
        },
        {
            label: 'ไม่ผ่านการวิเคราะห์', value: '20',
        },
        {
            label: 'รออนุมัติ', value: '2',
        },
        {
            label: 'รออนุมัติราคารถ', value: '17',
        },
        {
            label: 'อนุมัติ', value: '3',
        },
        {
            label: 'อนุมัติแบบมีเงื่อนไข', value: '21',
        },
        {
            label: 'ไม่อนุมัติ', value: '22',
        },
        {
            label: 'ลูกค้าคอนเฟิร์ม', value: '24',
        },
        {
            label: 'รอตรวจสอบเอกสาร', value: '26',
        },
        {
            label: 'รอทำสัญญา', value: '27',
        },
        {
            label: 'ลูกค้าปฏิเสธ', value: '25',
        },
        {
            label: 'Reject', value: '23',
        }
    ]

const op =
    [
        {
            label: 'ทั้งหมด', value: 'ทั้งหมด',
        },
        {
            label: 'รอธุรการรับ', value: '16',
        },
        {
            label: 'รอวิเคราะห์', value: '18',
        },
        {
            label: 'ผ่านการวิเคราะห์', value: '19',
        },
        {
            label: 'ไม่ผ่านการวิเคราะห์', value: '20',
        },
        {
            label: 'รออนุมัติ', value: '2',
        },
        {
            label: 'รออนุมัติราคารถ', value: '17',
        },
        {
            label: 'อนุมัติ', value: '3',
        },
        {
            label: 'อนุมัติแบบมีเงื่อนไข', value: '21',
        },
        {
            label: 'ไม่อนุมัติ', value: '22',
        },
        {
            label: 'ลูกค้าคอนเฟิร์ม', value: '24',
        },
        {
            label: 'รอตรวจสอบเอกสาร', value: '26',
        },
        {
            label: 'รอทำสัญญา', value: '27',
        },
        {
            label: 'ลูกค้าปฏิเสธ', value: '25',
        },
        {
            label: 'Reject', value: '23',
        }
    ]

const opre =
    [
        {
            label: 'ทั้งหมด', value: 'ทั้งหมด',
        },
        {
            label: 'ยื่นคำร้อง', value: 'ยื่นคำร้อง',
        },
        {
            label: 'รับคำร้อง', value: 'รับคำร้อง',
        },
        {
            label: 'ปฏิเสธคำร้อง', value: 'ปฏิเสธคำร้อง',
        },
        {
            label: 'ผ่านการวิเคราะห์', value: 'ผ่านการวิเคราะห์',
        },
        {
            label: 'รออนุมัติ', value: 'รออนุมัติ',
        },
        {
            label: 'อนุมัติ', value: 'อนุมัติ',
        },
    ]


const orderCar = [
    // "รอธุรการรับ",
    // "รออนุมัติราคารถ",
    // "รอวิเคราะห์",
    // "ผ่านการวิเคราะห์",
    // "ไม่ผ่านการวิเคราะห์",
    // "รออนุมัติ",
    // "อนุมัติ",
    // "ไม่อนุมัติ",
    // "รอทำสัญญา",
    // "ลูกค้าปฏิเสธ",
    // "Reject",
    // "ทั้งหมด",
    "ยังไม่ได้ส่งขอ",
    "ส่งเคสอนุมัติ",
    "ยื่นคำร้อง",
    "คำร้องไม่ถูกต้อง",
    "รับคำร้อง",
    "ปฏิเสธคำร้อง",
    "เสนอเคสรีแล้ว",
    "รอพี่หนุ่มรับ",
    "รอเคาะราคา",
    "ขอเอกสารเพิ่ม",
    "เคาะราคาแล้ว",
    "ปัดตก",
    "รอธุรการรับ",
    "รออนุมัติราคารถ",
    "รอวิเคราะห์",
    "ผ่านการวิเคราะห์",
    "ไม่ผ่านการวิเคราะห์",
    "รออนุมัติ",
    "อนุมัติ",
    "อนุมัติแบบมีเงื่อนไข",
    "ไม่อนุมัติ",
    "ลูกค้าคอนเฟิร์ม",
    "ลูกค้าปฏิเสธ",
    "รอตรวจสอบเอกสาร",
    "รอทำสัญญา",
    "ระงับเคส",
    "Reject",
    "ทั้งหมด",
]

const orderLand = [
    "รอพี่หนุ่มรับ",
    "รอธุรการรับ",
    "ขอเอกสารเพิ่มเติม",
    "รอวิเคราะห์",
    "ผ่านการวิเคราะห์",
    "ไม่ผ่านการวิเคราะห์",
    "รออนุมัติ",
    "อนุมัติ",
    "ไม่อนุมัติ",
    "รอทำสัญญา",
    "ลูกค้าปฏิเสธ",
    "Reject",
    "ทั้งหมด",
]

const orderAD = [
    "K0000001",
    "K0000002",
    "K0000003",
    "K0000057",
    "K0000063",
    "K0000065",
    "K0000074",
    "K0000075",
    "K0000076",
    "MIT00034",
    "MIT00026",
    "MIT00075",
    "MIT00092",
    "MIT00109",
    "MIT00115",
    "MIT00117",
    "MIT00122",
]

const productLoanTypeCar = [
    {
        label: 'ถือเล่มมา',
        value: 'ถือเล่มมา',
    },
    {
        label: 'ย้ายไฟแนนซ์',
        value: 'ย้ายไฟแนนซ์',
    },
    // {
    //     label: 'ปรับโครงสร้าง',
    //     value: 'ปรับโครงสร้าง',
    // },
    {
        label: 'ซื้อ-ขาย',
        value: 'ซื้อ-ขาย',
    },
    {
        label: 'Pre-Aaprove-ถือเล่มมา',
        value: 'Pre-Aaprove-ถือเล่มมา',
    },
    {
        label: 'Pre-Aaprove-ซื้อ-ขาย',
        value: 'Pre-Aaprove-ซื้อ-ขาย',
    },
    {
        label: 'Pre-Aaprove-ย้ายไฟแนนซ์',
        value: 'Pre-Aaprove-ย้ายไฟแนนซ์',
    },
    // {
    //     label: 'Pre-Aaprove-รีโอน',
    //     value: 'Pre-Aaprove-รีโอน',
    // },
    // {
    //     label: 'เปลี่ยนสัญญา',
    //     value: 'เปลี่ยนสัญญา',
    // },
    // {
    //     label: 'Pre-Aaprove-เปลี่ยนสัญญา',
    //     value: 'Pre-Aaprove-เปลี่ยนสัญญา',
    // },
    // {
    //     label: 'รี+เปลี่ยนสัญญา',
    //     value: 'รี+เปลี่ยนสัญญา',
    // },
    // {
    //     label: 'ปรับ+เปลี่ยนสัญญา',
    //     value: 'ปรับ+เปลี่ยนสัญญา',
    // },
    // {
    //     label: 'Pre-Aaprove-รี-Ploan',
    //     value: 'Pre-Aaprove-รี-Ploan',
    // },
    // {
    //     label: 'รี-3',
    //     value: 'รี-3',
    // },
    // {
    //     label: 'Pre-Aaprove-ปรับโครงสร้าง',
    //     value: 'Pre-Aaprove-ปรับโครงสร้าง',
    // },
    // {
    //     label: 'Pre-Aaprove-รี',
    //     value: 'Pre-Aaprove-รี',
    // },
    // {
    //     label: 'Pre-Aaprove-รี+เปลี่ยนสัญญา',
    //     value: 'Pre-Aaprove-รี+เปลี่ยนสัญญา',
    // },
    {
        label: 'Pre-Aaprove-Ploan',
        value: 'Pre-Aaprove-Ploan',
    },
]

const productLoanTypeLand = [
    {
        label: 'ถือโฉนด(ที่ดิน)',
        value: 'ถือโฉนด(ที่ดิน)',
    },
    {
        label: 'ย้ายไฟแนนซ์',
        value: 'ย้ายไฟแนนซ์',
    },
    // {
    //     label: 'ปรับโครงสร้าง',
    //     value: 'ปรับโครงสร้าง',
    // },
    {
        label: 'ซื้อ-ขาย',
        value: 'ซื้อ-ขาย',
    },
    {
        label: 'ที่ดิน+บ้าน',
        value: 'ที่ดิน+บ้าน',
    },
    {
        label: 'ฝากโฉนด',
        value: 'ฝากโฉนด',
    },
    {
        label: 'Pre-Aaprove-ถือโฉนด(ที่ดิน)',
        value: 'Pre-Aaprove-ถือโฉนด(ที่ดิน)',
    },

    {
        label: 'Pre-Aaprove-ซื้อ-ขาย',
        value: 'Pre-Aaprove-ซื้อ-ขาย',
    },
    {
        label: 'Pre-Aaprove-ย้ายไฟแนนซ์',
        value: 'Pre-Aaprove-ย้ายไฟแนนซ์',
    },
    {
        label: 'Pre-Aaprove-ฝากโฉนด',
        value: 'Pre-Aaprove-ฝากโฉนด',
    },
]

const loanPLorCH = [
    {
        label: 'P-LOAN',
        value: 'p-loan',
    },
    {
        label: 'เช่าซื้อ',
        value: 'เช่าซื้อ',
    },
    {
        label: 'เช่าซื้อ (0.84-0.75)',
        value: 'เช่าซื้อ1',
    }
]

const loanPLorCHBig = [
    {
        label: 'P-LOAN',
        value: 'p-loan',
    },
    {
        label: 'เช่าซื้อ',
        value: 'เช่าซื้อ',
    },
]

// const productLoanTypeCarRE = [
// {
//     label: 'ปรับโครงสร้าง',
//     value: 'ปรับโครงสร้าง',
// },
// {
//     label: 'เปลี่ยนสัญญา',
//     value: 'เปลี่ยนสัญญา',
// },
// {
//     label: 'รี+เปลี่ยนสัญญา',
//     value: 'รี+เปลี่ยนสัญญา',
// },
// {
//     label: 'ปรับ+เปลี่ยนสัญญา',
//     value: 'ปรับ+เปลี่ยนสัญญา',
// },
// {
//     label: 'รี-3',
//     value: 'รี-3',
// },
//     {
//         label: 'Pre-Aaprove-รีโอน',
//         value: 'Pre-Aaprove-รีโอน',
//     },
//     {
//         label: 'Pre-Aaprove-เปลี่ยนสัญญา',
//         value: 'Pre-Aaprove-เปลี่ยนสัญญา',
//     },
//     {
//         label: 'Pre-Aaprove-รี-Ploan',
//         value: 'Pre-Aaprove-รี-Ploan',
//     },
//     {
//         label: 'Pre-Aaprove-ปรับโครงสร้าง',
//         value: 'Pre-Aaprove-ปรับโครงสร้าง',
//     },
//     {
//         label: 'Pre-Aaprove-รี',
//         value: 'Pre-Aaprove-รี',
//     },
//     {
//         label: 'Pre-Aaprove-รี+เปลี่ยนสัญญา',
//         value: 'Pre-Aaprove-รี+เปลี่ยนสัญญา',
//     },
// ]

const countStatusRe = [
    {
        approvalStatus: 'ทั้งหมด',
        approvalStatusId: 'ทั้งหมด',
    },
    {
        approvalStatus: 'ยื่นคำร้อง',
        approvalStatusId: '5',
    },
    {
        approvalStatus: 'คำร้องไม่ถูกต้อง',
        approvalStatusId: '6',
    },
    {
        approvalStatus: 'รับคำร้อง',
        approvalStatusId: '7',
    },
    {
        approvalStatus: 'ปฏิเสธคำร้อง',
        approvalStatusId: '8',
    },
    {
        approvalStatus: 'ระงับเคส',
        approvalStatusId: '9',
    },
    {
        approvalStatus: 'เสนอเคสรีแล้ว',
        approvalStatusId: '10',
    }

]



const productLoanTypeCarRE = [
    {
        label: 'Pre-Aaprove-รีโอน',
        value: 'Pre-Aaprove-รีโอน',
    },
    {
        label: 'Pre-Aaprove-รี-Ploan',
        value: 'Pre-Aaprove-รี-Ploan',
    },
    {
        label: 'Pre-Aaprove-รี',
        value: 'Pre-Aaprove-รี',
    },
    // {
    //     label: 'Pre-Aaprove-รี+เปลี่ยนสัญญา',
    //     value: 'Pre-Aaprove-รี+เปลี่ยนสัญญา',
    // },
    // {
    //     label: 'Pre-Aaprove-ปรับโครงสร้าง',
    //     value: 'Pre-Aaprove-ปรับโครงสร้าง',
    // },
]

const productLoanTypeCarPAP = [
    {
        label: 'Pre-Aaprove-ปรับโครงสร้าง',
        value: 'Pre-Aaprove-ปรับโครงสร้าง',
    },
]

const productLoanTypeCarCHANGE = [
    {
        label: 'Pre-Aaprove-เปลี่ยนสัญญา',
        value: 'Pre-Aaprove-เปลี่ยนสัญญา',
    },
    {
        label: 'Pre-Aaprove-รี+เปลี่ยนสัญญา',
        value: 'Pre-Aaprove-รี+เปลี่ยนสัญญา',
    },
]

const productLoanTypeLandRE = [
    {
        label: 'Pre-Aaprove-รีโอน',
        value: 'Pre-Aaprove-รีโอน',
    },
    // {
    //     label: 'Pre-Aaprove-รี-Ploan',
    //     value: 'Pre-Aaprove-รี-Ploan',
    // },
    {
        label: 'Pre-Aaprove-รี',
        value: 'Pre-Aaprove-รี',
    },
    // {
    //     label: 'Pre-Aaprove-ปรับโครงสร้าง',
    //     value: 'Pre-Aaprove-ปรับโครงสร้าง',
    // },
    // {
    //     label: 'Pre-Aaprove-รี+เปลี่ยนสัญญา',
    //     value: 'Pre-Aaprove-รี+เปลี่ยนสัญญา',
    // },
]

const productLoanTypeLandPAP = [
    {
        label: 'Pre-Aaprove-ปรับโครงสร้าง',
        value: 'Pre-Aaprove-ปรับโครงสร้าง',
    },
]

const productLoanTypeLandCHANGE = [
    {
        label: 'Pre-Aaprove-เปลี่ยนสัญญา',
        value: 'Pre-Aaprove-เปลี่ยนสัญญา',
    },
    {
        label: 'Pre-Aaprove-รี+เปลี่ยนสัญญา',
        value: 'Pre-Aaprove-รี+เปลี่ยนสัญญา',
    },
]

const nopay = [
    {
        value: 12,
        label: 12,
    },
    {
        value: 18,
        label: 18,
    },
    {
        value: 24,
        label: 24,
    },
    {
        value: 30,
        label: 30,
    },
    {
        value: 36,
        label: 36,
    },
    {
        value: 42,
        label: 42,
    },
    {
        value: 48,
        label: 48,
    },
    {
        value: 54,
        label: 54,
    },
    {
        value: 60,
        label: 60,
    },
    {
        value: 66,
        label: 66,
    },
    {
        value: 72,
        label: 72,
    },
    {
        value: 78,
        label: 78,
    },
    {
        value: 84,
        label: 84,
    },
    {
        value: 90,
        label: 90,
    },
    {
        value: 96,
        label: 96,
    },
    {
        value: 102,
        label: 102,
    },
    {
        value: 108,
        label: 108,
    },
    {
        value: 114,
        label: 114,
    },
    {
        value: 120,
        label: 120,
    },
]

const loanTermMid = [
    {
        value: 12,
        label: 12,
    },
    {
        value: 18,
        label: 18,
    },
    {
        value: 24,
        label: 24,
    },
    {
        value: 30,
        label: 30,
    },
    {
        value: 36,
        label: 36,
    },
    {
        value: 42,
        label: 42,
    },
    {
        value: 48,
        label: 48,
    },
    {
        value: 54,
        label: 54,
    },
    {
        value: 60,
        label: 60,
    },
    {
        value: 66,
        label: 66,
    },
    {
        value: 72,
        label: 72,
    },
    {
        value: 78,
        label: 78,
    },
    {
        value: 84,
        label: 84,
    },
    {
        value: 90,
        label: 90,
    },
    {
        value: 96,
        label: 96,
    },
    {
        value: 102,
        label: 102,
    },
    {
        value: 108,
        label: 108,
    },
    {
        value: 114,
        label: 114,
    },
    {
        value: 120,
        label: 120,
    },
]

// เปลี่ยน product_loan_type สถานะอนุมัติ เท่านั้น
const optionsLandPLT = [
    {
        label: 'ถือโฉนด(ที่ดิน)',
        value: 'ถือโฉนด(ที่ดิน)',
    },
    {
        label: 'ซื้อ-ขาย',
        value: 'ซื้อ-ขาย',
    },
    {
        label: 'ที่ดิน+บ้าน',
        value: 'ที่ดิน+บ้าน',
    },
    {
        label: 'ฝากโฉนด',
        value: 'ฝากโฉนด',
    },
    {
        label: 'Pre-Aaprove-ถือโฉนด(ที่ดิน)',
        value: 'Pre-Aaprove-ถือโฉนด(ที่ดิน)',
    },
    {
        label: 'Pre-Aaprove-ซื้อ-ขาย',
        value: 'Pre-Aaprove-ซื้อ-ขาย',
    },
    {
        label: 'Pre-Aaprove-ฝากโฉนด',
        value: 'Pre-Aaprove-ฝากโฉนด',
    },
]
const optionsCarPLT = [
    {
        label: 'ถือเล่มมา',
        value: 'ถือเล่มมา',
    },
    {
        label: 'ซื้อ-ขาย',
        value: 'ซื้อ-ขาย',
    },
    {
        label: 'Pre-Aaprove-ถือเล่มมา',
        value: 'Pre-Aaprove-ถือเล่มมา',
    },
    {
        label: 'Pre-Aaprove-ซื้อ-ขาย',
        value: 'Pre-Aaprove-ซื้อ-ขาย',
    },
    {
        label: 'Pre-Aaprove-Ploan',
        value: 'Pre-Aaprove-Ploan',
    },
]
const optionsPLTOld = [
    {
        label: 'ย้ายไฟแนนซ์',
        value: 'ย้ายไฟแนนซ์',
    },
    {
        label: 'Pre-Aaprove-ย้ายไฟแนนซ์',
        value: 'Pre-Aaprove-ย้ายไฟแนนซ์',
    },
]

// เปลี่ยนจาก อนุมัติ/อนุมัติแบบมีเงื่อนไข/ไม่อนุมัติ 
const optionsChange = [
    {
        label: 'รอวิเคราะห์',
        value: 18,
    },
    {
        label: 'ผ่านการวิเคราะห์',
        value: 19,
    },
    {
        label: 'รออนุมัติ',
        value: 2,
    }
]

// ประเภทจ่าย (การเงิน)
const optionsPayType = [
    {
        label: 'ปกติ',
        value: 1,
    },
    {
        label: 'พิเศษ',
        value: 2,
    },
]
const optionsSearchPay = [
    {
        label: 'สัญญา',
        value: 1,
    },
    {
        label: 'เลขบัตรประชาชน',
        value: 2,
    },
    {
        label: 'ชื่อ-นามสกุล',
        value: 3,
    },
    {
        label: 'ทะเบียนรถ',
        value: 4,
    },
]
//
export {
    op, opre, orderCar, orderLand, orderAD, productLoanTypeCar, productLoanTypeLand,
    loanPLorCH, loanPLorCHBig, productLoanTypeCarRE, countStatusRe,
    productLoanTypeCarPAP, nopay, loanTermMid, productLoanTypeCarCHANGE,
    productLoanTypeLandRE, productLoanTypeLandPAP, productLoanTypeLandCHANGE, oppgre,
    optionsLandPLT, optionsCarPLT, optionsPLTOld, optionsChange, 
    optionsPayType, optionsSearchPay
};