import axios from 'axios';
import { printReceipt, getPaytype, FindPAYTYP, insertMemo1, printReceiptAuto } from "../../file_mid/all_api"

export const loadPrintDataPayment = async (data) => {
    console.log("loadPrintDataPayment", data)
    try {
        const response = await axios.post(printReceipt, data);
        if (response.status === 200) {
            console.log("response.data", response.data)
            return response.data;
        } else {
            return null;
        }
    } catch (error) {
        console.log("err", error);
        return null;
    }
};

export const loadPrintDataPaymentMulti = async (data) => {
    console.log("loadPrintDataPaymentMulti", data)
    try {
        const response = await axios.post(printReceiptAuto, data);
        if (response.status === 200) {
            console.log("response.data", response.data)
            return response.data;
        } else {
            return null;
        }
    } catch (error) {
        console.log("err", error);
        return null;
    }
};


export const loadDataPayment = async () => {
    console.log("loadDataPayment")
    try {
        const response = await axios.get(getPaytype);
        if (response.status === 200) {
            console.log("response.data", response.data)
            return response.data;
        } else {
            return null;
        }
    } catch (error) {
        console.log("err", error);
        return null;
    }
};

export const funcInsertMemo1 = async (contno, memo1) => {
    console.log("funcInsertMemo1")
    if (contno !== "" && memo1 !== "") {
        try {
            const response = await axios.post(insertMemo1, { CONTNO: contno, MEMO1: memo1 });
            if (response.status === 200) {
                console.log("response.data", response.data)
                return 200;
            } else {
                return 201;
            }
        } catch (error) {
            console.log("err", error);
            return 201;
        }
    }
};


export const loadDataFindPAYTYP = async (value, value2, isClickedTy) => {
    console.log("loadDataFindPAYTYP")
    if (value) {
        try {
            const response = await axios.post(FindPAYTYP, { textData: value, textData2: value2, isClickedTy: isClickedTy });
            if (response.status === 200) {
                console.log("response.data", response.data)
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            console.log("err", error);
            return null;
        }
    }
};