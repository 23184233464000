import React, { useEffect, useState } from 'react'
import { Button, Form, Card, Col, Row, Spin, Space, Divider, message, notification, Image, Modal } from 'antd';
import { PlusOutlined, EditOutlined, StopOutlined, RightOutlined, LeftOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs';
import MainAddGuaDoc from './Guarantor/MainAddGuaDoc';
import MainEditGuaDoc from './Guarantor/MainEditGuaDoc';
import { deleteFolderImgGuaCar, deleteguarantor, editgarno, showguarantorsdoc, getAllFolderGuaImg } from '../../file_mid/all_api';

export default function EditGuarantorDoc({ close, getData, sendback, dataFromTable, changepage, page, notes }) {
  // console.log("getData.car", getData?.cars)
  // console.log("getData.land", getData?.lands)
  // console.log("dataFromTable", dataFromTable)
  const [form] = Form.useForm();
  const [form1] = Form.useForm()
  const { confirm } = Modal
  const [api, contextHolder] = notification.useNotification();

  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false)
  const [guarantors, setGuarantors] = useState([]);
  const [addCount, setAddCount] = useState(0);
  const [getimages, setGetImages] = useState([]);
  const [sendB, setSendB] = useState(true)
  const [dataedit, setDataEdit] = useState([]); //เซตสำหรับแก้ไขข้อมูล 
  const [index1, setIndex] = useState(); //set index send to page edit
  const [indexedit, setIndexEdit] = useState(0); //set index send to page edit 

  const [isModalsAddGua, setIsModalsAddGua] = useState(false) // เปิด/ปิด modal เพิ่มคนค้ำ
  const [isModalsEditGua, setIsModalsEditGua] = useState(false) // เปิด/ปิด modal แก้ไขคนค้ำ

  const [checkEditGarno, setCheckEditGarno] = useState(false) // เปิด/ปิด แก้ไขลำดับคนค้ำ

  useEffect(() => {
    var dataloadGua = { typeLoan: dataFromTable.car, loanId: dataFromTable?.loanId }
    loadGuarantors(dataloadGua)
    if (dataFromTable !== undefined) {
      getImg()
    }
  }, [dataedit, sendB, isModalsAddGua, isModalsEditGua])

  useEffect(() => {
    if (guarantors?.length > 0) {
      countType()
    }
  }, [guarantors])

  useEffect(() => {
    if (addCount === 2) {
      Deletegarno()
    }
  }, [addCount])

  const countType = async () => {
    var yy = []
    guarantors.map((e) => {
      const newType = e.pathImg.split("/")
      const intTpye = parseInt(newType[4])
      if (isNaN(intTpye)) {
        yy.push(0)
      } else {
        yy.push(intTpye)
      }
    })
    var maxNumber = Math.max(...yy);
    if (isNaN(maxNumber)) {
      var newIntType = guarantors.length

    } else {
      var newIntType = maxNumber
    }
    setIndexEdit(newIntType)
  }

  const loadGuarantors = async (value) => {
    await axios.post(showguarantorsdoc, value)
      .then(res => {
        if (res.status === 200) {
          const modifiedData = res.data.map((e) => {
            return { ...e, checkDup: true };
          })
          setGuarantors(modifiedData)
        } else if (res.status === 204) {
          setGuarantors([])
          // console.log("ไม่พบข้อมูล")
        }
      }).catch((err) => {
        console.log("err", err)
      })
  }

  const getImg = async () => {
    const path = `one_images/${dataFromTable?.car}/${dataFromTable?.carId}/5`
    // console.log("path-customer", path)
    setLoading(true)
    await axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`)
      .then(res => {
        if (res.data) {
          // console.log("res.data", res.data)
          setGetImages(res.data)
          setLoading(false)
        }
      })
      .catch((err) => {
        // console.log("err", err)
        setLoading(false)
      })
    setLoading(false)
  }

  const handleAddGua = () => {
    setIsModalsAddGua(true)
  }

  const handleOpenEditGarno = () => {
    setCheckEditGarno(true)
  }

  const handleCloseEditGarno = () => {
    setSendB(dayjs())
    setCheckEditGarno(false)
  }

  const onChangeBack = () => {
    sendback(dayjs())
    changepage(page - 1)
  }
  const onChangeGo = () => {
    sendback(dayjs())
    changepage(page + 1)
  }
  const handleCancel = () => {
    close(false)
  }

  const Deletegarno = async () => {
    setLoading(true)
    await axios.put(deleteguarantor, guarantors)
      .then(res => {
        if (res.status === 200) {
          // console.log("res", res.data)
          if (res.data.length === 1) {
            setGuarantors([])
          }
          setAddCount(0)
          setLoading(false)
          message.success('ลบข้อมูลคนค้ำสำเร็จ')
        }
      })
      .catch(err => {
        console.log(err)
      }
      )
    setLoading(false)
  }

  const editGarnoSucc = (placement) => {
    api.success({
      message: <b>แก้ไขลำดับคนค้ำสำเร็จ</b>,
      // description: 'กรุณาเพิ่มที่อยู่ อย่างน้อย 1 ที่',
      placement,
    });
  }

  const handleEditGarno = async () => {
    await axios.put(editgarno, guarantors)
      .then(res => {
        if (res.status === 200) {
          editGarnoSucc('top')
          setSendB(dayjs())
          setCheckEditGarno(false)
        }
      })
      .catch((err) => {
        console.log("err", err)
        setLoading(false)
      })
  }

  const handleSwap = (index1, index2) => {
    const newData = [...guarantors];
    [newData[index1], newData[index2]] = [newData[index2], newData[index1]];
    setGuarantors(newData);
  };

  const genGuarantors = ({ item, index }) => {
    // console.log("item", item)
    var itemSplit = []
    if (item?.pathImg) {
      itemSplit = item.pathImg.split("/")
    }
    const handleEdit = (dataedit, index1) => {
      //console.log("GG", dataedit, index1)
      setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
      setDataEdit({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
      setIsModalsEditGua(true);
    }

    const handleDelete = (dataedit, index) => {
      confirm({
        title: 'คุณต้องการที่จะลบข้อมูลหรือไม่...?',
        content: 'กด OK เพื่อยืนยันการลบคนค้ำประกันแล้วรูปภาพทั้งหมดของผู้กู้',
        onOk() {
          showConfirmDelete({ dataedit, index })
        },
        onCancel() {
          message.error('ยกเลิกการลบข้อมูล')
        },
      });
    };

    // modal deleteImage
    const showConfirmDelete = async ({ dataedit, index }) => {
      setLoading(true)

      const folder = []
      const split = dataedit?.pathImg.split('/')[4];
      getimages.map((item, index1) => {
        var image = {}
        if (item?.pathImage.split('/')[4] === split) {
          image.attr = item.attr
          image.data = item.data
          image.idImage = item.idImage
          image.pathImage = item.pathImage
          folder.push(image)
        }
      })
      const mix = { inPath: dataedit.pathImg, guaImg: folder }
      await axios.delete(deleteFolderImgGuaCar, { data: mix })
        .then(res => {
          if (guarantors.length === 1) {
            setAddCount(2)
            // console.log("1")
          } else {
            // console.log("2")
            const data = guarantors.filter(item => item.garno !== index + 1);

            // เปลี่ยน "garno" ที่มีค่าเป็น 3 เป็น 2
            data.forEach((item, index) => {
              item.garno = index + 1;
            });
            setGuarantors(data)
            setAddCount(2)
          }
          // setTimeout(() => {

          // }, 1500)
          setLoading(false)
        })
        .catch((err) => {
          console.log("err", err)
          setLoading(false)
        })
      setLoading(false)
    }

    return (
      <Form
        name="basic"
        labelCol={{
          span: 12,
        }}
        wrapperCol={{
          span: 24,
        }}
        style={{
          maxWidth: '100%',
        }}
        initialValues={{
          remember: true,
        }}
        form={form1}
        autoComplete="off"
      >
        <Card style={{ width: '100%' }}>

          <Row justify={'center'}><b><u>คนค้ำที่ {index + 1}</u></b></Row>
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>

            </Col>
            <Col span={12}>
              <Form.Item style={{ margin: 3 }} label='เลขบัตรประชาชน' >
                <b>{item?.identificationId}</b>
              </Form.Item>
              <Form.Item style={{ margin: 3 }} label='ชื่อ' >
                <b>{item?.firstname}</b>
              </Form.Item>
              <Form.Item style={{ margin: 3 }} label='ชื่อเล่น' >
                <b>{item?.nickname}</b>
              </Form.Item>
              <Form.Item style={{ margin: 3 }} label='ความสัมพันธ์ของผู้ค้ำ' >
                <b>{item?.description ? item?.description : <p style={{ color: 'red' }}>ยังไม่เพิ่มความสัมพันธ์</p>}</b>
              </Form.Item>
              <Form.Item style={{ margin: 0 }} label='วันออกบัตร' >
                <b>{item?.issudt}</b>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ margin: 3 }} label='' >
              </Form.Item>
              <Form.Item style={{ margin: 3 }} label='นามสกุล' >
                <b>{item?.lastname}</b>
              </Form.Item>
              <Form.Item style={{ margin: 3 }} label='เบอร์โทร' >
                <b>{item?.phones?.length > 0 ? item?.phones[0].telp : <p style={{ color: 'red' }}>ยังไม่เพิ่มเบอร์โทร</p>}</b>
              </Form.Item>
              <Form.Item style={{ margin: 3 }} label='ที่อยู่' >
                <b>{item?.address?.length > 0 ?
                  <>
                    {item?.address[0].houseNumber} {item?.address[0].subdistrict} {item?.address[0].district} {item?.address[0].province}
                    {item?.address[0].zipCode ? " " + item?.address[0].zipCode : ""}
                  </> : 'ยังไม่เพิ่มเบอร์โทร'}</b>
              </Form.Item>
              <Form.Item style={{ margin: 0 }} label='วันบัตรหมดอายุ' >
                <b>{item?.expdt}</b>
              </Form.Item>
            </Col>
          </Row>
          <Divider style={{ margin: 5 }} />
          <Row gutter={32} justify={'center'}>
            <span>รูปภาพ : </span>
            <Col span={24} style={{ textAlign: 'center' }}>
              {itemSplit.length > 0 ? (
                <>
                  {getimages?.map((items, index) => {
                    const segments = items.pathImage.split("/")
                    if (itemSplit[4] === segments[4]) {
                      // console.log("test", getimages.length)
                      return (
                        <Image width={'100px'} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                      )
                    }

                  })}
                </>
              ) : null
              }
            </Col>
          </Row>
          <Divider style={{ margin: 5 }} />
          <Row justify={'center'}>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Space>
                {
                  item?.checkApCon === true ?
                    <>
                      <Button type="text"
                        style={{ background: "red", color: 'white' }}
                        onClick={() => { handleDelete(guarantors[index], index); }}
                      >
                        ลบคนค้ำ
                      </Button>
                    </>
                    : null
                }
                <Button type="text" style={{ background: "#e0e0e0" }} onClick={() => { handleEdit(guarantors[index], index); }}>
                  แก้ไขคนค้ำ
                </Button>
              </Space>
            </Col>
          </Row>
        </Card>
      </Form>
    )
  }

  const genGuarantorsEditGarno = ({ item, index }) => {

    return (
      <Form
        name="basic"
        labelCol={{
          span: 12,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        form={form1}
        autoComplete="off"
      >
        <Card style={{ width: '100%' }}                >
          <Row justify={'center'}>
            <Col span={18}>
              <Row justify={'center'}><b><u>คนค้ำที่ {item.garno}</u></b></Row>
              {/* <Row justify={'center'}><b><u>คนค้ำที่ {index + 1}</u></b></Row> */}
              <Row>
                <Col span={12}> <Form.Item style={{ margin: 0 }} label='ชื่อ-สกุล' >
                  <b>{item?.firstname} {item?.lastname}</b>
                </Form.Item>
                  <Form.Item style={{ margin: 0 }} label='ชื่อเล่น' >
                    <b>{item?.nickname}</b>
                  </Form.Item>
                  <Form.Item style={{ margin: 0 }} label='ความสัมพันกับผู้กู้' >
                    <b>{item?.description}</b>
                  </Form.Item>
                  <Form.Item style={{ margin: 0 }} label='วันออกบัตร' >
                    <b>{item?.issudt}</b>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item style={{ margin: 0 }} label='เลขบัตรประชาชน' >
                    <b>{item?.identificationId}</b>
                  </Form.Item>

                  <Form.Item style={{ margin: 0 }} label='เพศ' >
                    <b>{item?.gender === "M" ? "ชาย" : "หญิง"}</b>
                  </Form.Item>
                  {
                    item?.phones?.length >= 1 ?
                      <>
                        <Form.Item label='เบอร์โทร' style={{ margin: 0 }}>
                          <b>{item?.phones[0].telp}</b>
                        </Form.Item>
                      </>
                      :
                      <>
                        <Form.Item label='เบอร์โทร' style={{ margin: 0 }}>
                          <b>{item?.phones}</b>
                        </Form.Item>
                      </>
                  }
                  <Form.Item style={{ margin: 0 }} label='วันบัตรหมดอายุ' >
                    <b>{item?.expdt}</b>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>
              {
                checkEditGarno === true ?
                  <>
                    <Row justify={'center'}>
                      <Button icon={<UpOutlined />} onClick={() => handleSwap(index, index - 1)} disabled={index === 0}>
                        เลื่อนขึ้น
                      </Button>
                    </Row>
                    <Row justify={'center'}>
                      <Button icon={<DownOutlined />} onClick={() => handleSwap(index, index + 1)} disabled={index === guarantors.length - 1}>
                        เลื่อนลง
                      </Button>
                    </Row>
                  </>
                  : null
              }
            </Col>
          </Row>
        </Card>
      </Form>
    )
  }

  return (
    <>
      <Row justify={'center'}>
        <Card style={{ width: '1100px' }}>
          <Col style={{ textAlign: 'center' }} >
            <b><h2>ข้อมูลคนค้ำประกัน</h2></b>
          </Col>
          <Spin spinning={loading} size='large' tip=" Loading... ">
            <Row justify={'center'}>
              <Form
                name="basic"
                labelCol={{
                  span: 12,
                }}
                wrapperCol={{
                  span: 24,
                }}
                style={{
                  width: '100%',
                }}
                initialValues={{
                  remember: true,
                }}
                form={form}
                // onFinish={handleSubmit}
                autoComplete="off"
              >
                <Divider style={{ margin: 5 }} />
                <Row justify={'center'}>
                  {
                    checkEditGarno === false ?
                      <>
                        <Col span={24}>
                          {
                            guarantors?.length > 0 ?
                              <>
                                <Space direction="vertical" style={{ display: 'flex', }}>
                                  {guarantors?.map((item, index) => {
                                    return genGuarantors({ item, index, key: `{item.identificationId} - ${index}` });
                                  })}
                                </Space>
                              </>
                              :
                              null
                          }
                        </Col>
                      </>
                      :
                      <>
                        <Col span={24}>
                          {
                            guarantors?.length > 0 ?
                              <>
                                {guarantors?.map((item, index) => {
                                  return genGuarantorsEditGarno({ item, index, key: `{item.identificationId} - ${index}` });
                                })}
                              </>
                              :
                              null
                          }
                        </Col>
                      </>
                  }
                </Row>
                <Row justify={'center'} style={{ margin: 10 }} align={'middle'}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    {/* <Form.Item label='' name='imgGuarantor'> */}
                    {/* <Space> */}

                    {
                      checkEditGarno === false ?
                        <>
                          <Row justify={'center'} >
                            <Button type='primary' onClick={handleAddGua} icon={<PlusOutlined />}>เพิ่มคนค้ำ</Button>
                          </Row>
                          {
                            guarantors?.length > 1 ?
                              <>
                                <Row>
                                  <Col span={24} style={{ textAlign: 'right' }}>
                                    <Button type='primary' style={{ backgroundColor: "#faad14", color: "black" }} onClick={handleOpenEditGarno} icon={<EditOutlined />}>แก้ลำดับคนค้ำ</Button>
                                  </Col>
                                </Row>
                              </>
                              : null
                          }
                        </>
                        :
                        <>
                          <Space>
                            <Button type='primary' style={{ backgroundColor: "green" }} onClick={() => handleEditGarno(true)}>บันทึกลำดับผู้ค้ำ</Button>
                            <Button type='primary' danger onClick={handleCloseEditGarno} icon={<StopOutlined />}>ยกเลิกลำดับคนค้ำ</Button>
                          </Space>
                        </>
                    }
                    {/* </Space> */}
                    {/* </Form.Item> */}
                  </Col>
                </Row>
                <Divider style={{ margin: 5 }} />
                <Row justify={'center'} >
                  <div style={{ textAlign: "center" }} >
                    <Space>
                      {/* <Button type="primary" style={{ background: '#bfbfbf', color: '#000000' }} onClick={onChangeBack}>ย้อนกลับ</Button> */}
                      <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                      {/* <Button type="primary" onClick={onChangeGo}>ต่อไป</Button> */}
                    </Space>
                  </div>
                </Row>

              </Form>
            </Row>
          </Spin >
        </Card >
        {contextHolder}
      </Row >
      {
        isModalsAddGua ?
          <MainAddGuaDoc
            // pregarno={guarantors.length}
            getData={getData}
            open={isModalsAddGua}
            close={setIsModalsAddGua}
            sb={setSendB}
            dataindex={index1}
            indexedit={indexedit}
            dataFromTable={dataFromTable}
            guarantors={guarantors}
          />
          :
          null
      }
      {
        isModalsEditGua ?
          <MainEditGuaDoc
            open={isModalsEditGua}
            close={setIsModalsEditGua}
            sb={setSendB}
            dataGuarantor={guarantors}
            shootdata={dataedit}
            dataindex={index1}
            setSendback={setSendB}
            indexedit={indexedit}
          />
          :
          null
      }

    </>
  )
}
