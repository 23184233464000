import { Modal, Form, Button, Row, Spin, Input, Divider, Col, Space, Upload, Card, message, Select, DatePicker } from 'antd'
import React, { useState, useEffect } from 'react'
import Compressor from 'compressorjs';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import dayjs from 'dayjs';
import { checkCCAP, checkNewCCAP, testupload, uploadImgGuaTable } from '../../file_mid/all_api';

function AddGuarantoesNew({ open, close, indexedit, guarantors, getAddData, dataGuarantor, relationship }) {
    var countGu = 0
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm()
    const [form1] = Form.useForm()
    // const token = localStorage.getItem('token');
    const customers = useSelector(state => state.customers)
    const lands = useSelector(state => state.lands)
    const { confirm } = Modal

    const [guarantors1, setGuarantors] = useState();
    const [images, setImage] = useState([]);
    const [images1, setImage1] = useState();
    const [dataPostGuarantor, setDataPostGuarantor] = useState([]) // customerGuarantor
    const [dataLand, setDataLands] = useState({ ...lands?.data }) // customerGuarantor
    const [dataPostCustomer, setDataPostCustomer] = useState({ ...customers?.data })

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const [fileListGuarantor, setFileListGuarantor] = useState([]);
    const [fileListGuarantor1, setFileListGuarantor1] = useState([]);
    const [fileListGuarantors, setFileListGuarantors] = useState([]);
    const [fileListGuarantors1, setFileListGuarantors1] = useState([]);

    const [CheckIdCard, setCheckIdCard] = useState(false)
    const [indexedit1, setIndexedit1] = useState(indexedit);
    const [dataAddress, setDataAddress] = useState();

    // console.log('customers',customers);


    useEffect(() => {
        if (images1?.length === 0) {
            form.setFieldsValue({
                ...images1[0], ...images1[0]?.phones[0],
                birthdate: dayjs(images1[0]?.birthdate),
                issudt: dayjs(images1[0]?.issudt),
                expdt: dayjs(images1[0]?.expdt),
            })
        } else if (images1?.length >= 1) {
            if (images1[0]?.phones) {
                console.log("1")
                form.setFieldsValue({
                    ...images1[0], ...images1[0].phones[0],
                    birthdate: dayjs(images1[0]?.birthdate),
                    issudt: dayjs(images1[0]?.issudt),
                    expdt: dayjs(images1[0]?.expdt),
                })
            } else {
                console.log("2")
                form.setFieldsValue({
                    ...images1[0],
                    birthdate: dayjs(images1[0]?.birthdate),
                    issudt: dayjs(images1[0]?.issudt),
                    expdt: dayjs(images1[0]?.expdt),
                })
            }
        }
    }, [images1])

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleCancel1 = () => {
        setPreviewVisible(false);
    }

    const handleCancel = () => {
        close(false);
    };

    //////////// โชว์รูปภาพ ////////////////
    function createImageUrl(imageData) {
        // console.log(imageData)
        // const blob = new Blob([imageData.originFileObj], { type: imageData.type });
        // return URL.createObjectURL(blob);
        return imageData;
        // , fileList.pdf;
    }

    /////////// ดึงข้อมูลจากรูปบัตรประชาชนคนค้ำ /////////////////////////////////////////////////////////////////  
    const handleScanIdCardGuarantor = async (value) => {
        console.log('value333', value);

        if (guarantors?.length > 0) {
            var dataListGuarantor = [...guarantors]
        } else {
            var dataListGuarantor = []
        }
        for (let i = 0; i < value.length; i++) {
            var imgId = { image: value[i].image64 }
            const headers = { 'Content-Type': 'application/json' }
            await axios.post(testupload, imgId, { headers: headers })
                .then(async (res) => {
                    // console.log('res.data', res.data);
                    // const address = await splitAddress(res.data[0])
                    // await loadDataOne(res.data[0], address)
                    // delete res.data[0].address
                    if (res.data) {
                        if (dataGuarantor !== undefined) {
                            dataGuarantor?.map((e) => {
                                // console.log("e", e.identificationId)
                                if (e.identificationId === res.data[0]?.identificationId) {
                                    countGu = 1
                                    setFileListGuarantor()
                                    setFileListGuarantor1()
                                    console.log("บัตรคนค้ำซ้ำกัน")
                                    alert('บัตรคนค้ำซ้ำกัน')
                                }
                            })
                        }
                        if (countGu === 0) {
                            console.log("CC")
                            const b = await loadDataOneGuarantor(res.data[0])
                            dataListGuarantor.push({ ...b[0], imgs: value[i] })
                        }
                        // console.log("BB", dataListGuarantor)
                        setLoading(false)
                    }
                    else {
                        message.info('ไม่สามารถอ่านได้')
                        setLoading(false)
                    }
                    const address = await splitAddress(res.data[0])
                    await loadDataOne(res.data[0], address)


                })
                .catch((err) => console.log(err))
            setLoading(false)
        }
        // console.log("bb=", dataListGuarantor)
        if (countGu === 0) {
            setGuarantors({ ...guarantors, dataListGuarantor })
            showConfirm({ dataListGuarantor })
        }
    }

    // console.log('customers',customers);

    ////////////// ข้อมูลยูสในเดต้าเบสคนค้ำ ////////////////////////////////////////////////////////////////
    const loadDataOneGuarantor = async (data) => {
        setLoading(true)
        var dataListGuarantor = []
        if (!data?.identificationId) {
            setCheckIdCard(true)
            var unDataGua = {}
            // countGu = 1
            setFileListGuarantor()
            setFileListGuarantor1()
            console.log("ไม่มีเลขบัตรคนค้ำ")
            alert('ไม่สามารถอ่านบัตรคนค้ำประกันได้')
            unDataGua.identificationId = ""
            unDataGua.customerId = 0
            unDataGua.firstname = data?.firstname
            unDataGua.lastname = data?.lastname
            unDataGua.snam = data?.snam
            unDataGua.nickname = ""
            unDataGua.phones = []
            unDataGua.address = []
            unDataGua.gender = data?.gender
            unDataGua.birthdate = data?.birthdate
            unDataGua.expdt = data?.expdt
            unDataGua.issuby = data?.issuby
            dataListGuarantor.push(unDataGua)
            setDataPostGuarantor({ ...dataPostGuarantor, ...unDataGua, idCard: customers?.data?.identificationId })
            form.setFieldsValue({ nickname: "", telp: "" })
        }
        else if (dataPostCustomer?.identificationId === data?.identificationId) {
            countGu = 1
            setFileListGuarantor()
            setFileListGuarantor1()
            console.log("คนกู้กับคนค้ำเป็นคนเดียวกัน")
            alert('คนกู้กับคนค้ำเป็นคนเดียวกัน')
        }
        // if (data)
        else {
            var one = { identificationId: data.identificationId, idCard: customers?.data?.identificationId }
            setLoading(true)
            await axios.post(checkCCAP, one)
                .then((res) => {
                    if (res.data) {
                        //alert('มีข้อมูลในฐานข้อมูล')
                        message.info('คนค้ำมีข้อมูลในฐานข้อมูล')
                        delete res.data.address
                        delete res.data.carLoanDetails
                        delete res.data.emails
                        delete res.data.ojs
                        delete res.data.socials
                        dataListGuarantor.push(res.data)
                        setDataPostGuarantor({ ...dataPostGuarantor, ...data, customerId: 0, idCard: customers?.identificationId })
                        setCheckIdCard(false)
                        setLoading(false)
                    }
                    else {
                        // alert('คนค้ำไม่พบข้อมูลในฐานข้อมูล')
                        message.info('ไม่พบข้อมูลในฐานข้อมูล')
                        console.log("RES-DATA NO =", data)
                        dataListGuarantor.push(data)
                        setDataPostGuarantor({ ...dataPostGuarantor, ...data, customerId: 0, idCard: customers?.identificationId })
                        // splitAddress(res.data[0])
                        setCheckIdCard(true)
                        setLoading(false)
                    }
                })
                .catch((err) => console.log(err))
            setLoading(false)
        }

        // showConfirm({ dataListGuarantor })
        return dataListGuarantor
    }

    const showConfirm = ({ dataListGuarantor }) => {
        var note = dataListGuarantor?.length;
        var lastIndex = note - 1;
        var imageGuarantors1 = [...dataListGuarantor];
        setImage1(imageGuarantors1)
        guarantors?.map((img, index) => {
            imageGuarantors1.push({ ...img.imgs })
            setImage(imageGuarantors1)
        })

        setLoading(true)
        confirm({
            title: 'เพิ่มคนค้ำ?',
            content: dataListGuarantor[lastIndex]?.snam + ' ' + dataListGuarantor[lastIndex]?.firstname + ' ' + dataListGuarantor[lastIndex]?.lastname,
            onOk() {
                setGuarantors(dataListGuarantor)
                setLoading(false)
                setTimeout(() => {
                    setFileListGuarantor([])
                },)
            },
            onCancel() {
                message.error('ยกเลิก')
                setGuarantors(guarantors)
                setFileListGuarantor1({})
                setTimeout(() => {
                    setFileListGuarantor([])
                },)
                setLoading(false)
            },
        });
    }

    // ลดไซต์ภาพ
    // if (ez.size > 4000000) {
    //     console.log("4m ไม่ให้ส่ง");
    //     resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
    // } else {
    //     let quality;
    //     if (ez.size >= 2500000 && ez.size <= 4000000) {
    //         quality = 0.1;
    //     } else if (ez.size >= 2000000 && ez.size <= 2499999) {
    //         quality = 0.15;
    //     } else if (ez.size >= 1500000 && ez.size <= 1999999) {
    //         quality = 0.2;
    //     } else if (ez.size >= 1000000 && ez.size <= 1499999) {
    //         quality = 0.3;
    //     } else {
    //         quality = 0.5;
    //     }

    //     try {
    //         // ใช้ Promise เพื่อบีบอัดรูปภาพ
    //         const compressedFile = await new Promise((resolveCompress) => {
    //             new Compressor(ez, {
    //                 quality: quality,
    //                 success: (result) => {
    //                     resolveCompress(result);
    //                 },
    //             });
    //         });

    //         const fileOptions = {
    //             type: compressedFile.type,
    //             lastModified: Date.now(),
    //         };
    //         const compressedFileAsFile = new File([compressedFile], ez.name, fileOptions);
    //         image.imageCompressedFile = compressedFileAsFile;
    //         image.type = types
    //         allImg.push(image);
    //         //setAllImg((prevAllImg) => [...prevAllImg, image]);

    //         // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
    //         resolve(image);
    //     } catch (error) {
    //         console.error('Image compression error:', error);
    //         resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
    //     }
    // }

    //////////////////// อ่านบัตรคนค้ำ /////////////////////////
    const handleChangeGuarantor1 = async ({ fileList }) => {
        setLoading(true)
        console.log("fileList", fileList)
        setFileListGuarantor(fileList);
        var imageListGuarantor = []
        var i = 0
        for (const file of fileList) {
            const compressedFile = await new Promise((resolve) => {
                new Compressor(file.originFileObj, {
                    quality: 0.2, // เช่น, 60%
                    success: (result) => {
                        resolve(result);
                    },
                });
            });
            var image = {}
            var filename = file.name
            var image64 = await getBase64(compressedFile)
            // var image64 = file.originFileObj(compressedFile)
            image.filename = filename
            image.image64 = image64
            image.type = `5.${indexedit1 + 1}`
            imageListGuarantor.push(image)
            i++
        }
        await handleScanIdCardGuarantor(imageListGuarantor)
        setFileListGuarantor1({ ...fileListGuarantor1, imageListGuarantor });
        setLoading(false)
    };

    //////////////////// รูปเอกสาร ///////////////////////
    const handleChangeGuarantors1 = async ({ fileList }) => {
        setLoading(true)
        console.log("fileList", fileList)
        setFileListGuarantors(fileList);
        var imageListGuarantors = []
        var i = 0
        for (const file of fileList) {
            const compressedFile = await new Promise((resolve) => {
                new Compressor(file.originFileObj, {
                    quality: 0.1, // เช่น, 60%
                    success: (result) => {
                        resolve(result);
                    },
                });
            });
            var image = {}
            var filename = file.name
            var image64 = await getBase64(compressedFile)
            //var image64 = file.originFileObj
            image.filename = filename
            image.image64 = image64
            image.type = `5.${indexedit1 + 1}`
            imageListGuarantors.push(image)
            i++
        }
        setFileListGuarantors1({ ...fileListGuarantors1, imageListGuarantors });
        setLoading(false)
    };

    // const success = () => {
    //     Modal.success({
    //         content: 'บันทึกสำเร็จ',
    //         // path: navigate('/checkInfoCar')
    //     })
    // }

    const axi = async (InputImage) => {
        console.log("InputImage", InputImage)
        setLoading(true)
        await axios.post(uploadImgGuaTable, InputImage)
            .then(response => {
                if (response) {
                    console.log("response", response)
                    setLoading(false)
                    // close(false)
                }
            }).catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
    }

    const DataImagesGu = async ({ data }) => {
        console.log("data", data)
        setIndexedit1(indexedit1 + 1)
        let AddData2
        if (guarantors1?.length === 0) {
            let path = `one_images/land/${dataLand?.landId}/5/${indexedit + 1}`
            console.log("path1", path)
            AddData2 = [{ ...data[0], pathImg: path }];
            //รอเซตเข้าฐานข้อมูล path

        } else if (guarantors1?.length >= 1) {
            let path = `one_images/land/${dataLand?.landId}/5/${indexedit + 1}`
            console.log("path2", path)
            AddData2 = [{ ...data[0], pathImg: path }];//เก็บค่า state เดิมไว้ พร้อมส่ง type ไปด้วย เพื่อบอกค่าที่ส่ง
            // console.log("AddData2", AddData2)
            //รอเซตเข้าฐานข้อมูล path
        } else {
            console.log("?")
        }
        const InputImage = {
            mainImg: { carId: dataLand?.landId.toString(), carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), productTypeId: 2, img: data[0].imgs }
        }
        // เรียกใช้ function axi
        await axi(InputImage)

        console.log("AddData2", AddData2, indexedit1)
        // console.log("InputImage", InputImage)

        // confirm({
        //     title: 'บันทึกข้อมูลคนค้ำ?',
        //     onOk() {
        //         // โยนค่าข้าม Component
        getAddData({ AddData2, indexedit1 })

        setLoading(true)
        setTimeout(() => {
            // setLoading(false)
            close(false)
        }, [1500])

        //     },
        //     onCancel() {
        //         message.error('ยกเลิก')
        //         setLoading(false)
        //     },
        // });

    }

    // console.log('customers',customers);

    // ส่งค่าไปหน้าหลักของคนค้ำ
    const handleSubmit = () => {
        const imageListGuarantorPush = []
        // imageListGuarantorPush.push(...fileListGuarantor1?.imageListGuarantor)
        if (fileListGuarantor1?.imageListGuarantor) {
            imageListGuarantorPush.push(...fileListGuarantor1?.imageListGuarantor)
        }
        if (fileListGuarantors1?.imageListGuarantors) {
            imageListGuarantorPush.push(...fileListGuarantors1?.imageListGuarantors)
        }
        console.log("imageListGuarantorPush", imageListGuarantorPush)

        var data = []
        if (guarantors1?.length === 0) {
            data.push({ ...data[0], address: [dataAddress] })
            console.log("กรณียังไม่มีคนค้ำ")
        }
        if (guarantors1?.length > 0) {
            if (dataAddress) {
                data.push({ ...data[0], ...images1[0], imgs: imageListGuarantorPush, idCard: customers.data.identificationId, address: [dataAddress] })
            } else {
                data.push({ ...data[0], ...images1[0], imgs: imageListGuarantorPush, idCard: customers.data.identificationId }) //ต่อ array
                console.log("กรณีมีคนค้ำแล้ว")
            }

        }
        if (data) {
            if (!data[0]?.phones || data[0]?.phones?.length === 0 || data[0]?.phones[0]?.telp === "") {
                alert('กรุณาเพิ่มเบอร์โทร')
            } else if (!data[0]?.nickname) {
                alert('กรุณาเพิ่มชื่อเล่น')
            } else if (!data[0]?.identificationId || data[0]?.identificationId.length !== 13) {
                alert('กรุณาเพิ่มเลขบัตรประชาชนให้ถูกต้อง')
            } else if (!data[0]?.firstname) {
                alert('กรุณาเพิ่มชื่อ')
            } else if (!data[0]?.lastname) {
                alert('กรุณาเพิ่มนามสกุล')
            }
            else {
                console.log("ok", data)
                DataImagesGu({ data })
            }
        }
    }

    const handleIdCard = async (value) => {
        // console.log("value", value)
        const sumOne = { identificationId: value, idCard: customers?.data?.identificationId }
        setLoading(true)
        if (dataGuarantor !== undefined) {
            dataGuarantor?.map((e) => {
                if (e.identificationId === sumOne?.identificationId) {
                    countGu = 1
                    console.log("บัตรคนค้ำซ้ำกัน")
                    setImage1([{ ...images1[0], identificationId: "", phones: [] }])
                    form.setFieldsValue({ identificationId: "", telp: "" })
                    alert('บัตรคนค้ำซ้ำกัน กรุณากรอกเลขบัตรใหม่ !!!')
                }
            })
        }
        if (countGu === 0) {
            console.log("ตอนกรอกเองไม่ซ้ำ")
            if (sumOne?.identificationId === dataPostCustomer?.identificationId) {
                alert('เลขบัตรคนกู้กับคนค้ำเป็นคนเดียวกัน กรุณากรอกเลขบัตรใหม่ !!!')
                setImage1([{ ...images1[0], identificationId: "", phones: [] }])
                form.setFieldsValue({ identificationId: "", telp: "" })
            } else {
                await axios.post(checkNewCCAP, sumOne)
                    .then((res) => {
                        if (res.data) {
                            console.log('1');

                            delete res.data.address
                            delete res.data.emails
                            delete res.data.ojs
                            delete res.data.socials
                            message.info('มีข้อมูลในฐานข้อมูล')
                            setCheckIdCard(false)
                            setDataPostGuarantor({ ...dataPostGuarantor, ...res.data, idCard: customers?.data?.identificationId })
                            setGuarantors([res.data])
                            setImage1([{
                                ...images1[0], ...res.data,
                            }])
                        }
                        else {
                            console.log('2');

                            message.info('ไม่พบข้อมูลในฐานข้อมูล')
                            setCheckIdCard(true)
                            // setDataPostGuarantor({ ...dataPostGuarantor })
                            setImage1([{ ...images1[0], identificationId: value, customerId: 0, phones: [], idCard: customers?.identificationId }])


                            form.setFieldsValue({ telp: "" })
                        }
                    })
                    .catch((err) => console.log(err))
            }
        }
        setLoading(false)
    }

    const handlePhone = (e) => {
        const updatedGuarantors = [...images1];
        if (images1[0]?.phones?.length > 0) {
            // if (images1[0]?.phones !== undefined) {
            updatedGuarantors[0].phones[0].telp = e.target.value;
            setImage1(updatedGuarantors);
        }
        else {
            setImage1([{ ...images1[0], phones: [{ telp: e.target.value, phoneType: "เบอร์ติดต่อ", sendSms: "1" }] }])
        }
    }

    const handleIdentificationId = async (e) => {
        // console.log("e", e.target.value)
        if (e.target.value.length === 13) {
            //console.log("yes 13");
            await handleIdCard(e.target.value)
        } else {
            setImage1([{ ...images1[0], identificationId: e.target.value }])
            //console.log("no 13");
        }
    }

    let address = {
        customerId: 0,
        addressId: 0,
        houseNumber: '',
        houseRegistrationNumber: '',
        village: '',
        zipCode: '',
        soi: '',
        road: '',
        subdistrict: '',
        district: '',
        province: '',
        addressStatus: true,
        addressOnIdcard: true,
        workAddress: false,
        otherAddress: false,
        currentAddress: false,
        sendDocuments: false,
        houseRegistration: false
    }

    const splitAddress = async (value) => {
        console.log('value', value);

        let updatedCount = {}

        let part = value.address.split(" ")
        if (part[1].includes("หมู่ที่") === true) {
            //setHouseNumber(part[0] + part[1] + part[2])
            updatedCount.houseNumber = part[0] + part[1] + part[2]
        } else {
            //setHouseNumber(part[0])
            updatedCount.houseNumber = part[0]
        }
        //setMoo(part[2])
        //setDataAddress({...dataAddress, moo: part[2]})
        for (let i = 0; i <= part.length; i++) {
            if (part[i]?.includes("ซ.") === true) {
                let s = part[i].split("ซ.")
                //setSoi(s[1])
                updatedCount.soi = s[1]
            }
            if (part[i]?.includes("ถ.") === true) {
                let tanon = part[i].split("ถ.")
                //setRoad(tanon[1])
                updatedCount.road = tanon[1]
            }
            if (part[i]?.includes("ต.") === true) {
                let bn = part[i].split("ต.")
                //setSubdistrict(bn[1])
                updatedCount.subdistrict = bn[1]
            }
            if (part[i]?.includes("อ.") === true) {
                let oo = part[i].split("อ.")
                let oop = oo[1].split(",")
                if (oo[1]?.includes(",") === false) {
                    //setDistrict(oo[1])
                    updatedCount.district = oo[1]
                }
                if (oop[1]?.includes("จ.") === true) {
                    //setDistrict(oop[0])
                    updatedCount.district = oop[0]
                    let oops = oop[1].split("จ.")
                    //setProvince(oops[1])
                    updatedCount.province = oops[1]
                }
            }
            if (part[i]?.includes("จ.") === true) {
                let jh = part[i].split("จ.")
                //setProvince(jh[1])
                updatedCount.province = jh[1]
            }
        }
        if (value === undefined) {
            return address;
        } else {
            return {
                ...address,
                houseNumber: updatedCount.houseNumber,
                houseRegistrationNumber: updatedCount.houseRegistrationNumber,
                village: updatedCount.village,
                zipCode: updatedCount.zipCode,
                soi: updatedCount.soi,
                road: updatedCount.road,
                subdistrict: updatedCount.subdistrict,
                district: updatedCount.district,
                province: updatedCount.province
            };
        }
        // return address
    }
    const loadDataOne = async (data, add) => {
        // console.log("data =", data)
        console.log('add', add);

        const one = { identificationId: data.identificationId }
        //console.log("CUS", data.identificationId)
        if (!data.identificationId) {
            console.log("ไม่มีเลขบัตรคนกู้")
            alert('ไม่สามารถอ่านบัตรผู้ขอกู้ได้')
            // setDataPost()
            // setDataPhone()
            setDataAddress()
            // dispatch(addCareerIncome())
            // localStorage.removeItem('addCareerIncome');
        } else {
            // setIDCustomer(data.identificationId)
            setLoading(true)
            await axios.post(checkCCAP, one)
                .then((res) => {
                    if (res.data) {
                        // console.log('ดึงความสัมพันธ์ออกมาด้วย1',res.data);

                        setDataAddress(res.data.address[0])

                        if (res.data[0].identificationId != dataGuarantor.identificationId) { // เช็คคนค้ำซ้ำกัน
                            // message.info('มีข้อมูลในฐานข้อมูล')
                            if (window.confirm('คุณต้องการใช้ข้อมูลในฐานข้อมูลหรือไม่ ?')) {
                                // Save it!
                                // setDataPost(res.data)
                                // setDataPhone(res.data.phones[0])
                                // console.log('res.data.address[0]',res.data.address[0]);

                                setDataAddress(res.data.address[0])
                                // setDataCareerIncome(res.data.ojs[0])
                                // if (res.data?.ojs[0]) {
                                // dispatch(addCareerIncome(res.data.ojs[0]))
                                // localStorage.setItem('addCareerIncome', JSON.stringify(res.data.ojs[0]))
                                // }
                                if (res.data.expdt == null || res.data.issudt == null) {
                                    form.setFieldsValue(
                                        {
                                            ...res.data,
                                            telp: res?.data?.phones[0]?.telp,
                                            birthdate: dayjs(res?.data?.birthdate),
                                            issudt: '',
                                            expdt: '',
                                        }
                                    )
                                    setLoading(false)
                                }
                                else {
                                    form.setFieldsValue(
                                        {
                                            ...res.data,
                                            telp: res?.data?.phones[0]?.telp,
                                            birthdate: dayjs(res?.data?.birthdate),
                                            issudt: dayjs(res?.data?.issudt),
                                            expdt: dayjs(res?.data?.expdt),
                                        }
                                    )
                                    setLoading(false)
                                }
                            } else {
                                // Do nothing!
                                // console.log("dataAddress", data)
                                // setDataPost({ ...data, customerId: res.data.customerId })
                                // if (res.data.phones) {
                                // setDataPhone({ ...dataPhone, customerId: res.data.customerId, phoneId: res.data.phones[0].phoneId })
                                // }
                                // setDataCareerIncome()
                                // setDataAddress({ ...dataAddress, customerId: res.data.customerId, addressId: res.data.address[0].addressId })
                                if (data.expdt == null || data.issudt == null) {
                                    form.setFieldsValue(
                                        {
                                            ...data,
                                            nickname: '',
                                            gender: '',
                                            birthdate: dayjs(data?.birthdate),
                                            issudt: '',
                                            expdt: '',
                                            telp: '',
                                        }
                                    )
                                    setLoading(false)
                                }
                                else {
                                    form.setFieldsValue(
                                        {
                                            ...data,
                                            nickname: '',
                                            gender: '',
                                            birthdate: dayjs(data?.birthdate),
                                            issudt: dayjs(data?.issudt),
                                            expdt: dayjs(data?.expdt),
                                            telp: '',
                                        }
                                    )
                                }
                            }
                            setLoading(false)
                        }
                    }
                    else {
                        alert('ไม่พบข้อมูลในฐานข้อมูล')
                        // message.info('ไม่พบข้อมูลในฐานข้อมูล')
                        // setDataPost({ ...data, customerId: 0 })
                        // splitAddress(...data.address)
                        // setDataAddress({ ...data.address })
                        setDataAddress(add)
                        // setDataAddress(res.data.address[0])

                        if (data.expdt == null || data.issudt == null) {
                            form.setFieldsValue(
                                {
                                    ...data,
                                    nickname: '',
                                    gender: '',
                                    birthdate: dayjs(data?.birthdate) || "",
                                    issudt: '',
                                    expdt: '',
                                    telp: '',
                                }
                            )
                            setLoading(false)
                        }
                        else {
                            form.setFieldsValue(
                                {
                                    ...data,
                                    nickname: '',
                                    gender: '',
                                    birthdate: dayjs(data?.birthdate) || "",
                                    issudt: '',
                                    expdt: '',
                                    telp: '',
                                }
                            )
                        }
                        setLoading(false)
                    }
                })
                .catch((err) => console.log(err))
            setLoading(false)
        }
    }

    // console.log('dataAddress', dataAddress)
    // console.log('dataGuarantor', dataGuarantor);


    const handleFirstname = (e) => {
        setImage1([{ ...images1[0], firstname: e.target.value }])
    }

    const handleLastname = (e) => {
        setImage1([{ ...images1[0], lastname: e.target.value }])
    }

    const handleNickname = (e) => {
        setImage1([{ ...images1[0], nickname: e.target.value }])
    }
    const handleIssudt = (e) => {
        const value = e.target.value;
        setImage1([{ ...images1[0], issudt: value }])
    }
    const handleExpdt = (e) => {
        const value = e.target.value;
        setImage1([{ ...images1[0], expdt: value }])
    }
    const handleDescription = (value) => {
        // const value = e.target.value;
        setImage1([{ ...images1[0], description: value }])
    }

    const genGuarantors = ({ item, index }) => {
        // console.log("YY", item, index)
        // const handleEdit = (dataedit, index1) => {
        //     console.log("GG", dataedit, index1)
        //     setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
        //     setDataEdit({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
        //     setModalEditGuarantor(true);
        // }
        // const handleDelete = (dataedit, index) => {
        //     setLoading(true)
        //     setTimeout(() => {
        //         const deletearray = [...guarantors] // กระจายข้อมูลเข้าไปในตัวแปร deletearray
        //         deletearray.splice(index, 1); // เป็นการลบข้อมูลใน Array ตาม index ที่เลือกตาม Card
        //         // dispatch(addGuarantor(deletearray))
        //         // setGuarantors(deletearray);  // เซตค่าเข้าไปเก็บใน dataArray
        //         // message.success('ลบข้อมูลคนค้ำสำเร็จ')
        //     }, 1500)
        //     setLoading(false)
        // }

        return (
            <Form
                name="basic"
                labelCol={{
                    span: 12,
                }}
                wrapperCol={{
                    span: 24,
                }}
                style={{
                    maxWidth: '100%',
                }}
                initialValues={{
                    remember: true,
                }}
                form={form}
                // onFinish={handleSubmit}
                autoComplete="off"
            >
                <Card style={{ width: '100%' }}
                >
                    <Row justify={'center'}><b><u>คนค้ำที่ {index + 1}</u></b></Row>
                    <div style={{ textAlign: 'center' }}>
                        <Form.Item style={{ margin: 3 }} label='เลขบัตรประชาชน' name='identificationId'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input !',
                                },]}>
                            {/* <span>เลขบัตรประชาชน : </span> */}
                            <Input
                                //disabled
                                disabled={CheckIdCard ? false : true}
                                style={{ color: 'black' }}
                                type='text'
                                name='identificationId'
                                maxLength={13}
                                onChange={(e) => { handleIdentificationId(e) }}
                            >
                            </Input>
                            {/* <b>{item?.identificationId}</b> */}
                        </Form.Item>
                    </div>
                    <Row justify={'center'} className='main2'>
                        <aside style={{ width: '100%', }} >
                            <div style={{ textAlign: 'left' }}>
                                <Form.Item style={{ margin: 3 }} label='ชื่อ' name='firstname'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    {/* <span>ชื่อ : </span> */}
                                    <Input
                                        // disabled
                                        style={{ color: 'black' }}
                                        type='text'
                                        name='firstname'
                                        onChange={(e) => { handleFirstname(e) }}
                                    >
                                    </Input>
                                    {/* <b>{item?.firstname}</b> */}
                                </Form.Item>
                            </div>
                            <div style={{ textAlign: 'left' }}>
                                <Form.Item style={{ margin: 3 }} label='นามสกุล' name='lastname'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    {/* <span>นามสกุล : </span> */}
                                    <Input
                                        // disabled
                                        style={{ color: 'black' }}
                                        type='text'
                                        name='lastname'
                                        onChange={(e) => { handleLastname(e) }}
                                    >
                                    </Input>
                                    {/* <b>{item?.lastname}</b> */}
                                </Form.Item>
                            </div>
                            <div style={{ textAlign: 'left' }}>
                                <Form.Item style={{ margin: 3 }} label='ชื่อเล่น' name='nickname'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    {/* <span>ชื่อเล่น : </span> */}
                                    <Input
                                        type='text'
                                        name='nickname'
                                        // disabled
                                        style={{ color: 'black' }}
                                        onChange={(e) => { handleNickname(e) }}
                                    >
                                    </Input>
                                    {/* <b>{item?.nickname}</b> */}
                                </Form.Item>
                            </div>
                            <div style={{ textAlign: 'left' }}>
                                {item?.phones?.length > 0 ?
                                    <>
                                        <Form.Item
                                            label='เบอร์โทร'
                                            style={{ margin: 3 }}
                                            name='telp'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !',
                                                },]}
                                        // name='phones'
                                        >
                                            {/* <span>เบอร์โทร : </span> */}
                                            {/* <Input
                                                type='text'
                                                name='telp'
                                                disabled
                                                style={{ color: 'black' }}
                                            >
                                            </Input> */}
                                            <Input
                                                type='text'
                                                name='telp'
                                                // defaultValue={item?.phones[0]?.telp}
                                                // disabled
                                                style={{ color: 'black' }}
                                                onChange={(e) => { handlePhone(e) }}></Input>
                                            {/* <b>{item?.phones[0]?.telp}</b> */}
                                        </Form.Item>
                                    </>
                                    :
                                    <Form.Item
                                        label='เบอร์โทร'
                                        style={{ margin: 0 }}
                                        // name='phones'
                                        name='telp'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input !',
                                            },]}
                                    >
                                        {/* <span>เบอร์โทร : </span> */}
                                        {/* <Input
                                            type='text'
                                            name='telp'
                                            disabled
                                            style={{ color: 'black' }}
                                        >
                                        </Input> */}
                                        <Input
                                            type='text'
                                            name='telp'
                                            // disabled
                                            style={{ color: 'black' }}
                                            onChange={(e) => { handlePhone(e) }}></Input>
                                        {/* <b>{item?.phones?.telp}</b> */}
                                    </Form.Item>
                                }
                            </div>
                            <div>
                                <Form.Item label='วันออกบัตร' name='issudt' style={{ margin: 3 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    {/* <Input style={{ width: '100%', color: 'black' }}
                                        type='text'
                                        name='issudt'
                                        onChange={handleIssudt}
                                    /> */}
                                    <DatePicker format={'YYYY-MM-DD'}
                                        style={{ height: '40px', width: '100%', color: 'black' }}
                                        onChange={(e) => {
                                            if (e) {
                                                handleIssudt(e)
                                            }
                                        }} />
                                </Form.Item>

                            </div>
                            <div>
                                <Form.Item label='วันบัตรหมดอายุ' name='expdt' style={{ margin: 3 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    {/* <Input style={{ width: '100%', color: 'black' }}
                                        type='text'
                                        name='expdt'
                                        onChange={handleExpdt}
                                    /> */}
                                    <DatePicker format={'YYYY-MM-DD'}
                                        style={{ height: '40px', width: '100%', color: 'black' }}
                                        onChange={(e) => {
                                            if (e) {
                                                handleExpdt(e)
                                            }
                                        }} />
                                </Form.Item>
                            </div>
                            <div>
                                <Form.Item label='ความสัมพันธ์กับผู้กู้' name='description' style={{ margin: 3 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Select style={{ width: '100%', color: 'black' }}
                                        type='text'
                                        name='description'
                                        options={relationship}
                                        onChange={handleDescription}
                                    />
                                </Form.Item>
                            </div>
                        </aside>
                    </Row>
                </Card>
            </Form>
        )
    }

    return (
        <>
            <Modal
                open={open}
                onCancel={handleCancel}
                width={1000}
                footer={[null]}
            >
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form
                        name="basic"
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            width: '100%',
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        form={form1}
                        onFinish={handleSubmit}
                        autoComplete="off"
                    >
                        <Row justify={'center'}><b><u>เพิ่มข้อมูลคนค้ำ</u></b></Row>
                        <Divider style={{ margin: 5 }} />
                        <Row justify={'center'} className='main2'>
                            <Col span={22}>
                                <aside>
                                    <b>
                                        <div>
                                            <Row justify={'center'}>
                                                {guarantors1?.length > 0 ?
                                                    <>
                                                        {guarantors1?.map((item, index) => {
                                                            return genGuarantors({ item, index, key: `{item.identificationId} - ${index}` });
                                                        })}
                                                    </>
                                                    :
                                                    null
                                                }
                                            </Row>
                                            <Divider />
                                            {
                                                guarantors1?.length >= 1 ?
                                                    <>
                                                        {fileListGuarantor1?.imageListGuarantor?.map((item, index) => {
                                                            // console.log("item", item)
                                                            return (
                                                                <>
                                                                    <Row justify={'left'} > <span><b>รูปเอกสารผู้ค้ำ :</b></span></Row>
                                                                    <Row justify={'center'}>
                                                                        <Col span={24} style={{ textAlign: 'center' }}>
                                                                            {/*กรณีโชว์รูปโดยที่ยังไม่เข้าฐานข้อมูล */}
                                                                            <img width={'300px'} key={index} style={{ margin: 5 }} src={createImageUrl(item.image64)} alt={item.filename} />
                                                                            {/*กรณีโชว์รูปที่เข้าฐานข้อมูลแล้ว */}
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )
                                                        })}
                                                    </>
                                                    :
                                                    <Form.Item
                                                        label={<u>รูปบัตรประชาชนคนค้ำ ( 1 รูป)</u>}
                                                        name='imgGuarantor'
                                                    // style={{ textAlign: 'center' }}
                                                    >
                                                        <Upload
                                                            // multiple={true}
                                                            style={{ textAlign: 'center' }}
                                                            listType="picture-card"
                                                            fileList={fileListGuarantor}
                                                            onPreview={handlePreview}
                                                            onChange={handleChangeGuarantor1}
                                                            // onChange={handleChangeGuarantor}
                                                            beforeUpload={() => false}
                                                        >
                                                            {fileListGuarantor?.length >= 1 ? null : (
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div className="ant-upload-text">upload</div>
                                                                </div>
                                                            )}
                                                        </Upload>
                                                        <Modal open={previewVisible} onCancel={handleCancel1}>
                                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                        </Modal>
                                                    </Form.Item>
                                            }
                                        </div>
                                        <Divider />
                                        <div>
                                            <Row justify={'left'} > <span><b>รูปเอกสารผู้ค้ำ :</b></span></Row>
                                            <Row justify={'center'}>
                                                <Form.Item
                                                    label=''
                                                    name='imgGuarantor'
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    <Upload
                                                        multiple={true}
                                                        listType="picture-card"
                                                        fileList={fileListGuarantors}
                                                        onPreview={handlePreview}
                                                        onChange={handleChangeGuarantors1}
                                                        // onChange={handleChangeGuarantor}
                                                        beforeUpload={() => false}
                                                    >
                                                        {fileListGuarantors?.length >= 10 ? null : (
                                                            <div>
                                                                <PlusOutlined />
                                                                <div className="ant-upload-text">upload</div>
                                                            </div>
                                                        )}
                                                    </Upload>
                                                    <Modal open={previewVisible} onCancel={handleCancel1}>
                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </Form.Item>
                                            </Row>
                                        </div>
                                    </b>
                                </aside>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Space>
                                    <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                                    {/* <Button type="primary" htmlType="submit" style={{ background: "green" }} >บันทึก</Button> */}
                                    <Button type="primary" onClick={handleSubmit} style={{ background: "green" }} >บันทึก</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </>
    )
}

export default AddGuarantoesNew