import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Button, Divider, Space, Spin, Card, Modal, notification, Tag, Image } from 'antd'
import axios from 'axios'
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createReLandMk, createReMk, getImagess, newOffercaseReCar, newOffercaseReLand } from '../../file_mid/all_api';

export default function DetailContno({ allData, onCancel, changepage, dataType, numberRe, data, dataLand, beeypupLand, dataOld, againMode }) {

    const currentTime = dayjs();
    const navigate = useNavigate()
    const formattedTime = currentTime.format('DD-MM-YYYY');
    const currentTimeSeven = dayjs().add(7, 'day')
    const formattedTimeSeven = currentTimeSeven.format('DD-MM-YYYY');
    const { confirm } = Modal
    const [form] = Form.useForm();
    const addCars = localStorage.getItem('addCar')
    const addLands = localStorage.getItem('addLand')
    const addOccupation = localStorage.getItem('addOccupation')
    const branch = localStorage.getItem('branch')
    const username = localStorage.getItem('username')
    const dataAddCars = JSON.parse(addCars)
    const dataAddLands = JSON.parse(addLands)
    const dataOccupation = JSON.parse(addOccupation)
    // const dataBranch = JSON.parse(branch)
    // const dataUsername = JSON.parse(username)
    const [loading, setLoading] = useState(false)
    const [showCustmast, setShowCustmast] = useState()
    const [showBu, setShowBu] = useState()
    const [showProduct] = useState(dataAddCars)
    const [re1] = useState(numberRe)
    // const [re2, setRe2] = useState(allData?.re2)
    // const [dataProductType, setDataProductType] = useState()
    // const [dataProductLoanType, setDataProductLoanType] = useState()
    const [showImg64, setShowImg64] = useState([]) // โชว์รูป
    const [api, contextHolder] = notification.useNotification();
    const { imgs } = useSelector((state) => ({ ...state }))
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (data.PRODUCTS === "car" || data.DATA_TYPE === "rpsl") {
            setShowCustmast(allData?.custmast[0])
            setShowBu(allData?.bu1[0])
        } else {
            setShowCustmast(allData[0])
            setShowBu(dataLand)
        }
    }, [])

    console.log("data", data)
    console.log("showBu", showBu)

    useEffect(() => {
        if (imgs.data) {
            setShowImg64(imgs.data)
        }
    }, [])

    function createImageUrl(imageData) {
        return imageData.image64;
    }

    const handleSubmit = async () => {
        if (data?.DATA_TYPE && data?.DATA_TYPE !== "") {
            setLoading(true)
            let mixValue
            if (data.PRODUCTS === "car" || data.DATA_TYPE === "rpsl") {
                if (againMode) {
                    mixValue = {
                        identificationId: showCustmast?.CUSCOD,
                        snam: showCustmast?.SNAM,
                        firstname: showCustmast?.NAME1,
                        lastname: showCustmast?.NAME2,
                        nickname: showCustmast?.NICKNM,
                        birthdate: showCustmast?.BIRTHDT,
                        issudt: showCustmast?.ISSUDT,
                        expdt: showCustmast?.EXPDT,
                        GRDCOD: showCustmast?.GRDCOD,
                        // socials: [],
                        // emails: [],
                        // address: [],
                        phones: [
                            {
                                phoneType: "เบอร์ติดต่อ",
                                telp: showCustmast?.TELP,
                                sendSms: "1"
                            }
                        ],
                        cars: {
                            ...dataAddCars,
                            carChassisNumber: showCustmast?.STRNO,// STRNO เลขคัสซี
                            carEngineNumber: showCustmast?.ENGNO, // ENGNO เลขเครื่อง
                            carColor: showCustmast?.COLOR,
                            ISSUNO: showCustmast?.CONTNO,
                            carProvince: showCustmast?.DORECV,
                            carPlateNumber: showCustmast?.REGNO,
                            idModel: showCustmast?.MODEL,
                            carLoanDetailsRe: {
                                reLoanId: dataAddCars?.reLoanId,
                                productType: dataAddCars?.productType,
                                productLoanType: dataAddCars?.productLoanType,
                                DAMT: showBu?.DAMT,
                                tcshprc: showBu?.tcshprc,
                                balanc: showBu?.balanc,
                                totalpayment: showBu?.totalpayment,
                                neekong: showBu?.neekong,
                                beeypup: showBu?.beeypup,
                                OTHR: showBu?.OTHR,
                                NOPAY: showBu?.NOPAY,
                                nopays: showBu?.nopay,
                                reqNo: showBu?.req_no,
                                proposalBy: username,
                                branch: branch,
                                GRDCOD: showCustmast?.GRDCOD,
                                re1: re1,
                                reviewedBy: showCustmast?.CHECKER,
                                dataType: data?.DATA_TYPE,
                            },
                            img: imgs.data
                        },
                        ojs: dataOccupation?.ojs,
                        income: dataOccupation?.income,
                        expenses: dataOccupation?.expenses,
                    }
                    console.log("mixValueCarAG", mixValue)
                    await axios.post(newOffercaseReCar, mixValue)
                        .then((res) => {
                            if (res.status === 200) {
                                sendSuccess("top")
                                setTimeout(() => {
                                    setLoading(false)
                                    onCancel(false);
                                    navigate('/offercase-re')
                                }, 2000)
                            }
                        }).catch((err) => {
                            sendError("top")
                            console.log(err)
                            setLoading(false)
                        })
                } else {
                    mixValue = {
                        identificationId: showCustmast?.CUSCOD,
                        snam: showCustmast?.SNAM,
                        firstname: showCustmast?.NAME1,
                        lastname: showCustmast?.NAME2,
                        nickname: showCustmast?.NICKNM,
                        birthdate: showCustmast?.BIRTHDT,
                        issudt: showCustmast?.ISSUDT,
                        expdt: showCustmast?.EXPDT,
                        GRDCOD: showCustmast?.GRDCOD,
                        // socials: [],
                        // emails: [],
                        // address: [],
                        phones: [
                            {
                                phoneType: "เบอร์ติดต่อ",
                                telp: showCustmast?.TELP,
                                sendSms: "1"
                            }
                        ],
                        cars: {
                            ...dataAddCars,
                            carChassisNumber: showCustmast?.STRNO,// STRNO เลขคัสซี
                            carEngineNumber: showCustmast?.ENGNO, // ENGNO เลขเครื่อง
                            carColor: showCustmast?.COLOR,
                            ISSUNO: showCustmast?.CONTNO,
                            carProvince: showCustmast?.DORECV,
                            carPlateNumber: showCustmast?.REGNO,
                            carLoanDetailsRe: {
                                productType: dataAddCars?.productType,
                                productLoanType: dataAddCars?.productLoanType,
                                DAMT: showBu?.DAMT,
                                tcshprc: showBu?.tcshprc,
                                balanc: showBu?.balanc,
                                totalpayment: showBu?.totalpayment,
                                neekong: showBu?.neekong,
                                beeypup: showBu?.beeypup,
                                OTHR: showBu?.OTHR,
                                NOPAY: showBu?.NOPAY,
                                nopays: showBu?.nopay,
                                reqNo: showBu?.req_no,
                                proposalBy: username,
                                branch: branch,
                                GRDCOD: showCustmast?.GRDCOD,
                                re1: re1,
                                reviewedBy: showCustmast?.CHECKER,
                                dataType: data?.DATA_TYPE,
                            },
                            img: imgs.data
                        },
                        ojs: dataOccupation?.ojs,
                        income: dataOccupation?.income,
                        expenses: dataOccupation?.expenses,
                    }
                    console.log("mixValueCar", mixValue)
                    await axios.post(createReMk, mixValue)
                        .then((res) => {
                            if (res.status === 200) {
                                sendSuccess("top")
                                setTimeout(() => {
                                    setLoading(false)
                                    onCancel(false);
                                    navigate('/offercase-re')
                                }, 2000)
                            }
                        }).catch((err) => {
                            sendError("top")
                            console.log(err)
                            setLoading(false)
                        })
                }
            } else {
                if (againMode) {
                    mixValue = {
                        identificationId: showCustmast?.CUSCOD,
                        snam: showCustmast?.SNAM,
                        firstname: showCustmast?.NAME1,
                        lastname: showCustmast?.NAME2,
                        nickname: showCustmast?.NICKNM,
                        birthdate: showCustmast?.BIRTHDT,
                        issudt: showCustmast?.ISSUDT,
                        expdt: showCustmast?.EXPDT,
                        GRDCOD: showCustmast?.GRDCOD,
                        // socials: [],
                        // emails: [],
                        // address: [],
                        phones: [
                            {
                                phoneType: "เบอร์ติดต่อ",
                                telp: showCustmast?.TELP,
                                sendSms: "1"
                            }
                        ],
                        land: {
                            ...dataAddLands,
                            // carChassisNumber: showCustmast?.STRNO,// STRNO เลขคัสซี
                            // carEngineNumber: showCustmast?.ENGNO, // ENGNO เลขเครื่อง
                            // carColor: showCustmast?.COLOR,
                            ISSUNO: showCustmast?.CONTNO,
                            landLoanDetailsRe: {
                                reLoanId: dataAddLands?.reLoanId,
                                productType: "ที่ดิน",
                                productLoanLandType: dataAddLands.productLoanLandType,
                                DAMT: showBu?.DAMT,
                                tcshprc: showBu?.tcshprc,
                                balanc: showBu?.balanc,
                                totalpayment: showBu?.totalpayment,
                                neekong: showBu?.neekong,
                                beeypup: beeypupLand,
                                OTHR: showBu?.OTHR,
                                NOPAY: showBu?.NOPAY,
                                nopays: showBu?.nopays,
                                reqNo: showBu?.reqNo,
                                proposalBy: username,
                                branch: branch,
                                GRDCOD: showCustmast?.GRDCOD,
                                re1: re1,
                                reviewedBy: showCustmast?.CHECKER,
                                dataType: data?.DATA_TYPE,
                            },
                            img: imgs.data
                        },
                        ojs: dataOccupation?.ojs,
                        income: dataOccupation?.income,
                        expenses: dataOccupation?.expenses,
                    }
                    // console.log("mixValueLandAG", mixValue)
                    await axios.post(newOffercaseReLand, mixValue)
                        .then((res) => {
                            if (res.status === 200) {
                                sendSuccess("top")
                                setTimeout(() => {
                                    setLoading(false)
                                    onCancel(false);
                                    navigate('/offercaseland-re')

                                }, 2000)
                            }
                        }).catch((err) => {
                            sendError("top")
                            console.log(err)
                            setLoading(false)
                        })
                } else {
                    mixValue = {
                        identificationId: showCustmast?.CUSCOD,
                        snam: showCustmast?.SNAM,
                        firstname: showCustmast?.NAME1,
                        lastname: showCustmast?.NAME2,
                        nickname: showCustmast?.NICKNM,
                        birthdate: showCustmast?.BIRTHDT,
                        issudt: showCustmast?.ISSUDT,
                        expdt: showCustmast?.EXPDT,
                        GRDCOD: showCustmast?.GRDCOD,
                        // socials: [],
                        // emails: [],
                        // address: [],
                        phones: [
                            {
                                phoneType: "เบอร์ติดต่อ",
                                telp: showCustmast?.TELP,
                                sendSms: "1"
                            }
                        ],
                        land: {
                            ...dataAddLands,
                            // carChassisNumber: showCustmast?.STRNO,// STRNO เลขคัสซี
                            // carEngineNumber: showCustmast?.ENGNO, // ENGNO เลขเครื่อง
                            // carColor: showCustmast?.COLOR,
                            ISSUNO: showCustmast?.CONTNO,
                            landLoanDetailsRe: {
                                productType: "ที่ดิน",
                                productLoanLandType: dataAddLands.productLoanLandType,
                                DAMT: showBu?.DAMT,
                                tcshprc: showBu?.tcshprc,
                                balanc: showBu?.balanc,
                                totalpayment: showBu?.totalpayment,
                                neekong: showBu?.neekong,
                                beeypup: beeypupLand,
                                OTHR: showBu?.OTHR,
                                NOPAY: showBu?.NOPAY,
                                nopays: showBu?.nopays,
                                reqNo: showBu?.reqNo,
                                proposalBy: username,
                                branch: branch,
                                GRDCOD: showCustmast?.GRDCOD,
                                re1: re1,
                                reviewedBy: showCustmast?.CHECKER,
                                dataType: data?.DATA_TYPE,
                            },
                            img: imgs.data
                        },
                        ojs: dataOccupation?.ojs,
                        income: dataOccupation?.income,
                        expenses: dataOccupation?.expenses,
                    }
                    // console.log("mixValueLand", mixValue)
                    await axios.post(createReLandMk, mixValue)
                        .then((res) => {
                            if (res.status === 200) {
                                sendSuccess("top")
                                setTimeout(() => {
                                    setLoading(false)
                                    onCancel(false);
                                    navigate('/offercaseland-re')
                                }, 2000)
                            }
                        }).catch((err) => {
                            sendError("top")
                            console.log(err)
                            setLoading(false)
                        })
                }
            }
        } else {
            console.log("data?.DATA_TYPE",data?.DATA_TYPE)
        }
    };

    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        } else {
            return 0
        }
    }

    const showConfirmCancel = () => {
        confirm({
            title: 'คุณต้องการยกเลิก...?',
            onOk() {
                onCancel(false);
            },
            onCancel() {
            },
        });
    };

    const sendSuccess = (placement) => {
        api.success({
            message: <b>บันทึกสำเร็จ</b>,
            //   description:
            //     'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รอพิจารณาแล้ว',
            placement,
        });
    }

    const sendError = (placement) => {
        api.error({
            message: <b>บันทึกไม่สำเร็จ</b>,
            //   description:
            //     'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รอพิจารณาแล้ว',
            placement,
        });
    }

    const onChangeBack = () => {
        // if (showCustmast?.GRDCOD === "A") {
        if (data.PRODUCTS === "car" || data.DATA_TYPE === "rpsl") {
            changepage('2')
        } else {
            changepage('4')
        }
        // } else {
        //     changepage('2')
        // }
    }

    return (
        <Row justify={'center'}>
            <Card style={{ width: '1100px' }}>
                <Col style={{ textAlign: 'center' }} >
                    <b><h2>รายละเอียดสัญญา</h2></b>
                </Col>
                <Divider style={{ margin: 10 }} />
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form
                        name="basic"
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}

                        initialValues={{
                            remember: true,
                        }}
                        form={form}
                        onFinish={handleSubmit}
                        autoComplete="off"
                    >
                        <div className='main'>
                            <Card style={{ width: '100%' }}>
                                <Col justify={'center'}>
                                    <aside>
                                        <Col style={{ textAlign: 'left' }}>
                                            <b>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>เลขสัญญา : </span>{<b style={{ fontSize: '20px', lineHeight: '32px' }}>{data?.CONTNO}</b>}
                                                </div>
                                            </b>
                                            <b>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>เกรด : </span>{<b style={{ fontSize: '20px', lineHeight: '32px' }}>{data?.GRDCOD}</b>}
                                                </div>
                                            </b>
                                        </Col>
                                        <Col style={{ textAlign: 'right' }}>
                                            <div style={{ margin: 0 }}>
                                                ชื่อการตลาดที่เสนอ : <Tag color="geekblue">{data?.SALCOD}</Tag>
                                            </div>
                                        </Col>
                                    </aside>
                                </Col>
                                <Col justify={'center'}>
                                    <Divider orientation={'left'}><b><u>รายละเอียด</u></b></Divider>
                                    <aside style={{ width: '100%' }}>
                                        <b>
                                            <div style={{ margin: 0, }}>
                                                <span>ตัดสด ณ วันที่ : </span>
                                                <b style={{ fontSize: '18px' }}><u>{formattedTime}</u></b>
                                            </div>
                                        </b>
                                        <div label='เลขที่สัญญา' name='CONTNO' style={{ marginBottom: 3 }}>
                                            <span>เลขที่สัญญา : </span> <b>{showCustmast?.CONTNO}</b>
                                        </div>
                                        <div label='ชื่อ-สกุล' name='snam' style={{ marginBottom: 3 }}>
                                            <span>ชื่อ-สกุล : </span> <b>{showCustmast?.NAME1 + ' ' + showCustmast?.NAME2}</b>
                                        </div>
                                        <div label='เบอร์' name='snam' style={{ marginBottom: 3 }}>
                                            <span>เบอร์ : </span> <b>{showCustmast?.TELP}</b>
                                        </div>
                                        <div label='เกรด' name='snam' style={{ marginBottom: 3 }}>
                                            <span>เกรด : </span> <b> {showCustmast?.GRDCOD}</b>
                                        </div>
                                    </aside>
                                </Col>
                                {
                                    data?.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                                        data?.productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                                        data?.productLoanType === "รี+เปลี่ยนสัญญา" ||
                                        data?.productLoanType === "เปลี่ยนสัญญา" ?
                                        <>
                                            <Divider orientation={'left'}><b><u>ค่าใช้จ่ายที่ลูกค้าต้องจ่าย</u></b></Divider>
                                            <Divider style={{ margin: 3 }} />
                                            <aside style={{ width: '100%', background: '#061178', padding: '8px', color: 'white' }} >
                                                <div name='priceChange' style={{ marginBottom: 3 }}>
                                                    <span><b>ค่าเปลี่ยนสัญญา</b> : </span> <b> {currencyFormatOne(data?.priceChange)}</b>
                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                        บาท
                                                    </span>
                                                </div>
                                                <div name='overdue' style={{ marginBottom: 3 }}>
                                                    <span><b>ยอดค้าง + เบี้ยปรับ</b> : </span> <b> {currencyFormatOne(data?.overdue)}</b>
                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                        บาท
                                                    </span>
                                                </div>
                                                <div name='preChange' style={{ marginBottom: 3 }}>
                                                    <span><b>ค่างวดล่วงหน้า</b> : </span> <b> {currencyFormatOne(data?.preChange)}</b>
                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                        บาท
                                                    </span>
                                                </div><br />
                                                <div>
                                                    <span>ยอดรวม : </span> <b> {currencyFormatOne(~~data?.preChange + ~~data?.overdue + ~~data?.priceChange
                                                    )}</b>
                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                        บาท
                                                    </span>
                                                </div>
                                            </aside>
                                            <Divider style={{ margin: 3 }} />
                                        </>
                                        :
                                        null
                                }
                                <Col justify={'center'}>
                                    <Divider orientation={'left'}><b><u>รายละเอียดสินค้า</u></b></Divider>
                                    <aside style={{ width: '100%' }}>
                                        {
                                            data.PRODUCTS === "car" || data.DATA_TYPE === "rpsl" ?
                                                <>
                                                    <div label='ทะเบียนรถ' name='REGNO' style={{ marginBottom: 3 }}>
                                                        <span>ทะเบียนรถ : </span><b>{showCustmast?.REGNO}</b>
                                                    </div>
                                                    <div label='ยี่ห้อ' name='TYPE' style={{ marginBottom: 3 }}>
                                                        <span>ยี่ห้อ : </span><b>{showProduct?.carBrand}</b>
                                                    </div>
                                                    <div label='รุ่น' name='MODEL' style={{ marginBottom: 3 }}>
                                                        <span>รุ่น : </span><b>{showProduct?.carModel}</b>
                                                    </div>
                                                    <div label='สี' name='COLOR' style={{ marginBottom: 3 }}>
                                                        <span>สี : </span><b>{showCustmast?.COLOR}</b>
                                                    </div>
                                                    <div label='ปี' name='MANUYR' style={{ marginBottom: 3 }}>
                                                        <span>ปี : </span><b>{showProduct?.carYear}</b>
                                                    </div>
                                                    <div label='เลขเครื่อง' name='STRNO' style={{ marginBottom: 3 }}>
                                                        <span>เลขเครื่อง : </span><b>{showCustmast?.STRNO}</b>
                                                    </div>
                                                    <div label='เลขตัวถัง' name='ENGNO' style={{ marginBottom: 3 }}>
                                                        <span>เลขตัวถัง : </span><b>{showCustmast?.ENGNO}</b>
                                                    </div>
                                                    <div label='เจ้าหน้าที่ทำเคส' name='SALCOD' style={{ marginBottom: 3 }}>
                                                        <span>เจ้าหน้าที่ทำเคส : </span><b>{showCustmast?.SALCOD}</b>
                                                    </div>
                                                    <b>
                                                        <div style={{ margin: 0, }}>
                                                            <span>เรทรถ : </span>
                                                            <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatOne(showProduct?.carPrice)}</u></b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </div>
                                                    </b>
                                                </>
                                                :
                                                <>
                                                    {
                                                        dataAddLands?.productTypeLand === 1 ?
                                                            <>
                                                                <div style={{ marginBottom: 3 }}>
                                                                    <span>ประเภทเอกสาร : </span><b>{dataAddLands?.productTypeLand === 1 ? "นส.3 ก." : "นส.4 จ."}</b>
                                                                </div>
                                                                <div style={{ marginBottom: 3 }}>
                                                                    <span>จังหวัด : </span><b>{dataAddLands?.province}</b>
                                                                </div>
                                                                <div style={{ marginBottom: 3 }}>
                                                                    <span>อำเภอ : </span><b>{dataAddLands?.district}</b>
                                                                </div>
                                                                <div style={{ marginBottom: 3 }}>
                                                                    <span>เลขโฉนดที่ดิน : </span><b>{dataAddLands?.numberLand}</b>
                                                                </div>
                                                                {/* <div style={{ marginBottom: 3 }}>
                                                                    <span>ระวาง : </span><b>{dataAddLands?.numberLandLawang}</b>
                                                                </div> */}
                                                            </>
                                                            :
                                                            <>
                                                                <div style={{ marginBottom: 3 }}>
                                                                    <span>ประเภทเอกสาร : </span><b>{dataAddLands?.productTypeLand === 1 ? "นส.3 ก." : "นส.4 จ."}</b>
                                                                </div>
                                                                <div style={{ marginBottom: 3 }}>
                                                                    <span>จังหวัด : </span><b>{dataAddLands?.province}</b>
                                                                </div>
                                                                <div style={{ marginBottom: 3 }}>
                                                                    <span>อำเภอ : </span><b>{dataAddLands?.district}</b>
                                                                </div>
                                                                <div style={{ marginBottom: 3 }}>
                                                                    <span>เลขโฉนดที่ดิน : </span><b>{dataAddLands?.numberLand}</b>
                                                                </div>
                                                                <div style={{ marginBottom: 3 }}>
                                                                    <span>ระวาง : </span><b>{dataAddLands?.numberLandLawang}</b>
                                                                </div>
                                                            </>
                                                    }
                                                </>
                                        }
                                    </aside>
                                </Col>
                                <Col justify={'center'}>
                                    <Divider orientation={'left'}><b><u>ประเภทขอกู้</u></b></Divider>
                                    <b>
                                        <div style={{ margin: 0, }}>
                                            <span>ประเภทขอกู้ : </span>
                                            {
                                                data.PRODUCTS === "car" || data.DATA_TYPE === "rpsl" ?
                                                    <b ><u>{showProduct?.productLoanType}</u></b>
                                                    :
                                                    <b ><u>{dataAddLands?.productLoanLandType}</u></b>
                                            }
                                        </div>
                                        <div style={{ margin: 0, }}>
                                            <span>ใบนี้หมดอายุ ณ วันที่ : </span>
                                            <b style={{ color: 'red', fontSize: '20px' }}><u>{formattedTimeSeven}</u></b>
                                        </div>
                                    </b>

                                </Col>
                                <Col>
                                    {/* <Divider style={{ margin: 5 }} />
                                        <aside style={{ width: '100%' }}>
                                            <div className='text-left' >
                                                <h4><u>รายละเอียดยอดกู้</u></h4>
                                            </div><p />
                                            <n />
                                            <div label='ค่างวด' name='DAMT' style={{ marginBottom: 3 }}>
                                                <span>ค่างวด : </span><b>{showBu?.DAMT ? currencyFormatOne(showBu?.DAMT) + " บาท" : 0}</b>
                                            </div>
                                            <div label='ยอดจัดครั้งที่แล้ว' name='tcshprc' style={{ marginBottom: 3 }}>
                                                <span>ยอดจัดครั้งที่แล้ว : </span><b>{showBu?.tcshprc ? currencyFormatOne(showBu?.tcshprc) + " บาท" : 0}</b>
                                            </div>
                                            <div label='ราคาเช่าซื้อครั้งที่แล้ว' name='balanc' style={{ marginBottom: 3 }}>
                                                <span>ราคาเช่าซื้อครั้งที่แล้ว : </span><b>{showBu?.balanc ? currencyFormatOne(showBu?.balanc) + " บาท" : 0}</b>
                                            </div>
                                            <div label='ยอดที่ชำระมาแล้วทั้งหมด' name='totalpayment' style={{ marginBottom: 3 }}>
                                                <span>ยอดที่ชำระมาแล้วทั้งหมด : </span><b>{showBu?.totalpayment ? currencyFormatOne(showBu?.totalpayment) + " บาท" : 0}</b>
                                            </div>
                                            <div label='ล/น คงเหลือรวม' name='neekong' style={{ marginBottom: 3 }}>
                                                <span>ล/น คงเหลือรวม : </span><b>{showBu?.neekong ? currencyFormatOne(showBu?.neekong) + " บาท" : 0}</b>
                                            </div>
                                            <div label='เบี้ยปรับ' name='beeypup' style={{ marginBottom: 3 }}>
                                                <span>เบี้ยปรับ : </span><b>{showBu?.beeypup ? currencyFormatOne(showBu?.beeypup) + " บาท" : 0}</b>
                                            </div>
                                            <div label='ลูกหนี้อื่นๆ' name='OTHR' style={{ marginBottom: 3 }}>
                                                <span>ลูกหนี้อื่นๆ : </span><b>{showBu?.OTHR ? currencyFormatOne(showBu?.OTHR) + " บาท" : 0}</b>
                                            </div>
                                            <div label='จำนวนงวดที่ผ่อนมาแล้ว' name='NOPAY' style={{ marginBottom: 3 }}>
                                                <span>จำนวนงวดที่ผ่อนมาแล้ว : </span><b>{showBu?.NOPAY ? currencyFormatOne(showBu?.NOPAY) + " งวด" : 0}</b>
                                            </div>
                                            <div label='จำนวนงวดทั้งหมด' name='nopay' style={{ marginBottom: 3 }}>
                                                <span>จำนวนงวดทั้งหมด : </span><b>{showBu?.nopay ? currencyFormatOne(showBu?.nopay) + " งวด" : 0}</b>
                                            </div>
                                            <div label='ตัดสด งวดที่' name='req_no' style={{ marginBottom: 3 }}>
                                                <span>ตัดสด งวดที่ : </span><b>{showBu?.req_no ? currencyFormatOne(showBu?.req_no) : 0}</b>
                                            </div>
                                        </aside>
                                        <Divider style={{ margin: 5 }} />
                                        <aside style={{ width: '100%' }}>
                                            <div className='text-left' >
                                                <h4><u>รายละเอียดจัดใหม่</u></h4>
                                            </div><p />
                                            <n />
                                            <b>
                                                <div style={{ margin: 0, }}>
                                                    <span>ยอดรีไฟแนนซ์เพื่อจัดใหม่ : </span>
                                                    <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatOne(re1)}</u></b>
                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                        บาท
                                                    </span>
                                                </div>
                                            </b>
                                            <b>
                                                <div style={{ margin: 0, }}>
                                                    <span>ยอดจัดใหม่ : </span>
                                                    <b style={{ color: 'red', fontSize: '18px' }}><u>{".........."}</u></b>
                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                        บาท
                                                    </span>
                                                </div>
                                            </b>
                                            <b>
                                                <div style={{ margin: 0, }}>
                                                    <span>จำนวนงวด : </span>
                                                    <b style={{ color: 'red', fontSize: '18px' }}><u>{".........."}</u></b>
                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                        งวด
                                                    </span>
                                                </div>
                                            </b>
                                        </aside> */}
                                    {/* <Divider style={{ margin: 5 }} /> */}
                                    {/* {
                                            showCustmast?.GRDCOD !== "A" ?
                                                <>
                                                    <aside style={{ width: '100%' }}>
                                                        <div className='text-left' >
                                                            <h4><u>อาชึพ</u></h4>
                                                        </div><p />
                                                        <n />
                                                        <div style={{ margin: 0, }}>
                                                            <span>กลุ่มอาชีพ : </span>
                                                            <b>{dataOccupation?.ojs?.occupationalGroupTH}</b>
                                                        </div>
                                                        <div style={{ margin: 0, }}>
                                                            <span>อาชีพ : </span>
                                                            <b>{dataOccupation?.ojs?.governmentPrivateTH}</b>
                                                        </div>
                                                        <div style={{ margin: 0, }}>
                                                            <span>สาขาอาชีพ : </span>
                                                            <b>{dataOccupation?.ojs?.jobPositionTH}</b>
                                                        </div>
                                                        <div style={{ margin: 0, }}>
                                                            <span>ชื่อบริษัท(สถานที่ทำงาน) : </span>
                                                            <b>{dataOccupation?.ojs?.companyName}</b>
                                                        </div>
                                                        <div style={{ margin: 0, }}>
                                                            <span>รายได้ / เดือน : </span>
                                                            <b>{dataOccupation?.income?.incomeMonth}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </div>
                                                        <div style={{ margin: 0, }}>
                                                            <span>รายจ่าย / เดือน : </span>
                                                            <b>{dataOccupation?.expenses?.expensesMonth}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </div>
                                                    </aside>
                                                    <Divider style={{ margin: 5 }} />
                                                </>
                                                : null
                                        } */}
                                </Col>
                                <Col span={24}>
                                    {
                                        data.PRODUCTS === "car" || data.DATA_TYPE === "rpsl" ?
                                            <>
                                                <Divider orientation={'left'}><b><u>รูปประกอบการขออนุมัติ</u></b></Divider>
                                                {showImg64?.map((imageData, index) => {
                                                    if (imageData.type === 14) {
                                                        return (
                                                            <Image width={'150px'} key={index} style={{ margin: 5 }} src={createImageUrl(imageData)} alt={imageData.filename} />
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </>
                                            :
                                            <>
                                                {/* <Divider orientation={'left'}><b><u>รูปโฉนด/หน้า-หลัง</u></b></Divider>
                                                {showImg64?.map((imageData, index) => {
                                                    if (imageData.type === 3) {
                                                        return (
                                                            <Image width={'150px'} key={index} style={{ margin: 5 }} src={createImageUrl(imageData)} alt={imageData.filename} />
                                                        );
                                                    }
                                                    return null;
                                                })}
                                                <Divider orientation={'left'}><b><u>ใบแบ่งใบต่อ</u></b></Divider>
                                                {showImg64?.map((imageData, index) => {
                                                    if (imageData.type === 10) {
                                                        return (
                                                            <Image width={'150px'} key={index} style={{ margin: 5 }} src={createImageUrl(imageData)} alt={imageData.filename} />
                                                        );
                                                    }
                                                    return null;
                                                })}
                                                <Divider orientation={'left'}><b><u>ระวาง</u></b></Divider>
                                                {showImg64?.map((imageData, index) => {
                                                    if (imageData.type === 11) {
                                                        return (
                                                            <Image width={'150px'} key={index} style={{ margin: 5 }} src={createImageUrl(imageData)} alt={imageData.filename} />
                                                        );
                                                    }
                                                    return null;
                                                })} */}
                                                <Divider orientation={'left'}><b><u>รูปประกอบการขออนุมัติ</u></b></Divider>
                                                {showImg64?.map((imageData, index) => {
                                                    if (imageData.type === 14) {
                                                        return (
                                                            <Image width={'150px'} key={index} style={{ margin: 5 }} src={createImageUrl(imageData)} alt={imageData.filename} />
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </>
                                    }
                                    {
                                        againMode ?
                                            <>
                                                <Divider orientation={'left'}><b><u>รูปที่เคยเสนอมาแล้ว</u></b></Divider>
                                                {dataOld?.img?.map((e, index) => {
                                                    if (e.type === 14) {
                                                        return (
                                                            <>
                                                                <Image width={100} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                {/* <Image width={'150px'} key={index} style={{ margin: 5 }} src={createImageUrl(imageData)} alt={imageData.filename} /> */}
                                                            </>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </>
                                            : null
                                    }
                                </Col>
                                <Divider />
                                <Row justify={'center'}>
                                    <Space>
                                        <Button type="text" onClick={onChangeBack} style={{ background: "#bfbfbf", color: 'white' }} >
                                            ย้อนกลับ
                                        </Button>
                                        <Button type="primary" style={{ background: "red" }} onClick={showConfirmCancel}>
                                            ยกเลิก
                                        </Button>
                                        {
                                            loading === false ?
                                                <>
                                                    <Button type="text" htmlType="submit" style={{ background: '#389e0d', color: '#ffffff' }} >
                                                        บันทึก
                                                    </Button>
                                                </>
                                                :
                                                <>
                                                    <Button type='primary' htmlType="submit" disabled style={{ color: 'success' }} >
                                                        บันทึก
                                                    </Button>
                                                </>
                                        }
                                    </Space>
                                </Row>
                            </Card>
                        </div>
                    </Form>
                </Spin>
            </Card>
            {contextHolder}
        </Row >
    )
}
