import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Input, Card, Space, Select, notification, Row, Col, Checkbox } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import TextArea from 'antd/es/input/TextArea';
import { addcausename, approve, approvepre, cancelnote, notpass, reject, showcausename } from '../../../file_mid/all_api';

function Note({ open, close, shootdata, appStatus, setGetNotification, sendback, sendNotetoConclusion, setCheckApprove, valueCheckBox, closeAll, notPassData, handleCancel2 }) {

    const guarantor = useSelector(state => state.guarantor)
    const user = localStorage.getItem('username');
    // const [dataModalEdit, setDataModalEdit] = useState(shootdata);
    const [numForCheck, setNumForCheck] = useState({});
    const [addNote, setAddNote] = useState({ name: '' });
    const [options, setOptions] = useState([]);
    const [cause, setCause] = useState({});
    // const [note, setNote] = useState({});
    const [dis, setDis] = useState(false);
    const [formAdd] = Form.useForm()
    const [checkCause, setCheckCause] = useState(0);
    const [api, contextHolder] = notification.useNotification();
    const [statusAccept, setstatusAccept] = useState({});
    const [disCancel, setDisCancel] = useState(false);
    const [cutProductLoanType, setCutProductLoanType] = useState();
    // const [data, setData] = useState(notPassData.npCustomerNote.toString() + " " + notPassData.npAddressNote.toString());
    const [form] = Form.useForm()

    // console.log("valueCheckBox", valueCheckBox)
    // console.log("appStatus", appStatus)
    // console.log("statusAccept", statusAccept)
    // console.log("notPassData", notPassData)
    // console.log("shootdata", shootdata)
    // console.log("shootdata", shootdata.carLoanDetails.productLoanType)

    useEffect(() => {
        if (guarantor.data.typeLoan === "car") {
            if (appStatus === "ผ่านการวิเคราะห์") {
                setstatusAccept({
                    loanId: shootdata?.carLoanDetails?.loanId,
                    approvalStatus: 19,
                    status: "ผ่านการวิเคราะห์",
                    acceptPassDate: dayjs()
                })
            } else if (appStatus === "ไม่ผ่านการวิเคราะห์") {
                setstatusAccept({
                    loanId: shootdata?.carLoanDetails?.loanId,
                    approvalStatus: 20,
                    status: "ไม่ผ่านการวิเคราะห์",
                })
                form.setFieldsValue({
                    // npCustomerNote: notPassData.npCustomerNote
                    ...notPassData
                })
            } else if (appStatus === "Reject") {
                setstatusAccept({
                    loanId: shootdata?.carLoanDetails?.loanId,
                    approvalStatus: 23,
                    status: "Reject",
                    rejectStatus: true,
                    rejectDate: dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
                })
            } else if (appStatus === "cancel") {
                setstatusAccept({
                    loanId: shootdata?.loanId,
                    approvalStatus: 3,
                    status: "ยกเลิกการคอนเฟิร์ม",
                })
                setCause("ยกเลิกการคอนเฟิร์ม")
                setDisCancel(true)
                form.setFieldsValue(
                    {
                        cause: "ยกเลิกการคอนเฟิร์ม"
                    }
                )
            } else if (appStatus === "ผ่านการวิเคราะห์ (Pre)") {
                var PreAaproveSplit = []
                PreAaproveSplit = shootdata?.carLoanDetails?.productLoanType.split("Pre-Aaprove-")
                console.log("PreAaproveSplit", PreAaproveSplit[1])
                setCutProductLoanType(PreAaproveSplit[1])
                setstatusAccept({
                    loanId: shootdata?.carLoanDetails?.loanId,
                    approvalStatus: 3,
                    status: "อนุมัติ",
                    acceptPassDate: dayjs(),
                })
            }
            form.setFieldsValue(
                {
                    approvedLoanTerm: shootdata?.carLoanDetails?.loanTerm,
                }
            )
        } else if (guarantor.data.typeLoan === "land") {
            if (appStatus === "ผ่านการวิเคราะห์") {
                setstatusAccept({
                    loanId: shootdata?.landLoanDetails?.landLoanId,
                    approvalStatus: 19,
                    status: "ผ่านการวิเคราะห์",
                    acceptPassDate: dayjs()
                })
            } else if (appStatus === "ไม่ผ่านการวิเคราะห์") {
                setstatusAccept({
                    loanId: shootdata?.landLoanDetails?.landLoanId,
                    approvalStatus: 20,
                    status: "ไม่ผ่านการวิเคราะห์",
                    // approvedBy: user

                })
                form.setFieldsValue({
                    ...notPassData
                })
            } else if (appStatus === "Reject") {
                setstatusAccept({
                    loanId: shootdata?.landLoanDetails?.landLoanId,
                    approvalStatus: 23,
                    status: "Reject",
                    rejectStatus: true,
                    rejectDate: dayjs().format('YYYY-MM-DDTHH:mm:ssZ')

                })
            } else if (appStatus === "cancel") {
                setstatusAccept({
                    loanId: shootdata?.loanId,
                    approvalStatus: 3,
                    status: "ยกเลิกการคอนเฟิร์ม",
                })
                setCause("ยกเลิกการคอนเฟิร์ม")
                setDisCancel(true)
                form.setFieldsValue(
                    {
                        cause: "ยกเลิกการคอนเฟิร์ม"
                    }
                )
            } else if (appStatus === "ผ่านการวิเคราะห์ (Pre)") {
                var PreAaproveSplit = []
                PreAaproveSplit = shootdata?.landLoanDetails?.productLoanLandType?.split("Pre-Aaprove-")
                console.log("PreAaproveSplit", PreAaproveSplit[1])
                setCutProductLoanType(PreAaproveSplit[1])
                setstatusAccept({
                    loanId: shootdata?.landLoanDetails?.landLoanId,
                    approvalStatus: 3,
                    status: "อนุมัติ",
                    acceptPassDate: dayjs(),
                })
            }
            form.setFieldsValue(
                {
                    approvedLoanTerm: shootdata?.landLoanDetails?.loanLandTerm,
                }
            )
        }
    }, [shootdata])

    useEffect(() => {
        loadCauseName();
    }, [numForCheck])

    const handleCancel = () => {
        close(false);
    };

    const handleChange = (value) => {
        // console.log("value", value)
        setCause(`${value}`)
    }

    const addSuccess = (placement) => {
        api.success({
            message: "เพิ่มหมายเหตุแล้ว",
            description:
                'หมายเหตุที่ถูกเพิ่ม สามารถเลือกได้ในช่อง หมายเหตุ',
            placement,
        });
    };

    const handleOk = async () => {
        // setDis(true);
        if (appStatus === "Reject") {
            var rejectData = { ...guarantor.data, cause, ...statusAccept, checkGua: valueCheckBox, noteBy: user, rejectStatus: true, noteDate: dayjs().format('YYYY-MM-DDTHH:mm:ssZ') }
            sendReject(rejectData)
            // console.log("rejectData", rejectData)
        } else if (appStatus === "ผ่านการวิเคราะห์") {
            var NoteData = { ...guarantor.data, cause, ...statusAccept, checkGua: valueCheckBox, noteBy: user, rejectStatus: false, noteDate: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'), checkNote: "0" }
            sendNote(NoteData)
            // console.log("NoteData", NoteData)
        } else if (appStatus === "ไม่ผ่านการวิเคราะห์") {
            var NoteData = { ...guarantor.data, cause, ...statusAccept, checkGua: valueCheckBox, noteBy: user, rejectStatus: false, noteDate: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'), checkNote: "0", np: notPassData }
            sendNote(NoteData)
            // console.log("NoteData", NoteData)
        } else if (appStatus === "cancel") {
            var CancelData = { ...guarantor.data, cause, ...statusAccept, checkGua: null, noteBy: user, rejectStatus: false, noteDate: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'), checkNote: "0" }
            cancelNote(CancelData)
            // console.log("CancelData", CancelData)
        } else if (appStatus === "ผ่านการวิเคราะห์ (Pre)") {
            var ApprovePreData = { ...guarantor.data, cause, ...statusAccept, productLoanType: cutProductLoanType, checkGua: valueCheckBox, noteBy: user, rejectStatus: false, noteDate: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'), checkNote: "0" }
            ApprovePreNote(ApprovePreData)
            // console.log("ผ่านการวิเคราะห์ (Pre)", ApprovePreData)
        }
    };

    const sendReject = async (value) => {
        console.log("value", value)
        await axios.post(reject, value)
            .then(res => {
                if (res.status === 200) {
                    if (value.approvalStatus === 23) {
                        setGetNotification(3)
                    }
                    sendback(dayjs())
                    setTimeout(() => {
                        close(false)
                    }, 1000);
                }
            }).catch((err) => console.log(err))
    };

    const sendNote = async (value) => {
        console.log("value", value)
        await axios.put(approve, value)
            .then(res => {
                if (res.status === 200) {
                    if (value.approvalStatus === 20) {
                        handleUnApprove(value)
                    } else
                        // {
                        if (value.approvalStatus === 19) {
                            setGetNotification(1)
                            setCheckApprove(1)
                        } else {
                            setGetNotification(2)
                        }
                    sendback(dayjs())
                    setTimeout(() => {
                        close(false);
                    }, 1000);
                    // }
                }
            }).catch((err) => console.log(err))
    };

    const cancelNote = async (value) => {
        console.log("value", value)
        await axios.put(cancelnote, value)
            .then(res => {
                if (res.status === 200) {
                    CancelSuccess('top')
                    setTimeout(() => {
                        closeAll(false);
                    }, 1000);
                }
            }).catch((err) => console.log(err))
    };
    const ApprovePreNote = async (value) => {
        console.log("value", value)
        await axios.put(approvepre, value)
            .then(res => {
                if (res.status === 200) {
                    ApprovePreSuccess('top')
                    setTimeout(() => {
                        // close2(false)
                        handleCancel2()
                        // close(false);
                    }, 1000);
                }
            }).catch((err) => console.log(err))
    };

    const loadCauseName = async () => {
        await axios.get(showcausename)
            .then(res => {
                if (res.status === 200) {
                    const mapselect = res.data.map((item, index) => ({
                        ...item,
                        key: index,
                    }))
                    // console.log("res.data", res.data)
                    // console.log("mapselect", mapselect)
                    setOptions(mapselect)
                }
            }).catch((err) => console.log(err))
    };

    const handleAddCause = async () => {
        if (addNote.name !== '') {
            await axios.post(addcausename, addNote)
                .then(res => {
                    if (res.status === 200) {
                        setNumForCheck(dayjs())
                        formAdd.resetFields();
                        setCheckCause(0);
                        addSuccess("top")
                        setDis(false);
                    }
                }).catch((err) => console.log(err))
        }
    };

    const handleUnApprove = async (value) => {
        await axios.post(notpass, { ...notPassData, loanId: statusAccept.loanId, typeLoan: guarantor.data.typeLoan, npDate: dayjs() })
            .then(res => {
                if (res.status === 200) {
                    // console.log("TEST", res.data);
                    if (value.approvalStatus === 20) {
                        setGetNotification(2)
                    }
                    sendback(dayjs())
                    setTimeout(() => {
                        close(false);
                    }, 1000);
                }

            }).catch((err) => console.log(err))

    };

    const BTNAddCause = () => {
        if (checkCause === 0) {
            setCheckCause(1);
            setDis(true);
        } else {
            setCheckCause(0);
            setDis(false);
        }
    };

    const CancelSuccess = (placement) => {
        api.success({
            message: "ยกเลิกการคอนเฟิร์ม",
            placement,
        });
    };
    const ApprovePreSuccess = (placement) => {
        api.success({
            message: "อนุมัติ",
            placement,
        });
    };

    // const onChange = (e) => {
    //     console.log(`checked = ${e.target.checked}`);
    // };

    return (
        <div>
            <Modal title="กรุณายืนยันข้อมูล" open={open} onCancel={handleCancel} footer={[null]} width={800}>
                <Card>
                    <Form
                        onFinish={handleOk}
                        form={form}
                        labelCol={{
                            span: 6,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}>
                        <Row>
                            <Col span={24}>
                                <Form.Item>
                                    <b><u>{appStatus}</u></b>
                                </Form.Item>
                            </Col>
                            <Col span={22}>
                                {
                                    appStatus === "ไม่ผ่านการวิเคราะห์" ?
                                        <>
                                            <Form.Item label="หัวข้อที่ไม่ผ่านการวิเคราะห์">
                                                <Checkbox checked={notPassData?.npCustomer === false ? true : false} >ข้อมูลผู้ขอกู้</Checkbox>
                                                {/* <Checkbox checked={notPassData?.npAddress === false ? true : false} >ข้อมูลที่อยู่</Checkbox> */}
                                                <Checkbox checked={notPassData?.npOldLoan === false ? true : false} >ข้อมูลย้ายไฟแนนซ์</Checkbox>
                                                <Checkbox checked={notPassData?.npLoan === false ? true : false} >ข้อมูลขอกู้</Checkbox>
                                                <Checkbox checked={notPassData?.npProduct === false ? true : false} >ข้อมูลสินค้า</Checkbox>
                                                <Checkbox checked={notPassData?.npGuarantor === false ? true : false} >ข้อมูลผู้ค้ำ</Checkbox>
                                                <Checkbox checked={notPassData?.npCareer === false ? true : false} >อาชีพ - รายได้</Checkbox>
                                            </Form.Item>
                                            {
                                                notPassData?.npCustomer === false ?
                                                    <Form.Item
                                                        label="สาเหตุข้อมูลผู้ขอกู้"
                                                        name="npCustomerNote"
                                                    >
                                                        <Input name='npCustomerNote' disabled />
                                                    </Form.Item>
                                                    : null
                                            }
                                            {/* {
                                                notPassData?.npAddress === false ?
                                                    <Form.Item
                                                        label="สาเหตุข้อมูลที่อยู่"
                                                        name="npAddressNote"
                                                    >
                                                        <Input name='npAddressNote' disabled />
                                                    </Form.Item>
                                                    : null
                                            } */}
                                            {
                                                notPassData?.npOldLoan === false ?
                                                    <Form.Item
                                                        label="สาเหตุข้อมูลย้ายไฟแนนซ์"
                                                        name="npOldLoanNote"
                                                    >
                                                        <Input name='npOldLoanNote' disabled />
                                                    </Form.Item>
                                                    : null
                                            }
                                            {
                                                notPassData?.npLoan === false ?
                                                    <Form.Item
                                                        label="สาเหตุข้อมูลขอกู้"
                                                        name="npLoanNote"
                                                    >
                                                        <Input name='npLoanNote' disabled />
                                                    </Form.Item>
                                                    : null
                                            }
                                            {
                                                notPassData?.npProduct === false ?
                                                    <Form.Item
                                                        label="สาเหตุข้อมูลสินค้า"
                                                        name="npProductNote"
                                                    >
                                                        <Input name='npProductNote' disabled />
                                                    </Form.Item>
                                                    : null
                                            }
                                            {
                                                notPassData?.npCareer === false ?
                                                    <Form.Item
                                                        label="สาเหตุอาชีพ - รายได้"
                                                        name="npCareerNote"
                                                    >
                                                        <Input name='npCareerNote' disabled />
                                                    </Form.Item>
                                                    : null
                                            }
                                            {
                                                notPassData?.npGuarantor === false ?
                                                    <Form.Item
                                                        label="สาเหตุข้อมูลผู้ค้ำ"
                                                        name="npGuarantorNote"
                                                    >
                                                        <Input name='npGuarantorNote' disabled />
                                                    </Form.Item>
                                                    : null
                                            }
                                        </>
                                        : null
                                }


                                <Form.Item
                                    label="สาเหตุ"
                                    name="cause"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'กรุณากรอกสาเหตุ'
                                        },]}>
                                    <Select
                                        mode="multiple"
                                        style={{
                                            width: '100%',
                                        }}
                                        size="large"
                                        placeholder="เลือกหมายเหตุ"
                                        onChange={handleChange}
                                        // options={options}
                                        options={options.map(option => ({
                                            label: option.label, // หรือฟิลด์ที่ต้องการแสดง
                                            value: option.label,   // หรือฟิลด์ที่ใช้สำหรับการเลือก
                                            key: option.key,    // ใช้ key ที่กำหนดไว้
                                        }))}
                                        disabled={disCancel}
                                    />
                                </Form.Item>
                                {
                                    checkCause === 0 ?
                                        null
                                        :
                                        <div>
                                            <Form
                                                form={formAdd}
                                                labelCol={{
                                                    span: 6,
                                                }}
                                                wrapperCol={{
                                                    span: 16,
                                                }}>
                                                <Form.Item label='เพิ่มสาเหตุ' name='name'>
                                                    <Input type='text' size="small" placeholder='กรอกสาเหตุที่ต้องการเพิ่ม' style={{ color: 'black' }}
                                                        onChange={(e) => setAddNote({ ...addNote, name: e.target.value })}
                                                    />
                                                </Form.Item>
                                            </Form>
                                        </div>

                                }
                                <Form.Item
                                    label="หมายเหตุ"
                                    name="note"
                                >
                                    <TextArea rows={4} onChange={(e) => setstatusAccept({ ...statusAccept, note: e.target.value })} />
                                </Form.Item>


                            </Col>
                            <Col span={2}>
                                {
                                    checkCause === 0 ?
                                        <Form.Item>
                                            <Button type="primary" disabled={disCancel} onClick={BTNAddCause}>
                                                +
                                            </Button>
                                        </Form.Item>
                                        :
                                        <Form.Item>
                                            <Button type="primary" onClick={BTNAddCause}>
                                                -
                                            </Button>
                                        </Form.Item>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} >
                                <div style={{ textAlign: 'center' }}>
                                    <Space>
                                        {
                                            checkCause === 1 ?
                                                <Button type='primary' onClick={handleAddCause}>เพิ่มหมายเหตุ</Button>
                                                : null
                                        }
                                        <Button key="back" type='primary' danger onClick={handleCancel}>ปิด</Button>
                                        <Button type='primary' htmlType='submit' disabled={dis} style={{ backgroundColor: "Green" }}>ยืนยัน</Button>
                                    </Space>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Modal>
            {contextHolder}
        </div>
    )
};

export default Note