import React, { useState, useEffect } from 'react'
import { Form, Input, Row, Col, Button, Select, Divider, Space, Modal, message, Card, Upload, Checkbox, notification, Image, Spin } from 'antd'
import axios from 'axios'
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';
import Compressor from 'compressorjs';
import TextArea from 'antd/es/input/TextArea';
import { findcarmk, getImagess, getImagessPre, getocc, mutips2, mutips2pre, newgetsection, newgetsub, newupdatecustomerocc } from '../../file_mid/all_api';

function EditCareer({ getData, close, sendback, notPassData, saveResendNote, setSaveResendNote, checkPreNotPass }) {

    const [form] = Form.useForm();

    const [countDataPost, setCountDataPost] = useState(0)
    const [dataPost, setDataPost] = useState({ mainOccupation: true }) //เก็บอาชีพ
    const [occupation, setOccupation] = useState([]) // getdatabase occ
    const [loading, setLoading] = useState(false)
    const [sub, setSub] = useState([]) // getdatabase sub
    const [section, setSection] = useState([]) // getdatabase section
    const [money, setMoney] = useState({}) //เก็บรายได้
    const [expenses, setExpenses] = useState({}) //เก็บรายจ่าย
    const [selectProduct, setSelectProduct] = useState({})
    const [selectTypeCar, setSelectTypeCar] = useState()
    const [api, contextHolder] = notification.useNotification();
    const token = localStorage.getItem('token');


    //รูปภาพ
    /*เก็บรูปทั้งหมด*/
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [allImg, setAllImg] = useState([]);
    const [fileListCustomerCareer, setFileListCustomerCareer] = useState([]);
    const [fileListCustomerStatementImage, setFileListCustomerStatementImage] = useState([]);
    const [fileListCustomerStatementPDF, setFileListCustomerStatementPDF] = useState([]);

    const [resultImage, setResultImage] = useState([]);
    const newFormData = new FormData();

    const [resendNote, setResendNote] = useState();
    const [imageBlobzz, setImageBlobzz] = useState();
    const [checkSendNEW, setCheckSendNEW] = useState(false);



    useEffect(() => {
        setSelectProduct({ ...selectProduct, productTypeId: 1 })
        setSelectTypeCar({ ...selectTypeCar, productTypeCar: getData.cars.carLoanDetails.productTypeCar })

        if (getData?.ojs.length !== 0) {
            setExpenses({ ...getData?.ojs[0]?.income?.expenses })
            setMoney({ ...getData?.ojs[0]?.income })
            setDataPost({ ...getData?.ojs[0] })
            bpOccupation()
            bpSubOccupation(getData?.ojs[0]?.occupationalGroup)
            bpOccupationSection(getData?.ojs[0]?.governmentPrivate)
            form.setFieldsValue(
                {
                    ...getData?.ojs[0],
                    ...getData?.ojs[0]?.income,
                    ...getData?.ojs[0]?.income?.expenses
                }
            )
        } else {
            loadOccupation()
            setExpenses({})
            setMoney({})
            setDataPost({})
            form.setFieldsValue(
                {
                    occupationalGroup: "",
                    governmentPrivate: "",
                    jobPosition: "",
                    companyName: "",
                    incomeMonth: '',
                    expensesMonth: "",
                }
            )
        }
        form.setFieldsValue(
            {
                ...notPassData
            }
        )
        if (saveResendNote.careerNote) {
            form.setFieldsValue({
                ...saveResendNote
            })
        }
    }, [])

    useEffect(() => {
        const loadDataLand = async () => {
            setLoading(true)
            await axios.post(findcarmk, { carId: getData.cars.carId, customerId: getData.customerId })
                .then(res => {
                    if (res.status === 200) {
                        console.log("res.data555", res.data)
                        setImageBlobzz(res.data.cars.img)
                        setLoading(false)
                    }
                })
        }
        loadDataLand()
    }, [getData])

    useEffect(() => {
        imageBlobzz?.map((e, index) => {
            var PreAaproveSplit = []
            PreAaproveSplit = e.pathImage.split("/")
            if (PreAaproveSplit[3] === "13") {
                setCheckSendNEW(true)
            }
        })
    }, [imageBlobzz])

    const handleCancel1 = () => {
        close();
    }

    const SuccSend = (placement) => {
        api.success({
            message: <b>แก้ไขข้อมูลสำเร็จ</b>,
            // description: `กรุณาเพิ่มที่อยู่ผู้ค้ำ ลำดับที่ ${index + 1}`,
            placement,
        });
    }
    const SuccImg = (placement) => {
        api.success({
            message: <b>เพิ่มรูปสำเร็จ</b>,
            // description: `กรุณาเพิ่มที่อยู่ผู้ค้ำ ลำดับที่ ${index + 1}`,
            placement,
        });
    }

    const handleSubmit = async () => {
        setLoading(true)
        const result = {
            customer: { customerId: getData?.customerId },
            ojs: {
                occupationJobPositionId: dataPost?.occupationJobPositionId,
                occupationalGroup: dataPost?.occupationalGroup,
                governmentPrivate: dataPost?.governmentPrivate,
                jobPosition: dataPost?.jobPosition,
                companyName: dataPost?.companyName,
                occupationDescription: dataPost?.occupationDescription,
                mainOccupation: true,
                idPljob: dataPost?.idPljob,
                idPloccupation: dataPost?.idPloccupation,
                idArgroup: dataPost?.idArgroup
            },
            income: {
                incomesMainId: money?.incomesMainId,
                incomeMonth: money?.incomeMonth,
                incomeMainDescription: money?.incomeMainDescription
            },
            expenses: {
                expensesId: expenses?.expensesId,
                expensesMonth: expenses?.expensesMonth,
                expensesMonthDescription: expenses?.expensesMonthDescription
            }
        }
        console.log("result", result)
        await axios.post(newupdatecustomerocc, result)
            .then(async (res) => {
                if (allImg?.length > 0) {
                    await imgSend(allImg)
                    setLoading(false)
                } else {
                    sendback(dayjs())
                    setLoading(false)
                }
                SuccSend("top")
                handleSendNote()
            })
            .catch((err) => console.log("error", err))
        // setLoading(false)
    };

    const handleSendNote = async () => {
        if (resendNote !== undefined) {
            if (resendNote.careerNote !== "") {
                setSaveResendNote({ ...saveResendNote, ...resendNote })
            } else {
                setSaveResendNote({ ...saveResendNote, careerNote: null })
            }
        } else {
            // console.log("= undefined")
        }
    };

    const imgSend = (resultImgs) => {
        return new Promise(async (resolve, reject) => {
            try {
                var status;
                newFormData.append('idcus', getData?.cars?.carId);
                //newFormData.append('productTypeId', "1");
                newFormData.append('tm', dayjs().format("YYYY-MM-DDTHH:mm:ssZ"));

                for (const file of resultImgs) {
                    var image = {}
                    newFormData.append('file', file.imageCompressedFile);
                    newFormData.append("type", file.type);
                    image.type = file.type
                    resultImage.push(image)
                }
                if (checkPreNotPass) {
                    const response = await axios.post(mutips2pre, newFormData);
                    if (response.data) {
                        setAllImg([])
                        setFileListCustomerCareer([])
                        setFileListCustomerStatementImage([])
                        setFileListCustomerStatementPDF([])
                        console.log("TY Pre", response.data);
                        status = response.status;
                        resolve(status);
                    }
                } else {
                    const response = await axios.post(mutips2, newFormData);
                    if (response.data) {
                        setAllImg([])
                        setFileListCustomerCareer([])
                        setFileListCustomerStatementImage([])
                        setFileListCustomerStatementPDF([])
                        console.log("TY", response.data);
                        status = response.status;
                        resolve(status);
                    }
                }

            } catch (error) {
                error(error);
                reject(error);
            } finally {
                setLoading(false)
                console.log("OK")
                SuccImg("top")
                sendback(dayjs())
            }
        });
    };

    const getOccupation = (value) => {
        setCountDataPost(1)
        setDataPost({
            ...dataPost,
            governmentPrivate: "",
            governmentPrivateTH: "",
            jobPosition: "",
            companyName: "",
            occupationalGroup: value,
            occupationalGroupTH: occupation.find((e) => e.idOccupation === value).desc
        })
        loadSubOccupation(value)
    }

    const loadSubOccupation = async (value) => {
        const idOccupation = { idOccupation: value }
        setLoading(true)
        await axios.post(newgetsub, idOccupation)
            .then((res) => {
                setSub(res.data)
                setLoading(false)
                form.setFieldsValue(
                    {
                        governmentPrivate: "",
                        jobPosition: "",
                        companyName: "",
                    }
                )
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const getSection = (value) => {
        setCountDataPost(1)
        setDataPost({
            ...dataPost,
            jobPosition: value,
            jobPositionTH: section.find((e) => e.idSection === value).descSection,
            idPloccupation: section.find((e) => e.idSection === value).idPloccupation,
            idArgroup: section.find((e) => e.idSection === value).idArgroup
        })
    }

    const getSub = (value) => {
        setCountDataPost(1)
        setDataPost({
            ...dataPost,
            jobPosition: 32,
            jobPositionTH: "",
            idPloccupation: 12,
            idArgroup: 11,
            companyName: "",
            governmentPrivate: value,
            governmentPrivateTH: sub.find((e) => e.idSub === value).descSub,
            idPljob: sub.find((e) => e.idSub === value).idPljob
        })
        loadOccupationSection(value)
    }

    //รูปภาพ
    const compressImage = async (ez, types) => {
        var image = {};
        return new Promise(async (resolve) => {
            if (types !== 7) {
                if (ez.size > 4000000) {
                    console.log("4m ไม่ให้ส่ง");
                    resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                } else {
                    let quality;
                    if (ez.size >= 2500000 && ez.size <= 4000000) {
                        quality = 0.1;
                    } else if (ez.size >= 2000000 && ez.size <= 2499999) {
                        quality = 0.15;
                    } else if (ez.size >= 1500000 && ez.size <= 1999999) {
                        quality = 0.2;
                    } else if (ez.size >= 1000000 && ez.size <= 1499999) {
                        quality = 0.3;
                    } else {
                        quality = 0.5;
                    }

                    try {
                        // ใช้ Promise เพื่อบีบอัดรูปภาพ
                        const compressedFile = await new Promise((resolveCompress) => {
                            new Compressor(ez, {
                                quality: quality,
                                success: (result) => {
                                    resolveCompress(result);
                                },
                            });
                        });

                        const fileOptions = {
                            type: compressedFile.type,
                            lastModified: Date.now(),
                        };
                        const compressedFileAsFile = new File([compressedFile], ez.name, fileOptions);
                        image.imageCompressedFile = compressedFileAsFile;
                        image.type = types
                        allImg.push(image);

                        // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
                        resolve(image);
                    } catch (error) {
                        console.error('Image compression error:', error);
                        resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                    }
                }
            } else {
                // ... (โลจิกสำหรับ type 7)
                image.imageCompressedFile = ez;
                image.type = types
                allImg.push(image);
                resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
            }
        });
    };

    const beforeUpts = async (file, ty) => {
        try {
            setLoading(true)
            const compressedFile = await compressImage(file, ty);
            return Promise.resolve(compressedFile);
        } catch (error) {
            console.error('Image compression error:', error);
            message.error('Image compression failed.');
            return Promise.reject();
        }
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleCancel = () => {
        setPreviewVisible(false);
    }

    const handleChangeCustomerCareer = async ({ fileList }) => { // 6
        for (const removedFile of fileListCustomerCareer.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 6)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 6));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerCareer(fileList);

        setLoading(false)
    };

    const handleChangeCustomerStatementImage = async ({ fileList }) => { // 8
        for (const removedFile of fileListCustomerStatementImage.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 8)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 8));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerStatementImage(fileList);

        setLoading(false)
    };

    const handleChangeCustomerStatementPDF = async ({ fileList }) => { // 7
        for (const removedFile of fileListCustomerStatementPDF.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 7)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 7));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerStatementPDF(fileList);
        setLoading(false)
    };

    //////////////////////////////////////////////////////////////////////////

    const loadOccupationSection = async (value) => {
        const idSub = { idSub: value }
        setLoading(true)
        await axios.post(newgetsection, idSub)
            .then((res) => {
                setSection(res.data)
                setLoading(false)
                form.setFieldsValue(
                    {
                        jobPosition: "",
                        companyName: "",
                    }
                )
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const bpOccupation = async () => {
        setLoading(true)
        await axios.get(getocc)
            .then((res) => {
                setOccupation(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const bpSubOccupation = async (value) => {
        const idOccupation = { idOccupation: value }
        setLoading(true)
        await axios.post(newgetsub, idOccupation)
            .then((res) => {
                setSub(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const bpOccupationSection = async (value) => {
        const idSub = { idSub: value }
        setLoading(true)
        await axios.post(newgetsection, idSub)
            .then((res) => {
                setSection(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const loadOccupation = async () => {
        setLoading(true)
        await axios.get(getocc)
            .then((res) => {
                setOccupation(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    return (
        <Row justify={'center'}>
            <Spin spinning={loading} size='large' tip=" Loading... ">
                <Row justify={'center'} style={{ margin: '5px', fontSize: '18px' }} >รายละเอียดอาชีพ</Row>


                <Form
                    form={form}
                    labelCol={{
                        span: 12,
                    }}
                    style={{
                        width: '100%',
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    autoComplete="off"
                    onFinish={handleSubmit}
                >
                    <Card>
                        <Row className='main1' justify={'center'}>
                            <Col span={20}>
                                <aside style={{ width: '90%', textAlign: 'center' }}>
                                    <div>
                                        <Form.Item label="หมายเหตุ(ธุรการ)" name="npCareerNote">
                                            <TextArea rows={5} name='npCareerNote' disabled />
                                        </Form.Item>
                                        <Form.Item label="หมายเหตุ(การตลาด)" name="careerNote">
                                            <TextArea rows={5} name='careerNote' placeholder='หมายเหตุถึงธุรการ' onChange={(e) => setResendNote({ careerNote: e.target.value })} />
                                        </Form.Item>
                                    </div>
                                    {
                                        !checkPreNotPass ?
                                            <>
                                                <div>
                                                    <Form.Item label='กลุ่มอาชีพ' name='occupationalGroup'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}>
                                                        <Select
                                                            placeholder={'เลือก'}
                                                            style={{ height: '50px', width: '250px' }}
                                                            onChange={getOccupation}
                                                        >
                                                            {occupation?.map((item) => {
                                                                return (
                                                                    <Select.Option key={item.idOccupation} value={item.idOccupation}>
                                                                        {item.desc}
                                                                    </Select.Option>
                                                                )
                                                            })}

                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item label='เลือกอาชีพ' name='governmentPrivate'

                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}>
                                                        <Select
                                                            placeholder={'เลือก'}
                                                            style={{ height: '50px', width: '250px' }}
                                                            onChange={getSub}
                                                        >
                                                            {sub?.map((item) => {
                                                                return (
                                                                    <Select.Option key={item.idSub} value={item.idSub}>
                                                                        {item.descSub}
                                                                    </Select.Option>
                                                                )
                                                            })}

                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item label='สาขาอาชีพ' name='jobPosition'
                                                    >
                                                        <Select
                                                            placeholder={'เลือก'}
                                                            style={{ height: '50px', width: '250px' }}
                                                            onChange={getSection}
                                                        >
                                                            {section?.map((item) => {
                                                                return (
                                                                    <Select.Option key={item.idSection} value={item.idSection}>
                                                                        {item.descSection}
                                                                    </Select.Option>
                                                                )
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item label='ชื่อบริษัท สถานที่ทำงาน' name='companyName'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}>
                                                        <Input type='text'
                                                            style={{ height: '50px', width: '250px' }}
                                                            onChange={(e) => setDataPost({ ...dataPost, companyName: e.target.value })}></Input>
                                                    </Form.Item>
                                                    <Form.Item label='หมายเหตุ' name='occupationDescription'>
                                                        <Input type='text'
                                                            style={{ height: '50px', width: '250px' }}
                                                            onChange={(e) => setDataPost({ ...dataPost, occupationDescription: e.target.value })}></Input>
                                                    </Form.Item>
                                                    <Form.Item label='รายได้ต่อเดือน' name='incomeMonth'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}>
                                                        <Input type='text' suffix='บาท'
                                                            style={{ height: '50px', width: '250px' }}
                                                            onChange={(e) => setMoney({ ...money, incomeMonth: parseInt(e.target.value) })}></Input>
                                                    </Form.Item>
                                                    <Form.Item label='หมายเหตุ' name='incomeMainDescription'>
                                                        <Input type='text'
                                                            // style={{ color: 'black', height: '50px', width: '250px' }}
                                                            style={{ height: '50px', width: '250px' }}
                                                            onChange={(e) => setMoney({ ...money, incomeMainDescription: e.target.value })}></Input>
                                                    </Form.Item>
                                                    <Form.Item label='ค่าใช้จ่ายต่อ / เดือน' name='expensesMonth'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}>
                                                        <Input type='text' suffix='บาท'
                                                            // style={{ color: 'black', height: '50px', width: '250px' }}
                                                            style={{ height: '50px', width: '250px' }}
                                                            onChange={(e) => setExpenses({ ...expenses, expensesMonth: parseInt(e.target.value) })}></Input>
                                                    </Form.Item>
                                                    <Form.Item label='หมายเหตุ' name='expensesMonthDescription'>
                                                        <Input type='text'
                                                            // style={{ color: 'black', height: '50px', width: '250px' }}
                                                            style={{ height: '50px', width: '250px' }}
                                                            onChange={(e) => setExpenses({ ...expenses, expensesMonthDescription: e.target.value })}></Input>
                                                    </Form.Item>
                                                </div>
                                            </>
                                            : null
                                    }
                                </aside>
                                <Divider />
                                <Row justify={'center'}>
                                    <Col>
                                        <Row justify={'left'}><b><h3><u>อาชีพ (อัพโหลดไฟล์ไม่เกิน 4MB)</u></h3></b></Row>
                                        <Row justify={'center'}>
                                            <b>
                                                <Form.Item>
                                                    <Upload
                                                        accept="image/*"
                                                        multiple={true}
                                                        style={{ textAlign: 'center' }}
                                                        listType="picture-card"
                                                        fileList={fileListCustomerCareer}
                                                        onPreview={handlePreview}
                                                        onChange={handleChangeCustomerCareer}
                                                        beforeUpload={(file) => beforeUpts(file, 6).then(() => false).catch(() => true)}
                                                    >
                                                        {fileListCustomerCareer?.length >= 20 ? null : (
                                                            <div>
                                                                <PlusOutlined />
                                                                <div className="ant-upload-text">upload</div>
                                                            </div>
                                                        )}
                                                    </Upload>
                                                    <Modal open={previewVisible} onCancel={handleCancel}>
                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </Form.Item>
                                            </b>
                                        </Row>
                                        <Divider style={{ margin: 5 }} />
                                        <Row justify={'left'}><b><h3><u>รูปสเตทเมนต์ผู้กู้ (อัพโหลดไฟล์ไม่เกิน 4MB)</u></h3></b></Row>
                                        <Row justify={'center'}>
                                            <Col span={24}>
                                                <aside gutter={24} justify={'center'}>
                                                    <div className='gutter-row' span={12}>
                                                        <Row justify={'center'}><b><h3><u>ไฟล์ภาพ</u></h3></b></Row>
                                                        <Row justify={'center'}>
                                                            <b> <Form.Item
                                                                style={{ textAlign: 'center' }}
                                                            >
                                                                <Upload
                                                                    accept="image/*"
                                                                    multiple={true}
                                                                    style={{ textAlign: 'center' }}
                                                                    listType="picture-card"
                                                                    fileList={fileListCustomerStatementImage}
                                                                    onPreview={handlePreview}
                                                                    onChange={handleChangeCustomerStatementImage}
                                                                    beforeUpload={(file) => beforeUpts(file, 8).then(() => false).catch(() => true)}
                                                                >
                                                                    {fileListCustomerStatementImage?.length >= 20 ? null : (
                                                                        <div>
                                                                            <PlusOutlined />
                                                                            <div className="ant-upload-text">upload</div>
                                                                        </div>
                                                                    )}
                                                                </Upload>
                                                                <Modal open={previewVisible} onCancel={handleCancel}>
                                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                                </Modal>
                                                            </Form.Item>
                                                            </b>
                                                        </Row>
                                                    </div>
                                                    <div className='gutter-row' span={12}>
                                                        <Row justify={'center'}><b><h3><u>ไฟล์ PDF</u></h3></b></Row>
                                                        <Row justify={'center'}>
                                                            <b> <Form.Item
                                                                style={{ textAlign: 'center' }}
                                                            >
                                                                <Upload
                                                                    accept="application/pdf"
                                                                    multiple={true}
                                                                    style={{ textAlign: 'center' }}
                                                                    listType="picture-card"
                                                                    fileList={fileListCustomerStatementPDF}
                                                                    onPreview={handlePreview}
                                                                    onChange={handleChangeCustomerStatementPDF}
                                                                    beforeUpload={(file) => beforeUpts(file, 7).then(() => false).catch(() => true)}
                                                                >
                                                                    {fileListCustomerStatementPDF?.length >= 10 ? null : (
                                                                        <div>
                                                                            <PlusOutlined />
                                                                            <div className="ant-upload-text">upload</div>
                                                                        </div>
                                                                    )}
                                                                </Upload>
                                                                <Modal open={previewVisible} onCancel={handleCancel}>
                                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                                </Modal>
                                                            </Form.Item>

                                                            </b>
                                                        </Row>
                                                    </div>
                                                </aside>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                    <Card>
                        <Row justify={'left'}><b><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ</u></b></Row>
                        <Row gutter={32} justify={'center'}>
                            {imageBlobzz?.map((e, index) => {
                                var PreAaproveSplit = []
                                PreAaproveSplit = e.pathImage.split("/")
                                if (PreAaproveSplit[3] !== "13") {
                                    if (e.type === 6) {
                                        return (
                                            <>
                                                <Image width={"50px"} height={'50px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                            </>
                                        );
                                    }
                                }
                                return null;
                            })}
                        </Row>
                        <Divider />
                        {
                            checkSendNEW ?
                                <>
                                    <Row justify={'left'}><b><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ</u> (ใหม่)</b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        <Space direction="vertical" >
                                            <Row gutter={32} justify={'center'}>
                                                {imageBlobzz?.map((e, index) => {
                                                    var PreAaproveSplit = []
                                                    PreAaproveSplit = e.pathImage.split("/")
                                                    if (PreAaproveSplit[3] === "13") {
                                                        if (e.type === 6) {
                                                            return (
                                                                <>
                                                                    <Image width={"50px"} height={'50px'} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </>
                                                            );
                                                        }
                                                    }
                                                    return null;
                                                })}
                                            </Row>
                                        </Space>
                                    </Row>
                                </>
                                : null
                        }
                        <Divider/>
                        <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้</u></b></Row>
                        <Row gutter={32} justify={'center'}>
                            {imageBlobzz?.map((e, index) => {
                                var PreAaproveSplit = []
                                PreAaproveSplit = e.pathImage.split("/")
                                if (PreAaproveSplit[3] !== "13") {
                                    if (e.type === 8) {
                                        return (
                                            <>
                                                <Image width={"50px"} height={'50px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                            </>
                                        );
                                    }
                                }
                                return null;
                            })}
                        </Row>
                        <Divider />

                        {
                            checkSendNEW ?
                                <>
                                    <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้</u> (ใหม่)</b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        <Space direction="vertical">
                                            <Row gutter={32} justify={'center'}>
                                                {imageBlobzz?.map((e, index) => {
                                                    var PreAaproveSplit = []
                                                    PreAaproveSplit = e.pathImage.split("/")
                                                    if (PreAaproveSplit[3] === "13") {
                                                        if (e.type === 8) {
                                                            return (
                                                                <>
                                                                    <Image width={"50px"} height={'50px'} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </>
                                                            );
                                                        }
                                                    }
                                                    return null;
                                                })}
                                            </Row>
                                        </Space>
                                    </Row>
                                </>
                                : null
                        }
                        <Divider />

                        <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ PDF</u></b></Row>
                        <Row gutter={32} justify={'center'}>
                            {imageBlobzz?.map((e, index) => {
                                var PreAaproveSplit = []
                                PreAaproveSplit = e.pathImage.split("/")
                                if (PreAaproveSplit[3] !== "13") {
                                    if (e.type === 7) {
                                        return (
                                            <>
                                                <object data={`${getImagess}/${e.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                                    <p>Alternative text - include a link <a href={`${getImagess}/${e.pathImage}?token=${token}`}>to the PDF!</a></p>
                                                </object>
                                            </>
                                        )
                                    }
                                }
                                return null;
                            })}
                        </Row >
                        <Divider />

                        <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ PDF</u> (ใหม่)</b></Row>
                        <Row gutter={32} justify={'center'}>
                            <Space direction="vertical">
                                <Row gutter={32} justify={'center'}>
                                    {imageBlobzz?.map((e, index) => {
                                        var PreAaproveSplit = []
                                        PreAaproveSplit = e.pathImage.split("/")
                                        if (PreAaproveSplit[3] === "13") {
                                            if (e.type === 7) {
                                                return (
                                                    <>
                                                        <object data={`${getImagessPre}/${e.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                                            <p>Alternative text - include a link <a href={`${getImagessPre}/${e.pathImage}?token=${token}`}>to the PDF!</a></p>
                                                        </object>
                                                    </>
                                                )
                                            }
                                        }
                                        return null;
                                    })}
                                </Row >
                            </Space>
                        </Row>
                    </Card>
                    <Row >
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Divider />
                            <Space>
                                <Button type="primary" danger onClick={handleCancel1} >ปิด</Button>
                                <Button type="primary" htmlType="submit" style={{ background: "green" }}
                                    disabled={(fileListCustomerCareer.length &&
                                        (fileListCustomerStatementImage.length || fileListCustomerStatementPDF.length)) === 0}
                                >บันทึก</Button>
                            </Space>
                        </Col>

                    </Row>
                </Form>
            </Spin>
            {contextHolder}
        </Row >
    )
};

export default EditCareer