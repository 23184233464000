import React, { useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { Button, Card, Col, Divider, Form, Input, Row, Space, Spin, Table, notification, InputNumber, Tag, DatePicker, Checkbox, Select } from 'antd'
import axios from 'axios';
import dayjs from "dayjs";
// import { PrinterOutlined } from '@ant-design/icons';
import { columnsPay } from './columns/columns';
import { msError, msPrint, msReceipt, msData } from "../file_mid/allFormat";
import Payment from "../file_mid/reportPayment/Payment3New";
import { loadPrintDataPayment } from "../loan_history/load_data_print/data_pay";
import { mainReceipt, searchReceipt } from "../file_mid/all_api";
const { Option } = Select;

export default function Main() {
  // const token = localStorage.getItem('token')
  const [api, contextHolder] = notification.useNotification();
  // const currentDateTime = dayjs()
  const [form] = Form.useForm();
  const [dataRec, setDataRec] = useState();
  const [showTable, setShowTable] = useState(false);
  const componentPDF = useRef();
  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "ใบรับเงิน",
  });
  const [dataSend, setDataSend] = useState({ text: "", productType: 0, day1: dayjs(), day2: dayjs() });
  const [dataTable, setDataTable] = useState([]);
  const [arrayTable, setArrayTable] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadData()
  }, [])
  const loadData = async () => {
    setDataSend({ text: "", productType: 0, day1: dayjs(), day2: dayjs() })
    form.setFieldsValue({ text: "", productType: "" });
    setLoading(true)
    await axios.get(mainReceipt)
      .then((res) => {
        console.log("loadData", res.data)
        if (res.data) {
          setDataTable(res.data)
          setArrayTable(res.data)
        } else {
          setDataTable([])
          setArrayTable([])
          msData(api, "top")
        }
        setLoading(false)
      }).catch((err) => {
        console.log(err)
        setDataTable([])
        setArrayTable([])
        msError(api, "top")
        setLoading(false)
      })

  }

  const handleSubmit = async () => {
    // if (dataSend?.text !== "" && dataSend?.productType > 0) {
    console.log("dataSend", dataSend)
    setLoading(true)
    await axios.post(searchReceipt, dataSend)
      .then((res) => {
        console.log(res)
        if (res.data) {
          setDataTable(res.data)
          setArrayTable(res.data)
        } else {
          setDataTable([])
          setArrayTable([])
          msData(api, "top")
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log("err", err)
        setDataTable([])
        setArrayTable([])
        msError(api, "top")
        setLoading(false)
      })
    // } else {
    //   msReceipt(api, "top")
    // }
  }

  const resetSelect = () => {
    loadData()
  };

  const handleProductType = (value) => {
    console.log("value: " + value)
    setDataSend({ ...dataSend, productType: value, text: "" })
    form.setFieldsValue({ text: "", });
  }
  const handleSetData = async (record) => {
    console.log('Record :', record);
    let printData = {}
    let result = {}
    if (record) {
      setLoading(true);
      printData = { "CONTNO": record.CONTNO, "TMBILL": record.TMBILL }
      try {
        result = await loadPrintDataPayment(printData);
        setDataRec(result)
      } catch (error) {
        console.error("Error in handleInsert: ", error);
      } finally {

      }
      if (result === null) {
        console.log("เกิดข้อผิดพลาด");
        msPrint(api, "top")
        setDataRec(null)
        setLoading(false);
      } else {
        setShowTable(true);
        setTimeout(async () => {
          setLoading(false);
          generatePDF();
        }, 1000);
      }
    } else {
      msError(api, "top")
    }
  };

  const search = (data) => {
    const searchValue = data.target.value.toLowerCase();  // ทำให้ค่าใน input เป็นตัวพิมพ์เล็ก
    const greaterThanTen = arrayTable.filter(
      (item) =>
        item.CONTNO.toLowerCase().includes(searchValue) ||
        item.CUSCOD.toLowerCase().includes(searchValue) ||
        item.TMBILL.toLowerCase().includes(searchValue) ||
        item.NAME1.toLowerCase().includes(searchValue)
    );
    setDataTable(greaterThanTen)
  };

  return (
    <Card style={{ height: '100%' }}>
      <Form
        form={form}
      >
        <Row style={{ textAlign: 'center' }}>
          <Col span={24}>
            <Spin spinning={loading} size='large' tip="Loading...">
              <Col span={24}>
                <div className='text-center'>
                  <h2>พิมพ์ใบเสร็จ</h2>
                </div>
              </Col>

              {/* <Col span={24} style={{ textAlign: 'right' }}>

              </Col> */}
              <Divider />
              <Row gutter={16}>
                <Col span={16}>
                  <Card title="รายละเอียด" bordered={false}>
                    <Space>
                      <Form.Item label='วันที่' name='day1' style={{ height: '30px', width: '100%' }}>
                        <DatePicker format={'YYYY-MM-DD'} defaultValue={dataSend.day1}
                          style={{ height: '40px', width: '250px', color: 'black' }}
                          onChange={(e) => {
                            if (e) {
                              setDataSend({ ...dataSend, day1: e.format("YYYY-MM-DDTHH:mm:ssZ") })
                            }
                          }} />
                      </Form.Item>
                      {/* <Form.Item label='' style={{ height: '30px', width: '100%' }}>ถึง</Form.Item> */}
                      <Form.Item label='ถึง วันที่' name='day2' style={{ height: '30px', width: '100%' }}>
                        <DatePicker format={'YYYY-MM-DD'} defaultValue={dataSend.day2}
                          style={{ height: '40px', width: '250px', color: 'black' }}
                          onChange={(e) => {
                            if (e) {
                              setDataSend({ ...dataSend, day2: e.format("YYYY-MM-DDTHH:mm:ssZ") })
                            }
                          }} />
                      </Form.Item>
                    </Space>
                    <Space>
                      <Form.Item label="ค้นหาจาก" name="productType" style={{ height: '30px', width: '100%' }}>
                        <Select placeholder={'เลือก'}
                          style={{ height: '40px', width: '200px' }}
                          onChange={(value) => handleProductType(value)}
                        >
                          <Option value={1}>เลขสัญญา</Option>
                          <Option value={2}>เลขใบเสร็จ</Option>
                          <Option value={3}>เลขบัตรประชาชนลูกค้า</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item label="ข้อความ" name="text" style={{ height: '30px', width: '100%' }}>
                        <Input onChange={(e) => setDataSend({ ...dataSend, text: e.target.value })}></Input>
                      </Form.Item>
                      <Form.Item label="" style={{ height: '30px', width: '100%' }}>
                        <Button type='primary' onClick={handleSubmit} > Find </Button>
                      </Form.Item>
                      <Form.Item label="" style={{ height: '30px', width: '100%' }}>
                        <Button onClick={resetSelect} > Clear </Button>
                      </Form.Item>
                    </Space>
                    <Divider />
                  </Card>
                </Col>
              </Row>
              <Divider />
              <Row gutter={32} style={{ margin: 10 }}>
                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                  <Input.Search
                    style={{ width: '250px' }}
                    placeholder="ค้นหาในตาราง...."
                    onChange={search}
                    name="search"
                    id="search"
                    size="large"
                  />
                </Col>
              </Row>
              <Table
                rowKey={(record) => record.keyId}
                dataSource={dataTable}
                columns={columnsPay(handleSetData)}
                scroll={{
                  x: 1500,
                  y: 400,
                }}
                style={{ padding: 20 }}
              >
              </Table>
            </Spin>
          </Col>
        </Row >
      </Form >
      {contextHolder}
      {showTable &&
        <div className="print-only" ref={componentPDF} style={{ textAlign: 'center' }}>
          <Payment
            dataPrint={dataRec}
          />
        </div>
      }
    </Card >
  )
}