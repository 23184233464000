import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Spin, Card, Button, Tag, Divider, notification, Table, Space, message } from 'antd'
import dayjs from 'dayjs';
import axios from "axios";
import { mainDataExcel, headersThai, headersColumsName, mainDataExcelDetail, headersThaiDetail, headersColumsNameDetail } from '../loan_history/excel/cl';
import { DataExcelPay } from '../loan_history/excel/data_excel';
import { currencyFormatOne, msConnt, msError, msOK, msUnCancel } from "../file_mid/allFormat";
// import { calPay } from "../loan_history/cal/Cal";
import { columns } from '../loan_history/table/cm';
// import Anothr from "./Anothr";
import ExcelJS from 'exceljs';
import { detailCancelpay, updateCancelpay } from "../file_mid/all_api";

export default function ModelCancel({ open, close, dataFromTable, closeload }) {
    const { confirm } = Modal
    const token = localStorage.getItem('token')
    const nicknameSalcod = localStorage.getItem('nicknameSalcod')
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm()
    const [isClicked, setIsClicked] = useState(true);
    const [checkButton, setCheckButton] = useState(true)
    const [loading, setLoading] = useState(false);
    const [dataAll, setDataAll] = useState(null);
    const [dataTable, setDataTable] = useState([]);
    const [dataTableNew, setDataTableNew] = useState([]);

    useEffect(() => {
        console.log("dataFromTable =>", dataFromTable)
        loadData()
    }, [])

    const loadData = async () => {
        setLoading(true)
        let mixData
        if (dataFromTable?.CONTNO !== "" && dataFromTable?.TMBILL !== "" && nicknameSalcod !== "") {
            mixData = {
                ...dataFromTable,
                // CONTNO: dataFromTable.CONTNO.trim(), 
                // NETPAY: dataFromTable?.NETPAY, 
                // TMBILL: dataFromTable?.TMBILL, 
                // arrayDate: dataFromTable?.arrayDate,
                inputBy: nicknameSalcod,
            }
            console.log("mixData", mixData)
            await axios.post(detailCancelpay, mixData)
                // await axios.post("http://localhost:8070/api-db2/detail-cancelpay", mixData)
                .then(async (res) => {
                    if (res.status === 200) {
                        console.log("res.data payamt", res.data)
                        if (res.data) {
                            setDataAll(res.data)
                            if (res.data.oldDue) {
                                setDataTable(res.data.oldDue)
                            } else {
                                setDataTable([])
                            }
                            if (res.data.newDue) {
                                setDataTableNew(res.data.newDue)
                            } else {
                                setDataTableNew([])
                            }
                        } else {
                            setDataTable([])
                            setDataTableNew([])
                        }
                    } else if (res.status === 203) {
                        msUnCancel(api, "top")
                        setTimeout(() => {
                            setLoading(false)
                            handleCancel()
                        }, 2000)
                    } else {
                        msConnt(api, "top")
                        setTimeout(() => {
                            setLoading(false)
                            handleCancel()
                        }, 2000)
                    }
                    setLoading(false)
                })
                .catch((err) => {
                    console.log("err", err)
                    msError(api, "top")
                    setTimeout(() => {
                        setLoading(false)
                        handleCancel()
                    }, 2000)
                })
        } else {
            msConnt(api, "top")
            setLoading(false)
        }
    }
    const showConfirm = () => {
        confirm({
            title: 'คุณต้องการที่จะยกเลิกบิลนี้ใช่หรือไม่...?',
            content: 'กด OK เพื่อยืนยัน',
            onOk() {
                setLoading(true)
                okUpdate()
            },
            onCancel() {
                message.error('ยกเลิกการทำรายการ')
            },
        });
    };
    const okUpdate = async () => {
        if (dataAll) {
            const tk = JSON.parse(token)
            const headers = {
                "Authorization": `Bearer ${tk}`,
                "Menu": JSON.stringify("26")
            }
            console.log("dataAll", dataAll)
            await axios.post(updateCancelpay, dataAll, { headers: headers })
                // await axios.post("http://localhost:8070/auth-pay/update-cancelpay", dataAll, { headers: headers })
                .then(async (res) => {
                    msOK(api, "top")
                    setTimeout(() => {
                        setLoading(false)
                        handleCancel("OK")
                    }, 2000)
                }).catch((err) => {
                    console.log(err)
                    msError(api, "top")
                    setLoading(false)
                })
        } else {
            msError(api, "top")
            setLoading(false)
        }
    }
    const handleOk = async () => {
        close(false);
    };
    const handleCancel = (value) => {
        if (value === "OK") {
            closeload(false);
        } else {
            close(false);
        }
    };
    const buttonAmt = async () => {
        setCheckButton(true)
        setIsClicked(true)
    }
    const buttonPay = async () => {
        setCheckButton(false)
        setIsClicked(false)
    }
    const exportToExcel = async () => {
        if (dataTable?.length > 0) {
            var allData = []
            var allData2 = []
            const workbook = new ExcelJS.Workbook();
            allData = await DataExcelPay(allData, dataTable);
            allData2 = await DataExcelPay(allData2, dataTableNew);
            const addSheet = (sheetName, dataSource, headers_colums_name, headers_thai) => {
                const worksheet = workbook.addWorksheet(sheetName);
                worksheet.columns = mainDataExcel
                worksheet.addRow(headers_thai);
                dataSource.forEach((row) => {
                    const rowData = headers_colums_name.map((column) => row[column]);
                    worksheet.addRow(rowData);
                });
            };
            const headers_thai = headersThai
            const headers_colums_name = headersColumsName
            addSheet(`old ${dataFromTable.CONTNO.trim()}`, allData, headers_colums_name, headers_thai);
            addSheet(`new ${dataFromTable.CONTNO.trim()}`, allData2, headers_colums_name, headers_thai);
            workbook.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `dataPay ${dataFromTable.CONTNO.trim()}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        } else {
            console.log("ไม่มีข้อมูล")
        }
    }
    return (
        <>
            <Modal title="รายละเอียดสัญญา"
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                width={'1200px'}
                footer={[
                    <Row justify={'center'}>
                        <Space>
                            <Button disabled={loading} key="close" type='primary' danger onClick={handleCancel}>
                                ปิด
                            </Button>
                            <Button disabled={loading} key="save" type='primary' onClick={showConfirm}>
                                บันทึกข้อมูล
                            </Button>
                        </Space>
                    </Row>
                ]} >
                <Spin spinning={loading} size='large' tip="Loading...">
                    <Row justify={'center'}>
                        <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                            <b>รายละเอียดสัญญา</b>
                        </Col>
                    </Row>
                    <Row justify={'center'}>
                        <Form
                            labelCol={{
                                span: 12,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            style={{
                                width: '90%',
                            }}
                            form={form}
                            autoComplete="off"
                            initialValues={{ remember: true }}
                        >
                            <div className='main'>
                                <Card style={{ width: '100%' }}>
                                    <Row justify={'left'}><b><u>รายละเอียดสัญญา {dataFromTable?.CONTNO}</u></b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        <aside style={{ width: '90%' }}>
                                            <b>
                                                <div style={{ margin: 0, }}>
                                                    <span>เลขบิล : </span>
                                                    <b style={{ fontSize: '20px' }}><u>{dataFromTable?.TMBILL}</u></b>
                                                </div>
                                            </b>
                                            <div style={{ marginBottom: 0, }}>
                                                <span>รหัสชำระค่า : </span>
                                                <b>{dataFromTable?.PAYFOR}</b>
                                            </div>
                                            <div style={{ marginBottom: 0, }}>
                                                <span>รหัสชำระด้วย : </span>
                                                <b>{dataFromTable?.PAYTYP}</b>
                                            </div>
                                            <div style={{ marginBottom: 0, }}>
                                                <b>
                                                    <span>ชำระสุทธิ : </span>
                                                    <b style={{ fontSize: '20px', color: 'red' }}>{currencyFormatOne(dataFromTable?.NETPAY)}</b>
                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                        บาท
                                                    </span>
                                                </b>
                                            </div>
                                        </aside>
                                    </Row>
                                    {
                                        dataFromTable.YFLAG === "F" ?
                                            <>
                                                <Divider />
                                                <Form.Item label="*** ใบเสร็จนี้ตัดเข้าเงินต้น ***" style={{ margin: 0 }}>
                                                    <b style={{ fontSize: '20px', color: 'red' }}>{currencyFormatOne(dataFromTable?.PAYAMT)}</b>
                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                                </Form.Item>
                                            </>
                                            : null
                                    }
                                    <Divider />
                                    <div style={{ textAlign: 'right', margin: '10px' }}>
                                        <Button onClick={exportToExcel}>Export to Excel</Button>
                                    </div>
                                    <Col span={24}>
                                        <div className='text-right'>
                                            <Space>
                                                <Button onClick={buttonAmt} style={{ background: isClicked ? '#00FF00' : 'white' }}>ตารางดิวก่อนยกเลิก</Button>
                                                <Button onClick={buttonPay} style={{ background: !isClicked ? '#00FF00' : 'white' }}>ตารางดิวหลังยกเลิก</Button>
                                            </Space>
                                        </div>
                                    </Col>
                                    {
                                        checkButton ?
                                            <Table
                                                rowKey={(record) => record.uid}
                                                dataSource={dataTable}
                                                columns={columns}
                                                scroll={{
                                                    x: 1500,
                                                    y: 400,
                                                }}
                                                style={{ padding: 20 }}
                                            >
                                            </Table>
                                            :
                                            <Table
                                                rowKey={(record) => record.uid}
                                                dataSource={dataTableNew}
                                                columns={columns}
                                                scroll={{
                                                    x: 1500,
                                                    y: 400,
                                                }}
                                                style={{ padding: 20 }}
                                            >
                                            </Table>
                                    }
                                </Card>
                            </div>
                        </Form>
                    </Row>
                    {contextHolder}
                </Spin>
            </Modal >
        </>
    )
}
