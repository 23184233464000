import React, { useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "antd";
//import axios from "axios";
import Payment from "./PaymentAuto";

function SearchPDF({ tableSearch }) {
  console.log("tableSearch", tableSearch);
  //PDF
  const [triggerPDF, setTriggerPDF] = useState(false);

  const conponentPDF = useRef();
  const generatePDF = useReactToPrint({
    content: () => conponentPDF.current,
    documentTitle: "ใบรับเงินสัญญา ",
  });

  const handlePDF = () => {
    setTriggerPDF(true); // ตั้ง triggerPDF ที่นี่หลังจากได้รับข้อมูล
    tableSearch.forEach((item, index) => {});
  };

  //โหลด PDF
  useEffect(() => {
    if (triggerPDF) {
      generatePDF();
      setTriggerPDF(false); // Reset the trigger
    }
  }, [triggerPDF, generatePDF]);

  return (
    <>
      <Button type="primary" onClick={() => handlePDF()} danger>
        พิมพ์
      </Button>
      <div className="print-only" ref={conponentPDF} justify={"center"}>
        {tableSearch && tableSearch.length > 0
          ? tableSearch.map((item, index) => (
              <Payment item={item} key={index} />
            ))
          : []}
      </div>
    </>
  );
}
export default SearchPDF;
