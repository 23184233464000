// DataColumnsTabel.js
//import React from "react";
const DataColumnsTabel = [
  {
    title: "ลำดับ",
    dataIndex: "key",
    key: "key",
    render: (text, record, index) => index + 1,
  },
  {
    title: "เลขบิล",
    dataIndex: "TMBILL",
    key: "TMBILL",
  },
  {
    title: "วันที่ใบรับ",
    dataIndex: "TMBILDT",
    key: "TMBILDT",
  },
  {
    title: "สาขาที่รับชำระ",
    dataIndex: "LOCATRECV",
    key: "LOCATRECV",
  },
  {
    title: "เลขสัญญา",
    dataIndex: "CONTNO",
    key: "CONTNO",
  },
  {
    title: "สาขาสัญญา",
    dataIndex: "LOCATPAY",
    key: "LOCATPAY",
  },
  {
    title: "รหัสลูกค้า",
    dataIndex: "CUSCOD",
    key: "CUSCOD",
  },
  {
    title: "ข้อมูลลูกค้า",
    render: (text, record) => `${record.NAME1} ${record.NAME2}`,
    key: "NAME1",
  },
  {
    title: "รหัสชำระ",
    dataIndex: "PAYFOR",
    key: "PAYFOR",
  },
  {
    title: "ชำระด้วย",
    dataIndex: "PAYTYP",
    key: "PAYTYP",
  },
];
export default DataColumnsTabel;
