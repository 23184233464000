import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Card, Col, Row, Spin, Space, Divider, Upload, Modal, message, notification, Image, Input, DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ModalEditGarantor from './ModalEditGarantor';
import Compressor from 'compressorjs';
import axios from 'axios';

import { addImage } from '../../../redux/Image';
import { addGuarantor } from '../../../redux/Guarantor';
import { addCustomer } from '../../../redux/Customer';
import AddGuarantoesNew from './AddGuarantoesNew';
import { checkCCAP, testupload, getAllFolderGuaImg, deleteFolderImgGua } from '../../file_mid/all_api';

function AddGuarantors() {
    const addCustomers = localStorage.getItem('addCustomer')
    const dataAddCustomer = JSON.parse(addCustomers)
    const addGuarantors = localStorage.getItem('addGuarantor')
    const dataAddGuarantor = JSON.parse(addGuarantors)
    var countGu = 0
    const [form] = Form.useForm();
    const [form1] = Form.useForm()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [api, contextHolder] = notification.useNotification();
    const customers = useSelector(state => state.customers)
    const guarantor = useSelector(state => state.guarantor)
    const token = localStorage.getItem('token');
    const imgs = useSelector(state => state.imgs)
    const [loading, setLoading] = useState(false)
    const [guarantors, setGuarantors] = useState([]);
    const [image, setImage] = useState([]);
    const [modaleditGuarantor, setModalEditGuarantor] = useState(false) // customer

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [openAddGuarantor, setOpenAddGuarantor] = useState(false);


    const [fileListGuarantor, setFileListGuarantor] = useState([]);
    const [fileListGuarantor1, setFileListGuarantor1] = useState([]);
    const [fileListImages, setFileListImages] = useState([]);

    const [getimages, setGetImages] = useState([]);
    const [dataPostGuarantor, setDataPostGuarantor] = useState([]) // customerGuarantor
    const [dataPostCustomer, setDataPostCustomer] = useState(dataAddCustomer) // customerGuarantor
    // const [pathImage, setPathImage] = useState({ ...customers?.data })

    const [sendB, setSendB] = useState(true)

    const { confirm } = Modal

    const [dataedit, setDataEdit] = useState([]); //เซตสำหรับแก้ไขข้อมูล 
    const [index1, setIndex] = useState(); //set index send to page edit
    const [indexedit, setIndexEdit] = useState(0); //set index send to page edit 

    // console.log("getimages", getimages)
    // console.log("indexedit", indexedit)

    useEffect(() => {
        //if (modaleditGuarantor === false) {
        getImg()
        //}
    }, [dataedit, indexedit, modaleditGuarantor, sendB])

    useEffect(() => {
        // console.log("length", guarantor?.data?.length)
        if (dataAddGuarantor?.length > 0) {
            //setIndexEdit(guarantor.data.length)
            setGuarantors(dataAddGuarantor)
            countType()
        }
        // if(guarantor?.data?.length > 0){
        //     //setIndexEdit(guarantor.data.length)
        //     setGuarantors(guarantor.data)
        //     countType()
        // }
    }, [])

    const countType = async () => {
        const lastItem = dataAddGuarantor[dataAddGuarantor.length - 1];
        console.log("lastItem", lastItem)
        const newType = lastItem.pathImg.split("/")
        const intTpye = parseInt(newType[4])
        setIndexEdit(intTpye)
    }

    const getImg = async () => {
        const path = `one_images/${dataPostCustomer?.productTypeId === 1 ? "car" : "land"}/${dataPostCustomer.preImg}/5`
        console.log("path-customer", path)
        setLoading(true)
        await axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`)
            //await axios.get(`${getFolderGuaImg}/${shootdata?.dataedit?.pathImg}?token=${token}`)
            .then(res => {
                if (res.data) {
                    // console.log("res.data", res.data)
                    setGetImages(res.data)
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
        setLoading(false)
    }

    const handleOpen = () => {
        setOpenAddGuarantor(true)
    }

    const error = () => {
        Modal.error({
            title: 'บันทึกไม่สำเร็จ',
            content: 'กรุณาลองใหม่อีกครั้ง',
        })
    }
    const unauthorized = () => {
        Modal.error({
            title: 'unauthorized',
            content: 'คุณไม่มีสิทธิ์ใช้งานเมนูนี้',
        })
    }

    // คนค้ำ
    const handleChangeGuarantor = async ({ fileList }) => {
        setFileListGuarantor(fileList);
        var imageListGuarantor = []
        for (const file of fileList) {
            var image = {}
            var filename = file.name
            var image64 = await getBase64(file.originFileObj)
            image.filename = filename
            image.image64 = image64
            image.type = 5
            imageListGuarantor.push(image)
        }
        setFileListGuarantor1({ ...fileListGuarantor1, imageListGuarantor });

    };

    //console.log("dataPostCustomer", dataPostCustomer)
    /////////// ดึงข้อมูลจากรูปบัตรประชาชนคนค้ำ /////////////////////////////////////////////////////////////////  
    const handleScanIdCardGuarantor = async (value) => {
        if (guarantors?.length > 0) {
            var dataListGuarantor = [...guarantors]
        } else {
            var dataListGuarantor = []
        }
        for (let i = 0; i < value.length; i++) {
            var imgId = { image: value[i].image64 }
            setLoading(true)
            const headers = { 'Content-Type': 'application/json' }
            await axios.post(testupload, imgId, { headers: headers })
                .then(async (res) => {
                    delete res.data[0].address
                    if (res.data) {
                        // console.log(res.data[0])
                        const b = await loadDataOneGuarantor(res.data[0])
                        dataListGuarantor.push({ ...b[0], imgs: value[i] })
                        // console.log("BB", dataListGuarantor)
                        setLoading(false)
                    }
                    else {
                        message.info('ไม่สามารถอ่านได้')
                        setLoading(false)
                    }
                })
                .catch((err) => console.log(err))
            setLoading(false)
        }
        // console.log("bb=", dataListGuarantor)
        if (countGu === 0) {
            setGuarantors({ ...guarantors, dataListGuarantor })
            showConfirm({ dataListGuarantor })
        }
    }
    ////////////// ข้อมูลยูสในเดต้าเบสคนค้ำ ////////////////////////////////////////////////////////////////
    const loadDataOneGuarantor = async (data) => {
        setLoading(true)
        var dataListGuarantor = []
        if (!data?.identificationId) {
            countGu = 1
            setFileListGuarantor()
            setFileListGuarantor1()
            console.log("ไม่มีเลขบัตรคนค้ำ")
            alert('ไม่สามารถอ่านบัตรคนค้ำประกันได้')
        }
        // if (data)
        else {
            var one = { identificationId: data.identificationId }
            setLoading(true)
            await axios.post(checkCCAP, one)
                .then((res) => {
                    if (res.data) {
                        //alert('มีข้อมูลในฐานข้อมูล')
                        message.info('คนค้ำมีข้อมูลในฐานข้อมูล')
                        delete res.data.address
                        delete res.data.carLoanDetails
                        delete res.data.emails
                        delete res.data.ojs
                        delete res.data.socials
                        dataListGuarantor.push(res.data)
                        setDataPostGuarantor({ ...dataPostGuarantor, ...data, customerId: 0 })
                        setLoading(false)
                    }
                    else {
                        // alert('คนค้ำไม่พบข้อมูลในฐานข้อมูล')
                        message.info('ไม่พบข้อมูลในฐานข้อมูล')
                        console.log("RES-DATA NO =", data)
                        dataListGuarantor.push(data)
                        setDataPostGuarantor({ ...dataPostGuarantor, ...data, customerId: 0 })
                        setLoading(false)
                    }
                })
                .catch((err) => console.log(err))
            setLoading(false)
        }
        // showConfirm({ dataListGuarantor })
        return dataListGuarantor
    }

    const handleChangeGuarantor1 = async ({ fileList }) => {
        console.log("fileList", fileList)
        // setLoading(true)
        // setAllImg({ ...allImg, ts5: fileList });
        setFileListGuarantor(fileList);
        var imageListGuarantor = []
        // const compressedFileList = await Promise.all(
        //     fileList.map(async (file) => {
        //         // Compress the image
        //         const compressedFile = await new Promise((resolve) => {
        //             new Compressor(file.originFileObj, {
        //                 quality: 0.6, // เช่น, 60%
        //                 success: (result) => {
        //                     resolve(result);
        //                 },
        //             });
        //         });

        //         // Update the file with the compressed version
        //         return {
        //             ...file,
        //             originFileObj: compressedFile,
        //         };
        //     })
        // );
        var i = 0
        for (const file of fileList) {
            const compressedFile = await new Promise((resolve) => {
                new Compressor(file.originFileObj, {
                    quality: 0.6, // เช่น, 60%
                    success: (result) => {
                        resolve(result);
                    },
                });
            });
            var image = {}
            var filename = file.name
            var image64 = await getBase64(compressedFile)
            //var image64 = file.originFileObj
            image.filename = filename
            image.image64 = image64
            image.type = `5.${indexedit + 1}`
            imageListGuarantor.push(image)
            i++
        }
        setFileListGuarantor1({ ...fileListGuarantor1, imageListGuarantor });
        console.log("imageListGuarantor!!", imageListGuarantor)
        // if (imageListGuarantor) {
        //     handleScanIdCardGuarantor(imageListGuarantor);
        // } else {
        //     handleScanIdCardGuarantor([]);
        // }
        setLoading(false)
    };

    const handleSave = () => {
        // console.log("fileListGuarantor1.imageListGuarantor=", fileListGuarantor1.imageListGuarantor)
        if (fileListGuarantor1?.imageListGuarantor?.length > 0) {
            handleScanIdCardGuarantor(fileListGuarantor1.imageListGuarantor)
        }
        var Guarantors = []
        if (image?.length > 0) {
            var Guarantors = [...image]
        }
        if (fileListGuarantor1.imageListGuarantor?.length > 0) {
            Guarantors.push(...fileListGuarantor1.imageListGuarantor)
        }
        setFileListGuarantor1({})
        // console.log("Guarantors!!", Guarantors)
        setImage(Guarantors)
    }

    const showConfirm = ({ dataListGuarantor }) => {
        console.log("dataListGuarantor", dataListGuarantor)
        var note = dataListGuarantor?.length;
        var lastIndex = note - 1;
        console.log("GUarantors", note)
        console.log("lastIndex", lastIndex)
        var imageGuarantors1 = [...dataListGuarantor];
        console.log("imageGuarantors1BeforeMAP", imageGuarantors1)
        guarantors?.map((img, index) => {
            // console.log("imageGuarantors1Before", img)
            imageGuarantors1.push({ ...img.imgs })
            setImage(imageGuarantors1)
            // console.log("imageGuarantors1", imageGuarantors1)
        })
        // dispatch(addImage(imageGuarantors1))

        setLoading(true)
        confirm({
            title: 'เพิ่มคนค้ำ?',
            content: dataListGuarantor[lastIndex]?.snam + ' ' + dataListGuarantor[lastIndex]?.firstname + ' ' + dataListGuarantor[lastIndex]?.lastname,
            onOk() {
                setGuarantors(dataListGuarantor)
                dispatch(addGuarantor(dataListGuarantor))
                localStorage.setItem('addGuarantor', JSON.stringify(dataListGuarantor))
                setLoading(false)
                setTimeout(() => {
                    setFileListGuarantor([])
                },)
            },
            onCancel() {
                message.error('ยกเลิก')
                setGuarantors(guarantors)
                setFileListGuarantor1({})
                setTimeout(() => {
                    setFileListGuarantor([])
                },)
                setLoading(false)
            },
        });
    }


    const handleSubmit = async () => {
        setLoading(true)

        if (guarantors !== undefined) {
            let checkphones = 0
            let checknickname = 0
            guarantors.map((e, index) => {
                // console.log("e", e)
                if (e.nickname === undefined) {
                    checkphones = 1
                    // console.log('No nickname', index + 1)
                    const CheckAddrGua = (placement) => {
                        api.error({
                            message: <b>กรุณาเพิ่มที่อยู่ของผู้ค้ำ</b>,
                            description: `กรุณาเพิ่มที่อยู่ผู้ค้ำ ลำดับที่ ${index + 1}`,
                            placement,
                        });
                    }
                    CheckAddrGua("top")
                }
                if (e.phones === undefined) {
                    checknickname = 1
                    const CheckPhnGua = (placement) => {
                        api.error({
                            message: <b>กรุณาเพิ่มเบอร์โทรศัพท์ของผู้ค้ำ</b>,
                            description: `กรุณาเพิ่มเบอร์โทรศัพท์ของผู้ค้ำ ลำดับที่ ${index + 1}`,
                            placement,
                        });
                    }
                    CheckPhnGua("top")
                    // console.log('No Phones', index + 1)
                }
            })
            if (checkphones !== 1 && checknickname !== 1) {
                // console.log('OK')
                dispatch(addGuarantor(guarantors))
                localStorage.setItem('addGuarantor', JSON.stringify(guarantors))
                navigate('/offercase/addDetail');
                // if (numImg.numImg === 1) {
                //     await axios.post('https://offercase-car-i67f6gaaqa-as.a.run.app/Api/create-pre-part', result)
                //     //await axios.post('http://localhost:8080/Api/create-pre-part', result)
                //         .then(res => {
                //             delete res.data.img
                //             if (res.status === 200) {
                //                 console.log("resAddGu =", res.data)
                //                 dispatch(addCustomer({ ...res.data, ...selectProduct, ...selectTypeCar, ...numImg }))
                //                 setLoading(false)
                //                 navigate('/offercase/addDetail');
                //             }
                //         })
                //         .catch((err) => {
                //             if (err.response.request.status === 401) {
                //                 unauthorized()
                //             }
                //             else {
                //                 error()
                //             }
                //             console.log("err", err)
                //             setLoading(false)
                //         })
                // } else {
                //     setLoading(false)
                //     navigate('/offercase/addDetail');
                // }
            }
        } else {
            dispatch(addGuarantor(guarantors))
            localStorage.setItem('addGuarantor', JSON.stringify(guarantors))
            navigate('/offercase/addDetail');
            // if (numImg.numImg === 1) {
            //     await axios.post('https://offercase-car-i67f6gaaqa-as.a.run.app/Api/create-pre-part', result)
            //     //await axios.post('http://localhost:8080/Api/create-pre-part', result)
            //         .then(res => {
            //             delete res.data.img
            //             if (res.status === 200) {
            //                 console.log("resAddGu =", res.data)
            //                 dispatch(addCustomer({ ...res.data, ...selectProduct, ...selectTypeCar, ...numImg }))
            //                 setLoading(false)
            //                 navigate('/offercase/addDetail');
            //             }
            //         })
            //         .catch((err) => {
            //             if (err.response.request.status === 401) {
            //                 unauthorized()
            //             }
            //             else {
            //                 error()
            //             }
            //             console.log("err", err)
            //             setLoading(false)
            //         })
            // } else {
            //     setLoading(false)
            //     navigate('/offercase/addDetail');
            // }
        }



        // var imageGuarantors = [...image];
        // guarantors?.map((img, index) => {
        //     console.log("img", img)
        //     console.log("img11", img.imgs)
        //     imageGuarantors.push({ ...img.imgs })
        //     setImage(imageGuarantors)
        // })
        // dispatch(addImage(imageGuarantors))
        // dispatch(addImage(imageGuarantors))

        // navigate('/offercase/addDetail');
        setLoading(false)
    }
    const handleGoHome = () => {
        delete dataPostCustomer.companyname
        delete dataPostCustomer.taxID
        dispatch(addCustomer(dataPostCustomer))
        if (guarantors.length > 0) {
            dispatch(addGuarantor(guarantors))
            localStorage.setItem('addGuarantor', JSON.stringify(guarantors))
        }
        navigate('/offercase/addcustomer');
    }

    // const getAddData = ({ images1, imageListGuarantorPush, getIndex }) => {
    const getAddData = ({ AddData2, indexedit1 }) => {
        console.log('AddData2', AddData2, indexedit1)
        console.log('indexedit1 + 1', indexedit1 + 1)
        setIndexEdit(indexedit1 + 1)

        if (guarantors?.length === 0) {
            const AddDatanew = AddData2    //ประกาศตัวแปรเพื่อเก็บค่าที่เพิ่มเข้ามาไปเซตให้ dataArray
            console.log('1')
            setGuarantors([...AddDatanew]);
        } else if (guarantors?.length >= 1) {
            console.log('2')
            const AddDatanew = AddData2   //ประกาศตัวแปรเพื่อเก็บค่าที่เพิ่มเข้ามาไปเซตให้ dataArray
            setGuarantors([...guarantors, ...AddDatanew]);//เก็บค่า state เดิมไว้ พร้อมส่ง type ไปด้วย เพื่อบอกค่าที่ส่ง
        } else {
            console.log('AddData2 indexedit1', AddData2, indexedit1)
        }

    }


    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const handleCancel = () => {
        setPreviewVisible(false);
    }
    //////////// โชว์รูปภาพ ////////////////
    function createImageUrl(imageData) {
        return imageData;
    }
    // แก้ไขข้อมูลจากหน้า AddressEditData
    const fucnEdit = (data, getIndex) => {
        console.log("โยนค่ามาจากหน้าแก้ไขคนค้ำ", data, getIndex)
        let Theedit = [...guarantors]
        Theedit[getIndex] = data[0]
        console.log("Theedit", Theedit)
        //ข้อมูลใหม่แทนที่ข้อมูลเดิมในช่อง Input
        setGuarantors([...Theedit]);
    }

    const genGuarantors = ({ item, index }) => {

        var itemSplit = []
        if (item?.pathImg) {
            itemSplit = item.pathImg.split("/")
        }
        // console.log("itemSplit", itemSplit)
        // console.log("YY", item, index)
        const handleEdit = (dataedit, index1) => {
            //console.log("GG", dataedit, index1)
            setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
            setDataEdit({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setModalEditGuarantor(true);
        }

        // modal deleteImage
        const showConfirmDelete = async ({ dataedit, index }) => {
            setLoading(true)
            const mix = { inPath: dataedit.pathImg }
            await axios.delete(deleteFolderImgGua, { data: mix })
                .then(res => {
                    // console.log("res.data", res)
                    setTimeout(() => {
                        const deletearray = [...guarantors] // กระจายข้อมูลเข้าไปในตัวแปร deletearray
                        deletearray.splice(index, 1); // เป็นการลบข้อมูลใน Array ตาม index ที่เลือกตาม Card
                        dispatch(addGuarantor(deletearray))
                        localStorage.setItem('addGuarantor', JSON.stringify(deletearray))
                        setGuarantors(deletearray);  // เซตค่าเข้าไปเก็บใน dataArray
                        message.success('ลบข้อมูลคนค้ำสำเร็จ')
                    }, 1500)
                    setLoading(false)
                })
                .catch((err) => {
                    console.log("err", err)
                    setLoading(false)
                })
            setLoading(false)


        }

        const showConfirm = (dataedit, index) => {
            confirm({
                title: 'คุณต้องการที่จะลบคนค้ำ...?',
                onOk() {
                    handleDelete(dataedit, index)
                },
                onCancel() {
                    // message.success('ยกเลิก')
                },
            });
        };

        const handleDelete = (dataedit, index) => {
            // console.log("GsadaG", dataedit, index)
            showConfirmDelete({ dataedit, index })
        }


        return (
            <Form
                name="basic"
                labelCol={{
                    span: 12,
                }}
                wrapperCol={{
                    span: 24,
                }}
                style={{
                    maxWidth: '100%',
                }}
                initialValues={{
                    remember: true,
                }}
                form={form1}
                onFinish={handleSubmit}
                autoComplete="off"
            >
                <Card style={{ width: '500px' }} >
                    <Row justify={'center'}><b><u>คนค้ำที่ {index + 1}</u></b></Row>
                    {/* <Row justify={'center'}><b><u>คนค้ำที่ {index + 1}</u></b></Row> */}
                    <div style={{ textAlign: 'center' }}>
                        <Form.Item style={{ margin: 3 }} label='' >
                            <span>เลขบัตรประชาชน : </span>
                            {/* <Input
                                disabled
                                style={{ color: 'black' }}
                                type='text'
                                name='identificationId'
                            >
                            </Input> */}
                            <b>{item?.identificationId}</b>
                        </Form.Item>
                    </div>
                    <Row justify={'center'} gutter={32}>
                        {/* คอลัมน์ที่ 1 */}
                        <Col className='gutter-row' span={12}>
                            <aside style={{ width: '100%' }}>
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label=''>
                                        <span>ชื่อ : </span>
                                        <b>{item?.firstname}</b>
                                    </Form.Item>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label=''>
                                        <span>นามสกุล : </span>
                                        <b>{item?.lastname}</b>
                                    </Form.Item>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label=''>
                                        <span>ชื่อเล่น : </span>
                                        <b>{item?.nickname}</b>
                                    </Form.Item>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    {item?.phones?.length >= 1 ? (
                                        <Form.Item label='' style={{ margin: 3 }}>
                                            <span>เบอร์โทร : </span>
                                            <b>{item?.phones[0].telp}</b>
                                        </Form.Item>
                                    ) : (
                                        <Form.Item label='' style={{ margin: 0 }}>
                                            <span>เบอร์โทร : </span>
                                            <b>{item?.phones}</b>
                                        </Form.Item>
                                    )}
                                </div>
                            </aside>
                        </Col>

                        {/* คอลัมน์ที่ 2 */}
                        <Col className='gutter-row' span={12}>
                            <aside>
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label=''>
                                        <span>เพศ : </span>
                                        <b>{item?.gender}</b>
                                    </Form.Item>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label=''>
                                        <span>ความสัมพันธ์กับผู้กู้ : </span>
                                        <b>{item?.description}</b>
                                    </Form.Item>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label=''>
                                        <span>วันออกบัตร : </span>
                                        <b>{item?.issudt}</b>
                                    </Form.Item>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label=''>
                                        <span>วันบัตรหมดอายุ : </span>
                                        <b>{item?.expdt}</b>
                                    </Form.Item>
                                </div>
                            </aside>
                        </Col>
                    <Divider style={{ margin: 5 }} />
                </Row>
                <Row gutter={32} justify={'center'}>
                    <span>รูปภาพ : </span>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        {/* {item?.imgs?.map((items, index) => { */}
                        {itemSplit.length > 0 ? (
                            <>
                                {getimages?.map((items, index) => {
                                    const segments = items.pathImage.split("/")
                                    if (itemSplit[4] === segments[4]) {
                                        //console.log("test",getimages.length)
                                        return (
                                            // <img width={'300px'} key={index} style={{ margin: 5 }} src={createImageUrl(items.image64)} alt={items.filename} />

                                            <Image width={'300px'} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                                        )
                                    }

                                })}
                            </>
                        ) : null
                        }

                    </Col>
                </Row>
                <Divider style={{ margin: 5 }} />
                <Row justify={'center'}>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Space>
                            <Button type="text"
                                style={{ background: "red", color: 'white' }}
                                // onClick={() => { handleDelete(guarantors[index], index); }}
                                onClick={() => { showConfirm(guarantors[index], index); }}
                            >
                                ลบ
                            </Button>
                            <Button type="text"
                                style={{ background: "#e0e0e0" }}
                                onClick={() => { handleEdit(guarantors[index], index); }}
                            // onClick={() => { handleEdit(guarantors[index], index); }}
                            >
                                แก้ไข
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Card>
            </Form >
        )
}

return (
    <>
        <Row justify={'center'}>
            <Card style={{ width: '1100px' }}>

                <Col style={{ textAlign: 'center' }} >
                    <b><h2>ข้อมูลคนค้ำประกัน</h2></b>
                </Col>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Row justify={'center'}>
                        <Form
                            name="basic"
                            labelCol={{
                                span: 12,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            style={{
                                width: '100%',
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            form={form}
                            onFinish={handleSubmit}
                            autoComplete="off"
                        >
                            <Divider style={{ margin: 5 }} />
                            <Row justify={'center'}>
                                {guarantors?.length > 0 ?
                                    <>
                                        {guarantors?.map((item, index) => {
                                            return genGuarantors({ item, index, key: `{item.identificationId} - ${index}` });
                                        })}
                                    </>
                                    :
                                    null
                                }
                            </Row>
                            <Row justify={'center'} style={{ margin: 10 }} align={'middle'}>
                                <Col span={24} style={{ textAlign: 'center' }}>
                                    <Form.Item label='' name='imgGuarantor'>
                                        <Button
                                            type='primary'
                                            onClick={handleOpen}
                                        >
                                            เพิ่มคนค้ำ
                                        </Button>
                                        {/* <Upload
                                                multiple={true}
                                                listType="picture-card"
                                                fileList={fileListGuarantor}
                                                onPreview={handlePreview}
                                                onChange={handleChangeGuarantor1}
                                                // onChange={handleChangeGuarantor}
                                                beforeUpload={() => false}
                                            >
                                                {fileListGuarantor?.length >= 10 ? null : (
                                                    <div>
                                                        <PlusOutlined />
                                                        <div className="ant-upload-text">upload</div>
                                                    </div>
                                                )}
                                            </Upload>
                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                            </Modal> */}
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/* <Row justify={'center'} style={{ margin: 10 }}>
                                    {fileListGuarantor1?.imageListGuarantor?.length >= 1 ?
                                        <>
                                            <Button type="text" style={{ background: 'green', color: 'white' }} onClick={handleSave}>
                                                บันทึก
                                            </Button>
                                        </>
                                        :
                                        <>
                                            {
                                                fileListGuarantor1?.imageListGuarantor?.length === 0 ?
                                                    <>
                                                        <Button type="text" disabled style={{ background: 'green', color: 'white' }} onClick={handleSave}>
                                                            บันทึก
                                                        </Button>
                                                    </>
                                                    : null
                                            }
                                        </>
                                    }
                                </Row> */}
                            <Row justify={'center'}>
                                <h3><b style={{ color: 'red' }}>***ตรวจสอบข้อมูลคนค้ำว่าถูกต้อง แล้วกดต่อไป***</b></h3>
                            </Row>
                            <Divider style={{ margin: 5 }} />
                            <Row justify={'center'} >
                                <Space>
                                    <Button type="text" style={{ background: "#e0e0e0" }} onClick={(e) => { handleGoHome(e) }}>
                                        ย้อนกลับ
                                    </Button>
                                    {/* {guarantors?.length > 0 ?
                                            <> */}

                                    <Button type="primary" htmlType="submit">
                                        ต่อไป
                                    </Button>
                                    {/* </>
                                            :
                                            <>
                                                <Button type="primary" htmlType="submit" disabled>
                                                    ต่อไป
                                                </Button>
                                            </>
                                        } */}
                                </Space>
                            </Row>

                        </Form>
                    </Row>
                </Spin >
            </Card >
        </Row >
        {contextHolder}
        {
            modaleditGuarantor ?
                <ModalEditGarantor
                    open={modaleditGuarantor}
                    close={setModalEditGuarantor}
                    sb={setSendB}
                    dataGuarantor={guarantors}
                    shootdata={dataedit} // 
                    fucnEdit={fucnEdit}
                    dataindex={index1}
                />
                :
                null
        }
        {
            openAddGuarantor ?
                <AddGuarantoesNew
                    open={openAddGuarantor}
                    close={setOpenAddGuarantor}
                    dataGuarantor={guarantors} //ข้อมูลที่มีอยู่โยนไปหน้าเพิ่มคนค้ำ
                    getAddData={getAddData}
                    dataindex={index1}
                    indexedit={indexedit}
                />
                :
                null
        }
    </>
)
}

export default AddGuarantors