import React, { useEffect, useState } from 'react'
import { Button, Form, Card, Col, Row, Spin, Space, Divider, message, notification, Image, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import TextArea from 'antd/es/input/TextArea';
import EditGua from './Guarantor/EditGua';
import AddGua from './Guarantor/AddGua';
import dayjs from 'dayjs';
import { checkguarantors, deleteFolderImgGuaLand, deletegarno, getAllFolderGuaImg } from '../../file_mid/all_api';

function EditGuarantorLandNEW({ getData, close, notPassData, sendback, saveResendNote, setSaveResendNote, relationship }) {

    const [form] = Form.useForm();
    const [form1] = Form.useForm()
    const { confirm } = Modal
    const [api, contextHolder] = notification.useNotification();
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false)
    const [guarantors, setGuarantors] = useState([]);
    const [addCount, setAddCount] = useState(0);
    const [getimages, setGetImages] = useState([]);
    const [sendB, setSendB] = useState(true)
    const [dataedit, setDataEdit] = useState([]); //เซตสำหรับแก้ไขข้อมูล 
    const [index1, setIndex] = useState(); //set index send to page edit
    const [indexedit, setIndexEdit] = useState(0); //set index send to page edit 
    const [isModalsAddGua, setIsModalsAddGua] = useState(false) // เปิด/ปิด modal เพิ่มคนค้ำ
    const [isModalsEditGua, setIsModalsEditGua] = useState(false) // เปิด/ปิด modal แก้ไขคนค้ำ
    const [resendNote, setResendNote] = useState();

    useEffect(() => {
        var dataloadGua = { typeLoan: 'land', loanId: getData?.lands?.landLoanDetails?.landLoanId }
        loadGuarantors(dataloadGua)
        getImg()
        form.setFieldsValue({
            ...notPassData
        })
        if (saveResendNote.customerNote) {
            setResendNote(saveResendNote)
            form.setFieldsValue({
                ...saveResendNote
            })
        }
    }, [dataedit, indexedit, sendB, isModalsAddGua, isModalsEditGua])

    useEffect(() => {
        if (guarantors?.length > 0) {
            countType()
        }
    }, [guarantors])

    useEffect(() => {
        if (addCount === 2) {
            Deletegarno()
        }
    }, [addCount])

    // console.log("guarantors", guarantors)

    const countType = async () => {
        const lastItem = guarantors[guarantors.length - 1];
        const newType = lastItem.pathImg.split("/")
        const intTpye = parseInt(newType[4])
        if (isNaN(intTpye)) {
            var newIntType = guarantors.length
            console.log("if")
        } else {
            console.log("else")
            var newIntType = intTpye
        }
        setIndexEdit(newIntType)
        // setIndexEdit(intTpye)
    }

    const loadGuarantors = async (value) => {
        await axios.post(checkguarantors, value)
            .then(res => {
                if (res.status === 200) {
                    console.log("คนค้ำ", res.data)
                    const modifiedData = res.data.map((e) => {
                        return { ...e, checkDup: true };
                    })
                    setGuarantors(modifiedData)
                } else if (res.status === 204) {
                    setGuarantors([])
                    // console.log("ไม่พบข้อมูล")
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const getImg = async () => {
        const path = `one_images/land/${getData?.lands?.landId}/5`
        console.log("path-customer", path)
        setLoading(true)
        await axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`)
            .then(res => {
                if (res.data) {
                    console.log("res.data", res.data)
                    setGetImages(res.data)
                    setLoading(false)
                }
            })
            .catch((err) => {
                // console.log("err", err)
                setLoading(false)
            })
        setLoading(false)
    }

    const handleAddGua = () => {
        setIsModalsAddGua(true)
    }

    const handleSubmit = async () => {
        setLoading(true)
        sendback(dayjs())
        if (getData?.lands?.landLoanDetails?.approvalStatus !== 21) {
            handleSendNote()
        }
        setTimeout(() => {
            SuccGua('top')
            setLoading(false)
        }, 1500)

    }

    const handleSendNote = async () => {
        if (resendNote !== undefined) {
            if (resendNote.guarantorNote !== "") {
                setSaveResendNote({ ...saveResendNote, ...resendNote })
            } else {
                setSaveResendNote({ ...saveResendNote, guarantorNote: null })
            }
        } else {
            // console.log("= undefined")
        }
    };


    const SuccGua = (placement) => {
        api.success({
            message: <b>บันทึกสำเร็จ</b>,
            // description: `กรุณาเพิ่มที่อยู่ผู้ค้ำ ลำดับที่ ${index + 1}`,
            placement,
        });
    }

    const handleClose = () => {
        close()
    }

    const Deletegarno = async () => {
        setLoading(true)
        await axios.put(deletegarno, guarantors)
            .then(res => {
                if (res.status === 200) {
                    // console.log("res", res.data)
                    if (res.data.length === 1) {
                        setGuarantors([])
                    }
                    setAddCount(0)
                    setLoading(false)
                    message.success('ลบข้อมูลคนค้ำสำเร็จ')
                }
            })
            .catch(err => {
                console.log(err)
            }
            )
        setLoading(false)
    }

    const genGuarantors = ({ item, index }) => {

        var itemSplit = []
        if (item?.pathImg) {
            itemSplit = item.pathImg.split("/")
        }
        const handleEdit = (dataedit, index1) => {
            setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
            setDataEdit({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setIsModalsEditGua(true);
        }

        const handleDelete = (dataedit, index) => {
            console.log("dataedit", dataedit)
            confirm({
                title: 'คุณต้องการที่จะลบข้อมูลหรือไม่...?',
                content: 'กด OK เพื่อยืนยันการลบคนค้ำประกันแล้วรูปภาพทั้งหมดของผู้กู้',
                onOk() {
                    showConfirmDelete({ dataedit, index })
                },
                onCancel() {
                    message.error('ยกเลิกการลบข้อมูล')
                },
            });
        };

        // modal deleteImage
        const showConfirmDelete = async ({ dataedit, index }) => {
            setLoading(true)
            console.log("getimages", getimages)
            const folder = []
            const split = dataedit?.pathImg.split('/')[4];
            getimages.map((item, index1) => {
                var image = {}
                // const count = `${index + 1}`
                console.log("split = ", item?.pathImage.split('/')[4], split);
                if (item?.pathImage.split('/')[4] === split) {
                    image.attr = item.attr
                    image.data = item.data
                    image.idImage = item.idImage
                    image.pathImage = item.pathImage
                    folder.push(image)
                }
                // console.log("count = [1]", folder);
            })
            // console.log("folder", folder)
            const mix = { inPath: dataedit.pathImg, guaImg: folder }
            // console.log("mix", mix)
            await axios.delete(deleteFolderImgGuaLand, { data: mix })
                .then(res => {
                    if (guarantors.length === 1) {
                        setAddCount(2)
                        console.log("1")
                    } else {
                        console.log("2")
                        const data = guarantors.filter(item => item.garno !== index + 1);

                        // เปลี่ยน "garno" ที่มีค่าเป็น 3 เป็น 2
                        data.forEach((item, index) => {
                            item.garno = index + 1;
                        });
                        setGuarantors(data)
                        setAddCount(2)
                    }
                    // setTimeout(() => {

                    // }, 1500)
                    setLoading(false)
                })
                .catch((err) => {
                    console.log("err", err)
                    setLoading(false)
                })
            setLoading(false)
        }

        return (
            <Form
                name="basic"
                labelCol={{
                    span: 12,
                }}
                wrapperCol={{
                    span: 24,
                }}
                style={{
                    maxWidth: '100%',
                }}
                initialValues={{
                    remember: true,
                }}
                form={form1}
                onFinish={handleSubmit}
                autoComplete="off"
            >
                <Card style={{ width: '100%' }}                >
                    <Row justify={'center'}><b><u>คนค้ำที่ {index + 1}</u></b></Row>
                    <div style={{ textAlign: 'center' }}>
                        <Form.Item style={{ margin: 3 }} label='' >
                            <span>เลขบัตรประชาชน : </span>
                            <b>{item?.identificationId}</b>
                        </Form.Item>
                    </div>
                    <Row justify={'center'} gutter={32} className='main2'>
                        {/* คอลัมน์ที่ 1 */}
                        <Col className='gutter-row' span={12}>
                            <aside style={{ width: '100%' }}>
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label='' >
                                        <span>ชื่อ : </span>
                                        <b>{item?.firstname}</b>
                                    </Form.Item>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label='' >
                                        <span>นามสกุล : </span>
                                        <b>{item?.lastname}</b>
                                    </Form.Item>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label='' >
                                        <span>ชื่อเล่น : </span>
                                        <b>{item?.nickname}</b>
                                    </Form.Item>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    {item?.phones?.length >= 1 ?
                                        <>
                                            <Form.Item label='' style={{ margin: 3 }}>
                                                <span>เบอร์โทร : </span>
                                                <b>{item?.phones[0].telp}</b>
                                            </Form.Item>
                                        </>
                                        :
                                        <Form.Item label='' style={{ margin: 0 }}>
                                            <span>เบอร์โทร : </span>
                                            <b>{item?.phones}</b>
                                        </Form.Item>
                                    }
                                </div>
                            </aside>
                        </Col>

                        {/* คอลัมน์ที่ 2 */}
                        <Col className='gutter-row' span={12}>
                            <aside style={{ width: '100%' }}>
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label='' >
                                        <span>วันออกบัตร : </span>
                                        <b>{item?.issudt}</b>
                                    </Form.Item>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label='' >
                                        <span>วันบัตรหมดอายุ : </span>
                                        <b>{item?.expdt}</b>
                                    </Form.Item>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label='' >
                                        <span>ความสัมพันธ์กับผู้กู้ : </span>
                                        <b>{item?.description}</b>
                                    </Form.Item>
                                </div>
                            </aside>
                        </Col>

                        <Divider style={{ margin: 5 }} />
                    </Row>
                    <Row gutter={32} justify={'center'}>
                        <span>รูปภาพ : </span>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            {itemSplit.length > 0 ? (
                                <>
                                    {getimages?.map((items, index) => {
                                        const segments = items.pathImage.split("/")
                                        if (itemSplit[4] === segments[4]) {
                                            return (
                                                <Image width={'30%'} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                                            )
                                        }

                                    })}
                                </>
                            ) : null
                            }
                        </Col>
                    </Row>
                    <Divider style={{ margin: 5 }} />
                    <Row justify={'center'}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Space>
                                {
                                    getData.lands.landLoanDetails.approvalStatus === 21 ?
                                        null
                                        :
                                        <>
                                            {
                                                item?.approval ?
                                                    null
                                                    :
                                                    <>
                                                        <Button
                                                            type="text"
                                                            style={{ background: "red", color: 'white' }}
                                                            onClick={() => { handleDelete(guarantors[index], index); }}
                                                        >
                                                            ลบคนค้ำ
                                                        </Button>
                                                        <Button
                                                            type="text"
                                                            style={{ background: "#e0e0e0" }}
                                                            onClick={() => { handleEdit(guarantors[index], index); }}
                                                        >
                                                            แก้ไขคนค้ำ
                                                        </Button>
                                                    </>
                                            }

                                        </>
                                }
                            </Space>
                        </Col>
                    </Row>
                </Card>
            </Form>
        )
    }

    return (
        <>
            <Row justify={'center'}>
                <Card style={{ width: '1100px' }}>

                    <Col style={{ textAlign: 'center' }} >
                        <b><h2>ข้อมูลคนค้ำประกัน</h2></b>
                    </Col>
                    <Spin spinning={loading} size='large' tip=" Loading... ">
                        <Row justify={'center'}>
                            <Form
                                name="basic"
                                labelCol={{
                                    span: 12,
                                }}
                                wrapperCol={{
                                    span: 24,
                                }}
                                style={{
                                    width: '100%',
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                form={form}
                                onFinish={handleSubmit}
                                autoComplete="off"
                            >
                                <Divider style={{ margin: 5 }} />
                                {
                                    getData?.lands?.landLoanDetails?.approvalStatus === 21 ?
                                        null
                                        :
                                        <>
                                            <Form.Item label="หมายเหตุ(ธุรการ)" name="npGuarantorNote">
                                                <TextArea rows={5} name='npGuarantorNote' disabled />
                                            </Form.Item>
                                            <Form.Item label="หมายเหตุ(การตลาด)" name="guarantorNote">
                                                <TextArea rows={5} name='guarantorNote' placeholder='หมายเหตุถึงธุรการ' onChange={(e) => setResendNote({ guarantorNote: e.target.value })} />
                                            </Form.Item>
                                        </>
                                }

                                <Row justify={'center'}>
                                    {guarantors?.length > 0 ?
                                        <>
                                            {guarantors?.map((item, index) => {
                                                return genGuarantors({ item, index, key: `{item.identificationId} - ${index}` });
                                            })}
                                        </>
                                        :
                                        null
                                    }
                                </Row>
                                <Row justify={'center'} style={{ margin: 10 }} align={'middle'}>
                                    <Col span={24} style={{ textAlign: 'center' }}>
                                        <Form.Item label='' name='imgGuarantor'>
                                            <Button
                                                type='primary'
                                                onClick={handleAddGua}
                                                icon={<PlusOutlined />}
                                            >
                                                เพิ่มคนค้ำ
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row justify={'center'}>
                                    <h3><b style={{ color: 'red' }}>***ตรวจสอบข้อมูลคนค้ำว่าถูกต้อง แล้วกดต่อไป***</b></h3>
                                </Row>
                                <Divider style={{ margin: 5 }} />
                                <Row justify={'center'} >
                                    <Space>
                                        <Button type="text" style={{ background: "#e0e0e0" }} onClick={handleClose}>
                                            ปิด
                                        </Button>
                                        <Button type="primary" htmlType="submit">
                                            บันทึก
                                        </Button>
                                    </Space>
                                </Row>

                            </Form>
                        </Row>
                    </Spin >
                </Card >
                {contextHolder}
            </Row >
            {
                isModalsAddGua ?
                    <AddGua
                        pregarno={guarantors.length}
                        getData={getData}
                        open={isModalsAddGua}
                        close={setIsModalsAddGua}
                        sb={setSendB}
                        oldGua={guarantors}
                        dataindex={index1}
                        indexedit={indexedit}
                        relationship={relationship}


                    />
                    :
                    null
            }
            {
                isModalsEditGua ?
                    <EditGua
                        open={isModalsEditGua}
                        close={setIsModalsEditGua}
                        sb={setSendB}
                        dataGuarantor={guarantors}
                        shootdata={dataedit}
                        // fucnEdit={fucnEdit}
                        dataindex={index1}
                        relationship={relationship}
                    />
                    :
                    null
            }

        </>
    )
}

export default EditGuarantorLandNEW