import { PlusOutlined } from '@ant-design/icons'
import { Button, Card, Divider, Form, Row, Space, Spin, Modal, Col, message, Image, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { addImage } from '../../../redux/Image';
import { addGuarantor } from '../../../redux/Guarantor';
// import { addCustomer } from '../../../redux/Customer';
import AddGua from './guarantor/AddGua';
import EditGua from './guarantor/EditGua';
import { checkguarantors, getAllFolderGuaImg, deleteFolderImgGua, getGuarantor, deleteguarantor, deleteGua, deleteFolderImgGuaCar } from '../../file_mid/all_api';
// import AddGuarantoesNew from './AddGuarantoesNew';

function Guarantor({ close, changepage, data, page, relationship }) {

    const addCustomers = localStorage.getItem('addCustomer')
    const dataAddCustomer = JSON.parse(addCustomers)
    const addGuarantors = localStorage.getItem('addGuarantor')
    const dataAddGuarantor = JSON.parse(addGuarantors)
    const [getimages, setGetImages] = useState([]);
    // const [openAddGuarantor, setOpenAddGuarantor] = useState(false);

    const [api, contextHolder] = notification.useNotification();
    // const [dataPostCustomer, setDataPostCustomer] = useState(dataAddCustomer) // customerGuarantor
    const [modaleditGuarantor, setModalEditGuarantor] = useState(false) // customer
    const { confirm } = Modal
    const [loading, setLoading] = useState(false)
    const [isModalAddGua, setIsModalAddGua] = useState(false)
    const [isModalEditGua, setIsModalEditGua] = useState(false)
    const [guarantors, setGuarantors] = useState([]);

    const [dataedit, setDataEdit] = useState([]); //เซตสำหรับแก้ไขข้อมูล 
    const [index1, setIndex] = useState(); //set index send to page edit
    const [indexedit, setIndexEdit] = useState(0); //set index send to page edit 
    const [checkGua, setCheckGua] = useState('');

    // const navigate = useNavigate();
    const dispatch = useDispatch();

    // const [form] = Form.useForm();
    const [form1] = Form.useForm()

    // const dd = localStorage.getItem('dddd')
    // localStorage.setItem('addCar', JSON.stringify(dataPost))

    var countGu = 0

    const customers = useSelector(state => state.customers)
    const guarantor = useSelector(state => state.guarantor)
    const token = localStorage.getItem('token');
    const imgs = useSelector(state => state.imgs)
    const [sendB, setSendB] = useState()
    // console.log('checkGua', checkGua);


    useEffect(() => {
        getImg()
    }, [dataedit, indexedit, modaleditGuarantor, sendB])

    useEffect(() => {
        // if (dataAddGuarantor?.length > 0) {
        //     setGuarantors(dataAddGuarantor)
        //     countType()
        // }
        getGuarantors()
    }, [!isModalAddGua, !isModalEditGua])
    // }, [])


    const getGuarantors = async () => {
        setLoading(true)
        await axios.post(getGuarantor, { typeLoan: "car", carLandId: data?.carId })
            // await axios.post("http://localhost:8080/api/checkguarantor", { typeLoan: "car", carLandId: 14983 })
            .then(res => {
                // console.log("NewGuarantors", res.data)
                // setGuarantors(res.data)
                if (res.data?.length > 0) {
                    // setGuarantors(dataAddGuarantor)
                    setGuarantors(res.data)
                    countType(res.data)
                }
                setLoading(false)
            })
            .catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
    }



    const handleGo = () => {
        changepage(page + 1)
    }
    const handleBack = () => {
        changepage(page - 1)
    }
    const handleClose = () => {
        close()
    }

    const onChangeAddGua = () => {
        setIsModalAddGua(true)
        setCheckGua('1')
    }

    const countType = async (value) => {
        // console.log("value", value)
        // const lastItem = dataAddGuarantor[dataAddGuarantor.length - 1];
        const lastItem = value[value.length - 1];
        // console.log("lastItem", lastItem)
        const newType = lastItem.pathImg.split("/")
        const intTpye = parseInt(newType[4])
        setIndexEdit(intTpye)
    }

    const getImg = async () => {
        const path = `one_images/car/${data.carId}/5`
        // console.log("path-customer", path)
        setLoading(true)
        await axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`)
            .then(res => {
                if (res.data) {
                    // console.log("res.data", res.data)
                    setGetImages(res.data)
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
        setLoading(false)
    }

    const showConfirmCancel = () => {
        close(false)
    };

    const handleSubmit = async () => {
        setLoading(true)
        if (guarantors.length > 0) {
            if (guarantors !== undefined) {
                let checkphones = 0
                let checknickname = 0
                guarantors.map((e, index) => {
                    // console.log("e", e)
                    if (e.nickname === undefined) {
                        checkphones = 1
                        // console.log('No nickname', index + 1)
                        const CheckAddrGua = (placement) => {
                            api.error({
                                message: <b>กรุณาเพิ่มที่อยู่ของผู้ค้ำ</b>,
                                description: `กรุณาเพิ่มที่อยู่ผู้ค้ำ ลำดับที่ ${index + 1}`,
                                placement,
                            });
                        }
                        CheckAddrGua("top")
                    }
                    if (e.phones === undefined) {
                        checknickname = 1
                        const CheckPhnGua = (placement) => {
                            api.error({
                                message: <b>กรุณาเพิ่มเบอร์โทรศัพท์ของผู้ค้ำ</b>,
                                description: `กรุณาเพิ่มเบอร์โทรศัพท์ของผู้ค้ำ ลำดับที่ ${index + 1}`,
                                placement,
                            });
                        }
                        CheckPhnGua("top")
                        // console.log('No Phones', index + 1)
                    }
                })
                if (checkphones !== 1 && checknickname !== 1) {
                    // console.log('OK')
                    // dispatch(addGuarantor(guarantors))
                    localStorage.setItem('addGuarantor', JSON.stringify(guarantors))
                    handleGo()

                }
            } else {
                // dispatch(addGuarantor(guarantors))
                localStorage.setItem('addGuarantor', JSON.stringify(guarantors))
                handleGo()

            }

        } else {
            alert('กรุณาเพิ่มคนค้ำ')
        }

        setLoading(false)
    }

    const getAddData = ({ AddData2, indexedit1 }) => {
        setIndexEdit(indexedit1 + 1)
        if (guarantors?.length === 0) {
            const AddDatanew = AddData2    //ประกาศตัวแปรเพื่อเก็บค่าที่เพิ่มเข้ามาไปเซตให้ dataArray
            setGuarantors([...AddDatanew]);
        } else if (guarantors?.length >= 1) {
            const AddDatanew = AddData2   //ประกาศตัวแปรเพื่อเก็บค่าที่เพิ่มเข้ามาไปเซตให้ dataArray
            setGuarantors([...guarantors, ...AddDatanew]);//เก็บค่า state เดิมไว้ พร้อมส่ง type ไปด้วย เพื่อบอกค่าที่ส่ง
        } else {
        }

    }

    // แก้ไขข้อมูลจากหน้า AddressEditData
    const fucnEdit = (data, getIndex) => {
        // console.log("โยนค่ามาจากหน้าแก้ไขคนค้ำ", data, getIndex)
        let Theedit = [...guarantors]
        Theedit[getIndex] = data[0]
        // console.log("Theedit", Theedit)
        //ข้อมูลใหม่แทนที่ข้อมูลเดิมในช่อง Input
        setGuarantors([...Theedit]);
    }

    const genGuarantors = ({ item, index }) => {
        console.log('item', item);


        var itemSplit = []
        if (item?.pathImg) {
            itemSplit = item.pathImg.split("/")
        }
        const handleEdit = (dataedit, index1) => {
            setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
            setDataEdit({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setIsModalEditGua(true);
        }

        const deleteGuarantor = async ({ dataedit, index }) => {
            // console.log('dataedit', dataedit)
            let data = {
                typeLoan: "car",
                carLandId: dataedit?.carlandId,
                customerId: dataedit?.customerId
            }
            setLoading(true)

            await axios.put(deleteGua, data)
                .then(async (res) => {
                    // console.log('22222', res.data.status)
                    // console.log('22222555', res.data)

                    if (res.data) {
                        await showConfirmDelete({ dataedit, index })
                        // console.log("res.data", res.data)
                        setLoading(false)
                    }
                })
                .catch((err) => {
                    console.log("err", err)
                })
            setLoading(false)
        }

        // modal deleteImage
        const showConfirmDelete = async ({ dataedit, index }) => {
            // console.log('5555555', dataedit, index);
            // console.log('5555555',dataedit);
            setLoading(true)
            const folder = []
            const split = dataedit?.pathImg.split('/')[4];
            getimages.map((item, index1) => {
                var image = {}
                // const count = `${index + 1}`
                console.log("split = ", item?.pathImage.split('/')[4], split);
                if (item?.pathImage.split('/')[4] === split) {
                    image.attr = item.attr
                    image.data = item.data
                    image.idImage = item.idImage
                    image.pathImage = item.pathImage
                    folder.push(image)
                }
                // console.log("count = [1]", folder);
            })
            const mix = { inPath: dataedit.pathImg, guaImg: folder }
            await axios.delete(deleteFolderImgGuaCar, { data: mix })
                .then(res => {
                    // console.log("res.data", res)
                    setTimeout(() => {
                        const deletearray = [...guarantors] // กระจายข้อมูลเข้าไปในตัวแปร deletearray
                        deletearray.splice(index, 1); // เป็นการลบข้อมูลใน Array ตาม index ที่เลือกตาม Card
                        // dispatch(addGuarantor(deletearray))
                        localStorage.setItem('addGuarantor', JSON.stringify(deletearray))
                        setGuarantors(deletearray);  // เซตค่าเข้าไปเก็บใน dataArray
                        // deleteGuarantor(dataedit, index);  //ลบคนค้ำ
                        message.success('ลบข้อมูลคนค้ำสำเร็จ')
                    }, 1500)
                    setLoading(false)
                })
                .catch((err) => {
                    console.log("err", err)
                    setLoading(false)
                })
            setLoading(false)


        }

        const showConfirm = (dataedit, index) => {
            confirm({
                title: 'คุณต้องการที่จะลบคนค้ำ...?',
                onOk() {
                    handleDelete(dataedit, index)
                },
                onCancel() {
                    // message.success('ยกเลิก')
                },
            });
        };

        const handleDelete = (dataedit, index) => {
            deleteGuarantor({ dataedit, index }) // ลบคนค้ำ
            // showConfirmDelete({ dataedit, index })

        }


        return (
            <Form
                name="basic"
                labelCol={{
                    span: 12,
                }}
                wrapperCol={{
                    span: 24,
                }}
                style={{
                    maxWidth: '100%',
                }}
                initialValues={{
                    remember: true,
                }}
                form={form1}
                // onFinish={handleSubmit}
                autoComplete="off"
            >
                <Card style={{ width: '100%' }}                >
                    <Row justify={'center'}><b><u>คนค้ำที่ {index + 1}</u></b></Row>
                    <div style={{ textAlign: 'center' }}>
                        <Form.Item style={{ margin: 3 }} label='' >
                            <span>เลขบัตรประชาชน : </span>
                            <b>{item?.identificationId}</b>
                        </Form.Item>
                    </div>
                    <Row justify={'center'} gutter={32} className='main2'>
                        <Col className='gutter-row' span={24}>
                            <aside style={{ width: '100%', }} >
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label='' >
                                        <span>ชื่อ : </span>
                                        <b>{item?.firstname}</b>
                                    </Form.Item>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label='' >
                                        <span>นามสกุล : </span>
                                        <b>{item?.lastname}</b>
                                    </Form.Item>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label='' >
                                        <span>ชื่อเล่น : </span>
                                        <b>{item?.nickname}</b>
                                    </Form.Item>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    {item?.phones?.length >= 1 ?
                                        <>
                                            <Form.Item label='' style={{ margin: 3 }}>
                                                <span>เบอร์โทร : </span>
                                                <b>{item?.phones[0].telp}</b>
                                            </Form.Item>
                                        </>
                                        :
                                        <Form.Item label='' style={{ margin: 0 }}>
                                            <span>เบอร์โทร : </span>
                                            <b>{item?.phones}</b>
                                        </Form.Item>
                                    }
                                </div>
                                {/* <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label=''>
                                        <span>วันเกิด : </span>
                                        <b>{item?.birthdate}</b>
                                    </Form.Item>
                                </div> */}
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label='' >
                                        <span>เพศ : </span>
                                        <b>{item?.gender}</b>
                                    </Form.Item>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label='' >
                                        <span>ความสัมพันธ์กับผู้กู้ : </span>
                                        <b>{item?.description}</b>
                                    </Form.Item>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label='' >
                                        <span>วันออกบัตร : </span>
                                        <b>{item?.issudt}</b>
                                    </Form.Item>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label='' >
                                        <span>วันบัตรหมดอายุ : </span>
                                        <b>{item?.expdt}</b>
                                    </Form.Item>
                                </div>
                            </aside>
                        </Col>
                        <Divider style={{ margin: 5 }} />
                    </Row>
                    <Row gutter={32} justify={'center'}>
                        <span>รูปภาพ : </span>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            {
                                itemSplit.length > 0 ? (
                                    <>
                                        {getimages?.map((items, index) => {
                                            const segments = items.pathImage.split("/")
                                            if (itemSplit[4] === segments[4]) {
                                                return (
                                                    // <img width={'300px'} key={index} style={{ margin: 5 }} src={createImageUrl(items.image64)} alt={items.filename} />
                                                    // <Image width={'100px'} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                                                    <Image
                                                        key={index}
                                                        src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`}
                                                        alt={`Image ${index + 1}`}
                                                        style={{
                                                            width: '50px', // กำหนดความกว้าง
                                                            height: '50px', // กำหนดความสูง
                                                            objectFit: 'cover', // ปรับให้รูปภาพอยู่ในกรอบ
                                                            margin: '5px', // ระยะห่างระหว่างรูปภาพ
                                                        }}
                                                    />
                                                )
                                            }

                                        })}
                                    </>
                                ) : null
                            }
                        </Col>
                    </Row>
                    <Divider style={{ margin: 5 }} />
                    <Row justify={'center'}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Space>
                                <Button type="text"
                                    style={{ background: "red", color: 'white' }}
                                    onClick={() => { showConfirm(guarantors[index], index); }}
                                >
                                    ลบ
                                </Button>
                                <Button type="text"
                                    style={{ background: "#e0e0e0" }}
                                    onClick={() => { handleEdit(guarantors[index], index); }}
                                >
                                    แก้ไข
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Card>
            </Form>
        )
    }

    return (
        <Card style={{ width: '100%' }}>
            <Spin spinning={loading} size='large' tip=" Loading... ">
                <Row justify={'center'}> <b><h2>คนค้ำประกัน</h2></b></Row>
                <Divider />
                <Row justify={'center'}>
                    <Col span={24} style={{ textAlign: "right" }}>
                        <Button icon={<PlusOutlined />} onClick={onChangeAddGua}>เพิ่มคนค้ำ</Button>
                    </Col>
                    {/* <Col span={24}>

                    </Col> */}
                    <Divider />
                    <Row justify={'center'}>
                        {guarantors?.length > 0 ?
                            <>
                                {guarantors?.map((item, index) => {
                                    return genGuarantors({ item, index, key: `{item.identificationId} - ${index}` });
                                })}
                            </>
                            :
                            null
                        }
                    </Row>
                </Row>
                <Divider />
                <Row justify={'center'} style={{ marginTop: '15px' }} >
                    <Space>
                        {/* <Button type="primary" style={{ background: "#bfbfbf" }} onClick={onChangeBack}>
                            ย้อนกลับ
                        </Button>
                        <Button type="primary" style={{ background: "red" }} onClick={showConfirmCancel}>
                            ยกเลิก
                        </Button>
                        <Button type="primary" style={{ background: "green" }} onClick={handleSubmit} >
                            ต่อไป
                        </Button> */}

                        <Button type='primary' onClick={handleBack} >ย้อนกลับ</Button>
                        <Button type='primary' danger onClick={handleClose} >ยกเลิก</Button>
                        <Button type='primary' onClick={handleSubmit} style={{ backgroundColor: "green" }} >ต่อไป</Button>
                    </Space>
                </Row>
                {/* </Form> */}
            </Spin>
            {
                isModalAddGua ?
                    <AddGua
                        open={isModalAddGua}
                        close={setIsModalAddGua}
                        dataGuarantor={guarantors} //ข้อมูลที่มีอยู่โยนไปหน้าเพิ่มคนค้ำ
                        getAddData={getAddData}
                        dataindex={index1}
                        indexedit={indexedit}
                        datafromtable={data}
                        checkGua={checkGua}
                        relationship={relationship}
                    />
                    : null
            }
            {
                isModalEditGua ?
                    <EditGua
                        open={isModalEditGua}
                        close={setIsModalEditGua}
                        sb={setSendB}
                        dataGuarantor={guarantors}
                        shootdata={dataedit} // 
                        fucnEdit={fucnEdit}
                        dataindex={index1}
                        datafromtable={data}
                        relationship={relationship}

                    />
                    : null
            }
        </Card >
    )
}

export default Guarantor