//เหลือบาร์โค้ด
// import React, { useRef, useState, useEffect } from "react";
import React from "react";
import { Row, Col } from "antd";
import { dayFormatOne, currencyFormatOne } from "../allFormat";
import { ArabicNumberToText } from "../thaibath";
import moment from "moment";
import "./css/mainPage.css";
import P1 from './Pic/P1.jpg';
import P2 from './Pic/P2.png';
import P3 from './Pic/P3.jpg';
import P4 from './Pic/P4.png';
import P5 from './Pic/P5.png';
function Payment({ dataPrint }) {
  // console.log("dataPrint :", dataPrint)
  const dataCompany = dataPrint?.company
  const dataDetail = dataPrint?.detail
  const dataPay = dataPrint?.pay
  //qrCode
  const QRCODE_URL = "https://asia-east2-fir-express-c1aec.cloudfunctions.net/aa/loans";
  const ref2 = dataDetail[0]?.CONTNOCUT;
  //ชำระผ่านธนาคาร  "0405559001925"
  //const url = `${QRCODE_URL}/qr/0405559001925/00/006/${ref2}/000`;
  //ชำระผ่านเค้าน์เตอร์เซอร์วิส  "0405552000435"
  const url = `${QRCODE_URL}/qr/0405552000435/00/006/${ref2}/000`;
  // const company = 'บริษัท วัน มันนี่ จำกัด (สำนักงานใหญ่)';
   //const companyAddress = '1/20-24 ถ.มิตรภาพ ต.ในเมือง อ.เมือง จ.ขอนแก่น 40000';
   //const companyTel = 'เบอร์โทร 043-239-888 และ 043-239-074';
  // const nameCus = 'นางสาวมิณตรา คำหงษา';
  console.log("dataCompany :", dataCompany)
  console.log("dataDetail :", dataDetail)
  console.log("dataPay :", dataPay)
  return (
    dataPrint ? (
      <div className="divA4">
        <div className="divEdge30">
          <Row>
            <Col span={12} className="colLeft">{dataCompany[0]?.COMP_NM}</Col>
            <Col span={12} className="colLeft" style={{ fontWeight: 'bold', fontSize: '20px' }}>ใบรับเงิน</Col>
            
            <Col span={18} className="colLeft">{dataCompany[0]?.COMP_ADR1}</Col>
            <Col span={6} className="colLeft">เลขที่ใบเสร็จ : {dataPay[0]?.TMBILL}</Col>

            <Col span={12} className="colLeft">{dataCompany[0]?.TELP}</Col>
            <Col span={6} className="colLeft" style={{ fontWeight: 'bold', fontSize: '20px', color: 'red' }}>{dataPay[0]?.FLAG === 'C' ? 'ยกเลิก' : ''}</Col>
            <Col span={6} className="colLeft">วันที่พิมพ์ : {moment(dataPay[0]?.PAYDT).format('DD/MM/YYYY')}</Col>

            <Col span={13} className="colLeft">ชื่อลูกค้า : {dataDetail[0]?.NAME}</Col>
            <Col span={11} className="colLeft">เลขที่สัญญา : {dataDetail[0]?.CONTNO}</Col>

            <Col span={13} className="colLeft">ที่อยู่ : {dataDetail[0]?.ADDRES} {dataDetail[0]?.SOI} {dataDetail[0]?.TUMB}</Col>
            <Col span={11} className="colLeft">ยี่ห้อ {dataDetail[0]?.TYPE} รุ่น {dataDetail[0]?.MODEL} สี {dataDetail[0]?.COLOR}</Col>

            <Col span={13} className="colLeft">{dataDetail[0]?.AUMP} {dataDetail[0]?.PROVDES} {dataDetail[0]?.ZIP} โทร.{dataDetail[0]?.TELP}</Col>
            <Col span={11} className="colLeft">เลขตัวถัง {dataDetail[0]?.STRNO} ทะเบียน {dataDetail[0]?.REGNO}</Col>

            <Col span={13} className="colLeft" style={{marginBottom:'12px' }}>ชำระโดย : {dataPay[0]?.PAYDESC}</Col>
            <Col span={11} className="colLeft">คงเหลือยกมา : {currencyFormatOne(dataPay[0]?.NCARCST)}</Col>

            <div className="table">
              <Row className="rowTable">
                <Col span={2} className="colTable">ลำดับ</Col>
                <Col span={4} className="colTable">ชำระค่า</Col>
                <Col span={3} className="colTable">หักเงินต้น</Col>
                <Col span={3} className="colTable">ดอกเบี้ย</Col>
                <Col span={3} className="colTable">ส่วนลด</Col>
                <Col span={3} className="colTable">ค่าทวงถาม</Col>
                <Col span={3} className="colTable">ลดค่าทวงถาม</Col>
                <Col span={3} className="colTable">รับสุทธิ</Col>
              </Row>
              <Row className="rowTable">
                <Col span={2} className="colTable" style={{ height: '100px' }}>1</Col>
                <Col span={4} className="colTable">{dataPay[0]?.FORDESC}</Col>
                <Col span={3} className="colTable">{currencyFormatOne(dataPay[0]?.PAYAMT_N)}</Col>
                <Col span={3} className="colTable">{currencyFormatOne(dataPay[0]?.PAYAMT_V)}</Col>
                <Col span={3} className="colTable">{currencyFormatOne(dataPay[0]?.DISCT)}</Col>
                <Col span={3} className="colTable">{currencyFormatOne(dataPay[0]?.INTAMT)}</Col>
                <Col span={3} className="colTable">{currencyFormatOne(dataPay[0]?.DIFINTAMT)}</Col>
                <Col span={3} className="colTable">{currencyFormatOne(dataPay[0]?.NETPAY)}</Col>
              </Row>
              <Row className="rowTable">
                <Col span={6} className="colTable" style={{ flex: 2 }}>จำนวนเงินเป็นตัวอักษร</Col>
                <Col span={9} className="colTable" style={{ flex: 3 }}>{ArabicNumberToText(dataPay[0]?.NETPAY)}</Col>
                <Col span={6} className="colTable">จำนวนเงิน</Col>
                <Col span={3} className="colTable">{currencyFormatOne(dataPay[0]?.NETPAY)}</Col>
              </Row>
            </div>
            <Col span={24} className="colLeft">ยอดเงินคงเหลือ : {currencyFormatOne(dataPay[0]?.Balance)}</Col>

            {/* <Col span={18} className="colLeft">ค้างชำระ 1.00 งวด เป็นเงิน 445.00 บาท จากงวดที่ 46.00 ถึงงวดที่ 46.00 ค้างดอกเบี้ยล่าช้า 0.00 บาท</Col> */}
            <Col span={18} className="colLeft"></Col>
            <Col span={6} className="colCenter">ผู้รับเงิน ____________________</Col>

            <Col span={18} className="colLeft" style={{marginBottom:'12px' }}>ใบเสร็จรับเงินฉบับนี้จะสมบูรณ์เมื่อปรากฎลายเซ็นของผู้รับเงินครบถ้วนและเช็คของท่านผ่านบัญชีบริษัทฯ เรียบร้อยแล้ว</Col>
            <Col span={6} className="colCenter">( ออกโดยระบบอัตโนมัติ )</Col>

            <Col span={24} className="colUnderline" />
            {/* ส่วนกลาง */}
            <Col span={13} className="colLeft">ใบนำฝากชำระเงินค่าสินค้าหรือบริการ (Bill Payment Pay-in-silp)</Col>
            <Col span={11} className="colRight">ส่วนของลูกค้า</Col>

            <Col span={13} className="colLeft">{dataCompany[0]?.COMP_NM}</Col>
            <Col span={11} className="colRight" style={{ marginBottom:'5px'}}>โปรดเรียกเก็บค่าธรรมเนียมจากผู้ชำระเงิน</Col>

            <Col span={12} className="colLeft">{dataCompany[0]?.COMP_ADR1} โทร.{dataCompany[0]?.TELP}</Col>
            <Col span={6} className="colTable">สาขา................................................</Col>
            <Col span={6} className="colTable">วันที่.................................................</Col>

            <Col span={12} className="colLeft" style={{ lineHeight: '16px',marginBottom:'12px'}}>
              <div className="box"></div><img src={P1} alt="" className="picA" />ธ.กสิกรไทย A/C No. (15/20)
              <br /><div className="box"></div><img src={P2} alt="" className="picA" />ธ.กรุงไทย A/C No. CODE : 6624 (15)
              <br /><div className="box"></div><img src={P3} alt="" className="picA" /> ธ. ไทยพานิชย์ A/C No. (Bill Payment) (15/20)
            </Col>

            <Col span={12} className="colTable" style={{ lineHeight: '16px',textAlign: 'Left' }}>&nbsp;ชื่อลูกค้า &nbsp;&nbsp;&nbsp; {dataDetail[0]?.NAME}
              <br />&nbsp;Customer No (Ref. No.1) &nbsp;&nbsp;&nbsp; {dataPay[0]?.PAYFOR}
              <br />&nbsp;Customer No (Ref. No.2) &nbsp;&nbsp;&nbsp; {dataDetail[0]?.CONTNOCUT}</Col>
            <Col span={24} className="colLeft" style={{lineHeight: '16px',marginBottom:'20px' }}>
              <div className="box"></div><img src={P4} alt="" className="picA" />เคาน์เตอร์เซอร์วิส
              <br /> <div className="box"></div><img src={P5} alt="" className="picA" />เทสโก้โลตัส และ เทสโก้โลตัส เอ็กซ์เพรส</Col>

            <Col span={9} className="colCenter">รับชำระเป็นเงินสดเท่านั้น</Col>
            <Col span={5} className="colTableB">จำนวนเงิน/Amount</Col>
            <Col span={5} className="colTableB"></Col>
            <Col span={5} className="colTableB">บาท/Baht</Col>

            <Col span={9} className="colTableB">จำนวนเงินเป็นตัวอักษร/Amount in Words</Col>
            <Col span={15} className="colTableB"></Col>


            <Col span={15} className="colLeft">ผู้นำฝาก / Deposit By ........................................</Col>
            <Col span={9} className="colTableB">สำหรับเจ้าหน้าที่ธนาคาร</Col>

            <Col span={15} className="colLeft">โทรศัพท์ / Telephone ........................................</Col>
            <Col span={9} className="colTableB">ผู้รับเงิน ................................................................................</Col>

            <Col span={24} className="colUnderline" />
            {/* ส่วนล่าง */}
            <Col span={13} className="colLeft">ใบนำฝากชำระเงินค่าสินค้าหรือบริการ (Bill Payment Pay-in-silp)</Col>
            <Col span={11} className="colRight">ส่วนของธนาคาร</Col>

            <Col span={13} className="colLeft">{dataCompany[0]?.COMP_NM}</Col>
            <Col span={11} className="colRight" style={{ marginBottom:'5px'}}>โปรดเรียกเก็บค่าธรรมเนียมจากผู้ชำระเงิน</Col>

            <Col span={12} className="colLeft">{dataCompany[0]?.COMP_ADR1} โทร.{dataCompany[0]?.TELP}</Col>
            <Col span={6} className="colTable">สาขา................................................</Col>
            <Col span={6} className="colTable">วันที่.................................................</Col>

            <Col span={12} className="colLeft" style={{ lineHeight: '16px'}}>
              <div className="box"></div><img src={P1} alt="" className="picA" />ธ.กสิกรไทย A/C No. (15/20)
              <br /><div className="box"></div><img src={P2} alt="" className="picA" />ธ.กรุงไทย A/C No. CODE : 6624 (15)
              <br /><div className="box"></div><img src={P3} alt="" className="picA" /> ธ. ไทยพานิชย์ A/C No. (Bill Payment) (15/20)
            </Col>

            <Col span={12} className="colTable" style={{ lineHeight: '16px',textAlign: 'Left' }}>&nbsp;ชื่อลูกค้า &nbsp;&nbsp;&nbsp; { }
              <br />&nbsp;Customer No (Ref. No.1) &nbsp;&nbsp;&nbsp; {dataPay[0]?.PAYFOR}
              <br />&nbsp;Customer No (Ref. No.2) &nbsp;&nbsp;&nbsp; {dataDetail[0]?.CONTNOCUT}</Col>
            <Col span={24} className="colLeft" style={{lineHeight: '16px',marginBottom:'20px'}}>
              <div className="box"></div><img src={P4} alt="" className="picA" />เคาน์เตอร์เซอร์วิส
              <br /> <div className="box"></div><img src={P5} alt="" className="picA" />เทสโก้โลตัส และ เทสโก้โลตัส เอ็กซ์เพรส</Col>

            <Col span={9} className="colCenter">รับชำระเป็นเงินสดเท่านั้น</Col>
            <Col span={5} className="colTableB">จำนวนเงิน/Amount</Col>
            <Col span={5} className="colTableB"></Col>
            <Col span={5} className="colTableB">บาท/Baht</Col>

            <Col span={9} className="colTableB">จำนวนเงินเป็นตัวอักษร/Amount in Words</Col>
            <Col span={15} className="colTableB"></Col>

            <Col span={9} className="colLeft">ผู้นำฝาก / Deposit By ........................................</Col>
            <Col span={6} className="colLeft"><img alt="" style={{ position: "absolute", zIndex: -1,  border: "2px solid black"}} width={100} src={url} /></Col>
            <Col span={9} className="colTableB">สำหรับเจ้าหน้าที่ธนาคาร</Col>

            <Col span={9} className="colLeft">โทรศัพท์ / Telephone ........................................</Col>
            <Col span={6} className="colLeft"></Col>
            <Col span={9} className="colTableB">ผู้รับเงิน ................................................................................</Col>
          
          </Row>
        </div>
      </div>
    ) : null
  );
}
export default Payment;
