import React, { useState, useEffect } from "react";
import { Button, Card, Col, Divider, Form, Input, Row, Space, Spin, Table, notification, Modal, Select } from 'antd'
import axios from 'axios';
// import dayjs from "dayjs";
// import ExcelJS from 'exceljs';
import { msError, msConnt, msData, msCancel } from "../file_mid/allFormat";
import { columnsCl } from "../loan_history/table/cm_cancel";
import ModelCancel from "./ModelCancel";
import { loadMainCancelpay } from "../file_mid/all_api";
import { optionsSearchPay } from "../file_mid/all_options";
import { loadDataFindPAYTYP } from "../loan_history/load_data_print/data_pay";

export default function Main() {
  // const token = localStorage.getItem('token')
  // const currentDateTime = dayjs()
  const { confirm } = Modal
  const [form] = Form.useForm();
  const [formKey, setFormKey] = useState(0);
  const [isClickedTy, setIsClickedTy] = useState(1); // เลือกค้นหาจาก
  const [findPAYTYP, setFindPAYTYP] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [dataSend, setDataSend] = useState({ CONTNO: "", textData: "", textData2: "" });
  const [dataTable, setDataTable] = useState([]);
  // const [arrayTable, setArrayTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalCancel, setIsModalCancel] = useState(false);
  const [dataDue, setDataDue] = useState();
  // useEffect(() => {
  // }, [])

  const handleSubmit = async () => {
    setFormKey(prevKey => prevKey + 1);
    console.log("dataSend?.CONTNO =", dataSend?.CONTNO)
    if (dataSend?.CONTNO !== "") {
      setLoading(true)
      // await axios.post("http://localhost:8070/api-db2/load-main-cancelpay", dataSend)
      await axios.post(loadMainCancelpay, dataSend)
        .then(async (res) => {
          if (res.status === 200) {
            setDataTable(res.data)
            // setArrayTable(res.data)
          } else {
            setDataTable([])
            // setArrayTable([])
            setDataDue()
            msConnt(api, "top")
          }
          console.log("res.data", res.data)
          setLoading(false)
        })
        .catch((err) => {
          console.log("err", err)
          setDataTable([])
          // setArrayTable([])
          setDataDue()
          msError(api, "top")
          setLoading(false)
        })
    } else if (isClickedTy === 2 || isClickedTy === 3 || isClickedTy === 4) {
      if (dataSend.textData !== "") {
        setLoading(true)
        try {
          const results = await loadDataFindPAYTYP(dataSend.textData, dataSend.textData2, isClickedTy);
          if (!results) {
            msData(api, "top")
            setFindPAYTYP([])
          } else {
            setFindPAYTYP(results)
          }
        } catch (error) {
          console.error("Error in loadData: ", error);
          msData(api, "top")
          setFindPAYTYP([])
        } finally {
          setLoading(false);
        }
      }
    } else {
      msConnt(api, "top")
    }
  }

  const handleSelectCONTNO = async (value) => {
    console.log(value)
    if (value) {
      setLoading(true);
      setTimeout(() => {
        setDataSend({ ...dataSend, CONTNO: value })
        setLoading(false);
      }, 1000);
    }
  }
  const handleCONTNO = (e, number) => {
    if (number === 31 || number === 32) {
      if (number === 31) {
        setDataSend({ ...dataSend, CONTNO: "", textData: e.target.value })
      } else {
        setDataSend({ ...dataSend, CONTNO: "", textData2: e.target.value })
      }
      setFindPAYTYP([])
      form.setFieldsValue({ CONTNO: "", })
    } else {
      if (isClickedTy === 1) {
        console.log("e.target.value", e.target.value)
        setDataSend({ ...dataSend, CONTNO: e.target.value, textData: "" })
      } else {
        console.log("e.target.value", e.target.value)
        // setLoading(true);
        // setTimeout(() => {
        setDataSend({ ...dataSend, CONTNO: "", textData: e.target.value })
        setFindPAYTYP([])
        //   setLoading(false);
        // }, 1000);
        form.setFieldsValue({ CONTNO: "", })

      }
    }
  }

  // const search = (data) => {
  //   const searchValue = data.target.value.toLowerCase();  // ทำให้ค่าใน input เป็นตัวพิมพ์เล็ก
  //   const greaterThanTen = arrayTable.filter(
  //     (item) =>
  //       item.CONTNO.toLowerCase().includes(searchValue) ||
  //       item.CUSCOD.toLowerCase().includes(searchValue) ||
  //       item.TMBILL.toLowerCase().includes(searchValue)
  //   );
  //   setDataTable(greaterThanTen)
  // };

  const handleSetData = (record) => {
    if (record) {
      console.log('Record :', record);
      setDataDue(record)
      setIsModalCancel(true)
    }
  };

  const handleCancel = () => {
    console.log('handleCancel');
    setIsModalCancel(false)
  };
  const handleCancelLoad = async () => {
    console.log('handleCancelLoad');
    setIsModalCancel(false)
    await handleSubmit()
  };

  const showConfirmClear = () => {
    confirm({
      title: 'คุณต้องการที่ล้างข้อมูลนี้ใช่หรือไม่ ?',
      content: 'กด OK เพื่อยืนยัน',
      onOk() {
        // setLoading(true)
        clearData(0)
      },
      onCancel() {
        msCancel(api, "top")
      },
    });
  }
  const clearData = async (value) => {
    setLoading(true);
    console.log("clearData", value)
    if (value > 0) {
      setIsClickedTy(value)
      form.setFieldsValue({ CONTNO: "", textData: "", textData2: "", searchPay: value })
    } else {
      setIsClickedTy(1)
      form.setFieldsValue({ CONTNO: "", textData: "", textData2: "", searchPay: 1 })
    }
    setTimeout(() => {
      setDataSend({ CONTNO: "", textData: "", textData2: "" });
      setDataTable([])
      setFindPAYTYP([])
      setDataDue()
      setLoading(false);
    }, 1000);
  }
  return (
    <Card style={{ height: '100%' }}>
      <Form
        form={form}
        key={formKey}
        onFinish={handleSubmit}
      >
        <Row style={{ textAlign: 'center' }}>
          <Col span={24}>
            <Spin spinning={loading} size='large' tip="Loading...">
              <Col span={24}>
                <div className='text-center'>
                  <h2>ยกเลิกใบเสร็จ</h2>
                </div>
              </Col>
              <Divider />
              <Row gutter={32} justify={'center'}>
                <Col span={12}>
                  <Card title="ค้นหา" bordered={false}>
                    {/* <Space> */}
                    <Form.Item label="ค้นหาจาก" name="searchPay">
                      <Select
                        style={{ width: '100%', height: '40px' }}
                        defaultValue={isClickedTy}
                        onChange={clearData}
                        options={optionsSearchPay}
                      />
                    </Form.Item>
                    {
                      isClickedTy === 1 ?
                        <Form.Item label="เลขสัญญา" name="CONTNO" style={{ height: '30px', width: '100%' }}>
                          <Input onChange={(e) => handleCONTNO(e, 0)}></Input>
                        </Form.Item>
                        :
                        <>
                          {
                            isClickedTy === 3 ?
                              <>
                                <Form.Item label="ชื่อ-นามสกุล" name="textData" style={{ height: '30px', width: '100%' }}
                                  rules={[{ required: true, message: 'กรุณากรอกชื่อ !' },]}>
                                  <Input onChange={(e) => handleCONTNO(e, 31)}></Input>
                                </Form.Item>
                                <Form.Item label="" name="snam" style={{ height: '30px', width: '100%' }}>
                                  <b style={{ fontSize: '14px', color: 'red' }}>{"***ไม่จำเป็นต้องใส่คำนำหน้าชื่อ***"}</b>
                                </Form.Item>
                                {/* <Form.Item label="นามสกุล" name="textData2" style={{ height: '30px', width: '100%' }}
                                  rules={[{ required: true, message: 'กรุณากรอกนามสกุล !' },]}>
                                  <Input onChange={(e) => handleCONTNO(e, 32)}></Input>
                                </Form.Item> */}
                              </>
                              :
                              <Form.Item label="ข้อความ" name="textData" style={{ height: '30px', width: '100%' }}>
                                <Input onChange={(e) => handleCONTNO(e, 0)}></Input>
                              </Form.Item>
                          }
                          {
                            findPAYTYP?.length > 0 ?
                              <Form.Item label="เลือกสัญญา" name="CONTNO">
                                <Select
                                  showSearch
                                  style={{ width: '100%', height: '40px' }}
                                  onChange={handleSelectCONTNO}
                                  optionFilterProp="children"
                                  placeholder="Search to Select"
                                  filterOption={(inputValue, option) =>
                                    option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                  }
                                >
                                  {findPAYTYP?.map((e) => {
                                    return (
                                      <Select.Option key={e.keyId} value={e.CONTNO}>
                                        สัญญา {e.CONTNO} (ทะเบียน {e.REGNO}) (ชื่อ {e.NAME1} {e.NAME2})
                                        {/* ({e.PAYDESC}) */}
                                      </Select.Option>
                                    )
                                  })}
                                </Select>
                              </Form.Item>
                              : null
                          }
                        </>
                    }
                    <Space>
                      <Form.Item label="" style={{ height: '30px', width: '100%' }}>
                        <Button disabled={loading} type='primary' htmlType="submit"  >ตกลง</Button>
                      </Form.Item>
                      <Form.Item label="" style={{ height: '30px', width: '100%' }}>
                        <Button disabled={loading} onClick={showConfirmClear} >Clear</Button>
                      </Form.Item>
                    </Space>
                    {/* </Space> */}
                    <Divider />
                  </Card>
                </Col>
                <Col span={12}>
                  <Card title="รายละเอียดสัญญา" bordered={false}>
                    <Row gutter={32} justify={"center"}>
                      <Col span={16}>
                        {
                          dataSend.CONTNO !== "" ?
                            <Form.Item label="เลขที่สัญญา" style={{ margin: 0 }}>
                              <b style={{ color: 'blue' }}>{dataSend?.CONTNO}</b>
                            </Form.Item>
                            : null
                        }
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Divider />
              {/* <Row gutter={32} style={{ margin: 10 }}>
                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                  <Input.Search
                    style={{ width: '250px' }}
                    placeholder="ค้นหาในตาราง...."
                    onChange={search}
                    name="search"
                    id="search"
                    size="large"
                  />
                </Col>
              </Row> */}
              <Table
                rowKey={(record) => record.uid}
                dataSource={dataTable}
                columns={columnsCl(handleSetData)}
                scroll={{
                  x: 1500,
                  y: 400,
                }}
                style={{ padding: 20 }}
              >
              </Table>
            </Spin>
          </Col>
        </Row >
      </Form >
      {contextHolder}
      {
        isModalCancel ?
          <ModelCancel
            open={isModalCancel}
            close={handleCancel}
            dataFromTable={dataDue}
            closeload={handleCancelLoad}
          />
          : null
      }
    </Card >
  )
}
